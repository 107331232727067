import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useRef, Reducer, useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Footer } from '../../Components/Footer';
import QRCode from "qrcode.react";
import Door from '../../Components/logo/door_orange.svg'
import { usePreRegBookingInfoQuery, useUpdateRoomAssignmentMutation } from "../../generated/graphql";
import Loading from "../../Components/Loading/Loading";

export const ConfirmCheckIn = ({
  RegistrationDetails
}) => {

  const navigate = useNavigate();
  const dateFormat = require("dateformat");
  let urlOrigin = new URL(window.location.href)?.origin

  const {
    loading: loadingInfo,
    data: { PreRegBookingInfo } = { PreRegBookingInfo: [] }
  } = usePreRegBookingInfoQuery({
    variables: {
      HotelID: RegistrationDetails?.Booking?.HotelID,
      BookingID: RegistrationDetails?.Booking?.ID,
      RegistrationID: RegistrationDetails?.ID,
      QR: 'true'
    },
    fetchPolicy: 'no-cache'
  })

  const [UpdateRoomAssign, 
    { loading : loadingUpdateRoomAssign }
  ] = useUpdateRoomAssignmentMutation({
    variables: {
      BookingID: RegistrationDetails?.Booking?.ID,
      RegistrationID: RegistrationDetails?.ID,
      IsCompleted: true
    },
    onCompleted: data => {
      window.location.href = `
        ${urlOrigin}/pre-checkin/form?Hotel=${RegistrationDetails?.Booking?.HotelID}&Booking=${RegistrationDetails?.Booking?.ID}`
    }
  })

  const currRegistration = PreRegBookingInfo?.result?.map(x => {
    const reg = x?.Registrations?.map(r => {
      if (r.ID === RegistrationDetails?.ID) {
        return r
      }
    })?.filter(i => i !== undefined)

    return reg
  })?.filter(i => i !== undefined && i?.length > 0)[0]

  const styleDownload = {
    link: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'blue',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };

  const QrCodeRef = useRef<HTMLDivElement>(null);

  // HotelID/BookingID/RegistrationID
  const qrUrl = `${RegistrationDetails?.Booking?.HotelID}/${RegistrationDetails?.Booking?.ID}/${RegistrationDetails?.ID}`

  const QRContent = (
    <div
      ref={QrCodeRef}
      className="content-container "
      style={{ justifyContent: 'center', marginTop: '10px'}}
    >
      <QRCode
        renderAs={'canvas'}
        value={qrUrl}
      />
    </div>
  )

  const handleDownloadClick = () => {
    const qrCodeElement = QrCodeRef.current;
    if (qrCodeElement) {
      const canvas = qrCodeElement.querySelector('canvas');
      if (canvas) {
        const desiredWidth = 600; // Specify the desired width for the resized QR code
        const scaleFactor = desiredWidth / canvas.width;
        const desiredHeight = canvas.height * scaleFactor;

        const borderWidth = 10; // Specify the width of the white border
        const canvasWidthWithBorder = desiredWidth + 2 * borderWidth;
        const canvasHeightWithBorder = desiredHeight + 2 * borderWidth;

        const tempCanvas = document.createElement('canvas');
        const tempContext = tempCanvas.getContext('2d') as CanvasRenderingContext2D;

        tempCanvas.width = canvasWidthWithBorder;
        tempCanvas.height = canvasHeightWithBorder;

        tempContext.fillStyle = 'white';
        tempContext.fillRect(0, 0, canvasWidthWithBorder, canvasHeightWithBorder);

        const x = borderWidth;
        const y = borderWidth;

        tempContext.drawImage(canvas, x, y, desiredWidth, desiredHeight);

        const downloadLink = document.createElement('a');
        downloadLink.href = tempCanvas.toDataURL('image/png');
        downloadLink.download = 'hotelx_qr.png';
        downloadLink.click();
      }
    }
  };

  return loadingInfo ? <Loading /> : (
    <>
      <Box>
        <Box width="100%" display="block" margin={'5px 0 10px 0'}>
          <Typography display="flex" justifyContent={"center"} fontSize="14px" fontWeight={600}>
            Congratulation Successful Pre Check-In
          </Typography>
          <Typography display="flex" justifyContent={"center"} fontSize="12px" fontWeight={500}>
            See you soon!
          </Typography>
        </Box>
        <Box width="100%" display="block">
          <Typography display="flex" fontSize="13px" fontWeight={600}>
            Registration Info
          </Typography>
          <Card
            sx={{
              margin: "5px 0 14px 0",
              borderRadius: "5px",
              boxShadow:
                "0 3px 1px -2px transparent, 2px 4px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
            }}
          >
            <CardContent
              sx={{ ":last-child": { p: "10px" } }}
            >
              <Box>
                <Box width="100%" display="flex">
                  <Box width="50%" display="flex">
                    <Typography fontSize="12px" align="left" >
                      Booking No.: {PreRegBookingInfo?.BookingNo}
                    </Typography>
                  </Box>
                  <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                    <Typography fontSize="12px" >
                      {dateFormat(currRegistration[0]?.ArrivalDate, "dd/mm/yyyy")} - 
                      {dateFormat(currRegistration[0]?.DepartureDate, "dd/mm/yyyy")}
                    </Typography>
                  </Box>
                </Box>
                <Box width="100%" display="flex">
                  <Typography fontSize="12px">
                  {currRegistration[0]?.Guest?.Contact?.FullName}
                  </Typography>
                </Box>
                <Box width="100%" display="flex">
                  <Box width="50%" display="flex">
                    <Typography fontSize="12px" style={{ alignItems: 'left', textDecoration: "underline" }}>
                      {currRegistration[0]?.Guest?.Contact?.Email}
                    </Typography>
                  </Box>
                  {currRegistration[0]?.RoomID ? (
                    <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                      <Typography fontSize="12px" fontWeight={600} style={{ display: 'flex', alignItems: 'center' }}>
                        <Box display="flex" alignItems="center" justifyContent="center" marginRight="4px">
                          <img
                            src={Door}
                            alt={`IMG`}
                            loading="lazy"
                            width="15px"
                          />
                        </Box>
                        {currRegistration[0]?.Room?.RoomNo}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box width="100%" display="block">
          <Typography display="flex" fontSize="13px" fontWeight={600}>
            My QR
          </Typography>
          <Card
            sx={{
              margin: "5px 0 14px 0",
              borderRadius: "5px",
              boxShadow:
                "0 3px 1px -2px transparent, 2px 4px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
            }}
          >
            <CardContent
              sx={{ ":last-child": { p: "10px" } }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                {QRContent}
              </Box>
              <Box width="100%" display="flex" sx={{ justifyContent: 'flex-end', paddingRight: '20px'}}>
                <Typography fontSize="10px" fontWeight={400} color="black" align="right">
                  <span style={styleDownload.link} onClick={handleDownloadClick}>
                    Download
                  </span>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box width="100%" display="flex">
          <Typography style={{ fontSize: '10px', textAlign: 'center' }}>
            *Please download '<strong>My QR</strong>' into your photo gallery as your guest identity. 
            For express check-in scan '<strong>My QR</strong>' at front desk upon your arrival.
          </Typography>
        </Box>
      </Box>
      <Footer
        primary={true}
        options={[
          {
            name: 'Completed',
            onClick: () => {
              localStorage.removeItem('SelectedRoom')
              UpdateRoomAssign()
            },
          },
        ]}
      />
    </>
  )
}