import React from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  EmailIcon,
  EmailShareButton,
} from 'react-share'
import Typography from "@mui/material/Typography";

interface Props {
  QRContentDialog: any;
  openQR: any;
  handleCloseQR: any;
  ShareTitle: any;
  URL: any;
  EmailShare: any;
  setSimpleEmail: any
}
const dateFormat = require('dateformat')

export const htmlConverter = ({ FormContent, Name }) => {
  var blob = new Blob([FormContent], { type: 'text/html' })
  blob['name'] = Name + '.html'
  blob['lastModified'] = Date.UTC(
    dateFormat(new Date(), 'yyyy'),
    dateFormat(new Date(), 'mm'),
    dateFormat(new Date(), 'dd')
  )
  blob['lastModifiedDate'] = new Date()

  return blob
}

export const QRDialog = ({
  QRContentDialog,
  openQR,
  handleCloseQR,
  ShareTitle,
  URL,
  EmailShare,
  setSimpleEmail
}: Props) => {
  return (
    <>
      <Dialog open={openQR}  onClose={handleCloseQR} fullWidth>
        <DialogTitle
          className="dialog-title"
          sx={{ fontWeight: "bold", color: "#ff9800", backgroundColor: "#fff5e7" }}
        >
          <Typography fontSize="15px" fontWeight={600}>
            QR Code
          </Typography>
          <Divider sx={{ backgroundColor: "#ffffff", height: '1px', width: '100%', borderBottom: 'none' }} />

        </DialogTitle>

        <DialogContent>
          <Card
            sx={{
              margin: "5px 0 14px 0",
              borderRadius: "5px",
              boxShadow:
                "0 3px 1px -2px transparent, 2px 4px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              {QRContentDialog}
            </Box>
          </Card>
          <Box display="flex" alignItems="center" justifyContent="center">
            <TelegramShareButton title={ShareTitle} url={URL}>
              <TelegramIcon
                style={{ marginRight: '5px' }}
                size={25}
                round={true}
              />
            </TelegramShareButton>
            <WhatsappShareButton title={ShareTitle} url={URL}>
              <WhatsappIcon
                style={{ marginRight: '5px' }}
                size={25}
                round={true}
              />
            </WhatsappShareButton>
            <FacebookShareButton title={ShareTitle} url={URL}>
              <FacebookIcon
                style={{ marginRight: '5px' }}
                size={25}
                round={true}
              />
            </FacebookShareButton>
            <EmailShareButton onClick={() => setSimpleEmail(true)} title={ShareTitle} url={URL}>
              <EmailIcon
                style={{ marginRight: '5px' }}
                size={25}
                round={true}
              />
            </EmailShareButton>
            <TwitterShareButton title={ShareTitle} url={URL}>
              <TwitterIcon
                style={{ marginRight: '5px' }}
                size={25}
                round={true}
              />
            </TwitterShareButton>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseQR}
            sx={{
              backgroundColor: "#ff9800",
              "&:hover": {
                backgroundColor: "#ff9800",
              },
              "&:active": {
                backgroundColor: "#ff9800",
              },
            }}
          >
            <Typography style={{ color: "#ffffff" }}>
              Cancel
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
