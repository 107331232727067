export const Receipt = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <!--[if gte mso 9]>
    <xml>
      <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <!--[if !mso]><!-->
      <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700|Playfair+Display:400,400i,700,700i|Raleway:400,400i,700,700i|Roboto:400,400i,700,700i" rel="stylesheet" />
      <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
    <!--<![endif]-->
    <title>Receipt</title>
    <!--[if gte mso 9]>
    <style type="text/css" media="all">
      @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
      sup { font-size: 100% !important; }
    </style>
    <![endif]-->
    <style type="text/css" media="screen">
      @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
      @media screen and (min-width: 600px) {
        .document-content {
          padding: 0 !important;
          justify-content: center !important;

        }
      }
      @media screen and (min-width: 992px) {
      .document-content {
          padding: 0 !important;
          justify-content: center !important;
          margin: 0 auto;
          max-width: 800px;
        }
      }  
      @media print {
        @page {
          size: A4;
        }
      }
      * {
          font-family: poppins;
          -webkit-print-color-adjust: exact;
          -webkit-text-size-adjust:none;
        }

      body  
      { 
        /* this affects the margin on the content before sending to printer */ 
        margin: 0px;  
      }
      .header-right {
        color: white !important;
      }

      #booking {
        border-collapse: collapse;
        width: 100%;
        margin-top: 20px;
      }

      #booking td,
      #booking th {
        border: 2px solid #2c2c2c;
        padding: 8px;
        font-size: 18px;
        text-align: center;
      }

      #booking tr:nth-child(even) {
        background-color: #2c2c2c;
        color: #fff;
      }

      #booking th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #2c2c2c;
        color: white;
        font-weight: 200;
        font-size: 18px;
        text-align: center;
      }

      #description th {
        border: 1px solid #ececec;
      }

      #description tr:nth-child(even) {
        background-color: #fff;
      }

      #description tr:nth-child(odd) {
        background-color: #fff;
      }

      #total {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
      }

      #total td,
      #total th {
        border: 2px solid #2c2c2c;
        padding: 8px;
        font-size: 18px;
      }

      #total tr:nth-child(even) {
        background-color: #2c2c2c;
        color: white;
      }

      #total tr:nth-child(odd) {
        background-color: #2c2c2c;
        color: white;
      }

      #total th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #2c2c2c;
        color: white;
        font-weight: 200;
        font-size: 18px;
      }

      #total tfoot tr th {
        background-color: #2c2c2c !important;
      }
    </style>
  </head>

  <body style="-webkit-text-size-adjust:none;  -webkit-print-color-adjust: exact;  ">
    <style type="text/css" media="print">
      @page 
      {
        size: A4;   /* auto is the initial value */
        margin: 0mm;  /* this affects the margin in the printer settings */
      }
    </style>
    <div class="document-content" style="background:#fff;">
      <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="padding:2px;">
        <thead>
          <tr>
            <td style="width:100%; display:flex; justify-content: center; margin-bottom: 12px;">
              <img src=!!HotelImage!! width="35%" style="width: 100%; max-width: 125px;" >
            </td>
          </tr>
          <tr>
            <td style="width:100%;  display: flex; justify-content: center; text-align: center;">
              <div>
                <div style="font-size: 12px; font-weight: 600; line-height: 1.5; margin-bottom: 12px;">
                  !!HotelName!!
                </div> 
                <div style="font-size: 12px; font-weight: 400; line-height: 1.5;  margin-bottom: 12px;">
                  (SST Reg No: !!SSTRegNo!!)
                </div>
                <div style="font-size: 14px; font-weight: 700; line-height: 1.5;">
                  !!Document!!
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style="width:100%;  display: flex;justify-content: end; ">
              <div>
                <div style="font-size: 12px; font-weight: 600; line-height: 1.5; margin-bottom: 12px;">
                  !!ReceiptNo!!
                </div> 
                <div style="font-size: 10px; font-weight: 400; line-height: 1.5;  margin-bottom: 12px;">
                  Date: !!Date!!
                </div>
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="report-content-cell">
              <div class="main">
                <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="padding:12px;">
                  <tr>
                    <td style="font-size:12px; font-weight: 300;">
                      Bill To
                    </td>
                  </tr>

                  <tr>
                    <td style="font-size:12px; font-weight: 600; color:#2c2c2c;">
                      !!GuestName!!
                    </td>
                  </tr>
                  <tr style="margin-bottom: 6px;">
                    <td style="font-size:10px; font-weight: 400; line-height: 18px; width:50%;">
                      !!AgentName!!
                    </td>
            
                    <td style="font-size:10px; font-weight: 400;   line-height: 18px; width:50%;">
                      IC : !!IdentityCard!!
                    </td>
                    <td >
                        
                    </td>
                  </tr>
                  <tr style="margin-bottom: 6px;">
                    <td style="font-size:10px; font-weight: 400;   line-height: 18px; width:50%;">
                      Company Name : !!BeneficiaryName!!
                    </td>
                    <td style="font-size:10px; font-weight: 400;  line-height: 18px; width:50%;">
                      Phone : !!GuestMobileNo!!
                    </td>
                  </tr>
              
                  <tr style="margin-bottom: 6px;">
                    <td style="font-size:10px; font-weight: 400;   line-height: 18px; width:50%;">
                      Address : !!GuestAddress!!
                    </td>
                
                    <td style="font-size:10px; font-weight: 400;    width:50%;">
                      Email : !!GuestEmail!!
                    </td>
                  </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="margin-bottom: 12px;" >
                  <tr 
                    class="" 
                    style="     
                      background-color: #ececec;
                      color: #2c2c2c;
                      font-size: 10px;
                      line-height: 28px;
                      text-align: center;"
                  >
                    <th style="font-weight: 500;">Arrival Date</th>
                    <th style="font-weight: 500;">Departure Date</th>
                    <th style="font-weight: 500;">Invoice No.</th>
                    <th style="font-weight: 500;">Booking No.</th>
                    <th style="font-weight: 500;">Cashier Name</th>
                  </tr>
                  <tr  
                    style="    background-color:#fff;
                      color: #2c2c2c;
                      font-size: 10px;
                      line-height: 28px;
                      text-align: center;"
                  >
                    <td><div style="line-height: 14px;">
                      !!ArrivalDate!!
                    </div>
                    <div style="color:#2c2c2c; font-size: 10px; line-height: 14px;">
                      !!ArrivalTime!!
                    </div>
                    </td>
                    <td><div style="line-height: 14px;">
                      !!DepartureDate!!
                    </div>
                    <div style="color:#2c2c2c; font-size: 10px; line-height: 14px;">
                      !!DepartureTime!!
                        </div>
                    </td>
                    <td>!!RoomNo!!</td>
                    <td>!!BookingNo!!</td>
                    <td>!!CashierName!!</td>
                  </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0" id="description"  >
                  <tr 
                    style="
                      background-color: #ececec;
                      color: #2c2c2c;
                      font-size: 10px;
                      line-height: 28px;
                      text-align: center;"
                  >
                    <th style="font-weight: 500;">Date</th>
                    <th style="font-weight: 500;">Description</th>
                    <th style="font-weight: 500;">Ref No.</th>
                    <th style="font-weight: 500;">Total </th>
                  </tr>
                    !!ListItem!!
                </table>
                
                <table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin-bottom: 12px;">
                  <tr class="" style="     
                    background-color: #ececec;
                    color: #2c2c2c;
                    font-size: 10px;
                    line-height: 28px;
                    text-align: center;"
                  ></tr>
                  <tr style="    
                    background-color:#fff;
                    color: #2c2c2c;
                    font-size: 10px;
                    line-height: 28px;
                    text-align: center;"
                  >
                    <tr class="room-detail" style="
                      color: #212121;
                      font-size: 10px;
                      line-height: 28px;
                      text-align: center;"
                    >
                      <td style="text-align: center; width: 20%;">
                      </td>
                      <td style="text-align: left; width: 40%;">
                      </td>
                      <td style="width: 20%;">
                        TOTAL
                      </td>
                      <td style="text-align: center; width: 20%;">
                        !!TotalAmount!!
                      </td>
                    </tr>
                  </tr>
                </table>
                
                <div style="width: 100%; display: flex;">
                  <div style="width: 50%;  margin: 4px; padding: 6px;">
                    <div style="display: flex;">
                        <img src="${require('../../Components/logo/Document/mobile.png')}" style=" width: 20px;  margin-right: 10px;">
                        <span style="vertical-align: -webkit-baseline-middle;font-size: 10px;">!!HotelMobileNo!!</span>
                    </div>
                    <br/>
                    
                    <div style="margin-bottom: 50px;">
                      <div style="display: flex;">
                        <img src="${require('../../Components/logo/Document/email.png')}" style=" width: 20px;  margin-right: 10px;">
                        <span style="vertical-align: -webkit-baseline-middle;font-size: 10px; white-space: unset;">!!HotelEmail!!</span>
                      </div>
                      <br/>
                      <div style="display: flex;">
                        <img src="${require('../../Components/logo/Document/website.png')}" style=" width: 20px;  margin-right: 10px;">
                        <span style="vertical-align: -webkit-baseline-middle;font-size: 10px; white-space: unset;">!!HotelWebsite!!</span>
                      </div>
                      <br/>
                      <div style="width:100%;">
                        <img src="${require('../../Components/logo/Document/location.png')}" style=" width: 20px; height: auto; float: left; margin-right: 10px;">
                        <div style="vertical-align: -webkit-baseline-middle;font-size: 10px;  white-space: unset;">
                          !!HotelAddress!!              
                        </div>
                      </div>
                      <br/>
                    </div>
                </div>
              </div>
              <div
                style="font-style: italic; font-weight: 500; color: #2c2c2c; font-size: 14px; text-align: center; margin-top: 20px;"
              >
                Thank you for Staying with us</div>
              <div>
                <table 
                  width="100%" border="0" cellspacing="0" cellpadding="0" 
                  style="margin-top: 30px; background-color: #ececec; padding:12px; height: 75px;"
                >
                  <tr>
                    <td style="font-size:10px; font-weight: 400; line-height: 20px; width:80%; color:#2c2c2c;">
                      This Receipt is computer-generated, no signature is required
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>  
        </tbody>
      </table>
    </div>
  </body>
</html>`