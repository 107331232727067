import React, { useEffect, useRef, useState } from "react"
import Card from "@mui/material/Card";
import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import QRCode from "qrcode.react";
import { TermsDialog } from "../DialogComponent/TermsDialog";
import { QRDialog, htmlConverter } from "../DialogComponent/QRDialog";
import PersonIcon from '@mui/icons-material/Person';
import Door from '../../Components/logo/door_orange.svg'
import PDF from '../../Components/logo/pdf.svg'
import { useEmailSendMutation, useImageUploadMutation, usePreCheckInQrQuery, useShowBookingConfirmationEmailQuery } from "../../generated/graphql";
import { b64toBlob } from "../../Components/Hooks/OCRhelper";
import ReactDOMServer from 'react-dom/server'
import { useSnackBar } from "../../Components/Hooks/useSnackBar";
import SnackBarMsg from "../../Components/SnackBar/SnackBarMsg";
import { useNavigate } from "react-router-dom";

interface Props {
  state: any;
  index: any;
  ExpandMore: any;
  expanded: any;
  handleExpandClick: any;
  HotelDetails: any;
  localState: any;
  URLHotelID: any;
  URLBookingID: any;
  SharerNo: any;
  BookingNo: any;
  IsOnlinePayment: any;
}

export const CheckInView = ({
  state,
  index,
  ExpandMore,
  expanded,
  handleExpandClick,
  HotelDetails,
  localState,
  URLHotelID,
  URLBookingID,
  SharerNo,
  BookingNo,
  IsOnlinePayment,
}: Props) => {


  const [openTerm, setOpenTerm] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const [simple, setSimple] = useState(false)
  const [imgUrl, setimgUrl] = useState(null as any)
  const [sendEmail, setSendEmail] = useState(false)
  const { snackFunc, snackBarMessage, openSnackBar, close } = useSnackBar()

  const [EmailSend, { loading: loadingSentEmail }] = useEmailSendMutation()
  const navigate = useNavigate();

  const [ImageUpload, { loading }] = useImageUploadMutation()

  var Title = `${HotelDetails?.HotelName
    } has shared you a QR Code please click `

  function generateUniqueCID() {
    const uniqueId = Date.now().toString(); // Use a timestamp as part of the identifier
    const randomPart = Math.random().toString(36).substring(2, 8); // Add a random part
    return `image-${uniqueId}-${randomPart}`;
  }
  const handleOpenTerm = () => {
    setOpenTerm(true);
  };
  const handleCloseTerm = () => {
    setOpenTerm(false);
  };
  const handleOpenQR = () => {
    setOpenQR(true);
  };
  const handleCloseQR = () => {
    setOpenQR(false);
  };

  const styles = {
    link: {
      fontSize: '12px',
      fontWeight: 400,
      color: "#ff9800",
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };

  const dateFormat = require("dateformat");

  const QrCodeRef = useRef<HTMLDivElement>(null);

  const CheckInList = localState?.Guest?.filter(x => x?.SharerNo === state?.SharerNo)

  const Registration = state?.Registrations?.filter(x => x?.SharerNo === SharerNo)[0]

  console.log(Registration, "ada");


  const QRContent = (
    <div
      ref={QrCodeRef}
      className="content-container"
      style={{ justifyContent: 'center', marginBottom: '12px' }}
    >
      <QRCode
        renderAs={'canvas'}
        size={40} // Adjust the size here to make the QR code smaller
        value={`${URLHotelID}/${URLBookingID}/${CheckInList[0]?.ContactInfo[0]?.Registration}`}
      />
    </div>
  );

  const QRContentDialog = (
    <div
      ref={QrCodeRef}
      className="content-container"
      style={{ justifyContent: 'center', marginBottom: '12px' }}
    >
      <QRCode
        renderAs={'canvas'}
        size={128} // Adjust the size here to make the QR code smaller
        value={`${URLHotelID}/${URLBookingID}/${CheckInList[0]?.ContactInfo[0]?.Registration}`}
      />
    </div>
  );

  const {
    data: { PreCheckInQr } = {
      PreCheckInQr: [],
    }
  } = usePreCheckInQrQuery({
    variables: {
      BookingID: URLBookingID,
      HotelID: URLHotelID,
      RegistrationID: CheckInList[0]?.ContactInfo[0]?.Registration
    },
    fetchPolicy: 'no-cache',
  })

  const QRContentShare = (uniqueCID) => (
    <div
      ref={QrCodeRef}
      className="content-container"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <a href={PreCheckInQr?.qrPath}
        target="_blank"
        data-saferedirecturl="#">
        {uniqueCID === "nonEmail" ? (
          <img
            src={PreCheckInQr?.qrCodeUrl}
            width="500px"
            height="500px"
          />
        ) : (
          <img
            src={`cid:${uniqueCID}`}
            width="300px"
            height="300px"
          />
        )}
      </a>
    </div>
  );

  const onShareSubmit = () => {

    const QRContentString = ReactDOMServer.renderToStaticMarkup(QRContentShare('nonEmail'));

    var contentType = 'text/html'
    var encodedStringBtoA = btoa(QRContentString)
    const blob = b64toBlob(encodedStringBtoA, contentType, 512)
    blob['name'] = 'QRCode' + '.html'
    blob['lastModified'] = Date.UTC(
      dateFormat(new Date(), 'yyyy'),
      dateFormat(new Date(), 'mm'),
      dateFormat(new Date(), 'dd')
    )
    blob['lastModifiedDate'] = new Date()

    ImageUpload({
      variables: {
        AttachmentInput: blob,
      },
    }).then(i => {
      setSimple(true)
      if (i.data !== undefined && i.data !== null) { setimgUrl(i.data.ImageUpload.ImageURL) }
    })
  }

  useEffect(() => {
    const uniqueCID = generateUniqueCID();

    const QRContentString = ReactDOMServer.renderToStaticMarkup(QRContentShare(uniqueCID));

    sendEmail &&
      EmailSend({
        variables: {
          Body: {
            Header: `QR Code`,
            Body: QRContentString,
          },
          HotelID: URLHotelID,
          Email: Registration?.Guest?.Contact?.Email || '',
          BookingID: URLBookingID,
          isGuestEmail: false,
          Attachments: {
            filename: 'image.jpg',
            path: PreCheckInQr?.qrCodeUrl,
            cid: uniqueCID,
          },
        },
      }).then(w => {
        if (w.data !== undefined && w.data !== null) {
          if (w.data.EmailSend === true) {
            snackFunc('Sent Email Successfully!', false)
            setSimple(false)
          } else {
            snackFunc('Sent Email Fail!', false)
          }
        }
      })
  }, [sendEmail])

  const Room = Registration?.Room?.RoomNo === undefined || Registration?.Room?.RoomNo === null ? 'Room ' + SharerNo : Registration?.Room?.RoomNo

  return (
    <>

      {CheckInList?.map((x: any, i: any) => {
        return (
          <div key={x.RoomType}>
            <Card
              sx={{
                mb: "14px",
                borderRadius: "4px",
                boxShadow:
                  "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
              }}
            >
              <CardActions key={x.SharerNo} disableSpacing style={{ backgroundColor: "#fff4dc" }}>
                <Box width="100%">
                  <Box width="100%" display="flex" >
                    <Box width="100%" display="flex">
                      <Box display="flex" alignItems="center" justifyContent="center" marginBottom="20px">
                        <img
                          src={Door}
                          alt={`IMG`}
                          loading="lazy"
                          width="15px"
                        />
                      </Box>
                      <Typography fontSize="14px" fontWeight={600} color='#ff9800' align="left">
                        {Room + " - " + x.RoomType}
                      </Typography>
                    </Box>
                    {/* <div> */}
                    <Box key={x?.SharerNo} display="flex" sx={{ justifyContent: 'flex-end' }}>
                      <IconButton style={{ pointerEvents: 'none' }}>
                        <PersonIcon sx={{ fontSize: 20, color: '#ff9800' }} />
                        <Typography fontSize="14px" fontWeight={600} color="#ff9800" >
                          {x?.ContactInfo?.length}
                        </Typography>
                      </IconButton>

                    </Box>
                    <ExpandMore
                      expand={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded}
                      onClick={(SharerNo) => handleExpandClick(x.SharerNo)}
                      aria-expanded={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded}
                      aria-label="show more"
                      sx={{ fontSize: 20, color: "black", margin: "0" }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Box>

                  <Box width="100%" display="flex">
                    <Typography fontSize="14px" fontWeight={500} color="black" align="left" marginTop="-15px" >
                      {dateFormat(x.ContactInfo[0]?.ArrivalDate, "dd mmm yyyy")}  -  {dateFormat(x.ContactInfo[0]?.DepartureDate, "dd mmm yyyy")}                  </Typography>
                  </Box>

                  <Box width="100%" display="flex">
                    <Typography fontSize="14px" fontWeight={500} color="black" align="left" >
                      {x?.ContactInfo[0]?.FullName}
                    </Typography>
                  </Box>
                </Box>
              </CardActions>
              <Collapse key={index} in={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded} timeout="auto" unmountOnExit>
                <CardContent>
                  {x?.ContactInfo?.map((k: any, index2: number) => {
                    return (
                      <Box key={index2}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',

                        }}
                      >
                        <Grid container spacing={0}>
                          <Grid item xs={11}>
                            <Typography fontSize="12px" fontWeight={600} color="black">
                              Guest Name
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>

                            <Box
                              display="flex"
                              justifyContent="center"
                              style={{
                                width: '50%',
                                height: '1px',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setimgUrl(null)
                                onShareSubmit()
                                handleOpenQR();

                              }}
                            >
                              {QRContent}
                            </Box>

                            <QRDialog QRContentDialog={QRContentDialog} openQR={openQR} handleCloseQR={handleCloseQR} URL={imgUrl} ShareTitle={Title} EmailShare={k?.Email ? true : false} setSimpleEmail={setSendEmail} />

                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {k?.FullName}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}  >
                              Email
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {k?.Email}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}>
                              Mobile No.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {k?.MobileNo}
                            </Typography>
                          </Grid>

                          {IsOnlinePayment === true && (
                            <>
                              <Grid item xs={12}>
                                <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"} marginBottom={"7px"}>
                                  Bill history
                                </Typography>
                              </Grid>
                              <Grid item xs={3} >
                                <Card
                                  sx={{
                                    paddingTop: "7px",
                                    paddingBottom: "7px",
                                    boxShadow:
                                      "0 3px 1px -2px transparent, 2px 4px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
                                  }}
                                >
                                  <img
                                    src={PDF}
                                    className="img-preview"
                                    loading="lazy"
                                    alt="img"
                                    style={{ width: 50, display: "block", margin: "0 auto" }} 
                                    onClick={() => {
                                      navigate('/pre-checkin/view/receipt', {state: {
                                        HotelDetails: HotelDetails,
                                        BookingID: URLBookingID,
                                        BookingNo: BookingNo,
                                        RoomNo: state?.Registrations?.filter(
                                          x => x.IsPrincipal === true)[0]?.Room?.RoomNo,
                                        AdvancePaymentID: state?.Registrations?.filter(
                                          x => x.IsPrincipal === true)[0]?.AdvancePayment?.filter(
                                            adv => adv.PaymentStatus === 'Paid')[0]?.ID,
                                        RegistrationsBySharerNo: x,
                                        GuestInfo: k
                                      }})
                                    }}
                                  />
                                </Card>
                                <Typography fontSize="12px" fontWeight={400} color="black" marginTop={"10px"} textAlign="center" >
                                  Receipt.pdf
                                </Typography>
                              </Grid>
                            </>
                          )}
                          {/* <Grid item xs={1} >
                          </Grid>
                          <Grid item xs={3} >
                            <Card
                              sx={{
                                paddingTop: "7px",
                                paddingBottom: "7px",
                                boxShadow:
                                  "0 3px 1px -2px transparent, 2px 4px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
                              }}
                            >
                              <img
                                src={PDF}
                                className="img-preview"
                                loading="lazy"
                                alt="img"
                                style={{ width: 50, display: "block", margin: "0 auto" }}
                                onClick={() => {

                                }} />
                            </Card>
                            <Typography fontSize="12px" fontWeight={400} color="black" marginTop={"10px"} textAlign="center" >
                              Invoice.pdf
                            </Typography>
                          </Grid> */}
                          <Grid item xs={12} paddingBottom={"10px"}>
                            <FormControlLabel
                              control={<Checkbox name="checkedB"
                                color="primary"
                                checked={true}
                                readOnly />}
                              label={<Typography fontSize="12px" fontWeight={400} color="black" align="left" >
                                I acknowledge and accept the hotel <span style={styles.link} onClick={handleOpenTerm}

                                >Terms and Condition</span>
                              </Typography>}

                            />
                            <TermsDialog 
                              HotelDetails={HotelDetails} 
                              openTerm={openTerm} 
                              handleCloseTerm={handleCloseTerm}
                              type={"T&C"}
                            />

                          </Grid>
                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}  >
                              E-Signature
                            </Typography>
                          </Grid>
                          <Grid item xs={12} marginBottom={"20px"}>
                            <Box
                              sx={{
                                width: 320, height: 100,
                                border: '3px solid', borderColor: 'rgb(220,220,220)', borderRadius: '15px'
                              }}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <img
                                src={Registration?.Gallery?.find(y => y?.ID === k?.SignatureUrl)?.ImageURL}
                                alt={`IMG`}
                                loading="lazy"
                                style={{ objectFit: 'cover' }}

                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  }
                  )}
                </CardContent>
              </Collapse>


            </Card>
            <SnackBarMsg
              open={openSnackBar}
              message={snackBarMessage}
              onAction={close}
            />
          </div>
        )
      })}
    </>
  );

}