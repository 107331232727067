import BigNumber from 'bignumber.js'

export interface IValidatorOverload {
  (v: string): boolean
  (v: number): boolean
  (v: object): boolean
  (v: Array<any>): boolean
  (v: any): boolean
}

export const throwOverloadValueTypeNotFoundErr = (v: any) => {
  throw new Error(`There's no overload for the value type: ${v}`)
}

export const isString: IValidatorOverload = (v: any) =>
  typeof v === 'string' ? true : false

export const isNumber: IValidatorOverload = (v: any) => {
  switch (true) {
    case isString(v):
      return !/[\D]/g.test(v)
    case typeof v === 'number':
      return true
    default:
      return false
  }
}

export const isBigNumber: IValidatorOverload & { (v: BigNumber): boolean } = (
  v: any
) => (v instanceof BigNumber ? true : false)

export interface IAmtStr {
  (v: BigNumber, prefix?: string, suffix?: string): string
  (
    v: BigNumber,
    prefix?: string,
    suffix?: string,
    format?: BigNumber.Format
  ): string
  (v: string, prefix?: string, suffix?: string): string
  (
    v: string,
    prefix?: string,
    suffix?: string,
    format?: BigNumber.Format
  ): string
  (v: number, prefix?: string, suffix?: string): string
  (
    v: string,
    prefix?: string,
    suffix?: string,
    format?: BigNumber.Format
  ): string
}

export const amtStr: IAmtStr = (
  v: any = '0.00',
  prefix: string = '',
  suffix: string = '',
  format: BigNumber.Format = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    suffix,
  }
) => {
  if (!v) v = 0
  let result: string = ''

  switch (true) {
    case isBigNumber(v):
      result = (<BigNumber>v).toFormat(2, format)
      break
    case isString(v):
      ;(<string>v).replace(',', '')
      result = new BigNumber(<string>v || 0).toFormat(2, format)
      break
    case isNumber(v):
      result = new BigNumber(<number>v || 0).toFormat(2, format)
      break
    default:
      throwOverloadValueTypeNotFoundErr(v)
  }

  return result
}