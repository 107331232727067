import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import React, { Reducer, useCallback, useContext, useEffect, useReducer, useState } from "react";
import Loading from "../Components/Loading/Loading";
import { MainWrapper } from "../Pages/MainWrapper";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ChooseRoom } from './FormComponent/ChooseRoom';
import { Payment } from './FormComponent/Payment';
import { AssignRoom } from './FormComponent/AssignRoom';
import { ConfirmCheckIn } from './FormComponent/ConfirmCheckIn';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {
  usePreRegRegistrationDetailsQuery,
  useUpdateRoomAssignmentMutation,
  useAdvancePaymentUpdateMutation
} from "../generated/graphql";
import { useLocation, useNavigate } from "react-router-dom"
import { SocketContext } from "../Pages/Socket/SocketContext";

const StepIconConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ff9800',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ff9800',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#d3d3d3',
    borderRadius: 1,
  },
}));

export const PreCheckInFormV2 = () => {

  const navigate = useNavigate();
  const location = useLocation()
  const localstate: any = location.state;
  const [activeStep, setActiveStep] = useState(1)
  const [assignRoom, setAssignRoom] = useState(false)
  const [roomSelect, setRoomSelect] = useState(null)
  let url = new URL(window.location.href)
  let urlOrigin = new URL(window.location.href)?.origin
  const queryParameters = new URLSearchParams(window.location.search);
  let successUrl = url?.search?.includes("SUCCESS")
  let cancelledUrl = url?.search?.includes("CANCELLED")
  const status = queryParameters.get("status") as string
  const orderID = queryParameters.get("orderId") as string
  const IsOnlinePayment = JSON.parse(localStorage.getItem('IsOnlinePayment') as any)

  const {
    loading: RegistrationDetailsLoadings,
    data: { PreRegRegistrationDetails } = { PreRegRegistrationDetails: null },
  } = usePreRegRegistrationDetailsQuery({
    variables: {
      HotelID: localstate 
        ? localstate?.HotelID 
        : JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.Booking?.HotelID,
      BookingID: localstate 
        ? localstate?.BookingID
        : JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.Booking?.ID,
      RegistrationID: localstate 
        ? localstate?.RegistrationID
        : JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.ID
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      localStorage.setItem('RegistrationDetails', JSON.stringify(data.PreRegRegistrationDetails))
    }
  })

  const [UpdateRoomAssign, 
    { loading : loadingUpdateRoomAssign }
  ] = useUpdateRoomAssignmentMutation({})

  const [AdvancePaymentUpdate, 
    { loading : loadingAdvancePaymentUpdate }
  ] = useAdvancePaymentUpdateMutation({})

  const stepLabel = ['Pre CheckIn', 'Choose Room', 'Payment', 'Confirm']
  const stepLabel2 = ['Pre CheckIn', 'Confirm']
  const StepIcon = (props) => {
    const { active, completed, className } = props;
    return (
      <>
        {completed ? (
          <CheckCircleIcon style={{ fontSize: '20px' ,color: '#ff9800' }}/>
        ) : active ? (
          <CheckCircleOutlineIcon style={{ fontSize: '20px', color: '#ff9800' }}/>
        ) : (
          <CheckCircleOutlineIcon style={{ fontSize: '20px', color: '#d3d3d3' }}/>
        )}
      </>
    )
  }

  useEffect(() => {
    if (status !== null) {
      AdvancePaymentUpdate({
        variables: {
          Status: status.toLowerCase(),
          OrderID: orderID,
          CheckOutID: JSON.stringify(localStorage.getItem('CheckOutID')),
          HotelID: JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.Booking?.HotelID,
          ReceiptNo: localStorage.getItem('ReceiptNo'),
          AppName: "PreCheckIn"
        }
      }).then(i => {
        if (status !== "SUCCESS") {
          UpdateRoomAssign({
            variables: {
              BookingID: JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.Booking?.ID,
              RegistrationID: JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.ID,
              RoomID: '',
              RoomTypeID: JSON.parse(localStorage.getItem('SelectedRoom') as any)?.RoomTypeID ||
                JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.ID
            }
          })
          setActiveStep(2)
        } else {
          setActiveStep(3)
        }
      })
    }
  }, [status])

  const stepper = (
    <Box display="flex">
      <Stack sx={{ width: '100%' }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<StepIconConnector/>}
        >
          { (localstate?.IsOnlinePayment === true || IsOnlinePayment === true) ? stepLabel?.map(label => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepIcon}>
                  <Typography fontSize="12px" style={{ marginTop: '-10px', lineHeight: '1.2' }}>
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            )) : stepLabel2?.map(label => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepIcon}>
                  <Typography fontSize="12px" style={{ marginTop: '-10px', lineHeight: '1.2' }}>
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))
          }
        </Stepper>
      </Stack>
    </Box>
  )

  const stepperContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            {(localstate?.IsOnlinePayment === true || IsOnlinePayment === true) && 
              assignRoom === false ? 
              (
                <ChooseRoom
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  assignRoom={assignRoom}
                  setAssignRoom={setAssignRoom}
                  RegistrationDetails={PreRegRegistrationDetails}
                />
              ): (localstate?.IsOnlinePayment === true || IsOnlinePayment === true) && 
                assignRoom === true ? (
                <AssignRoom
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  roomSelect={roomSelect}
                  setRoomSelect={setRoomSelect}
                  RegistrationDetails={PreRegRegistrationDetails}
                  UpdateRoomAssign={UpdateRoomAssign}
                />
              ) : (
                <ConfirmCheckIn
                  RegistrationDetails={PreRegRegistrationDetails}
                />
              )
            }
          </>
        )
      case 2:
        return (
          <Payment
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            RegistrationDetails={PreRegRegistrationDetails}
            roomSelect={roomSelect}
          />
        )
      case 3:
        return (
          <ConfirmCheckIn
            RegistrationDetails={PreRegRegistrationDetails}
          />
        )
    }
  }

  return (
    RegistrationDetailsLoadings ||
    loadingUpdateRoomAssign ||
    loadingAdvancePaymentUpdate
  ) ? <Loading /> : (
    <>
      <MainWrapper>
        <Box
          sx={{ p: 2 , background: 'transparent'}}
          width={"800px"}
          margin="auto"
          bgcolor={"#F7FAF7"}
          display="flex"
          flexDirection="column"
          minHeight="90vh"
        >
          {(localstate?.IsOnlinePayment === true || IsOnlinePayment === true) && activeStep !== 3 && (
            <Box display="flex" marginBottom={'15px'} alignItems={'center'}>
              <IconButton 
                style={{ padding: '0px', color: 'black'}}
                onClick={() => {
                  if (activeStep === 1 && assignRoom === true) {
                    UpdateRoomAssign({
                      variables: {
                        BookingID: JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.Booking?.ID,
                        RegistrationID: JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.ID,
                        RoomID: '',
                        RoomTypeID: JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.RoomTypeID,
                        IsCompleted: false
                      }
                    })
                    localStorage.removeItem('SelectedRoom')
                    setAssignRoom(false)
                  } else if (activeStep === 2 && cancelledUrl) {
                    setActiveStep(activeStep - 1)
                    navigate('/pre-checkin/form/room')
                  } else if (activeStep !== 1) {
                    setActiveStep(activeStep - 1)
                  } else if (activeStep === 1) {
                    window.location.href = `
                      ${urlOrigin}/pre-checkin/form?Hotel=${
                        localstate 
                          ? localstate?.HotelID 
                          : JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.Booking?.HotelID
                      }&Booking=${
                        localstate 
                          ? localstate?.BookingID
                          : JSON.parse(localStorage.getItem('RegistrationDetails') as any)?.Booking?.ID
                      }`
                  }
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Typography fontSize="15px" fontWeight={600} paddingLeft={'5px'}>
                {PreRegRegistrationDetails?.Guest?.Contact?.FullName}
              </Typography>
            </Box>
          )}
          <Card
            sx={{
              padding: '0px',
              margin: "0 0 14px 0",
              borderRadius: "5px",
              boxShadow:
                "0 3px 1px -2px transparent, 2px 4px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
            }}
          >
            <CardContent
              sx={{ ":last-child": { p: "10px" } }}
              style={{ padding: '7px'}}
            >
              {stepper}
            </CardContent>
          </Card>
          {stepperContent()}
        </Box>
      </MainWrapper>
    </>
  )
}