import { Button, Typography, makeStyles } from '@mui/material'
import React from 'react'
interface IFooterProps {
  options?: any
  chipLabel?: string
  value?: string | number
  single?: Boolean
  isHidden?: boolean
  totalAmt?: boolean
  extraNote?: string
  primary?: boolean
  input?: boolean
  inputData?: string 
}

export const Footer = (props: IFooterProps) => {
  const { 
    chipLabel, 
    options, 
    value, 
    isHidden, 
    totalAmt, 
    extraNote, 
    primary, 
    input, 
    inputData 
  } = props
  
  let single: boolean = false

  if (options?.length === 1) {
    single = true
  }

  const inputStyle = {
    display: 'flex', 
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingRight: '10px'
  }

  return (
    <div className="footer">
      {chipLabel && (
        <div className="footer-label">
          <div className="chip-label">{chipLabel}</div>

          <span className="label-value">{value}</span>
        </div>
      )}
      {isHidden ? null : (
        <div className="footer-btn" 
          style={ input ? inputStyle : {
            // display: 'flex',
            // justifyContent: 'center'
          }}
        >
          {input ? (
            <>
              <div style={{ display: 'block' }}>
                <Typography display="flex" width="100%" fontSize="12px" fontWeight={500}>
                  Selected Room:
                </Typography>
                <Typography display="flex" width="100%" fontSize="14px" fontWeight={600}>
                  {inputData}
                </Typography>
              </div>
              <div>
                {options.map((el: any, index: any) => (
                <Button
                  key={index}
                  type="submit"
                  sx={{
                    backgroundColor: "#ff9800",
                    "&:hover": {
                      backgroundColor: "#ff9800", // Keep the same background color on hover
                    },
                    "&:active": {
                      backgroundColor: "#ff9800", // Keep the same background color when clicked
                    }, color: "#ffffff"
                  }}
                  style={{ 
                    minWidth: options.length > 2 ? '50px' : '126px', 
                    color: '#ffffff',
                    backgroundColor: !el?.disabled && '#ff9800',
                    padding: '6px 16px',
                    borderRadius: '4px'
                  }}
                  variant="contained"
                  className={single ? 'single' : ''}
                  onClick={el.onClick}
                  disabled={el.disabled}
                  {...el.props}
                >
                  {el.name}
                </Button>
              ))}
              </div>
            </>
          ):(
            <>
              {options.map((el: any, index: any) => (
                <Button
                  key={index}
                  type="submit"
                  sx={{
                    backgroundColor: "#ff9800",
                    "&:hover": {
                      backgroundColor: "#ff9800", // Keep the same background color on hover
                    },
                    "&:active": {
                      backgroundColor: "#ff9800", // Keep the same background color when clicked
                    }, color: "#ffffff"
                  }}
                  style={{ 
                    minWidth: options.length > 2 ? '50px' : '126px', 
                    color: '#ffffff',
                    backgroundColor: !el?.disabled && '#ff9800',
                    padding: '6px 16px',
                    borderRadius: '4px'
                  }}
                  variant="contained"
                  className={single ? 'single' : ''}
                  onClick={el.onClick}
                  disabled={el.disabled}
                  {...el.props}
                >
                  {el.name}
                </Button>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  )
}
