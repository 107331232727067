import { FabricContext } from './FabricContext'
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { fabric } from 'fabric'
import { MapInteractionCSS } from 'react-map-interaction'

interface Props {
  jsonData?: any
  width?: any
  height?: any
  state?: any
  dispatch?: any
}

export const PreRegFloorPlan = (props: Props) => {

  const {
    jsonData = null, width = 816, height = 800,
    state,
    dispatch
  } = props

  const [value, setValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  })

  let text, group, circle
  let polygonMode = false

  // control
  const drawCondition = () => {
    let condition;
    condition = 
      (activeObject !== null &&
        state?.selectedRoom?.filter(x => x?.IsSelected === true)?.length !== 1 &&
       activeObject?.data?.FloorPlan?.filter(x => x?.IsPreCheckin === true && x?.IsHotel === true)[0]?.Coordinates !== "0,0,101,0,101,101,0,101"
      ) || (
        activeObject !== null &&
        state?.selectedRoom?.filter(x => x?.IsSelected === true)?.length === 1 &&
        activeObject?.data?.IsSelected === true && 
        activeObject?.data?.FloorPlan?.filter(x => x?.IsPreCheckin === true && x?.IsHotel === true)[0]?.Coordinates !== "0,0,101,0,101,101,0,101"
      )
    
    return condition
  }
  // --------------------- //

  // canvas
  const canvasEl = useRef(null)

  const {
    canvas,
    initCanvas,
    activeObject,
    setActiveObject,
    loadFromJSON,
  }: any = useContext(FabricContext)

  useLayoutEffect(() => {
    if (jsonData) {
      loadFromJSON(canvasEl.current, jsonData)
    } else {
      initCanvas(canvasEl.current, {
        width: width,
        height: height,
      })
    }
  }, [canvasEl, initCanvas, loadFromJSON, jsonData, height, width])

  const updateActiveObject = useCallback(
    e => {
      if (!e) {
        return
      }
      setActiveObject(canvas.getActiveObject())
      canvas.renderAll()
    },
    [canvas, setActiveObject]
  )

  useEffect(() => {
    if (!canvas) {
      return
    }
    canvas.on('selection:created', updateActiveObject)
    canvas.on('selection:updated', updateActiveObject)
    canvas.on('selection:cleared', updateActiveObject)

    return () => {
      canvas.off('selection:created')
      canvas.off('selection:cleared')
      canvas.off('selection:updated')
    }
  }, [canvas, updateActiveObject])
  // --------------------- //

  // --- draw useEffect --- //
  const [cWidth, setCWidth] = useState(0)
  const [cHeight, setCHeight] = useState(0)
  const image = new Image()
  image.src = state?.selectedFloor?.ImageURL
  image.onload = () => {
    setCHeight(image.naturalHeight)
    setCWidth(image.naturalWidth)
  }

  useEffect(() => {
    canvas?.clear()
    canvas?.setDimensions({ width: cWidth, height: cHeight })
    fabric.Image.fromURL(state?.selectedFloor?.ImageURL, img => {
      canvas?.setBackgroundImage(img, canvas.renderAll.bind(canvas))
    })

    polygonMode = false

    console.log('first', state?.selectedFloor)
    state?.selectedFloor?.Room?.forEach(el => {
      if (el?.ID) {
        Draw(el)
      }
      
    })
  }, [state?.selectedFloor?.Room, state?.selectedFloor?.ImageURL, cWidth, cHeight])

  useEffect(() => {
    if (drawCondition()) {
      selected()
      canvas?.remove(...canvas?.getObjects())
      state?.selectedRoom?.forEach(el => {
        Draw(el)
      })
    }
  }, [activeObject, state?.selectedFloor?.ImageURL, state?.selectedRoom?.filter(x => x?.IsSelected === true)?.length])

  // distance formula
  const DistanceFormula = (x1, x2, y1, y2) => {
    const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))

    return distance
  }
  // --------------------- //

  // draw
  const Draw = room => {
    let isUserDraw: boolean = false
    let isSquareOrRectangle: boolean = true

    let polygonCoordinate: any = []
    let polygon: any

    if (room && room?.FloorPlan?.filter(x => x?.IsPreCheckin === true && x?.IsHotel === false)[0]?.Coordinates) {
      var splitCoord = room?.FloorPlan?.filter(x => x?.IsPreCheckin === true && x?.IsHotel === false)[0]?.Coordinates?.split(',')

      if (splitCoord?.length === 8) {
        let topLine = DistanceFormula(
          splitCoord[2],
          splitCoord[0],
          splitCoord[3],
          splitCoord[1]
        )
        let rightLine = DistanceFormula(
          splitCoord[4],
          splitCoord[2],
          splitCoord[5],
          splitCoord[3]
        )
        let bottomLine = DistanceFormula(
          splitCoord[6],
          splitCoord[4],
          splitCoord[7],
          splitCoord[5]
        )
        let leftLine = DistanceFormula(
          splitCoord[0],
          splitCoord[6],
          splitCoord[1],
          splitCoord[7]
        )

        if (
          topLine === rightLine &&
          topLine === bottomLine &&
          topLine === leftLine
        ) {
          // square
          isSquareOrRectangle = true
          isUserDraw = false
        } else if (topLine === bottomLine && rightLine === leftLine) {
          isSquareOrRectangle = true
          isUserDraw = false
        } else {
          isSquareOrRectangle = false
          isUserDraw = true
        }
      } else {
        isSquareOrRectangle = false
        isUserDraw = true
      }

      for (var a = 0; a < splitCoord?.length; a += 2) {
        if (a != splitCoord?.length - 1) {
          splitCoord[a].replace(/\s/g, '') // remove space
          splitCoord[a + 1].replace(/\s/g, '') // remove space

          polygonCoordinate?.push(
            new fabric.Point(
              parseFloat(splitCoord[a]),
              parseFloat(splitCoord[a + 1])
            )
          )

          circle = new fabric.Circle({
            radius: 50,
            fill:
            room?.IsSelected === false
              ? state?.selectedRoom?.filter(x => x?.IsSelected === true)?.length === 1
                ? '#dcdcdc'
                : '#ff9800'
              : '#00bf0f',
            top: polygonCoordinate[0]?.y,
            left: polygonCoordinate[0]?.x
          });

        }
      }
    } else {
      circle = new fabric.Circle({
        radius: 50,
        fill:
          room?.IsSelected === false
            ? state?.selectedRoom?.filter(x => x?.IsSelected === true)?.length === 1
              ? '#dcdcdc'
              : '#ff9800'
            : '#00bf0f',
        top: polygonCoordinate[0]?.y,
        left: polygonCoordinate[0]?.x
      });
    }

    let cloneGroup = new fabric.Group([circle], {})

    text = new fabric.Text(room?.RoomNo, {
      fontFamily: 'Poppins',
      fontSize: 12,
      lineHeight: 14,
      fontWeight: 700,
      fill: room?.IsSelected === false ? 'black' : 'white',
      textAlign: 'center',
      top: cloneGroup?.aCoords?.tl?.y + 40,
      left: cloneGroup?.aCoords?.tl?.x + 40,
    })

    group = new fabric.Group([text, cloneGroup], {
      transparentCorners: false,
      hasBorders: false,
      hasRotatingPoint: true,
      lockScalingFlip: state?.selectedFloor?.ImageURL !== undefined ? true : false,
      newCoord: polygonCoordinate,
      isUserDraw: isUserDraw,
      hasControls: false,
      lockMovementX: state?.selectedFloor?.ImageURL !== undefined ? false : true,
      lockMovementY: state?.selectedFloor?.ImageURL !== undefined ? false : true,
      data: room,
      objectCaching: false,
    })

    text.bringToFront()
    canvas?.add(group).renderAll()
  }
  // --------------------- //

  // select
  const selected = () => {
    const newArr: any = state?.selectedRoom
    let selected = activeObject?.data
    selected.IsSelected = !selected.IsSelected
    dispatch({
      type: 'selectedRoom',
      payload: newArr
    })
    dispatch({
      type: 'choosenRoom',
      payload: selected.IsSelected === false ? "" : activeObject?.data
    })
  }

  return (
    <>
      <MapInteractionCSS
        value={value}
        onChange={value => {setValue(value)}}
        maxScale={5}
      >
        <canvas ref={canvasEl} id="fabric-canvas" />
      </MapInteractionCSS>
    </>
  )
}