import { Box, Button, InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import Loading from '../Components/Loading/Loading'
import {
  useHotelDetailsQuery,
  useIsBookingExistLazyQuery
} from '../generated/graphql'
import { MainWrapper } from '../Pages/MainWrapper'
import { useSnackBar } from '../Components/Hooks/useSnackBar'
import SnackBarMsg from '../Components/SnackBar/SnackBarMsg'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from "react-router-dom";

export default function HotelPreCheckInForm () {
  const queryParameters = new URLSearchParams(window.location.search)
  let navigate = useNavigate()

  const URLHotelID = queryParameters.get('Hotel') as string

  const {
    loading: HotelLoadings,
    data: { HotelDetails } = { HotelDetails: null }
  } = useHotelDetailsQuery({
    variables: {
      HotelID: URLHotelID
    },
    fetchPolicy: 'no-cache'
  })
  console.log(HotelDetails, 'HotelDetails')

  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()

  const [IsBookingno, setIsBookingno] = useState({
    BookingNo: ''
  })

  const [
    loadBookingExist,
    {
      loading: BookingExistLoading,
      data: { IsBookingExist } = { IsBookingExist: [] },
    }
  ] = useIsBookingExistLazyQuery({
    fetchPolicy: 'no-cache',
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        snackFunc(
          'We are unable to find your booking. Please ensure your booking number is correct.',
          false
        )
      })
    },
    onCompleted: data => {
      navigate(data.IsBookingExist.FormUrl)
      console.log('first', data.IsBookingExist.FormUrl)
    }
  })
  console.log(IsBookingExist, 'exist')

  const [searchBar, setSearch] = useState(false)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent default form submission
    }
  }

  return HotelLoadings || BookingExistLoading ? (
    <Loading />
  ) : (
    <>
      <MainWrapper>
        <Box
          sx={{ p: 2 }}
          justifyContent='center'
          alignItems='center'
          width={'800px'}
          margin='auto'
          bgcolor={'#F7FAF7'}
        >
          {' '}
          {/* HEADER */}
          <Grid container spacing={2}>
            <Grid item xs={16}>
              <Box
                display='flex'
                mb='12px'
                alignItems='center'
                justifyContent='center'
              >
                <Box
                  display='flex'
                  mb='12px'
                  alignItems='center'
                  justifyContent='center'
                >
                  <img
                    src={HotelDetails?.LogoImage?.ImageURL as any}
                    alt={`IMG`}
                    loading='lazy'
                    width='180px'
                  />
                </Box>
              </Box>
              <Typography
                fontSize='17px'
                fontWeight={600}
                mb='8px'
                align='center'
              >
                {HotelDetails?.HotelName}
              </Typography>
              <Typography
                fontSize='13px'
                fontWeight={500}
                mb='12px'
                color='black'
                align='center'
              >
                Search a booking to Pre-Checkin
              </Typography>
              <Typography
                fontSize='14px'
                fontWeight={600}
                mb='8px'
                align='center'
              >
                Enter your booking number/Confirmation number.
              </Typography>
            </Grid>
          </Grid>
          <Box
            component='form'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '15vh'
            }}
            onKeyDown={handleKeyDown} // Attach key down handler to form
          >
            <TextField
              required
              label='Booking Number'
              value={IsBookingno?.BookingNo}
              onChange={e => setIsBookingno({ BookingNo: e.target.value })}
              variant='standard'
              InputLabelProps={{
                style: { paddingLeft: '7px' }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      aria-label='close'
                      onClick={() => setIsBookingno({ BookingNo: '' })}
                      className={`close-search ${searchBar ? null : 'hide'}`}
                      sx={{ paddingRight: '12px' }}
                    >
                      <CloseIcon
                        style={{ fontSize: '17px', marginBottom: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                padding: '8px'
              }}
            />
          </Box>
          <Box
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              disabled={IsBookingno?.BookingNo === ''}
              sx={{
                backgroundColor:
                  IsBookingno?.BookingNo === '' ? '#D3D3D3' : '#ff9800',
                '&:hover': {
                  backgroundColor: '#ff9800'
                },
                '&:active': {
                  backgroundColor: '#ff9800'
                },

                paddingLeft: '40px',
                paddingRight: '40px'
              }}
              onClick={() => {
                loadBookingExist({
                  variables: {
                    BookingNo: IsBookingno?.BookingNo?.replace(/\s+/g, ''),
                    HotelID: URLHotelID
                  }
                })
                setIsBookingno(IsBookingno)
              }}
            >
              <Typography style={{ color: '#ffffff' }}>Search</Typography>
            </Button>
          </Box>
          <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
        </Box>
      </MainWrapper>
    </>
  )
}
