import {
  Box,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { Reducer, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Footer } from '../../Components/Footer';
import { useIsRoomExistQuery } from "../../generated/graphql";
import Loading from "../../Components/Loading/Loading";

export const ChooseRoom = ({
  activeStep,
  setActiveStep,
  assignRoom,
  setAssignRoom,
  RegistrationDetails
}) => {

  let urlOrigin = new URL(window.location.href)?.origin

  const {
    loading: IsRoomExistLoadings,
    data: { IsRoomExist } = { IsRoomExist: null },
  } = useIsRoomExistQuery({
    variables: {
      HotelID: RegistrationDetails?.Booking?.HotelID,
      ArrivalDate: RegistrationDetails?.ArrivalDate,
      RoomTypeID: RegistrationDetails?.RoomTypeID
    },
    fetchPolicy: 'no-cache',
  })

  return IsRoomExistLoadings ? <Loading/> : (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            style={{ minWidth: '220px' }}
            sx={{
              backgroundColor: "#ff9800",
              "&:hover": {
                backgroundColor: "#ff9800",
              },
              "&:active": {
                backgroundColor: "#ff9800",
              }, 
              color: "#ffffff",
              fontSize: '15px'
            }}
            onClick={() => { setAssignRoom(true) }}
            disabled= {IsRoomExist === false}
          >
            Choose Room
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={'15px 0 0 0'}
        >
          <Typography 
            onClick={() => {setActiveStep(activeStep + 1)}}
            fontSize="14px" fontWeight={300} color={'#ff9800'}
          >
            Proceed without choosing Room
          </Typography>
          <KeyboardArrowRightIcon sx={{ fontSize: '16px', color: '#ff9800' }}/>
        </Box>
      </Box>
      <Footer
        primary={true}
        options={[
          {
            name: 'Back',
            onClick: () => {
              if (activeStep !== 1) {
                setActiveStep(activeStep - 1)
              } else {
                window.location.href = `
                  ${urlOrigin}/pre-checkin/form?Hotel=${
                    RegistrationDetails?.Booking?.HotelID
                  }&Booking=${
                    RegistrationDetails?.Booking?.ID
                  }`
              }
            },
          },
        ]}
      />
    </>
  )
}