import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import Typography from "@mui/material/Typography";
interface Props {
  HotelDetails: any;
  openTerm: any;
  handleCloseTerm: any;
  type: any;
}

export const TermsDialog = ({
  HotelDetails,
  openTerm,
  handleCloseTerm,
  type,
}: Props) => {

  return (
    <>

      <Dialog open={openTerm} onClose={handleCloseTerm} fullWidth>
        <DialogTitle sx={{ fontWeight: "bold", color: "#ff9800", backgroundColor: "#fff5e7" }}>
          <Typography fontSize="15px" fontWeight={600}>
            {type === "T&C" ? "Terms and Condition" : "PDPA"}
          </Typography>
          <Divider sx={{ backgroundColor: "#ffffff", height: '1px', width: '100%', borderBottom: 'none' }} />
        </DialogTitle>
        <DialogContent>
          {type === "T&C" ? (
            HotelDetails?.RegCardTnC 
              ? ( <div dangerouslySetInnerHTML={{ __html: HotelDetails.RegCardTnC }} />) 
              : null
          ):(
            HotelDetails?.PDPA 
              ? (<div dangerouslySetInnerHTML={{ __html: HotelDetails.PDPA }} />) 
              : null
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseTerm}
            sx={{
              backgroundColor: "#ff9800",
              "&:hover": {
                backgroundColor: "#ff9800",
              },
              "&:active": {
                backgroundColor: "#ff9800",
              },
            }}
          >  <Typography style={{ color: "#ffffff" }}>
              Close
            </Typography>

          </Button>
        </DialogActions>
      </Dialog>


    </>
  );

}