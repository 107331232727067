import './App.css';
import '../src/app.scss'
import Main from './Pages/Main';
import { Routes, Route } from "react-router-dom"
import { Theme } from "./Theme/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { BookingForm } from "./Pages/BookingForm";
import { PreCheckInForm } from "./Pages/PreCheckInForm";
import { ApolloLink, ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client'
// import { Theme } from '@mui/material';
// import { useLocation } from 'react-router-dom';
// import { ApolloClient } from 'apollo-client'
// import { onError } from 'apollo-link-error'
// import { HttpLink } from 'apollo-link-http'
// import { TokenRefreshLink } from 'apollo-link-token-refresh';
// import { getAccessToken, setAccessToken } from './AccessToken';
// import { access } from 'fs/promises';
// import jwtDecode from 'jwt-decode';
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';
import BookingInfo from './Pages/BookingInfo';
import RegistrationInfo from './Pages/RegistrationInfo';
import MissingBookingInfo from './Pages/MissingBookingInfo';
import PreCheckIn from './PreCheckIn/PreCheckIn';
import { PreCheckInFormV2 } from './PreCheckIn/PreCheckInForm';
import { PreCheckInDocument } from './PreCheckIn/PreCheckInDocument';
import { SocketContextProvider } from './Pages/Socket/SocketContext';
import HotelPreCheckInForm  from './HotelPreCheckIn/HotelPreCheckInForm';
function App() {

  let hotelxNodeUrl
  // export let hotelxNodeUrl: any
  switch (process.env.REACT_APP_API_URL) {
    case 'imperial-build':
      hotelxNodeUrl = 'https://hotelxapi-imperial.hotelx.asia/hotelx'
      break
    case 'dev-build':
      hotelxNodeUrl = 'https://hotelx-dev-api.ifca.io/hotelx'
      break
    case 'prod-build':
      hotelxNodeUrl = 'https://hotelxapi.hotelx.asia/hotelx'
      break
    case 'promenade-build':
      hotelxNodeUrl = 'https://hotelxapi-promenade.hotelx.asia/hotelx'
      break
    case 'uat-build':
      hotelxNodeUrl = 'https://hotelx-uat-api.ifca.io/hotelx'
      break
    case 'uat-promenade-build':
        hotelxNodeUrl = 'https://hotelxapi-promenade-uat.hotelx.asia/hotelx'
      break
    default:
      hotelxNodeUrl = 'http://localhost:8000/hotelx'
      break
  }
  const UploadLink = createUploadLink({
    uri: hotelxNodeUrl,
    credentials: "include",
  });

  const Client = new ApolloClient({
    uri: hotelxNodeUrl,
    cache: new InMemoryCache(),
    link: ApolloLink.from([UploadLink as any])
  });

  return (
    <ThemeProvider theme={Theme}>
      <ApolloProvider client={Client}>
        <CssBaseline />
        <SocketContextProvider>
        <Routes>
          <Route path="/pre-checkin" element={<Main />} />
          <Route path="/pre-checkin/view" element={<BookingInfo />} />
          <Route path="/Pre-Registration-Form" element={<BookingForm />} />
          <Route path="/pre-checkin-form" element={<PreCheckInForm />} />
          <Route path="/pre-checkin-form/view" element={<RegistrationInfo />} />
          <Route path="/missing-booking-info" element={<MissingBookingInfo />} />
          <Route path="/pre-checkin/form" element={<PreCheckIn />} />
          <Route path="/pre-checkin/form/room" element={<PreCheckInFormV2 />} />
          <Route path="/pre-checkin/form/room/:status" element={<PreCheckInFormV2 />} />
          <Route path="/pre-checkin/view/invoice" element={<PreCheckInDocument type={'Invoice'} />} />
          <Route path="/pre-checkin/view/receipt" element={<PreCheckInDocument type={'Receipt'} />} />
          <Route path="/pre-checkin/hotel" element={<HotelPreCheckInForm />} />
        </Routes>
        </SocketContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}
export default App;