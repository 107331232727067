import {
  Box,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { Reducer, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router";
import { Footer } from '../../Components/Footer';
import {
  useBillSummaryDetailsQuery,
  useOnlinePaymentMutation,
  usePreRegPaymentInsertMutation,
} from "../../generated/graphql";
import SnackBarMsg from "../../Components/SnackBar/SnackBarMsg";
import { useSnackBar } from "../../Components/Hooks/useSnackBar";
import Loading from "../../Components/Loading/Loading";
import { differenceInDays } from 'date-fns'
import { amtStr } from '../../Components/helper/numFormatter'


export let PaymentUrl = ''
switch (process.env.REACT_APP_API_URL) {
  case 'dev-build':
    PaymentUrl = 'https://hotelx-dev.ifca.io'
    break
  case 'prod-build':
    PaymentUrl = 'https://pre-reg-checkin.hotelx.asia'
    break
  case 'hms1-prod-build':
    PaymentUrl = 'https://pre-reg-checkin.hotelx.asia'
    break
  case 'hms2-prod-build':
    PaymentUrl = 'https://pre-reg-checkin.hotelx.asia'
    break
  case 'hms3-prod-build':
    PaymentUrl = 'https://pre-reg-checkin.hotelx.asia'
    break
  case 'promenade-build':
    PaymentUrl = 'https://pre-reg-checkin-promenade.hotelx.asia'
    break
  case 'promenade-uat-build':
    PaymentUrl = 'https://pre-reg-checkin-promenade-uat.hotelx.asia'
    break
  case 'uat-build':
    PaymentUrl = 'https://pre-reg-checkin-uat.hotelx.asia'
    break
  default:
    PaymentUrl = 'http://localhost:3302'
    break
}

export const Payment = ({
  activeStep,
  setActiveStep,
  RegistrationDetails,
  roomSelect
}) => {
  const dateFormat = require("dateformat");
  const selectedRoomID = JSON.parse(localStorage.getItem('SelectedRoom') as any)?.ID
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()

  const {
    loading: BillSummaryDetailsLoadings,
    data: { BillSummaryDetails } = { BillSummaryDetails: null },
  } = useBillSummaryDetailsQuery({
    variables: {
      BillSummaryInput: {
        HotelID: RegistrationDetails?.Booking?.HotelID,
        RegistrationID: RegistrationDetails?.ID,
        ArrivalDate: RegistrationDetails?.ArrivalDate,
        DepartureDate: RegistrationDetails?.DepartureDate,
        IsChooseRoom: selectedRoomID ? true : false,
        RoomID: selectedRoomID ? JSON.parse(localStorage.getItem('SelectedRoom') as any)?.ID : undefined
      }
    },
    fetchPolicy: 'no-cache',
  })

  const [OnlinePayment, 
    { loading : loadingOnlinePayment }
  ] = useOnlinePaymentMutation()

  const [PreRegPaymentInsert, 
    { loading: loadingPreRegPaymentInsert }
  ] = usePreRegPaymentInsertMutation({
    variables: {
      HotelID: RegistrationDetails?.Booking?.HotelID,
      BookingID: RegistrationDetails?.Booking?.ID,
      RegistrationID: RegistrationDetails?.ID,
      Amount: `${BillSummaryDetails?.TotalAmount}`,
      RoomID: JSON.parse(localStorage.getItem('SelectedRoom') as any)?.ID
        ? JSON.parse(localStorage.getItem('SelectedRoom') as any)?.ID
        : undefined,
      RoomTypeID: JSON.parse(localStorage.getItem('SelectedRoom') as any)?.ID
        ? JSON.parse(localStorage.getItem('SelectedRoom') as any)?.RoomTypeID
        : undefined
    },
    onCompleted: data => {
      if (data?.PreRegPaymentInsert?.ReceiptNo) {
        localStorage.setItem('ReceiptNo', data?.PreRegPaymentInsert?.ReceiptNo)
        OnlinePayment({
          variables: {
            redirectUrl: `${PaymentUrl}/pre-checkin/form/room/:status`,
            PayAmount: Number(BillSummaryDetails?.TotalAmount),
            Detail: 'Detail',
            HotelID: RegistrationDetails?.Booking?.HotelID,
            ReceiptNo: data.PreRegPaymentInsert?.ReceiptNo,
            AdvancePaymentID: data?.PreRegPaymentInsert?.AdvPaymentID,
            Title: 'Room Payment',
          }
        }).then(i => {
          if (i.data?.OnlinePayment?.code === 'SUCCESS') {
            localStorage.setItem('CheckOutID', i.data.OnlinePayment.item!.checkoutId!)
            localStorage.setItem('RegistrationDetails', JSON.stringify(RegistrationDetails))
            window.location.href = i.data?.OnlinePayment?.item?.url!
          } else {
            i.data?.OnlinePayment?.error?.code !== ''
              ? snackFunc(i.data?.OnlinePayment?.error?.message!, false)
              : snackFunc('Insert Fail', false)
          }
        });
      }
    }
  })

  let NoOfDays = differenceInDays(
    new Date(dateFormat(RegistrationDetails?.DepartureDate, 'yyyy-mm-dd')),
    new Date(dateFormat(RegistrationDetails?.ArrivalDate, 'yyyy-mm-dd'))
  )

  return (
    BillSummaryDetailsLoadings ||
    loadingPreRegPaymentInsert ||
    loadingOnlinePayment 
  ) ? <Loading/> : (
    <>
      <Box>
        <Card
            sx={{
              borderRadius: "5px",
              boxShadow:
                "0 3px 1px -2px transparent, 2px 4px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
            }}
          >
            <CardContent
              sx={{ ":last-child": { p: "10px" } }}
            >
              <Box width="100%" display="flex"
                style={{ borderBottom: '1px solid #ccc', marginBottom: '5px' }}
              >
                <Typography fontSize="14px" fontWeight={600} mb={'5px'}>
                  Bill Summary
                </Typography>
              </Box>
              <Box width="100%" display="flex">
                <Box width="50%" display="flex">
                  <Typography fontSize="12px" fontWeight={600} align="left" >
                    Room Charge - {NoOfDays} Night
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="12px" fontWeight={600}>
                    {amtStr(BillSummaryDetails?.BaseAmount)}
                  </Typography>
                </Box>
              </Box>
              <Box width="100%" display="flex">
                <Typography fontSize="10px" mb={'5px'}>
                  {dateFormat(RegistrationDetails?.ArrivalDate, "dd/mm/yyyy")} - 
                  {dateFormat(RegistrationDetails?.DepartureDate, "dd/mm/yyyy")}
                </Typography>
              </Box>
              <Box width="100%" display="flex">
                <Box width="50%" display="flex">
                  <Typography fontSize="12px" fontWeight={600} align="left" >
                    Preferred Room
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="12px" fontWeight={600}>
                    {amtStr(BillSummaryDetails?.PreferredRoomCharge)}
                  </Typography>
                </Box>
              </Box>
              <Box width="100%" display="flex">
                <Typography fontSize="10px" mb={'5px'}>
                  {dateFormat(RegistrationDetails?.ArrivalDate, "dd/mm/yyyy")}
                </Typography>
              </Box>
              <Box width="100%" display="flex"
                style={{ borderBottom: '1px solid #ccc', marginBottom: '5px' }}
              >
                <Box width="50%" display="flex" style={{ marginBottom: '5px' }}>
                  <Typography fontSize="12px" fontWeight={600} align="left" >
                    Service Tax
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="12px" fontWeight={600}>
                  {amtStr(Number(BillSummaryDetails?.TaxAmount) + 
                    Number(BillSummaryDetails?.ServiceCharge))}
                  </Typography>
                </Box>
              </Box>
              <Box width="100%" display="flex"
                style={{ borderBottom: '1px solid #ccc', marginBottom: '5px' }}
              >
                <Box width="50%" display="flex" style={{ marginBottom: '5px' }}>
                  <Typography fontSize="12px" fontWeight={600} align="left" >
                    Total
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="12px" fontWeight={600}>
                  {amtStr(BillSummaryDetails?.TotalAmount)}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
      </Box>
      <Footer
        primary={true}
        options={[
          {
            name: 'Payment',
            onClick: () => {
              PreRegPaymentInsert()
            },
          },
        ]}
      />
      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
    </>
  )
}