import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  MyCustomScalar: any;
  Upload: any;
};

export type ArAccountOsLastPayment = {
  __typename?: 'ARAccountOSLastPayment';
  LastPaymentDT?: Maybe<Scalars['DateTime']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
};

export type ArAccountSummary = {
  __typename?: 'ARAccountSummary';
  CNSum?: Maybe<Scalars['Float']>;
  DNSum?: Maybe<Scalars['Float']>;
  InvSum?: Maybe<Scalars['Float']>;
  ORSum?: Maybe<Scalars['Float']>;
  RefundSum?: Maybe<Scalars['Float']>;
};

export type ArAgingAnalysisRespose = {
  __typename?: 'ARAgingAnalysisRespose';
  day30: Scalars['Float'];
  day60: Scalars['Float'];
  day90: Scalars['Float'];
  day120: Scalars['Float'];
  day150: Scalars['Float'];
  day180: Scalars['Float'];
  daygt180: Scalars['Float'];
};

export type ArSpecification = {
  __typename?: 'ARSpecification';
  Age1?: Maybe<Scalars['Float']>;
  Age2?: Maybe<Scalars['Float']>;
  Age3?: Maybe<Scalars['Float']>;
  Age4?: Maybe<Scalars['Float']>;
  FinalReminder?: Maybe<Scalars['Float']>;
  InterestAccount?: Maybe<Scalars['String']>;
  InterestTrx?: Maybe<Scalars['String']>;
  Reminder1?: Maybe<Scalars['Float']>;
  Reminder2?: Maybe<Scalars['Float']>;
  Reminder3?: Maybe<Scalars['Float']>;
  Reminder4?: Maybe<Scalars['Float']>;
};

export type ArSpecificationInput = {
  Age1?: InputMaybe<Scalars['Float']>;
  Age2?: InputMaybe<Scalars['Float']>;
  Age3?: InputMaybe<Scalars['Float']>;
  Age4?: InputMaybe<Scalars['Float']>;
  FinalReminder?: InputMaybe<Scalars['Float']>;
  InterestAccount?: InputMaybe<Scalars['String']>;
  InterestTrx?: InputMaybe<Scalars['String']>;
  Reminder1?: InputMaybe<Scalars['Float']>;
  Reminder2?: InputMaybe<Scalars['Float']>;
  Reminder3?: InputMaybe<Scalars['Float']>;
  Reminder4?: InputMaybe<Scalars['Float']>;
};

export type ArSummaryInfo = {
  __typename?: 'ARSummaryInfo';
  LastCreatedDT?: Maybe<Scalars['DateTime']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
  noOfDocument?: Maybe<Scalars['Float']>;
};

export enum ArTransactionStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
  Retention = 'RETENTION',
  Submit = 'SUBMIT'
}

export type AccountMappingGlInput = {
  AdvancePayment?: InputMaybe<Array<GlInput>>;
  Charges?: InputMaybe<Array<GlInput>>;
  Deposit?: InputMaybe<Array<GlInput>>;
  OfficialReceipt?: InputMaybe<Array<GlInput>>;
  Payment?: InputMaybe<Array<GlInput>>;
  Refund?: InputMaybe<Array<GlInput>>;
};

export type AccountMappingResponse = {
  __typename?: 'AccountMappingResponse';
  Description?: Maybe<Scalars['String']>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
};

export type AccountMappingTaxGlInput = {
  TaxGL?: InputMaybe<Array<TaxGlInput>>;
};

export type ActionLogEntity = InterfaceBase & {
  __typename?: 'ActionLogEntity';
  AccountID: Scalars['String'];
  ActionName: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IPAddress?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  LoginDateTime: Scalars['DateTime'];
  LogoutDateTime?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
  SoftwareName?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
};

export type ActionLogInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  ActionName?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IPAddress?: InputMaybe<Scalars['String']>;
  LoginDateTime?: InputMaybe<Scalars['DateTime']>;
  LogoutDateTime?: InputMaybe<Scalars['DateTime']>;
  SocketID?: InputMaybe<Scalars['String']>;
  SoftwareName?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type ActivitiesEntity = InterfaceBase & {
  __typename?: 'ActivitiesEntity';
  AccountID: Scalars['String'];
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Attachment?: Maybe<Scalars['String']>;
  Audio?: Maybe<GalleryEntity>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Debtor?: Maybe<DebtorEntity>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};

export type ActivitiesInput = {
  AccountID: Scalars['String'];
  ActivityDate?: InputMaybe<Scalars['DateTime']>;
  Attachment?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<Scalars['String']>;
};

export type AddConfirmationInput = {
  Email: Scalars['String'];
  FullName: Scalars['String'];
  RegistrationID: Scalars['String'];
};

export type AddInformationInput = {
  Address?: InputMaybe<AddressInput>;
  BirthDate: Scalars['DateTime'];
  Email: Scalars['String'];
  FullName: Scalars['String'];
  IcPhotoBack: Array<Scalars['Upload']>;
  IcPhotoFront: Array<Scalars['Upload']>;
  IsPreCheckIn?: InputMaybe<Scalars['Boolean']>;
  IsTerm?: InputMaybe<Scalars['Boolean']>;
  MobileNo: Scalars['String'];
  NRIC: Scalars['String'];
  Nationality: Scalars['String'];
  RegistrationID: Scalars['String'];
  Signature: Array<Scalars['Upload']>;
  SpecialRequest?: InputMaybe<Scalars['String']>;
};

export type AdditionalMultiRegInput = {
  AttachmentInput?: InputMaybe<Scalars['Upload']>;
  ContactID?: InputMaybe<Scalars['String']>;
  GuestID?: InputMaybe<Scalars['String']>;
  IdPicture?: InputMaybe<Scalars['Upload']>;
  IsPDPA?: InputMaybe<Scalars['Boolean']>;
  IsTTx?: InputMaybe<Scalars['Boolean']>;
  RegID?: InputMaybe<Scalars['String']>;
  TTxReasonID?: InputMaybe<Scalars['String']>;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  AddressType?: Maybe<AddressType>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  AddressType?: InputMaybe<AddressType>;
  ID?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  postCodeName?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export enum AddressType {
  Delivery = 'DELIVERY',
  Primary = 'PRIMARY',
  Site = 'SITE'
}

export type AdjustmentTaxOutput = {
  __typename?: 'AdjustmentTaxOutput';
  SSF?: Maybe<Scalars['Boolean']>;
  SSFAmount?: Maybe<Scalars['Float']>;
  TTx?: Maybe<Scalars['Boolean']>;
  TTxAmount?: Maybe<Scalars['Float']>;
};

export type AdvPaymentGlEntity = InterfaceTrx & {
  __typename?: 'AdvPaymentGLEntity';
  AdvPaymentType?: Maybe<Scalars['String']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type AdvancePaymentEntity = InterfaceTrx & {
  __typename?: 'AdvancePaymentEntity';
  Amount?: Maybe<Scalars['Float']>;
  Booking?: Maybe<BookingEntity>;
  BookingID: Scalars['String'];
  CardNo?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
  Folio?: Maybe<FolioEntity>;
  FolioID?: Maybe<Scalars['String']>;
  ForfeitTax?: Maybe<Array<ForfeitTaxEntity>>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsARPosted: Scalars['Boolean'];
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPosted: Scalars['Boolean'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  PaySessionNo?: Maybe<Scalars['Float']>;
  PaymentClass?: Maybe<PaymentClass>;
  PaymentDate: Scalars['DateTime'];
  PaymentStatus?: Maybe<PaymentStatus>;
  PaymentType?: Maybe<PaymentType>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ReversedAdvancePaymentID?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
};

export type AdvancePaymentInput = {
  AdvancePaymentDate?: InputMaybe<Scalars['DateTime']>;
  Amount?: InputMaybe<Scalars['Float']>;
  BookingID?: InputMaybe<Scalars['String']>;
  CardNo?: InputMaybe<Scalars['String']>;
  CheckOutID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  PaySessionNo?: InputMaybe<Scalars['Float']>;
  PaymentClass?: InputMaybe<PaymentClass>;
  PaymentDate?: InputMaybe<Scalars['DateTime']>;
  PaymentStatus?: InputMaybe<PaymentStatus>;
  PaymentType?: InputMaybe<PaymentType>;
  ReceiptNo?: InputMaybe<Scalars['String']>;
  ReferenceNo?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
  ReversedAdvancePaymentID?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  TaxAmount?: InputMaybe<Scalars['Float']>;
  UserID?: InputMaybe<Scalars['String']>;
  einvoice_type?: InputMaybe<Scalars['String']>;
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type AllocationEntity = InterfaceTrx & {
  __typename?: 'AllocationEntity';
  AccountID: Scalars['String'];
  AllocDate?: Maybe<Scalars['DateTime']>;
  AllocationAmount: Scalars['Float'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreditDetail: Array<DebitNoteEntity>;
  CreditID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  DebitDetail: Array<CreditNoteEntity>;
  DebitID?: Maybe<Scalars['String']>;
  DebitRefTable?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
};

export type AmenitiesInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type AssignRoomInput = {
  RegistrationID?: InputMaybe<Scalars['String']>;
  RoomID?: InputMaybe<Scalars['String']>;
  SharerNo?: InputMaybe<Scalars['String']>;
};

export type AtestEntity = InterfaceTrx & {
  __typename?: 'AtestEntity';
  Address?: Maybe<Array<Address>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
};

export type AtestInput = {
  Address?: InputMaybe<Array<AddressInput>>;
};

export type AuditEntity = {
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type AvailabilityEntity = InterfaceTrx & {
  __typename?: 'AvailabilityEntity';
  AllottedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DynamicRoom?: Maybe<Scalars['Float']>;
  EffectiveDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID: Scalars['String'];
  SystemAdj?: Maybe<Scalars['Float']>;
  Waitlist?: Maybe<Scalars['Float']>;
};

export type AvailabilityInput = {
  AllottedRoom?: InputMaybe<Scalars['Float']>;
  BlockedRoom?: InputMaybe<Scalars['Float']>;
  BookedRoom?: InputMaybe<Scalars['Float']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DynamicRoom?: InputMaybe<Scalars['Float']>;
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
  Waitlist?: InputMaybe<Scalars['Float']>;
};

export type AvailabilityOutput = {
  __typename?: 'AvailabilityOutput';
  AllottedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ID?: Maybe<Scalars['String']>;
  Pricing?: Maybe<Array<PricingEntity>>;
  SeasonType: Scalars['String'];
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  TotalVacantShow?: Maybe<Scalars['Float']>;
  Waitlist?: Maybe<Scalars['Float']>;
};

export type BiFolioResponse = {
  __typename?: 'BIFolioResponse';
  Folio?: Maybe<Array<FolioEntity>>;
  TotalFolio?: Maybe<Scalars['Float']>;
};

export type BiReceiptRefundResponse = {
  __typename?: 'BIReceiptRefundResponse';
  BIListing?: Maybe<Array<PaymentTestClass>>;
  TotalListing?: Maybe<Scalars['Float']>;
};

export type BankAccountEntity = InterfaceTrx & {
  __typename?: 'BankAccountEntity';
  AccountHolderName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  BankClearance?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  BankContactNo?: Maybe<Scalars['String']>;
  BankGLAcct?: Maybe<GuestLedgerAcctJson>;
  BankName?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Array<ContactPerson>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsCash?: Maybe<Scalars['Boolean']>;
  IsKeyCardCash?: Maybe<Scalars['Boolean']>;
  IsSecurityCash?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  SwiftCode?: Maybe<Scalars['String']>;
};

export type BankAccountInput = {
  AccountHolderName?: InputMaybe<Scalars['String']>;
  AccountNo?: InputMaybe<Scalars['String']>;
  Address?: InputMaybe<AddressInput>;
  BankClearance?: InputMaybe<Scalars['String']>;
  BankCode?: InputMaybe<Scalars['String']>;
  BankContactNo?: InputMaybe<Scalars['String']>;
  BankName?: InputMaybe<Scalars['String']>;
  Branch?: InputMaybe<Scalars['String']>;
  ContactPerson?: InputMaybe<Array<ContactPersonInput>>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsCash?: InputMaybe<Scalars['Boolean']>;
  IsKeyCardCash?: InputMaybe<Scalars['Boolean']>;
  IsSecurityCash?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  SwiftCode?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  AcctHolder?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  AcctHolder?: InputMaybe<Scalars['String']>;
  AcctNo?: InputMaybe<Scalars['String']>;
  Branch?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: InputMaybe<Scalars['String']>;
  commonStatus?: InputMaybe<CommonStatus>;
  createdBy?: InputMaybe<Scalars['String']>;
};

export type BillLedgerEntity = InterfaceTrx & {
  __typename?: 'BillLedgerEntity';
  BaseAmount?: Maybe<Scalars['Float']>;
  BillLedgerSignature?: Maybe<GalleryEntity>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  Folio?: Maybe<FolioEntity>;
  FolioID?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  IncidentalChargeID: Scalars['String'];
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsRoomService?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Payment?: Maybe<PaymentEntity>;
  PaymentID?: Maybe<Scalars['String']>;
  PrincipalRegistration: RegistrationEntity;
  Qty?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  RefBillLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  Signature?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TaxBillLedger?: Maybe<Array<BillLedgerTaxEntity>>;
  TrxAmount?: Maybe<Scalars['Float']>;
  TrxDate: Scalars['DateTime'];
  UnitPrice?: Maybe<Scalars['Float']>;
};

export type BillLedgerInput = {
  BaseAmount?: InputMaybe<Scalars['Float']>;
  BillLedgerTaxInput?: InputMaybe<Array<BillLedgerTaxInput>>;
  ChargeType?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  DiscountAmount?: InputMaybe<Scalars['Float']>;
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IncidentalChargeID?: InputMaybe<Scalars['String']>;
  IsChargeToRoom?: InputMaybe<Scalars['Boolean']>;
  IsDelivered?: InputMaybe<Scalars['Boolean']>;
  IsRoomService?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  OutletCode?: InputMaybe<Scalars['String']>;
  PaymentID?: InputMaybe<Scalars['String']>;
  Qty?: InputMaybe<Scalars['Float']>;
  Reason: Scalars['String'];
  RefBillLedgerID?: InputMaybe<Scalars['String']>;
  ReferenceNo?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
  ReversedBillLedgerID?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  Signature?: InputMaybe<Scalars['String']>;
  TaxAmount?: InputMaybe<Scalars['Float']>;
  TrxAmount?: InputMaybe<Scalars['Float']>;
  TrxDate: Scalars['DateTime'];
  UnitPrice?: InputMaybe<Scalars['Float']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type BillLedgerTaxEntity = InterfaceTrx & {
  __typename?: 'BillLedgerTaxEntity';
  AccountID: Scalars['String'];
  BillLedger?: Maybe<BillLedgerEntity>;
  BillLedgerID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  TaxAmount: Scalars['Float'];
  TaxID: Scalars['String'];
  TaxInfo?: Maybe<TaxEntity>;
  TaxRate: Scalars['Float'];
  TaxSchemeDetailID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
};

export type BillLedgerTaxInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  BillLedgerID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  TaxAmount?: InputMaybe<Scalars['Float']>;
  TaxID?: InputMaybe<Scalars['String']>;
  TaxRate?: InputMaybe<Scalars['Float']>;
  TaxSchemeDetailID?: InputMaybe<Scalars['String']>;
  TaxSchemeID?: InputMaybe<Scalars['String']>;
};

export type BillScheduleEntity = InterfaceTrx & {
  __typename?: 'BillScheduleEntity';
  AccountID: Scalars['String'];
  AdvancePaymentID?: Maybe<Scalars['String']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  ChargeType: ChargeType;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  FromRegistration?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPosted: Scalars['Boolean'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Rate?: Maybe<RateEntity>;
  RateID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  ReversedBillScheduleID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TaxBillSchedule?: Maybe<Array<BillScheduleTaxEntity>>;
  TaxInfo?: Maybe<TaxEntity>;
  Transaction?: Maybe<IncidentalChargeEntity>;
  TransactionID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionType>;
  TrxAmount?: Maybe<Scalars['Float']>;
  TrxDate: Scalars['DateTime'];
};

export type BillScheduleInput = {
  AccountID: Scalars['String'];
  BaseAmount?: InputMaybe<Scalars['Float']>;
  ChargeType: ChargeType;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DiscountAmount?: InputMaybe<Scalars['Float']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RateID?: InputMaybe<Scalars['String']>;
  Reason?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReversedBillScheduleID?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  TaxAmount?: InputMaybe<Scalars['Float']>;
  TransactionID?: InputMaybe<Scalars['String']>;
  TransactionType: TransactionType;
  TrxAmount?: InputMaybe<Scalars['Float']>;
  TrxDate: Scalars['DateTime'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type BillScheduleTaxEntity = InterfaceTrx & {
  __typename?: 'BillScheduleTaxEntity';
  AccountID: Scalars['String'];
  BillSchedule?: Maybe<BillScheduleEntity>;
  BillScheduleID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  TaxAmount: Scalars['Float'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxSchemeDetailID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
};

export type BillSummaryInput = {
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  IsChooseRoom: Scalars['Boolean'];
  RegistrationID: Scalars['String'];
  RoomID?: InputMaybe<Scalars['String']>;
};

export type BlockRoomLogEntity = InterfaceTrx & {
  __typename?: 'BlockRoomLogEntity';
  BlockDate: Scalars['DateTime'];
  BlockRoomLogGallery?: Maybe<Array<BlockRoomLogGalleryEntity>>;
  BlockStatus?: Maybe<RoomStatusEntity>;
  BlockStatusID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsRelease?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Reason?: Maybe<Scalars['String']>;
  ReleaseDT?: Maybe<Scalars['DateTime']>;
  ReleaseDate: Scalars['DateTime'];
  ReleaseRoomStatus: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  Room?: Maybe<RoomEntity>;
  RoomID: Scalars['String'];
};

export type BlockRoomLogGalleryEntity = InterfaceBase & {
  __typename?: 'BlockRoomLogGalleryEntity';
  BlockRoomLog?: Maybe<BlockRoomLogEntity>;
  BlockRoomLogID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type BlockRoomLogInput = {
  BlockDate: Scalars['DateTime'];
  BlockStatusID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  GalleryID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsRelease?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Reason?: InputMaybe<Scalars['String']>;
  ReleaseDate: Scalars['DateTime'];
  Remarks?: InputMaybe<Scalars['String']>;
  RoomID?: InputMaybe<Scalars['String']>;
  RoomNo?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type BookingAttachmentDetailEntity = InterfaceBase & {
  __typename?: 'BookingAttachmentDetailEntity';
  BookingAttachment?: Maybe<BookingAttachmentEntity>;
  BookingAttachmentID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export type BookingAttachmentDetailInput = {
  BookingAttachmentID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  GalleryID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
};

export type BookingAttachmentEntity = InterfaceBase & {
  __typename?: 'BookingAttachmentEntity';
  AttachmentDetail?: Maybe<Array<BookingAttachmentDetailEntity>>;
  Booking?: Maybe<BookingEntity>;
  BookingID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type BookingAttachmentInput = {
  BookingID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
};

export type BookingBillScheduleResponse = {
  __typename?: 'BookingBillScheduleResponse';
  BillScheduleAmount?: Maybe<Array<BillScheduleEntity>>;
  DiscountAmount?: Maybe<Array<RateAdjustmentAuditEntity>>;
};

export type BookingCancellation = {
  __typename?: 'BookingCancellation';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  BookingNo?: Maybe<Scalars['String']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  RateAmount?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type BookingEntity = InterfaceTrx & {
  __typename?: 'BookingEntity';
  AccountID: Scalars['String'];
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  AllRegistration?: Maybe<Array<RegistrationEntity>>;
  ArrivalDate: Scalars['DateTime'];
  BillingInstruction?: Maybe<Scalars['String']>;
  BookedRegistration?: Maybe<Array<RegistrationEntity>>;
  BookingNo: Scalars['String'];
  BookingRemark?: Maybe<Scalars['String']>;
  BookingStatus: BookingStatus;
  ChargeToCityLedger?: Maybe<Scalars['Boolean']>;
  Contact?: Maybe<ContactEntity>;
  ContactID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Debtor?: Maybe<DebtorEntity>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DepartureDate: Scalars['DateTime'];
  Folio?: Maybe<Array<FolioEntity>>;
  GroupName?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsCms?: Maybe<Scalars['Boolean']>;
  IsCmsTtx?: Maybe<Scalars['Boolean']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  OTACollection?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Registration?: Maybe<Array<RegistrationEntity>>;
  Remark?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<SalesChannelEntity>;
  SalesChannelID?: Maybe<Scalars['String']>;
  Segment?: Maybe<SegmentEntity>;
  SegmentID?: Maybe<Scalars['String']>;
  Source?: Maybe<Source>;
  SpecialRequest?: Maybe<Scalars['String']>;
  TotalAdvPayment: Scalars['Float'];
  TotalRoomRate: Scalars['Float'];
  isPrepaid?: Maybe<Scalars['Boolean']>;
};


export type BookingEntityBookedRegistrationArgs = {
  Status?: InputMaybe<Scalars['String']>;
};

export type BookingInfoResponse = {
  __typename?: 'BookingInfoResponse';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  BookingNo?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  FullName?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  RoomInfo?: Maybe<Array<RoomInfoResponse>>;
};

export type BookingInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  ArrivalDate?: InputMaybe<Scalars['DateTime']>;
  BillingInstruction?: InputMaybe<Scalars['String']>;
  BookingNo?: InputMaybe<Scalars['String']>;
  BookingRemark?: InputMaybe<Scalars['String']>;
  BookingStatus?: InputMaybe<BookingStatus>;
  ChargeToCityLedger?: InputMaybe<Scalars['Boolean']>;
  ContactID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  DepartureDate?: InputMaybe<Scalars['DateTime']>;
  GroupName?: InputMaybe<Scalars['String']>;
  GuestID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsCms?: InputMaybe<Scalars['Boolean']>;
  IsCmsTtx?: InputMaybe<Scalars['Boolean']>;
  IsGroupBooking?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Reason?: InputMaybe<Scalars['String']>;
  ReferenceNo?: InputMaybe<Scalars['String']>;
  SalesChannelID?: InputMaybe<Scalars['String']>;
  SegmentID?: InputMaybe<Scalars['String']>;
  Source?: InputMaybe<Source>;
  SpecialRequest?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
  isPrepaid?: InputMaybe<Scalars['Boolean']>;
};

export type BookingRoomAssignInput = {
  RegistrationID?: InputMaybe<Scalars['String']>;
  RoomID?: InputMaybe<Scalars['String']>;
};

export enum BookingStatus {
  Booked = 'Booked',
  Cancelled = 'Cancelled',
  CheckOut = 'CheckOut',
  Inhouse = 'Inhouse',
  NoShow = 'NoShow',
  Pending = 'Pending',
  Waitlist = 'Waitlist'
}

export type CbExportEntity = InterfaceTrx & {
  __typename?: 'CBExportEntity';
  AccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  CBDate?: Maybe<Scalars['DateTime']>;
  CBType?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DescriptionTransfer?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ReferenceNo?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
};

export type CmsBookingEntity = InterfaceBase & {
  __typename?: 'CMSBookingEntity';
  AccountID: Scalars['String'];
  ChannelManager?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsProcess: Scalars['Boolean'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type CmsLogEntity = InterfaceBase & {
  __typename?: 'CMSLogEntity';
  BookingNo?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  LogDate: Scalars['DateTime'];
  Message?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type CmsOperationControlEntity = InterfaceBase & {
  __typename?: 'CMSOperationControlEntity';
  ChannelManager?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ProcessStatus: Scalars['String'];
  ProcessType: Scalars['String'];
};

export type CmsOperationControlInput = {
  ChannelManager: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  ModifiedDT?: InputMaybe<Scalars['DateTime']>;
  ProcessStatus: Scalars['String'];
  ProcessType: Scalars['String'];
};

export type CmsResponseEntity = InterfaceBase & {
  __typename?: 'CMSResponseEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  LogDate: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  RawData?: Maybe<Scalars['String']>;
  ResponseData?: Maybe<Scalars['String']>;
};

export type ChannelManagerEntity = InterfaceBase & {
  __typename?: 'ChannelManagerEntity';
  AuthKey?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  HotelMapID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Login?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
  Pass?: Maybe<Scalars['String']>;
  Url?: Maybe<Scalars['String']>;
};

export type ChannelManagerInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Login?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  Pass: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
};

export enum ChargeType {
  Booking = 'Booking',
  ExtendStay = 'ExtendStay',
  ExtendStayPkg = 'ExtendStayPkg',
  LateCheckOut = 'LateCheckOut',
  Package = 'Package',
  RoomUpgrade = 'RoomUpgrade',
  Taxes = 'Taxes'
}

export type CheckOut = {
  __typename?: 'CheckOut';
  PaySessionNo?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Errors>;
  item?: Maybe<Item>;
};

export type CheckoutReinstateResponse = {
  __typename?: 'CheckoutReinstateResponse';
  ModifiedBy?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
};

export type ChooseRoomAllocationEntity = InterfaceTrx & {
  __typename?: 'ChooseRoomAllocationEntity';
  AccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Location?: Maybe<LocationEntity>;
  LocationID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Room?: Maybe<RoomEntity>;
  RoomID?: Maybe<Scalars['String']>;
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID?: Maybe<Scalars['String']>;
};

export type ChooseRoomAllocationInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID?: InputMaybe<Scalars['String']>;
  Input: Array<InputItem>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
};

export type CityLedgerAttachmentEntity = InterfaceBase & {
  __typename?: 'CityLedgerAttachmentEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreditNote?: Maybe<CreditNoteEntity>;
  CreditNoteID?: Maybe<Scalars['String']>;
  DebitNote?: Maybe<DebitNoteEntity>;
  DebitNoteID?: Maybe<Scalars['String']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  ID: Scalars['String'];
  Invoice?: Maybe<InvoiceEntity>;
  InvoiceID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  OfficialReceipt?: Maybe<OfficialReceiptEntity>;
  OfficialReceiptID?: Maybe<Scalars['String']>;
  Refund?: Maybe<RefundEntity>;
  RefundID?: Maybe<Scalars['String']>;
};

export type CityLedgerAttachmentInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  CreditNoteID?: InputMaybe<Scalars['String']>;
  DebitNoteID?: InputMaybe<Scalars['String']>;
  GalleryID?: InputMaybe<Scalars['String']>;
  ID: Scalars['String'];
  InvoiceID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  OfficialReceiptID?: InputMaybe<Scalars['String']>;
  RefundID?: InputMaybe<Scalars['String']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Block = 'BLOCK',
  Inactive = 'INACTIVE'
}

export type CompOutput = {
  __typename?: 'CompOutput';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  accountId: Scalars['String'];
  address?: InputMaybe<AddressInput>;
  baseCurrencyId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  companyRegNo?: InputMaybe<Scalars['String']>;
  companyTax?: InputMaybe<Scalars['String']>;
  contactNo?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eInvClientID?: InputMaybe<Scalars['String']>;
  eInvClientSecret?: InputMaybe<Scalars['String']>;
  eInvClientSecret2?: InputMaybe<Scalars['String']>;
  eInvEndDate?: InputMaybe<Scalars['String']>;
  eInvStartDate?: InputMaybe<Scalars['String']>;
  eSign?: InputMaybe<Scalars['String']>;
  eSignExpiryDate?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  groupGstRegNo?: InputMaybe<Scalars['String']>;
  gstExpiryDate?: InputMaybe<Scalars['DateTime']>;
  gstNo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  imageBucketFile?: InputMaybe<Scalars['String']>;
  imageLogoPosition?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isEInvIntegrated: Scalars['Boolean'];
  isoImplementation?: InputMaybe<Scalars['Boolean']>;
  isoLogoPosition?: InputMaybe<Scalars['String']>;
  modBy?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  officeTel?: InputMaybe<Scalars['String']>;
  parentCompanyId?: InputMaybe<Scalars['String']>;
  recordStatus?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['String']>;
  sstNo?: InputMaybe<Scalars['String']>;
  tin?: InputMaybe<Scalars['String']>;
  ttxNo?: InputMaybe<Scalars['String']>;
  watermarkImplementation?: InputMaybe<Scalars['Boolean']>;
  watermarkWording?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export enum ComputationRules {
  FlatRate = 'FlatRate',
  PerPerson = 'PerPerson'
}

export enum ComputeMethod {
  TaxOnGross = 'TAX_ON_GROSS',
  TaxOnTaxedPrice = 'TAX_ON_TAXED_PRICE'
}

export type ContactEntity = InterfaceBase & {
  __typename?: 'ContactEntity';
  AccountID?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  BusinessEmail?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Designation?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  GeneralEmail?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  InvoiceEmail?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  Marital?: Maybe<Marital>;
  MobileNo?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  NRIC?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  NotificationMobileNo?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  PassportExpireDate?: Maybe<Scalars['DateTime']>;
  PassportNo?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  ProfilePictureGallery?: Maybe<GalleryEntity>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  ReservationEmail?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Address?: InputMaybe<AddressInput>;
  BirthDate?: InputMaybe<Scalars['DateTime']>;
  BusinessEmail?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Designation?: InputMaybe<Scalars['String']>;
  Email?: InputMaybe<Scalars['String']>;
  FaxNo?: InputMaybe<Scalars['String']>;
  FullName?: InputMaybe<Scalars['String']>;
  Gender?: InputMaybe<Gender>;
  GeneralEmail?: InputMaybe<Scalars['String']>;
  GuestID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  InvoiceEmail?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Marital?: InputMaybe<Marital>;
  MobileNo?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  NRIC?: InputMaybe<Scalars['String']>;
  Nationality?: InputMaybe<Nationality>;
  NotificationMobileNo?: InputMaybe<Scalars['String']>;
  OldGuestID?: InputMaybe<Scalars['String']>;
  OwnerEmail?: InputMaybe<Scalars['String']>;
  PassportExpireDate?: InputMaybe<Scalars['DateTime']>;
  PassportNo?: InputMaybe<Scalars['String']>;
  PhoneNo?: InputMaybe<Scalars['String']>;
  ProfilePicture?: InputMaybe<Scalars['String']>;
  Race?: InputMaybe<Race>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  Religion?: InputMaybe<Religion>;
  ReservationEmail?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
  VehicleNo?: InputMaybe<Scalars['String']>;
  WebUrl?: InputMaybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: InputMaybe<Scalars['String']>;
  associateID?: InputMaybe<Scalars['String']>;
  contactNo?: InputMaybe<Scalars['String']>;
  designation?: InputMaybe<Scalars['String']>;
  docRefTable?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ContractAllotmentEntity = InterfaceBase & {
  __typename?: 'ContractAllotmentEntity';
  Adjust?: Maybe<Scalars['Float']>;
  AllotDate: Scalars['DateTime'];
  AllowDeleteAllotment?: Maybe<Scalars['Boolean']>;
  Contract?: Maybe<ContractEntity>;
  ContractID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  InitialAllot?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PickUp?: Maybe<Scalars['Float']>;
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID: Scalars['String'];
  SysAdjust?: Maybe<Scalars['Float']>;
  SysRelease?: Maybe<Scalars['Float']>;
};

export type ContractAllotmentInput = {
  Adjust?: InputMaybe<Scalars['Float']>;
  AllotDate?: InputMaybe<Scalars['DateTime']>;
  ContractID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  InitialAllot?: InputMaybe<Scalars['Float']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  PickUp?: InputMaybe<Scalars['Float']>;
  RoomTypeID: Scalars['String'];
  SysAdjust?: InputMaybe<Scalars['Float']>;
  SysRelease?: InputMaybe<Scalars['Float']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type ContractEntity = InterfaceBase & {
  __typename?: 'ContractEntity';
  Allotment?: Maybe<Scalars['Boolean']>;
  AllowDeleteContract?: Maybe<Scalars['Boolean']>;
  ChannelManager?: Maybe<Scalars['String']>;
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
  ContractAllotmentByRoomType?: Maybe<Array<ContractAllotmentEntity>>;
  ContractNo?: Maybe<Scalars['String']>;
  ContractRate?: Maybe<Array<ContractRateEntity>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CutOffDays?: Maybe<Scalars['Float']>;
  Debtor?: Maybe<DebtorEntity>;
  DebtorID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Elastic?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsCMS?: Maybe<Scalars['Boolean']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PaymentTerms?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  RoomUpgrade?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
};

export type ContractInput = {
  Allotment?: InputMaybe<Scalars['Boolean']>;
  ChannelManager?: InputMaybe<Scalars['String']>;
  ContractNo?: InputMaybe<Scalars['String']>;
  CutOffDays?: InputMaybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  Elastic?: InputMaybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsCMS?: InputMaybe<Scalars['Boolean']>;
  IsKiosk?: InputMaybe<Scalars['Boolean']>;
  PaymentTerms?: InputMaybe<Scalars['Float']>;
  Remarks?: InputMaybe<Scalars['String']>;
  RoomUpgrade?: InputMaybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type ContractRateEntity = InterfaceBase & {
  __typename?: 'ContractRateEntity';
  Contract?: Maybe<ContractEntity>;
  ContractID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  RatePolicy?: Maybe<RateEntity>;
  RatePolicyID: Scalars['String'];
};

export type ContractRateInput = {
  ContractID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  RatePolicyID: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  entityID: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  roleID: Scalars['String'];
};

export type CoreLedger = InterfaceTrx & {
  __typename?: 'CoreLedger';
  AccountID: Scalars['String'];
  Amount: Scalars['Float'];
  Attachment?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Status: ArTransactionStatus;
  VoidDate?: Maybe<Scalars['DateTime']>;
};

export type CoreLedgerInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Amount: Scalars['Float'];
  Currency?: InputMaybe<Currency>;
  CurrencyRate?: InputMaybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  PostedDate?: InputMaybe<Scalars['DateTime']>;
  ReferenceNo: Scalars['String'];
  Status?: InputMaybe<ArTransactionStatus>;
  einvoice_doc_ref_invoice_id?: InputMaybe<Scalars['String']>;
  einvoice_status?: InputMaybe<Scalars['String']>;
  is_einvoice?: InputMaybe<Scalars['Boolean']>;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  ID: Scalars['String'];
  accountID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  permissionID: Scalars['Float'];
  roleID: Scalars['String'];
};

export type CoreTaxTrxLedger = InterfaceTrx & {
  __typename?: 'CoreTaxTrxLedger';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  TaxAmount: Scalars['Float'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxSchemeDetailID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
};

export type CoreTrxLedger = InterfaceTrx & {
  __typename?: 'CoreTrxLedger';
  BaseAmount: Scalars['Float'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DiscountedAmount: Scalars['Float'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Quantity: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  ServiceCharge: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TransactionID: Scalars['String'];
  TrxAmount: Scalars['Float'];
  TrxName?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
};

export type CoreTrxLedgerInput = {
  AllocationAmount?: InputMaybe<Scalars['Float']>;
  BaseAmount: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  Quantity: Scalars['Float'];
  ReferenceNo?: InputMaybe<Scalars['String']>;
  Remarks?: InputMaybe<Scalars['String']>;
  ServiceCharge: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TransactionID: Scalars['String'];
  TrxAmount: Scalars['Float'];
  TrxName?: InputMaybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
};

export enum Country {
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaHerzegovina = 'Bosnia_Herzegovina',
  Brazil = 'Brazil',
  BritishVirginIslands = 'British_Virgin_Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape_Verde',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  CoteDIvoire = 'Cote_d_Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech_Republic',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  DominicaCommonwealth = 'Dominica_Commonwealth',
  DominicanRepublic = 'Dominican_Republic',
  EastAfrica = 'East_Africa',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  ElSalvador = 'El_Salvador',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FaeroeIsland = 'Faeroe_Island',
  FalklandIslands = 'Falkland_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadaloupe = 'Guadaloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  MiddleEast = 'Middle_East',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'Netherlands_Antilles',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NiueIsland = 'Niue_Island',
  NorfolkIsland = 'Norfolk_Island',
  NorthKorea = 'North_Korea',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Others = 'Others',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Palestine = 'Palestine',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  SouthKorea = 'South_Korea',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  StHelena = 'St_Helena',
  StKittsAndNevis = 'St_Kitts_and_Nevis',
  StLucia = 'St_Lucia',
  StPierreAndMiquelon = 'St_Pierre_and_Miquelon',
  StVincentAndTheGrenadines = 'St_Vincent_and_the_Grenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  UsVirginIslands = 'US_Virgin_Islands',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStatesOfAmerica = 'United_States_of_America',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  WallisAndFutuna = 'Wallis_and_Futuna',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export type CountryReportOutput = {
  __typename?: 'CountryReportOutput';
  CountryDARR?: Maybe<Scalars['Float']>;
  CountryDGuestCount?: Maybe<Scalars['Float']>;
  CountryDOtherRevenue?: Maybe<Scalars['Float']>;
  CountryDRoomCount?: Maybe<Scalars['Float']>;
  CountryDRoomRevenue?: Maybe<Scalars['Float']>;
  CountryMARR?: Maybe<Scalars['Float']>;
  CountryMGuestCount?: Maybe<Scalars['Float']>;
  CountryMOtherRevenue?: Maybe<Scalars['Float']>;
  CountryMRoomCount?: Maybe<Scalars['Float']>;
  CountryMRoomRevenue?: Maybe<Scalars['Float']>;
  CountryName?: Maybe<Scalars['String']>;
  CountryYARR?: Maybe<Scalars['Float']>;
  CountryYGuestCount?: Maybe<Scalars['Float']>;
  CountryYOtherRevenue?: Maybe<Scalars['Float']>;
  CountryYRoomCount?: Maybe<Scalars['Float']>;
  CountryYRoomRevenue?: Maybe<Scalars['Float']>;
};

export type CreateAuditInput = {
  createdBy?: InputMaybe<Scalars['String']>;
};

export type CreditAllocationInput = {
  AllocationAmount?: InputMaybe<Scalars['Float']>;
  DebitID?: InputMaybe<Scalars['String']>;
  DebitRefTable?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  Type?: InputMaybe<Scalars['String']>;
};

export type CreditCardMapping = {
  __typename?: 'CreditCardMapping';
  Name?: Maybe<Scalars['String']>;
};

export type CreditCardMappingInput = {
  Name?: InputMaybe<Scalars['String']>;
};

export type CreditNoteEntity = InterfaceTrx & {
  __typename?: 'CreditNoteEntity';
  AccountID: Scalars['String'];
  Allocation: Array<AllocationEntity>;
  Amount: Scalars['Float'];
  Attachment?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  GroupDocumentNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  LedgerTrx: Array<CreditNoteTrxEntity>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  OutstandingAmount: Scalars['Float'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  Status: ArTransactionStatus;
  VoidDate?: Maybe<Scalars['DateTime']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
};

export type CreditNoteEntityInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Amount: Scalars['Float'];
  Currency?: InputMaybe<Currency>;
  CurrencyRate?: InputMaybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  PostedDate?: InputMaybe<Scalars['DateTime']>;
  ReferenceNo: Scalars['String'];
  Status?: InputMaybe<ArTransactionStatus>;
  einvoice_doc_ref_invoice_id?: InputMaybe<Scalars['String']>;
  einvoice_status?: InputMaybe<Scalars['String']>;
  is_einvoice?: InputMaybe<Scalars['Boolean']>;
};

export type CreditNoteTrxEntity = InterfaceTrx & {
  __typename?: 'CreditNoteTrxEntity';
  BaseAmount: Scalars['Float'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreditNoteID: Scalars['String'];
  CreditNoteTrxTax?: Maybe<Array<CreditNoteTrxTaxEntity>>;
  DiscountedAmount: Scalars['Float'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Quantity: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  ServiceCharge: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TransactionID: Scalars['String'];
  TrxAmount: Scalars['Float'];
  TrxName?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
};

export type CreditNoteTrxInput = {
  AllocationAmount?: InputMaybe<Scalars['Float']>;
  BaseAmount: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  Quantity: Scalars['Float'];
  ReferenceNo?: InputMaybe<Scalars['String']>;
  Remarks?: InputMaybe<Scalars['String']>;
  ServiceCharge: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TransactionID: Scalars['String'];
  TrxAmount: Scalars['Float'];
  TrxName?: InputMaybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
};

export type CreditNoteTrxTaxEntity = InterfaceTrx & {
  __typename?: 'CreditNoteTrxTaxEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreditNoteTrx?: Maybe<CreditNoteTrxEntity>;
  CreditNoteTrxID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  TaxAmount: Scalars['Float'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxSchemeDetailID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
};

export enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW'
}

export type CurrentInhouseOutput = {
  __typename?: 'CurrentInhouseOutput';
  HouseLimit?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
};

export type CurrentTaxDetailsOutput = {
  __typename?: 'CurrentTaxDetailsOutput';
  GovTax?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
};

export type CustomizedDocumentEntity = InterfaceBase & {
  __typename?: 'CustomizedDocumentEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocType?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type DashBoardRespond = {
  __typename?: 'DashBoardRespond';
  CollectionMonthToDay?: Maybe<Scalars['Float']>;
  CollectionToday?: Maybe<Scalars['Float']>;
  CurrentArr?: Maybe<Scalars['Float']>;
  CurrentComplimentary?: Maybe<Scalars['Float']>;
  CurrentDayUse?: Maybe<Scalars['Float']>;
  CurrentInHouse?: Maybe<Scalars['Float']>;
  CurrentOccupancyRate?: Maybe<Scalars['Float']>;
  CurrentOccupiedRoom?: Maybe<Scalars['Float']>;
  CurrentOthers?: Maybe<Scalars['Float']>;
  CurrentRoomRevenue?: Maybe<Scalars['Float']>;
  DueIn?: Maybe<Scalars['Float']>;
  DueOut?: Maybe<Scalars['Float']>;
  ProjectArr?: Maybe<Scalars['Float']>;
  ProjectComplimentary?: Maybe<Scalars['Float']>;
  ProjectDayUse?: Maybe<Scalars['Float']>;
  ProjectInHouse?: Maybe<Scalars['Float']>;
  ProjectOccupancyRate?: Maybe<Scalars['Float']>;
  ProjectOccupiedRoom?: Maybe<Scalars['Float']>;
  ProjectOthers?: Maybe<Scalars['Float']>;
  ProjectRoomRevenue?: Maybe<Scalars['Float']>;
  RevenueMonthToDay?: Maybe<Scalars['Float']>;
  RevenueToday?: Maybe<Scalars['Float']>;
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalDueOut?: Maybe<Scalars['Float']>;
  TotalGuest?: Maybe<Scalars['Float']>;
  TotalInHouseRoom?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalUnAssignedRoomRegistration?: Maybe<Scalars['Float']>;
};

export type DebitAllocationInput = {
  AllocationAmount: Scalars['Float'];
  CreditID?: InputMaybe<Scalars['String']>;
  CreditRefTable?: InputMaybe<Scalars['String']>;
  DebitID?: InputMaybe<Scalars['String']>;
  DebitRefTable?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  Type?: InputMaybe<Scalars['String']>;
};

export type DebitNoteEntity = InterfaceTrx & {
  __typename?: 'DebitNoteEntity';
  AccountID: Scalars['String'];
  Allocation: Array<AllocationEntity>;
  Amount: Scalars['Float'];
  Attachment?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  LedgerTrx: Array<DebitNoteTrxEntity>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  OutstandingAmount: Scalars['Float'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  QrCodeUrl?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Status: ArTransactionStatus;
  VoidDate?: Maybe<Scalars['DateTime']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
};

export type DebitNoteEntityInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Amount: Scalars['Float'];
  Currency?: InputMaybe<Currency>;
  CurrencyRate?: InputMaybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  PostedDate?: InputMaybe<Scalars['DateTime']>;
  ReferenceNo: Scalars['String'];
  Status?: InputMaybe<ArTransactionStatus>;
  einvoice_doc_ref_invoice_id?: InputMaybe<Scalars['String']>;
  einvoice_status?: InputMaybe<Scalars['String']>;
  is_einvoice?: InputMaybe<Scalars['Boolean']>;
};

export type DebitNoteTrxEntity = InterfaceTrx & {
  __typename?: 'DebitNoteTrxEntity';
  BaseAmount: Scalars['Float'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebitNoteID: Scalars['String'];
  DebitNoteTrxTax?: Maybe<Array<DebitNoteTrxTaxEntity>>;
  DiscountedAmount: Scalars['Float'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Quantity: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  ServiceCharge: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TransactionID: Scalars['String'];
  TrxAmount: Scalars['Float'];
  TrxName?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
};

export type DebitNoteTrxInput = {
  AllocationAmount?: InputMaybe<Scalars['Float']>;
  BaseAmount: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  Quantity: Scalars['Float'];
  ReferenceNo?: InputMaybe<Scalars['String']>;
  Remarks?: InputMaybe<Scalars['String']>;
  ServiceCharge: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TransactionID: Scalars['String'];
  TrxAmount: Scalars['Float'];
  TrxName?: InputMaybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
};

export type DebitNoteTrxTaxEntity = InterfaceTrx & {
  __typename?: 'DebitNoteTrxTaxEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebitNoteTrx?: Maybe<DebitNoteTrxEntity>;
  DebitNoteTrxID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  TaxAmount: Scalars['Float'];
  TaxID: Scalars['String'];
  TaxInfo?: Maybe<TaxEntity>;
  TaxRate: Scalars['Float'];
  TaxSchemeDetailID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
};

export enum DebtorCategory {
  Corporate = 'Corporate',
  Government = 'Government',
  TravelAgent = 'TravelAgent'
}

export type DebtorContactEntity = InterfaceBase & {
  __typename?: 'DebtorContactEntity';
  Contact?: Maybe<ContactEntity>;
  ContactID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Debtor?: Maybe<DebtorEntity>;
  DebtorID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  SalesChannelID: Scalars['String'];
};

export type DebtorContactInput = {
  ContactID: Scalars['String'];
  DebtorID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type DebtorEntity = InterfaceBase & {
  __typename?: 'DebtorEntity';
  ARTotalDue: Scalars['Float'];
  ARTotalOutstanding: Scalars['Float'];
  BillingInstruction?: Maybe<Scalars['String']>;
  Booking?: Maybe<Array<BookingEntity>>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
  ContactID: Scalars['String'];
  Contract?: Maybe<Array<ContractEntity>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreditCardMapping?: Maybe<Array<CreditCardMapping>>;
  CreditLimit?: Maybe<Scalars['Float']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Currency?: Maybe<Currency>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorAccountRemark?: Maybe<Scalars['String']>;
  DebtorCategory?: Maybe<DebtorCategory>;
  DebtorContact?: Maybe<Array<DebtorContactEntity>>;
  DebtorContacts?: Maybe<Scalars['String']>;
  DebtorProfile?: Maybe<DebtorProfileEntity>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<DebtorTypeEntity>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  InvoiceType?: Maybe<InvoiceType>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsFrontDeskActive?: Maybe<Scalars['Boolean']>;
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
  IsIndividual?: Maybe<Scalars['Boolean']>;
  IseCommerce?: Maybe<Scalars['Boolean']>;
  LastBooking?: Maybe<Scalars['DateTime']>;
  LastPaymentDate?: Maybe<Scalars['DateTime']>;
  Login?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name: Scalars['String'];
  Password?: Maybe<Scalars['String']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  Remarks?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  SegmentDetails?: Maybe<SegmentEntity>;
  TaxRegNo?: Maybe<Scalars['String']>;
  Tin?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
};

export type DebtorInput = {
  BillingInstruction?: InputMaybe<Scalars['String']>;
  CompanyRegNo?: InputMaybe<Scalars['String']>;
  ContactID?: InputMaybe<Scalars['String']>;
  CreditCardMapping?: InputMaybe<Array<CreditCardMappingInput>>;
  CreditLimit?: InputMaybe<Scalars['Float']>;
  CreditTerm?: InputMaybe<Scalars['Float']>;
  Currency?: InputMaybe<Currency>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorAccountRemark?: InputMaybe<Scalars['String']>;
  DebtorCategory?: InputMaybe<DebtorCategory>;
  DebtorContacts?: InputMaybe<Scalars['String']>;
  DebtorProfileID?: InputMaybe<Scalars['String']>;
  DebtorTypeID?: InputMaybe<Scalars['String']>;
  GSTNo?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  InvoiceType?: InputMaybe<InvoiceType>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsFrontDeskActive?: InputMaybe<Scalars['Boolean']>;
  IsGroupInvoice?: InputMaybe<Scalars['Boolean']>;
  IsIndividual?: InputMaybe<Scalars['Boolean']>;
  IseCommerce?: InputMaybe<Scalars['Boolean']>;
  Login?: InputMaybe<Scalars['String']>;
  Name: Scalars['String'];
  Password?: InputMaybe<Scalars['String']>;
  Remarks?: InputMaybe<Scalars['String']>;
  SalesChannel?: InputMaybe<Scalars['String']>;
  Segment?: InputMaybe<Scalars['String']>;
  TaxRegNo?: InputMaybe<Scalars['String']>;
  Tin?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type DebtorPaymentInput = {
  AllocationAmount?: InputMaybe<Scalars['Float']>;
  InvoiceID?: InputMaybe<Scalars['String']>;
};

export type DebtorProfileEntity = InterfaceBase & {
  __typename?: 'DebtorProfileEntity';
  Contact?: Maybe<ContactEntity>;
  ContactID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  Email?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Password?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type DebtorProfileInput = {
  ContactID?: InputMaybe<Scalars['String']>;
  Email?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Password?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
  lastestAccessedEntity?: InputMaybe<Scalars['String']>;
};

export type DebtorTypeEntity = InterfaceBase & {
  __typename?: 'DebtorTypeEntity';
  AccrualAccount?: Maybe<Scalars['String']>;
  AdvanceAccount?: Maybe<Scalars['String']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  ControlAccount?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorType: Scalars['String'];
  DepositAccount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  RevenueAccount?: Maybe<Scalars['String']>;
};

export type DebtorTypeInput = {
  AccrualAccount?: InputMaybe<Scalars['String']>;
  AdvanceAccount?: InputMaybe<Scalars['String']>;
  ControlAccount?: InputMaybe<Scalars['String']>;
  DebtorType?: InputMaybe<Scalars['String']>;
  DepositAccount?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  RevenueAccount?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type DepartmentEntity = InterfaceBase & {
  __typename?: 'DepartmentEntity';
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  AllowIncidental: Scalars['Boolean'];
  AllowReason: Scalars['Boolean'];
  AllowSalesChannel: Scalars['Boolean'];
  Code?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IncidentalCharge?: Maybe<Array<IncidentalChargeEntity>>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsServiceRequest: Scalars['Boolean'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
  Reason?: Maybe<Array<ReasonEntity>>;
  SalesChannel?: Maybe<Array<SalesChannelEntity>>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
};

export type DepartmentInput = {
  AllowGuestApp?: InputMaybe<Scalars['Boolean']>;
  AllowIncidental?: InputMaybe<Scalars['Boolean']>;
  AllowReason?: InputMaybe<Scalars['Boolean']>;
  AllowSalesChannel?: InputMaybe<Scalars['Boolean']>;
  Code?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsServiceRequest?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
};

export type DepartmentReportOutput = {
  __typename?: 'DepartmentReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export enum DepositClass {
  Forfeiture = 'Forfeiture',
  KeyCardDeposit = 'KeyCardDeposit',
  Refund = 'Refund',
  Reversed = 'Reversed',
  SecurityDeposit = 'SecurityDeposit'
}

export type DepositGlEntity = InterfaceTrx & {
  __typename?: 'DepositGLEntity';
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DepositType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type DepositInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  BookingID: Scalars['String'];
  DepositClass: DepositClass;
  FolioAmount: Scalars['Float'];
  FolioType: FolioType;
  HotelID: Scalars['String'];
  PaymentType: PaymentType;
  RegistrationID: Scalars['String'];
  Remarks?: InputMaybe<Scalars['String']>;
};

export type DepositLedgerEntity = InterfaceTrx & {
  __typename?: 'DepositLedgerEntity';
  AccountID?: Maybe<Scalars['String']>;
  ActualDepositClass?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  DepositClass: DepositClass;
  Folio?: Maybe<FolioEntity>;
  FolioID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  PaymentType: PaymentType;
  ReferenceNo?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  ReversedID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
};

export type DepositLedgerInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  DepositAmount: Scalars['Float'];
  DepositClass: DepositClass;
  DepositNo?: InputMaybe<Scalars['String']>;
  FolioID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  PaymentType: PaymentType;
  ReferenceNo: Scalars['String'];
  RegistrationID: Scalars['String'];
  Remarks?: InputMaybe<Scalars['String']>;
  ReversedID?: InputMaybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type DepositResponse = {
  __typename?: 'DepositResponse';
  DepositResult?: Maybe<Scalars['MyCustomScalar']>;
};

export enum DiscountType {
  ByAmount = 'By_Amount',
  ByPercentage = 'By_Percentage'
}

export enum DiscrepancyReason {
  Skip = 'Skip',
  Sleep = 'Sleep'
}

export type DisplaySequenceInput = {
  DisplaySequence?: InputMaybe<Scalars['Float']>;
  ID?: InputMaybe<Scalars['String']>;
};

export type DocumentNumberDetailEntity = InterfaceBase & {
  __typename?: 'DocumentNumberDetailEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
  DocumentNumberHeaderID: Scalars['String'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  NextNumber?: Maybe<Scalars['Float']>;
  Sequence: Scalars['Float'];
  StartFrom?: Maybe<Scalars['Float']>;
};

export type DocumentNumberDetailInput = {
  AccountID: Scalars['String'];
  DocumentNumberHeaderID?: InputMaybe<Scalars['String']>;
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  NextNumber?: InputMaybe<Scalars['Float']>;
  Sequence: Scalars['Float'];
  StartFrom?: InputMaybe<Scalars['Float']>;
};

export type DocumentNumberHeaderEntity = InterfaceBase & {
  __typename?: 'DocumentNumberHeaderEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocumentNumberDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  Type: Scalars['String'];
};

export type DocumentNumberHeaderInput = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  RefRecordID?: InputMaybe<Scalars['String']>;
  RefTable?: InputMaybe<Scalars['String']>;
  SampleOutput?: InputMaybe<Scalars['String']>;
  Type: Scalars['String'];
};

export type DocumentNumberingEntity = InterfaceBase & {
  __typename?: 'DocumentNumberingEntity';
  Booking?: Maybe<Scalars['Float']>;
  ConsolidatedEInvoice?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreditNote?: Maybe<Scalars['Float']>;
  DebitNote?: Maybe<Scalars['Float']>;
  Deposit?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID?: Maybe<Scalars['String']>;
  HotelRefund?: Maybe<Scalars['Float']>;
  ID: Scalars['String'];
  Incidental?: Maybe<Scalars['Float']>;
  Invoice?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PaySession?: Maybe<Scalars['Float']>;
  Receipt?: Maybe<Scalars['Float']>;
  Refund?: Maybe<Scalars['Float']>;
};

export type DocumentNumberingInput = {
  Booking?: InputMaybe<Scalars['Float']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  CreditNote?: InputMaybe<Scalars['Float']>;
  DebitNote?: InputMaybe<Scalars['Float']>;
  Deposit?: InputMaybe<Scalars['Float']>;
  Folio?: InputMaybe<Scalars['Float']>;
  HotelID: Scalars['String'];
  HotelRefund?: InputMaybe<Scalars['Float']>;
  ID: Scalars['String'];
  Incidental?: InputMaybe<Scalars['Float']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  PaySession?: InputMaybe<Scalars['Float']>;
  Receipt?: InputMaybe<Scalars['Float']>;
  Refund?: InputMaybe<Scalars['Float']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type DocumentTemplateEntity = InterfaceBase & {
  __typename?: 'DocumentTemplateEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Folio?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Invoice?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Receipt?: Maybe<Scalars['String']>;
  RegCard?: Maybe<Scalars['String']>;
};

export type DocumentTemplateInput = {
  Folio?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  Invoice?: InputMaybe<Scalars['String']>;
  Receipt?: InputMaybe<Scalars['String']>;
  RegCard?: InputMaybe<Scalars['String']>;
};

export type DynamicRateJson = {
  __typename?: 'DynamicRateJson';
  Rate?: Maybe<Scalars['Float']>;
  RoomQuota?: Maybe<Scalars['Float']>;
  Tier?: Maybe<Scalars['Float']>;
};

export type DynamicRateJsonInput = {
  Rate?: InputMaybe<Scalars['Float']>;
  RoomQuota?: InputMaybe<Scalars['Float']>;
  Tier?: InputMaybe<Scalars['Float']>;
};

export type EDocParamsEntity = InterfaceBase & {
  __typename?: 'EDocParamsEntity';
  ColName: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocType: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type EDocTemplateEntity = InterfaceBase & {
  __typename?: 'EDocTemplateEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  TemplateBody?: Maybe<Scalars['String']>;
};

export type EDocTemplateInput = {
  AccountID: Scalars['String'];
  DocType?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  TemplateBody?: InputMaybe<Scalars['String']>;
};

export type EInvoiceConsolidationDetailEntity = InterfaceTrx & {
  __typename?: 'EInvoiceConsolidationDetailEntity';
  Amount: Scalars['Float'];
  ConsolidationID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocAmt: Scalars['Float'];
  EIConsolidation?: Maybe<EInvoiceConsolidationEntity>;
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RefRecordID: Scalars['String'];
  RefTable: Scalars['String'];
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt: Scalars['Float'];
};

export type EInvoiceConsolidationDetailInput = {
  Amount?: InputMaybe<Scalars['Float']>;
  ConsolidationID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DocAmt?: InputMaybe<Scalars['Float']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RefRecordID?: InputMaybe<Scalars['String']>;
  RefTable?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  TaxAmt?: InputMaybe<Scalars['Float']>;
};

export type EInvoiceConsolidationEntity = InterfaceTrx & {
  __typename?: 'EInvoiceConsolidationEntity';
  AccountID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  CompanyID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocDate: Scalars['DateTime'];
  DocNo?: Maybe<Scalars['String']>;
  EIConsolidationDetail?: Maybe<Array<EInvoiceConsolidationDetailEntity>>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Module?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<EInvoiceConsolidationTrxType>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
};

export type EInvoiceConsolidationInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Amount?: InputMaybe<Scalars['Float']>;
  CompanyID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  DocAmt?: InputMaybe<Scalars['Float']>;
  DocDate?: InputMaybe<Scalars['DateTime']>;
  DocNo?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  Submenu?: InputMaybe<Scalars['String']>;
  TaxAmt?: InputMaybe<Scalars['Float']>;
  einvoice_cancelled_by?: InputMaybe<Scalars['String']>;
  einvoice_cancelled_date?: InputMaybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: InputMaybe<Scalars['String']>;
  einvoice_directsend?: InputMaybe<Scalars['Boolean']>;
  einvoice_failed_send_reason?: InputMaybe<Scalars['String']>;
  einvoice_failed_validation_date?: InputMaybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: InputMaybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: InputMaybe<Scalars['String']>;
  einvoice_qr_url?: InputMaybe<Scalars['String']>;
  einvoice_rejected_confirm_by?: InputMaybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: InputMaybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: InputMaybe<Scalars['String']>;
  einvoice_rejected_request_date?: InputMaybe<Scalars['DateTime']>;
  einvoice_sent_date?: InputMaybe<Scalars['DateTime']>;
  einvoice_status?: InputMaybe<Scalars['String']>;
  einvoice_trx_type?: InputMaybe<Scalars['String']>;
  einvoice_validation_date?: InputMaybe<Scalars['DateTime']>;
};

export enum EInvoiceConsolidationTrxType {
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  Invoice = 'INVOICE'
}

export type EInvoiceSubscriptionEntity = InterfaceBase & {
  __typename?: 'EInvoiceSubscriptionEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export enum EInvoiceType {
  Account = 'Account',
  Individual = 'Individual'
}

export type EditConfirmationInput = {
  ContactID: Scalars['String'];
  Email: Scalars['String'];
  FullName: Scalars['String'];
  OldFullName?: InputMaybe<Scalars['String']>;
  OldNRIC?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
};

export type EditInformationInput = {
  Address?: InputMaybe<AddressInput>;
  BirthDate: Scalars['DateTime'];
  ContactID: Scalars['String'];
  Email: Scalars['String'];
  FullName: Scalars['String'];
  IcPhotoBack?: InputMaybe<Array<Scalars['Upload']>>;
  IcPhotoFront?: InputMaybe<Array<Scalars['Upload']>>;
  IsPreCheckIn?: InputMaybe<Scalars['Boolean']>;
  IsTerm?: InputMaybe<Scalars['Boolean']>;
  MobileNo: Scalars['String'];
  NRIC: Scalars['String'];
  Nationality: Scalars['String'];
  OldFullName?: InputMaybe<Scalars['String']>;
  OldNRIC?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  Signature: Array<Scalars['Upload']>;
  SpecialRequest?: InputMaybe<Scalars['String']>;
};

export type EditRegInput = {
  ArrivalDate?: InputMaybe<Scalars['DateTime']>;
  BookingID?: InputMaybe<Scalars['String']>;
  DepartureDate?: InputMaybe<Scalars['DateTime']>;
  ID?: InputMaybe<Scalars['String']>;
  Mode?: InputMaybe<Scalars['String']>;
  NewDiscountAmount?: InputMaybe<Scalars['Float']>;
  NewNoOfRoom?: InputMaybe<Scalars['Float']>;
  NewPromoCode?: InputMaybe<Scalars['String']>;
  NewRatePolicyID?: InputMaybe<Scalars['String']>;
  NewRoomRate?: InputMaybe<Scalars['Float']>;
  NewRoomTypeID?: InputMaybe<Scalars['String']>;
  OldArrivalDate?: InputMaybe<Scalars['DateTime']>;
  OldDepartureDate?: InputMaybe<Scalars['DateTime']>;
  OldDiscountAmount?: InputMaybe<Scalars['Float']>;
  OldNoOfRoom?: InputMaybe<Scalars['Float']>;
  OldPromoCode?: InputMaybe<Scalars['String']>;
  OldRatePolicyID?: InputMaybe<Scalars['String']>;
  OldRoomRate?: InputMaybe<Scalars['Float']>;
  OldRoomTypeID?: InputMaybe<Scalars['String']>;
  OverbookRoomNo?: InputMaybe<Scalars['Float']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  SharerNo?: InputMaybe<Scalars['Float']>;
};

export type EditTaxPolicyInput = {
  classType: Scalars['String'];
  code: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
  deletedId: Array<Scalars['String']>;
  description: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  modBy?: InputMaybe<Scalars['String']>;
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetailInput>;
  taxId: Scalars['String'];
};

export type EditTaxSchemeInput = {
  createdBy: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExclusive?: InputMaybe<Scalars['Boolean']>;
  modBy: Scalars['String'];
  name: Scalars['String'];
  taxSchemeDetail?: InputMaybe<Array<TaxSchemeDetailInput>>;
};

export type EffectiveRateJson = {
  __typename?: 'EffectiveRateJson';
  Amount?: Maybe<Scalars['Float']>;
  ComputationRule?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
};

export type EffectiveRateJsonInput = {
  Amount?: InputMaybe<Scalars['Float']>;
  ComputationRule?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IncidentalChargeID?: InputMaybe<Scalars['String']>;
};

export type EmailAttachmentInput = {
  cid?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
};

export type EmailBodyInput = {
  Body: Scalars['String'];
  Header: Scalars['String'];
};

export type EmailCampignEntity = InterfaceBase & {
  __typename?: 'EmailCampignEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  EmailDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsSent?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ShareDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Title: Scalars['String'];
  html?: Maybe<Scalars['String']>;
};

export type EmailCampignInput = {
  Attachment?: InputMaybe<Scalars['Upload']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  EmailDate?: InputMaybe<Scalars['DateTime']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  GalleryID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsDraft?: InputMaybe<Scalars['Boolean']>;
  IsSent?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ShareDate?: InputMaybe<Scalars['DateTime']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Title: Scalars['String'];
  html?: InputMaybe<Scalars['String']>;
};

export type EmailLogEntity = InterfaceTrx & {
  __typename?: 'EmailLogEntity';
  ActionType: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  LogData: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  RoomNo: Scalars['String'];
};

export type EmailTemplateEntity = InterfaceBase & {
  __typename?: 'EmailTemplateEntity';
  CancelBooking?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Postpaid?: Maybe<Scalars['String']>;
  Prepaid?: Maybe<Scalars['String']>;
  ThankYou?: Maybe<Scalars['String']>;
  Welcome?: Maybe<Scalars['String']>;
};

export type EmailTemplateInput = {
  CancelBooking?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  PostPaid?: InputMaybe<Scalars['String']>;
  Prepaid?: InputMaybe<Scalars['String']>;
  ThankYou?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
  Welcome?: InputMaybe<Scalars['String']>;
};

export type EntRoleUsrAsgEntity = AuditEntity & {
  __typename?: 'EntRoleUsrAsgEntity';
  ID: Scalars['String'];
  accountID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  entityID: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type EntRoleUsrAsgInput = {
  ID?: InputMaybe<Scalars['String']>;
  commonStatus?: InputMaybe<CommonStatus>;
  createdBy?: InputMaybe<Scalars['String']>;
  hotelID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type Errors = {
  __typename?: 'Errors';
  code?: Maybe<Scalars['String']>;
  debug?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ExportedGlTransferResponse = {
  __typename?: 'ExportedGLTransferResponse';
  AccountID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  CompanyName?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  JournalTypeName?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ExtendStayOutPut = {
  __typename?: 'ExtendStayOutPut';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type FacilityEntity = InterfaceBase & {
  __typename?: 'FacilityEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  FacilityGallery?: Maybe<Array<FacilityGalleryEntity>>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Level?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Title?: Maybe<Scalars['String']>;
};

export type FacilityGalleryEntity = InterfaceBase & {
  __typename?: 'FacilityGalleryEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Facility?: Maybe<FacilityEntity>;
  FacilityID: Scalars['String'];
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type FacilityGalleryInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  FacilityID: Scalars['String'];
  GalleryID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type FacilityInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Level?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Title?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FolioEntity = InterfaceBase & {
  __typename?: 'FolioEntity';
  AccountID: Scalars['String'];
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  BeneficiaryAddress?: Maybe<Scalars['String']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  Booking?: Maybe<BookingEntity>;
  ClosedBy?: Maybe<Scalars['String']>;
  ClosedDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  DocumentType?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioName?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioType?: Maybe<FolioType>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsConsolidated?: Maybe<Scalars['Boolean']>;
  IsPabx?: Maybe<Scalars['Boolean']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  IsSuspend?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  NonGuestBalance: NonGuestBalance;
  NonGuestInfo?: Maybe<NonGuestInfoJson>;
  Payment?: Maybe<Array<PaymentEntity>>;
  QrCodeUrl?: Maybe<Scalars['String']>;
  RefFolio?: Maybe<FolioEntity>;
  RefFolioID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  RoomCharges?: Maybe<Array<RoomLedgerEntity>>;
  RoomChargesSuspend?: Maybe<Array<RoomLedgerEntity>>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  SendEInvoice?: Maybe<Scalars['Boolean']>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_type?: Maybe<EInvoiceType>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
};

export type FolioHistoryResponse = {
  __typename?: 'FolioHistoryResponse';
  FolioType?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  InvoiceNo?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
};

export type FolioInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  BeneficiaryAddress?: InputMaybe<Scalars['String']>;
  BeneficiaryName?: InputMaybe<Scalars['String']>;
  ClosedBy?: InputMaybe<Scalars['String']>;
  ClosedDate?: InputMaybe<Scalars['DateTime']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  FolioAmount?: InputMaybe<Scalars['Float']>;
  FolioDate?: InputMaybe<Scalars['DateTime']>;
  FolioNo?: InputMaybe<Scalars['String']>;
  FolioType?: InputMaybe<FolioType>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  InvoiceNo?: InputMaybe<Scalars['String']>;
  IsSuspend?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  NonGuestInfo?: InputMaybe<NonGuestInfoJsonInput>;
  RefFolioID?: InputMaybe<Scalars['String']>;
  RefID?: InputMaybe<Scalars['String']>;
  SendEInvoice?: InputMaybe<Scalars['Boolean']>;
  UserID?: InputMaybe<Scalars['String']>;
  einvoice_type?: InputMaybe<EInvoiceType>;
};

export type FolioOutput = {
  __typename?: 'FolioOutput';
  AccountID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioType?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  PromoCode?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RegID?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  RoomRate?: Maybe<Scalars['Float']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
  Signature?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export enum FolioType {
  DepositFolio = 'DepositFolio',
  GroupFolio = 'GroupFolio',
  GuestFolio = 'GuestFolio',
  NonGuest = 'NonGuest',
  OpenFolio = 'OpenFolio',
  RefundForfeiture = 'RefundForfeiture'
}

export type ForecastOutPut = {
  __typename?: 'ForecastOutPut';
  Category?: Maybe<Scalars['String']>;
  DayAfterTomorrow?: Maybe<Scalars['Float']>;
  TodayDate?: Maybe<Scalars['Float']>;
  TomorrowDate?: Maybe<Scalars['Float']>;
};

export type ForfeitTaxEntity = InterfaceTrx & {
  __typename?: 'ForfeitTaxEntity';
  AccountID: Scalars['String'];
  AdvancePayment?: Maybe<AdvancePaymentEntity>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Payment?: Maybe<PaymentEntity>;
  PaymentID: Scalars['String'];
  SourceTable?: Maybe<Scalars['String']>;
  SourceType?: Maybe<Scalars['String']>;
  TaxAmount: Scalars['Float'];
  TaxID: Scalars['String'];
  TaxInfo?: Maybe<TaxEntity>;
  TaxRate: Scalars['Float'];
  TaxSchemeDetailID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
};

export type FrontDeskInfoLineResponse = {
  __typename?: 'FrontDeskInfoLineResponse';
  DepartureDueOut?: Maybe<Scalars['Float']>;
  DepositSum?: Maybe<Scalars['Float']>;
  RegistrationDueOut?: Maybe<Scalars['Float']>;
  TotalCheckIn?: Maybe<Scalars['Float']>;
  TotalContactTracingOutlet?: Maybe<Scalars['Float']>;
  TotalDepartureDueOut?: Maybe<Scalars['Float']>;
  TotalDepositRoom?: Maybe<Scalars['Float']>;
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalGroupCheckIn?: Maybe<Scalars['Float']>;
  TotalGroupDueIn?: Maybe<Scalars['Float']>;
  TotalGuest?: Maybe<Scalars['Float']>;
  TotalNonGuestFolio?: Maybe<Scalars['Float']>;
  TotalRegistrationDueOut?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalSuspendFolio?: Maybe<Scalars['Float']>;
  TotalUnAssignedRoomRegistration?: Maybe<Scalars['Float']>;
  TotalWalkIn?: Maybe<Scalars['Float']>;
};

export type FrontDeskInfoResponse = {
  __typename?: 'FrontDeskInfoResponse';
  AdvPaymentSum?: Maybe<Scalars['Float']>;
  BillScheduleSum?: Maybe<Scalars['Float']>;
  DepositSum?: Maybe<Scalars['Float']>;
  FolioCount?: Maybe<Scalars['Float']>;
  IncidentalChargesSum?: Maybe<Scalars['Float']>;
  PaymentSum?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ServiceRequestCount?: Maybe<Scalars['Float']>;
  TotalOutStandingAmount?: Maybe<Scalars['Float']>;
};

export type FrontDeskPaymentInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Amount?: InputMaybe<Scalars['Float']>;
  BaseAmount?: InputMaybe<Scalars['Float']>;
  BillLedgerID?: InputMaybe<Scalars['String']>;
  BillScheduleID?: InputMaybe<Scalars['String']>;
  BookingID: Scalars['String'];
  CardNo?: InputMaybe<Scalars['String']>;
  ChargeType?: InputMaybe<ChargeType>;
  CheckOutID?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  FromRegistration?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LedgerType?: InputMaybe<Scalars['String']>;
  LedgerTypeID?: InputMaybe<Scalars['String']>;
  ModuleName?: InputMaybe<Scalars['String']>;
  PaymentClass?: InputMaybe<PaymentClass>;
  PaymentStatus?: InputMaybe<PaymentStatus>;
  PaymentType?: InputMaybe<PaymentType>;
  RatePolicyID?: InputMaybe<Scalars['String']>;
  ReceiptNo?: InputMaybe<Scalars['String']>;
  ReferenceNo?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  RoomLedgerID?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  SplitGroup?: InputMaybe<Scalars['Float']>;
  TaxAmount?: InputMaybe<Scalars['Float']>;
  TransactionType?: InputMaybe<TransactionType>;
  TrxDate?: InputMaybe<Scalars['DateTime']>;
  eInvoiceType?: InputMaybe<Scalars['String']>;
};

export type FrontDeskResponse = {
  __typename?: 'FrontDeskResponse';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<ChargeType>;
  Code?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  DepositClass?: Maybe<DepositClass>;
  DepositID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  IncidentalAmt?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  PaymentAmt?: Maybe<Scalars['Float']>;
  PaymentID?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<PaymentType>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TransactionType?: Maybe<TransactionType>;
  TrxAmount?: Maybe<Scalars['Float']>;
};

export type GaInHouseResponse = {
  __typename?: 'GAInHouseResponse';
  GuestBilling?: Maybe<Array<PaymentFoilioResponse>>;
  GuestListing?: Maybe<Array<RegistrationEntity>>;
};

export type GlAccountPeriodEntity = {
  __typename?: 'GLAccountPeriodEntity';
  AccountID?: Maybe<Scalars['String']>;
  AccountPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  FPeriod?: Maybe<Scalars['String']>;
  FYear?: Maybe<Scalars['String']>;
  MonthEndClose?: Maybe<Scalars['Boolean']>;
  RecordStatus?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  YearEndClose?: Maybe<Scalars['Boolean']>;
};

export type GlExportEntity = InterfaceTrx & {
  __typename?: 'GLExportEntity';
  AccountCode?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Credit?: Maybe<Scalars['Float']>;
  Debit?: Maybe<Scalars['Float']>;
  Department?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Division?: Maybe<Scalars['String']>;
  GLDate: Scalars['DateTime'];
  GLType?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Registration?: Maybe<HotelEntity>;
  Source?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
};

export type GlInput = {
  Description?: InputMaybe<Scalars['String']>;
  DescriptionType?: InputMaybe<Scalars['String']>;
  GL?: InputMaybe<GuestLedgerAcctJsonInput>;
  ID?: InputMaybe<Scalars['String']>;
};

export type GlPostResponse = {
  __typename?: 'GLPostResponse';
  ExportedGLTransfer?: Maybe<Array<ExportedGlTransferResponse>>;
  GLPost?: Maybe<Scalars['Boolean']>;
};

export type GalleryEntity = InterfaceBase & {
  __typename?: 'GalleryEntity';
  AccountID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  ImageURL?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type GalleryInput = {
  AccountID: Scalars['String'];
  BucketFileName?: InputMaybe<Scalars['String']>;
  ID: Scalars['String'];
  ImageURL?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export enum Gender {
  Female = 'Female',
  Male = 'Male'
}

export type GeneralTinEntity = {
  __typename?: 'GeneralTinEntity';
  displaySeq?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  tin?: Maybe<Scalars['String']>;
  tinParty?: Maybe<Scalars['String']>;
};

export type GroupInHouseOutput = {
  __typename?: 'GroupInHouseOutput';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  GroupName?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type GuestAppFeatureEntity = InterfaceBase & {
  __typename?: 'GuestAppFeatureEntity';
  AccountID: Scalars['String'];
  Bills?: Maybe<Scalars['Boolean']>;
  Booking?: Maybe<Scalars['Boolean']>;
  Chargeable?: Maybe<Scalars['Boolean']>;
  CheckIn?: Maybe<Scalars['Boolean']>;
  CheckOut?: Maybe<Scalars['Boolean']>;
  CloseHour?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DefaultTrx?: Maybe<Scalars['String']>;
  DigitalKey?: Maybe<Scalars['Boolean']>;
  Directories?: Maybe<Scalars['Boolean']>;
  ExtendStay?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  Incidental?: Maybe<IncidentalChargeEntity>;
  IsActive?: Maybe<Scalars['Boolean']>;
  LateCheckOut?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  MyProfile?: Maybe<Scalars['Boolean']>;
  MyRequest?: Maybe<Scalars['Boolean']>;
  OnlinePayment?: Maybe<Scalars['Boolean']>;
  OnlineShop?: Maybe<Scalars['Boolean']>;
  OperationHour?: Maybe<Scalars['String']>;
  PackageRedemption?: Maybe<Scalars['Boolean']>;
  PreCheckinAdvTime?: Maybe<Scalars['Float']>;
  RoomService?: Maybe<Scalars['Boolean']>;
  ScanQR?: Maybe<Scalars['Boolean']>;
  SelfCheckin?: Maybe<Scalars['Boolean']>;
  SelfChooseRoom?: Maybe<Scalars['Boolean']>;
};

export type GuestAppSettingInput = {
  Bills?: InputMaybe<Scalars['Boolean']>;
  Booking?: InputMaybe<Scalars['Boolean']>;
  Chargeable?: InputMaybe<Scalars['Boolean']>;
  CheckIn?: InputMaybe<Scalars['Boolean']>;
  CheckOut?: InputMaybe<Scalars['Boolean']>;
  CloseHour?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  CreatedDT?: InputMaybe<Scalars['String']>;
  DefaultTrx?: InputMaybe<Scalars['String']>;
  DigitalKey?: InputMaybe<Scalars['Boolean']>;
  Directories?: InputMaybe<Scalars['Boolean']>;
  ExtendStay?: InputMaybe<Scalars['Boolean']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  LateCheckOut?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ModifiedDT?: InputMaybe<Scalars['String']>;
  MyProfile?: InputMaybe<Scalars['Boolean']>;
  MyRequest?: InputMaybe<Scalars['Boolean']>;
  OnlinePayment?: InputMaybe<Scalars['Boolean']>;
  OnlineShop?: InputMaybe<Scalars['Boolean']>;
  OperationHour?: InputMaybe<Scalars['String']>;
  PackageRedemption?: InputMaybe<Scalars['Boolean']>;
  PreCheckinAdvTime?: InputMaybe<Scalars['Float']>;
  RoomService?: InputMaybe<Scalars['Boolean']>;
  ScanQR?: InputMaybe<Scalars['Boolean']>;
  SelfCheckin?: InputMaybe<Scalars['Boolean']>;
  SelfChooseRoom?: InputMaybe<Scalars['Boolean']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type GuestLedgerAcctInput = {
  Acct?: InputMaybe<Scalars['String']>;
  Dept?: InputMaybe<Scalars['String']>;
  Div?: InputMaybe<Scalars['String']>;
  Type?: InputMaybe<Scalars['String']>;
};

export type GuestLedgerAcctJson = {
  __typename?: 'GuestLedgerAcctJson';
  ExpenseCredit?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  ExpenseType?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDiv?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseType?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type GuestLedgerAcctJsonInput = {
  ExpenseCredit?: InputMaybe<Scalars['String']>;
  ExpenseDebit?: InputMaybe<Scalars['String']>;
  ExpenseDept?: InputMaybe<Scalars['String']>;
  ExpenseDiv?: InputMaybe<Scalars['String']>;
  ExpenseType?: InputMaybe<Scalars['String']>;
  RevenueCredit?: InputMaybe<Scalars['String']>;
  RevenueDebit?: InputMaybe<Scalars['String']>;
  RevenueDept?: InputMaybe<Scalars['String']>;
  RevenueDiv?: InputMaybe<Scalars['String']>;
  ReverseCredit?: InputMaybe<Scalars['String']>;
  ReverseDebit?: InputMaybe<Scalars['String']>;
  ReverseDept?: InputMaybe<Scalars['String']>;
  ReverseDiv?: InputMaybe<Scalars['String']>;
  ReverseType?: InputMaybe<Scalars['String']>;
  Type?: InputMaybe<Scalars['String']>;
};

export type GuestMovementEntity = InterfaceBase & {
  __typename?: 'GuestMovementEntity';
  Booking?: Maybe<BookingEntity>;
  BookingID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  GuestID: Scalars['String'];
  GuestProfile?: Maybe<GuestProfileEntity>;
  Hotel?: Maybe<HotelEntity>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  RefID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type GuestMovementInput = {
  BookingID?: InputMaybe<Scalars['String']>;
  GuestID: Scalars['String'];
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  RefID?: InputMaybe<Scalars['String']>;
  Type?: InputMaybe<Scalars['String']>;
};

export type GuestPackageRedeem = {
  __typename?: 'GuestPackageRedeem';
  BillLedgerID?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  IsBillLedger?: Maybe<Scalars['Boolean']>;
  IsRedeem?: Maybe<Scalars['Boolean']>;
  Pax?: Maybe<Scalars['Float']>;
};

export type GuestProfileEntity = InterfaceBase & {
  __typename?: 'GuestProfileEntity';
  AccountID: Scalars['String'];
  Booking?: Maybe<Array<BookingEntity>>;
  Company?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
  ContactID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorID?: Maybe<Scalars['String']>;
  GuestBookedRoomList: Array<RegistrationEntity>;
  GuestHistoryCount: Scalars['Float'];
  GuestHistoryList: Array<RegistrationEntity>;
  GuestType?: Maybe<GuestType>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ICBackGallery?: Maybe<GalleryEntity>;
  ICGallery?: Maybe<GalleryEntity>;
  ID: Scalars['String'];
  IcBackPicture?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  LastYearOfVisit: Scalars['Float'];
  Login?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  NewsLetter?: Maybe<Scalars['Boolean']>;
  NoOfVisit: Scalars['Float'];
  PassportGallery?: Maybe<GalleryEntity>;
  PassportPicture?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  ServiceRequestCount: Scalars['Float'];
  SpecialRequest?: Maybe<Scalars['String']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
};

export type GuestProfileInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Company?: InputMaybe<Scalars['String']>;
  ContactID: Scalars['String'];
  CreatedBy?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  GuestType?: InputMaybe<GuestType>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID: Scalars['String'];
  IcBackPicture?: InputMaybe<Scalars['String']>;
  IdPicture?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsTTx?: InputMaybe<Scalars['Boolean']>;
  IsVIP?: InputMaybe<Scalars['Boolean']>;
  Login?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  NewsLetter?: InputMaybe<Scalars['Boolean']>;
  PassportPicture?: InputMaybe<Scalars['String']>;
  Password?: InputMaybe<Scalars['String']>;
  SpecialRequest?: InputMaybe<Scalars['String']>;
  TTxReasonID?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
  VehicleNo?: InputMaybe<Scalars['String']>;
};

export type GuestProfileResponse = {
  __typename?: 'GuestProfileResponse';
  GuestProfile?: Maybe<Array<GuestProfileEntity>>;
  TotalGuestProfile?: Maybe<Scalars['Float']>;
};

export type GuestReviewInfoLineResponse = {
  __typename?: 'GuestReviewInfoLineResponse';
  InActivated?: Maybe<Scalars['Float']>;
  LessThan3Star?: Maybe<Scalars['Float']>;
  MoreThan3Star?: Maybe<Scalars['Float']>;
};

export enum GuestType {
  Blacklist = 'Blacklist',
  Normal = 'Normal',
  Skipper = 'Skipper',
  Vip1 = 'VIP1',
  Vip2 = 'VIP2',
  Vip3 = 'VIP3',
  Vip4 = 'VIP4',
  Vip5 = 'VIP5'
}

export type HlsBookingRecordEntity = InterfaceBase & {
  __typename?: 'HLSBookingRecordEntity';
  BookingID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ReservationPayload?: Maybe<Scalars['String']>;
};

export type HlsBookingRecordInput = {
  BookingID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ProcessCount?: InputMaybe<Scalars['Float']>;
  Processed?: InputMaybe<Scalars['Boolean']>;
  ReservationPayload?: InputMaybe<Scalars['String']>;
};

export type HotelAvailabilityOutput = {
  __typename?: 'HotelAvailabilityOutput';
  Occupancy?: Maybe<Array<Occupancy>>;
  RoomTypesList?: Maybe<Array<RoomTypesListOutput>>;
};

export type HotelEntity = InterfaceBase & {
  __typename?: 'HotelEntity';
  ARSpecification?: Maybe<ArSpecification>;
  AboutHotel?: Maybe<Scalars['String']>;
  AboutHotelGalleryID?: Maybe<Scalars['String']>;
  AboutHotelImage?: Maybe<GalleryEntity>;
  AccountID: Scalars['String'];
  AdvanceForfeitTaxID?: Maybe<Scalars['String']>;
  AllowCancellationDays?: Maybe<Scalars['Float']>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  BlockRoomReleaseStatus?: Maybe<RoomStatusEntity>;
  BlockRoomReleaseStatusID?: Maybe<Scalars['String']>;
  Booking?: Maybe<Array<BookingEntity>>;
  CancelCheckInRoomStatus?: Maybe<RoomStatusEntity>;
  CancelCheckInRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  CancelCheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CeilingPercentage?: Maybe<Scalars['Float']>;
  ChannelManager?: Maybe<ChannelManagerEntity>;
  ChannelManagerID?: Maybe<Scalars['String']>;
  CheckInPrompt: Scalars['Boolean'];
  CheckInRoomStatus?: Maybe<RoomStatusEntity>;
  CheckInRoomStatusID?: Maybe<Scalars['String']>;
  CheckInTime?: Maybe<Scalars['DateTime']>;
  CheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  CheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CheckOutTime?: Maybe<Scalars['DateTime']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Complimentary?: Maybe<RateEntity>;
  ComplimentaryID?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
  ContactID?: Maybe<Scalars['String']>;
  CorporateURL?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Scalars['String']>;
  DayUse?: Maybe<RateEntity>;
  DayUseID?: Maybe<Scalars['String']>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  DebtorType?: Maybe<Array<DebtorTypeEntity>>;
  Denomination?: Maybe<Scalars['Float']>;
  Department?: Maybe<Array<DepartmentEntity>>;
  DepositForfeitTaxID?: Maybe<Scalars['String']>;
  DocumentNumbering?: Maybe<DocumentNumberingEntity>;
  DocumentRemark?: Maybe<Scalars['String']>;
  EInvEffectiveDate?: Maybe<Scalars['DateTime']>;
  EInvoiceAdvPaymentClassification?: Maybe<Scalars['String']>;
  EInvoiceDepositForfeitClassification?: Maybe<Scalars['String']>;
  EInvoiceRCClassification?: Maybe<Scalars['String']>;
  EInvoiceSCClassification?: Maybe<Scalars['String']>;
  EInvoiceStateTaxClassification?: Maybe<Scalars['String']>;
  EmailBlastDays?: Maybe<Scalars['Float']>;
  EmailCampign?: Maybe<Array<EmailCampignEntity>>;
  Encoders?: Maybe<Scalars['JSON']>;
  Facility?: Maybe<Array<FacilityEntity>>;
  FloorPercentage?: Maybe<Scalars['Float']>;
  FloorPriceKickInDays?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Array<FolioEntity>>;
  GuestAppURL?: Maybe<Scalars['String']>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
  GuestNotice?: Maybe<Scalars['Boolean']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  HTx?: Maybe<Scalars['Float']>;
  HotelAddress?: Maybe<Address>;
  HotelAdjustmentIncidentalID?: Maybe<Scalars['String']>;
  HotelCode?: Maybe<Scalars['String']>;
  HotelDebtor?: Maybe<DebtorEntity>;
  HotelGallery?: Maybe<Array<HotelGalleryEntity>>;
  HotelName?: Maybe<Scalars['String']>;
  HotelRate?: Maybe<Array<RoomTypeEntity>>;
  HotelRating?: Maybe<Array<HotelRatingEntity>>;
  HotelType?: Maybe<PropertyType>;
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  HouseUse?: Maybe<RateEntity>;
  HouseUseID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  IsCmsAllotment?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  IsDayUse?: Maybe<Scalars['Boolean']>;
  IsFloorPlan?: Maybe<Scalars['Boolean']>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsInclusive?: Maybe<Scalars['Boolean']>;
  IsOOI?: Maybe<Scalars['Boolean']>;
  IsOOO?: Maybe<Scalars['Boolean']>;
  IsOnlineBooking?: Maybe<Scalars['Boolean']>;
  IsOnlinePayment?: Maybe<Scalars['Boolean']>;
  IsPostPaid?: Maybe<Scalars['Boolean']>;
  IsRoomTaxable?: Maybe<Scalars['Boolean']>;
  KeycardDeposit?: Maybe<Scalars['Float']>;
  KeycardVendor?: Maybe<Scalars['String']>;
  LateCheckOutCharge?: Maybe<Array<LateCheckOutChargeEntity>>;
  Levy?: Maybe<Scalars['Float']>;
  LiveDate?: Maybe<Scalars['DateTime']>;
  Location?: Maybe<Array<LocationEntity>>;
  Logo?: Maybe<Scalars['String']>;
  LogoImage?: Maybe<GalleryEntity>;
  MSICCode?: Maybe<Scalars['String']>;
  MSICDesc?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  NAProcessTime?: Maybe<Scalars['DateTime']>;
  News?: Maybe<Array<NewsEntity>>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  OnlinePaymentStoreID?: Maybe<Scalars['String']>;
  OnlinePaymentTnC?: Maybe<Scalars['String']>;
  OnlineTnC?: Maybe<Scalars['String']>;
  PDPA?: Maybe<Scalars['String']>;
  Payment?: Maybe<Array<PaymentEntity>>;
  PaymentGateway?: Maybe<PaymentGatewayEntity>;
  PaymentGatewayID?: Maybe<Scalars['String']>;
  Price?: Maybe<Array<PricingOutput>>;
  Promotion?: Maybe<Array<PromotionEntity>>;
  Q3RevenueMapping?: Maybe<HotelQ3RevenueAcctJson>;
  Q3StatisticsMapping?: Maybe<Q3StatisticsMapping>;
  RatePolicy?: Maybe<Array<RateEntity>>;
  RegCardTnC?: Maybe<Scalars['String']>;
  RegulationClause?: Maybe<Scalars['String']>;
  Room?: Maybe<Array<RoomEntity>>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  RoomStatus?: Maybe<Array<RoomStatusEntity>>;
  RoomType?: Maybe<Array<RoomTypeEntity>>;
  RoundingGLAcct?: Maybe<GuestLedgerAcctJson>;
  RoundingMethod?: Maybe<Scalars['String']>;
  SOAFooter?: Maybe<Scalars['String']>;
  SOAImage?: Maybe<GalleryEntity>;
  SSTRegNo?: Maybe<Scalars['String']>;
  SeasonCalendar?: Maybe<Array<SeasonCalendarEntity>>;
  SecurityDeposit?: Maybe<Scalars['Float']>;
  Segment?: Maybe<Array<SegmentEntity>>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
  StateFund?: Maybe<Scalars['Boolean']>;
  TTx?: Maybe<Scalars['Float']>;
  TTxRegNo?: Maybe<Scalars['String']>;
  Tax?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TransferRoomStatus?: Maybe<RoomStatusEntity>;
  TransferRoomStatusID?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  WeightedAverage: Scalars['Float'];
};

export type HotelGalleryEntity = InterfaceBase & {
  __typename?: 'HotelGalleryEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type HotelGalleryInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsDefault?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type HotelInput = {
  ARSpecification?: InputMaybe<ArSpecificationInput>;
  AboutHotel?: InputMaybe<Scalars['String']>;
  AboutHotelGalleryID?: InputMaybe<Scalars['String']>;
  AccountID?: InputMaybe<Scalars['String']>;
  AdvanceForfeitTaxID?: InputMaybe<Scalars['String']>;
  AllowCancellationDays?: InputMaybe<Scalars['Float']>;
  BlockRoomReleaseStatusID?: InputMaybe<Scalars['String']>;
  CancelCheckInRoomStatusID?: InputMaybe<Scalars['String']>;
  CancelCheckOutRoomStatusID?: InputMaybe<Scalars['String']>;
  CeilingPercentage?: InputMaybe<Scalars['Float']>;
  ChannelManagerID?: InputMaybe<Scalars['String']>;
  CheckInPrompt?: InputMaybe<Scalars['Boolean']>;
  CheckInRoomStatusID?: InputMaybe<Scalars['String']>;
  CheckInTime?: InputMaybe<Scalars['DateTime']>;
  CheckOutRoomStatusID?: InputMaybe<Scalars['String']>;
  CheckOutTime?: InputMaybe<Scalars['DateTime']>;
  CompanyID?: InputMaybe<Scalars['String']>;
  CompanyName?: InputMaybe<Scalars['String']>;
  CompanyRegNo?: InputMaybe<Scalars['String']>;
  ComplimentaryID?: InputMaybe<Scalars['String']>;
  ContactID?: InputMaybe<Scalars['String']>;
  CorporateURL?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Currency?: InputMaybe<Scalars['String']>;
  DayUseID?: InputMaybe<Scalars['String']>;
  DepositForfeitTaxID?: InputMaybe<Scalars['String']>;
  DocumentRemark?: InputMaybe<Scalars['String']>;
  EInvoiceAdvPaymentClassification?: InputMaybe<Scalars['String']>;
  EInvoiceDepositForfeitClassification?: InputMaybe<Scalars['String']>;
  EInvoiceRCClassification?: InputMaybe<Scalars['String']>;
  EInvoiceSCClassification?: InputMaybe<Scalars['String']>;
  EInvoiceStateTaxClassification?: InputMaybe<Scalars['String']>;
  EmailBlastDays?: InputMaybe<Scalars['Float']>;
  FloorPercentage?: InputMaybe<Scalars['Float']>;
  FloorPriceKickInDays?: InputMaybe<Scalars['Float']>;
  GuestAppURL?: InputMaybe<Scalars['String']>;
  GuestNotice?: InputMaybe<Scalars['Boolean']>;
  HTx?: InputMaybe<Scalars['Float']>;
  HotelAddress?: InputMaybe<AddressInput>;
  HotelCode?: InputMaybe<Scalars['String']>;
  HotelName?: InputMaybe<Scalars['String']>;
  HotelType?: InputMaybe<PropertyType>;
  HouseUseID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsChargeToRoom?: InputMaybe<Scalars['Boolean']>;
  IsCmsAllotment?: InputMaybe<Scalars['Boolean']>;
  IsComplimentary?: InputMaybe<Scalars['Boolean']>;
  IsDayUse?: InputMaybe<Scalars['Boolean']>;
  IsFloorPlan?: InputMaybe<Scalars['Boolean']>;
  IsHouseUse?: InputMaybe<Scalars['Boolean']>;
  IsInclusive?: InputMaybe<Scalars['Boolean']>;
  IsKiosk?: InputMaybe<Scalars['Boolean']>;
  IsOOI?: InputMaybe<Scalars['Boolean']>;
  IsOOO?: InputMaybe<Scalars['Boolean']>;
  IsOnlineBooking?: InputMaybe<Scalars['Boolean']>;
  IsOnlinePayment?: InputMaybe<Scalars['Boolean']>;
  IsPostPaid?: InputMaybe<Scalars['Boolean']>;
  IsRoomTaxable?: InputMaybe<Scalars['Boolean']>;
  KeycardDeposit?: InputMaybe<Scalars['Float']>;
  Levy?: InputMaybe<Scalars['Float']>;
  Logo?: InputMaybe<Scalars['String']>;
  MSICCode?: InputMaybe<Scalars['String']>;
  MSICDesc?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  NAProcessTime?: InputMaybe<Scalars['DateTime']>;
  OnlineBookingURL?: InputMaybe<Scalars['String']>;
  OnlinePaymentTnC?: InputMaybe<Scalars['String']>;
  OnlineTnC?: InputMaybe<Scalars['String']>;
  PDPA?: InputMaybe<Scalars['String']>;
  PaymentGatewayID?: InputMaybe<Scalars['String']>;
  Q3StatisticsMapping?: InputMaybe<Q3StatisticsMappingInput>;
  RegCardTnC?: InputMaybe<Scalars['String']>;
  RegulationClause?: InputMaybe<Scalars['String']>;
  SOAFooter?: InputMaybe<Scalars['String']>;
  SSTRegNo?: InputMaybe<Scalars['String']>;
  SecurityDeposit?: InputMaybe<Scalars['Float']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  StateFund?: InputMaybe<Scalars['Boolean']>;
  TTx?: InputMaybe<Scalars['Float']>;
  Tax?: InputMaybe<Scalars['Float']>;
  TransferRoomStatusID?: InputMaybe<Scalars['String']>;
  TravelAgentURL?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type HotelNotificationEntity = InterfaceBase & {
  __typename?: 'HotelNotificationEntity';
  AccountID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  NotificationType?: Maybe<Scalars['String']>;
  ReadStatus?: Maybe<Scalars['Boolean']>;
  RefID?: Maybe<Scalars['String']>;
  RefItem?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  User?: Maybe<UserEntity>;
  UserID?: Maybe<Scalars['String']>;
};

export type HotelNotificationInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  CreatedDT?: InputMaybe<Scalars['DateTime']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  Message?: InputMaybe<Scalars['String']>;
  NotificationType?: InputMaybe<Scalars['String']>;
  ReadStatus?: InputMaybe<Scalars['Boolean']>;
  RefID?: InputMaybe<Scalars['String']>;
  RefItem?: InputMaybe<Scalars['String']>;
  RefTable?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

/** These permissions are latest applicable for HMS */
export enum HotelPermission {
  AcctJournalAcctMappingBankAcctActive = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____ACTIVE',
  AcctJournalAcctMappingBankAcctCreate = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____CREATE',
  AcctJournalAcctMappingBankAcctDelete = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____DELETE',
  AcctJournalAcctMappingBankAcctEdit = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____EDIT',
  AcctJournalAcctMappingBankAcctView = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____VIEW',
  AcctJournalAcctMappingCityGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_CITY_GL____EDIT',
  AcctJournalAcctMappingCityGlView = 'ACCT_JOURNAL_ACCT_MAPPING_CITY_GL____VIEW',
  AcctJournalAcctMappingHotelGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_HOTEL_GL____EDIT',
  AcctJournalAcctMappingHotelGlView = 'ACCT_JOURNAL_ACCT_MAPPING_HOTEL_GL____VIEW',
  AcctJournalAcctMappingTaxGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_TAX_GL____EDIT',
  AcctJournalAcctMappingTaxGlView = 'ACCT_JOURNAL_ACCT_MAPPING_TAX_GL____VIEW',
  AcctJournalAcctMappingView = 'ACCT_JOURNAL_ACCT_MAPPING_______VIEW',
  AcctJournalCbJournalDetailIndividualDownload = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_DOWNLOAD',
  AcctJournalCbJournalDetailIndividualShare = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_SHARE',
  AcctJournalCbJournalDetailIndividualView = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_VIEW',
  AcctJournalCbJournalDetailDownload = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____DOWNLOAD',
  AcctJournalCbJournalDetailShare = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____SHARE',
  AcctJournalCbJournalDetailView = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____VIEW',
  AcctJournalCbJournalCreate = 'ACCT_JOURNAL_CB_JOURNAL_______CREATE',
  AcctJournalCbJournalDownload = 'ACCT_JOURNAL_CB_JOURNAL_______DOWNLOAD',
  AcctJournalCbJournalShare = 'ACCT_JOURNAL_CB_JOURNAL_______SHARE',
  AcctJournalCbJournalView = 'ACCT_JOURNAL_CB_JOURNAL_______VIEW',
  AcctJournalGlJournalDetailIndividualDownload = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_DOWNLOAD',
  AcctJournalGlJournalDetailIndividualShare = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_SHARE',
  AcctJournalGlJournalDetailIndividualView = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_VIEW',
  AcctJournalGlJournalDetailDownload = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____DOWNLOAD',
  AcctJournalGlJournalDetailShare = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____SHARE',
  AcctJournalGlJournalDetailView = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____VIEW',
  AcctJournalGlJournalCreate = 'ACCT_JOURNAL_GL_JOURNAL_______CREATE',
  AcctJournalGlJournalDownload = 'ACCT_JOURNAL_GL_JOURNAL_______DOWNLOAD',
  AcctJournalGlJournalShare = 'ACCT_JOURNAL_GL_JOURNAL_______SHARE',
  AcctJournalGlJournalView = 'ACCT_JOURNAL_GL_JOURNAL_______VIEW',
  AcctJournalIgnoreView = 'ACCT_JOURNAL_IGNORE_______VIEW',
  BookingAdvancePaymentInfoCreate = 'BOOKING_ADVANCE_PAYMENT_INFO____CREATE',
  BookingAdvancePaymentInfoEdit = 'BOOKING_ADVANCE_PAYMENT_INFO____EDIT',
  BookingAdvancePaymentInfoView = 'BOOKING_ADVANCE_PAYMENT_INFO____VIEW',
  BookingAttachmentsCreate = 'BOOKING_ATTACHMENTS____CREATE',
  BookingAttachmentsDelete = 'BOOKING_ATTACHMENTS____DELETE',
  BookingAttachmentsEdit = 'BOOKING_ATTACHMENTS____EDIT',
  BookingAttachmentsView = 'BOOKING_ATTACHMENTS_______VIEW',
  BookingAuditlogView = 'BOOKING_AUDITLOG_______VIEW',
  BookingBillingInstructionCreate = 'BOOKING_BILLING_INSTRUCTION_______CREATE',
  BookingBillingInstructionView = 'BOOKING_BILLING_INSTRUCTION_______VIEW',
  BookingBillingScheduleEdit = 'BOOKING_BILLING_SCHEDULE_______EDIT',
  BookingBillingScheduleView = 'BOOKING_BILLING_SCHEDULE_______VIEW',
  BookingBookingInfoEdit = 'BOOKING_BOOKING_INFO_______EDIT',
  BookingCancelBookingCancel = 'BOOKING_CANCEL_BOOKING_______CANCEL',
  BookingCancelRoomCancel = 'BOOKING_CANCEL_ROOM_______CANCEL',
  BookingCloneBookingCreate = 'BOOKING_CLONE_BOOKING_______CREATE',
  BookingConfirmLetterView = 'BOOKING_CONFIRM_LETTER_______VIEW',
  BookingHouseLimitCreate = 'BOOKING_HOUSE_LIMIT_______CREATE',
  BookingHouseLimitView = 'BOOKING_HOUSE_LIMIT_______VIEW',
  BookingIgnoreCreate = 'BOOKING_IGNORE_______CREATE',
  BookingIgnoreView = 'BOOKING_IGNORE_______VIEW',
  BookingRoomingListListCreate = 'BOOKING_ROOMING_LIST_LIST____CREATE',
  BookingRoomingListListDelete = 'BOOKING_ROOMING_LIST_LIST____DELETE',
  BookingRoomingListListEdit = 'BOOKING_ROOMING_LIST_LIST____EDIT',
  BookingRoomingListListView = 'BOOKING_ROOMING_LIST_LIST____VIEW',
  BookingRoomAssignmentListAssignment = 'BOOKING_ROOM_ASSIGNMENT_LIST____ASSIGNMENT',
  BookingRoomAssignmentListView = 'BOOKING_ROOM_ASSIGNMENT_LIST____VIEW',
  BookingSpecialRequestCreate = 'BOOKING_SPECIAL_REQUEST_______CREATE',
  BookingSpecialRequestView = 'BOOKING_SPECIAL_REQUEST_______VIEW',
  BusinessInsightAnalyticsAmountDueByAgingView = 'BUSINESS_INSIGHT_ANALYTICS_AMOUNT_DUE_BY_AGING____VIEW',
  BusinessInsightAnalyticsCollectionByDebtortypeView = 'BUSINESS_INSIGHT_ANALYTICS_COLLECTION_BY_DEBTORTYPE____VIEW',
  BusinessInsightAnalyticsCountryAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_COUNTRY_ANALYSIS____VIEW',
  BusinessInsightAnalyticsCustomerByRevenueView = 'BUSINESS_INSIGHT_ANALYTICS_CUSTOMER_BY_REVENUE____VIEW',
  BusinessInsightAnalyticsDebtorAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_DEBTOR_ANALYSIS____VIEW',
  BusinessInsightAnalyticsDebtorLedgerStatusView = 'BUSINESS_INSIGHT_ANALYTICS_DEBTOR_LEDGER_STATUS____VIEW',
  BusinessInsightAnalyticsHotelStatisticView = 'BUSINESS_INSIGHT_ANALYTICS_HOTEL_STATISTIC____VIEW',
  BusinessInsightAnalyticsHotelStatusView = 'BUSINESS_INSIGHT_ANALYTICS_HOTEL_STATUS____VIEW',
  BusinessInsightAnalyticsMaidProductivityView = 'BUSINESS_INSIGHT_ANALYTICS_MAID_PRODUCTIVITY____VIEW',
  BusinessInsightAnalyticsMarketingPersonnalView = 'BUSINESS_INSIGHT_ANALYTICS_MARKETING_PERSONNAL____VIEW',
  BusinessInsightAnalyticsNationalityAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_NATIONALITY_ANALYSIS____VIEW',
  BusinessInsightAnalyticsOnlineTravelAgentView = 'BUSINESS_INSIGHT_ANALYTICS_ONLINE_TRAVEL_AGENT____VIEW',
  BusinessInsightAnalyticsRatingReviewAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_RATING_REVIEW_ANALYSIS____VIEW',
  BusinessInsightAnalyticsRevenueAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsRevenueByRoomtypeView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_BY_ROOMTYPE____VIEW',
  BusinessInsightAnalyticsRevenueByTransactionView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_BY_TRANSACTION____VIEW',
  BusinessInsightAnalyticsSegmentAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_SEGMENT_ANALYSIS____VIEW',
  BusinessInsightAnalyticsServiceRequestView = 'BUSINESS_INSIGHT_ANALYTICS_SERVICE_REQUEST____VIEW',
  BusinessInsightAnalyticsSourceAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_SOURCE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsStateAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_STATE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsTotalAgingView = 'BUSINESS_INSIGHT_ANALYTICS_TOTAL_AGING____VIEW',
  BusinessInsightAnalyticsView = 'BUSINESS_INSIGHT_ANALYTICS_______VIEW',
  BusinessInsightDigitalDocumentDepositPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_DEPOSIT____PRINT',
  BusinessInsightDigitalDocumentDepositShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_DEPOSIT____SHARE',
  BusinessInsightDigitalDocumentDepositView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_DEPOSIT____VIEW',
  BusinessInsightDigitalDocumentFolioPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____PRINT',
  BusinessInsightDigitalDocumentFolioShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____SHARE',
  BusinessInsightDigitalDocumentFolioView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____VIEW',
  BusinessInsightDigitalDocumentReceiptPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____PRINT',
  BusinessInsightDigitalDocumentReceiptShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____SHARE',
  BusinessInsightDigitalDocumentReceiptView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____VIEW',
  BusinessInsightDigitalDocumentRegistrationCardPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____PRINT',
  BusinessInsightDigitalDocumentRegistrationCardShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____SHARE',
  BusinessInsightDigitalDocumentRegistrationCardView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____VIEW',
  BusinessInsightDigitalDocumentView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_______VIEW',
  BusinessInsightDigitalReportBookingAdvancePaymentListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_BOOKING_ADVANCE_PAYMENT_LISTING____VIEW',
  BusinessInsightDigitalReportBookingStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_BOOKING_STATUS____VIEW',
  BusinessInsightDigitalReportCashierCollectionSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_COLLECTION_SUMMARY____VIEW',
  BusinessInsightDigitalReportCashierDetailListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_DETAIL_LISTING____VIEW',
  BusinessInsightDigitalReportCashierSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_SUMMARY____VIEW',
  BusinessInsightDigitalReportCityLedgerTransactionView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CITY_LEDGER_TRANSACTION____VIEW',
  BusinessInsightDigitalReportConsolidatedEinvoiceReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CONSOLIDATED_EINVOICE_REPORT____VIEW',
  BusinessInsightDigitalReportDailyPostingDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DAILY_POSTING_DETAIL____VIEW',
  BusinessInsightDigitalReportDebtorAgingDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_AGING_DETAIL____VIEW',
  BusinessInsightDigitalReportDebtorAgingSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_AGING_SUMMARY____VIEW',
  BusinessInsightDigitalReportDebtorAllocationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_ALLOCATION____VIEW',
  BusinessInsightDigitalReportDebtorLedgerDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_LEDGER_DETAIL____VIEW',
  BusinessInsightDigitalReportDebtorLedgerView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_LEDGER____VIEW',
  BusinessInsightDigitalReportDebtorRevenueView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_REVENUE____VIEW',
  BusinessInsightDigitalReportDebtorStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_STATUS____VIEW',
  BusinessInsightDigitalReportDownloadGlView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DOWNLOAD_GL____VIEW',
  BusinessInsightDigitalReportEinvoiceListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_EINVOICE_LISTING____VIEW',
  BusinessInsightDigitalReportEinvoiceReconciliationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_EINVOICE_RECONCILIATION____VIEW',
  BusinessInsightDigitalReportGroupReservationSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GROUP_RESERVATION_SUMMARY____VIEW',
  BusinessInsightDigitalReportGroupReservationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GROUP_RESERVATION____VIEW',
  BusinessInsightDigitalReportGuestBalanceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GUEST_BALANCE____VIEW',
  BusinessInsightDigitalReportGuestRoomView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GUEST_ROOM____VIEW',
  BusinessInsightDigitalReportHistoricalForecastView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_HISTORICAL_FORECAST____VIEW',
  BusinessInsightDigitalReportInhouseGuestView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_INHOUSE_GUEST____VIEW',
  BusinessInsightDigitalReportManagerReportFinancialView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_MANAGER_REPORT_FINANCIAL____VIEW',
  BusinessInsightDigitalReportManagerReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_MANAGER_REPORT____VIEW',
  BusinessInsightDigitalReportOccupiedView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_OCCUPIED____VIEW',
  BusinessInsightDigitalReportOutstandingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_OUTSTANDING____VIEW',
  BusinessInsightDigitalReportPackageListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PACKAGE_LISTING____VIEW',
  BusinessInsightDigitalReportProductionCorpGovView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_CORP_GOV____VIEW',
  BusinessInsightDigitalReportProductionRateCodeeView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_RATE_CODEE____VIEW',
  BusinessInsightDigitalReportProductionRoomNoView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_ROOM_NO___VIEW',
  BusinessInsightDigitalReportProductionRoomTypeView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_ROOM_TYPE____VIEW',
  BusinessInsightDigitalReportProductionSalesChannelView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SALES_CHANNEL____VIEW',
  BusinessInsightDigitalReportProductionSegmentView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SEGMENT____VIEW',
  BusinessInsightDigitalReportProductionSourceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SOURCE____VIEW',
  BusinessInsightDigitalReportProductionStateView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_STATE____VIEW',
  BusinessInsightDigitalReportRefundableDepositListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_REFUNDABLE_DEPOSIT_LISTING____VIEW',
  BusinessInsightDigitalReportRoomAvailabilityView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_AVAILABILITY____VIEW',
  BusinessInsightDigitalReportRoomDiscrepancyView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_DISCREPANCY____VIEW',
  BusinessInsightDigitalReportRoomMaintenanceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_MAINTENANCE____VIEW',
  BusinessInsightDigitalReportRoomStatusMovementView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_STATUS_MOVEMENT____VIEW',
  BusinessInsightDigitalReportRoomStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_STATUS____VIEW',
  BusinessInsightDigitalReportSalesAndServicesListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SALES_AND_SERVICES_LISTING____VIEW',
  BusinessInsightDigitalReportSecurityDepositView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SECURITY_DEPOSIT____VIEW',
  BusinessInsightDigitalReportServiceRequestView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SERVICE_REQUEST____VIEW',
  BusinessInsightDigitalReportStatementOfAccountOutstandingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_STATEMENT_OF_ACCOUNT_OUTSTANDING____VIEW',
  BusinessInsightDigitalReportStatementOfAccountView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_STATEMENT_OF_ACCOUNT____VIEW',
  BusinessInsightDigitalReportTourismTaxView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TOURISM_TAX____VIEW',
  BusinessInsightDigitalReportTransactionCancelView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_CANCEL____VIEW',
  BusinessInsightDigitalReportTransactionLedgerView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_LEDGER____VIEW',
  BusinessInsightDigitalReportTransactionTransferView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_TRANSFER____VIEW',
  BusinessInsightDigitalReportUserActivityLogReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_USER_ACTIVITY_LOG_REPORT____VIEW',
  BusinessInsightDigitalReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_______VIEW',
  BusinessInsightIgnoreView = 'BUSINESS_INSIGHT_IGNORE_______VIEW',
  CheckoutAdjustmentIgnoreBenificiary = 'CHECKOUT_ADJUSTMENT_IGNORE_______BENIFICIARY',
  CheckoutAdjustmentIgnoreChangePayment = 'CHECKOUT_ADJUSTMENT_IGNORE_______CHANGE_PAYMENT',
  CheckoutAdjustmentIgnoreCreate = 'CHECKOUT_ADJUSTMENT_IGNORE_______CREATE',
  CheckoutAdjustmentIgnoreCreditNote = 'CHECKOUT_ADJUSTMENT_IGNORE_______CREDIT_NOTE',
  CheckoutAdjustmentIgnoreDebitNote = 'CHECKOUT_ADJUSTMENT_IGNORE_______DEBIT_NOTE',
  CheckoutAdjustmentIgnoreShare = 'CHECKOUT_ADJUSTMENT_IGNORE_______SHARE',
  CheckoutAdjustmentIgnoreView = 'CHECKOUT_ADJUSTMENT_IGNORE_______VIEW',
  CityLedgerAccountTransactionCreditNoteAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____ALLOCATE',
  CityLedgerAccountTransactionCreditNoteCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____CANCEL',
  CityLedgerAccountTransactionCreditNoteCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____CREATE',
  CityLedgerAccountTransactionCreditNoteDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____DRAFT',
  CityLedgerAccountTransactionCreditNoteEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____EDIT',
  CityLedgerAccountTransactionCreditNotePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____PRINT',
  CityLedgerAccountTransactionCreditNoteSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____SUBMIT',
  CityLedgerAccountTransactionCreditNoteView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____VIEW',
  CityLedgerAccountTransactionDebitNoteAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____ALLOCATE',
  CityLedgerAccountTransactionDebitNoteCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____CANCEL',
  CityLedgerAccountTransactionDebitNoteCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____CREATE',
  CityLedgerAccountTransactionDebitNoteDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____DRAFT',
  CityLedgerAccountTransactionDebitNoteEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____EDIT',
  CityLedgerAccountTransactionDebitNotePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____PRINT',
  CityLedgerAccountTransactionDebitNoteSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____SUBMIT',
  CityLedgerAccountTransactionDebitNoteView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____VIEW',
  CityLedgerAccountTransactionGroupInvoiceAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____ALLOCATE',
  CityLedgerAccountTransactionGroupInvoiceCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____CANCEL',
  CityLedgerAccountTransactionGroupInvoiceCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____CREATE',
  CityLedgerAccountTransactionGroupInvoiceDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____DRAFT',
  CityLedgerAccountTransactionGroupInvoiceEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____EDIT',
  CityLedgerAccountTransactionGroupInvoicePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____PRINT',
  CityLedgerAccountTransactionGroupInvoiceSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____SUBMIT',
  CityLedgerAccountTransactionGroupInvoiceView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____VIEW',
  CityLedgerAccountTransactionInvoiceAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____ALLOCATE',
  CityLedgerAccountTransactionInvoiceCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____CANCEL',
  CityLedgerAccountTransactionInvoiceCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____CREATE',
  CityLedgerAccountTransactionInvoiceDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____DRAFT',
  CityLedgerAccountTransactionInvoiceEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____EDIT',
  CityLedgerAccountTransactionInvoicePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____PRINT',
  CityLedgerAccountTransactionInvoiceSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____SUBMIT',
  CityLedgerAccountTransactionInvoiceView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____VIEW',
  CityLedgerAccountTransactionOfficialReceiptAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____ALLOCATE',
  CityLedgerAccountTransactionOfficialReceiptCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____CANCEL',
  CityLedgerAccountTransactionOfficialReceiptCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____CREATE',
  CityLedgerAccountTransactionOfficialReceiptDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____DRAFT',
  CityLedgerAccountTransactionOfficialReceiptEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____EDIT',
  CityLedgerAccountTransactionOfficialReceiptPrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____PRINT',
  CityLedgerAccountTransactionOfficialReceiptSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____SUBMIT',
  CityLedgerAccountTransactionOfficialReceiptView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____VIEW',
  CityLedgerAccountTransactionRefundAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____ALLOCATE',
  CityLedgerAccountTransactionRefundCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____CANCEL',
  CityLedgerAccountTransactionRefundCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____CREATE',
  CityLedgerAccountTransactionRefundDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____DRAFT',
  CityLedgerAccountTransactionRefundEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____EDIT',
  CityLedgerAccountTransactionRefundPrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____PRINT',
  CityLedgerAccountTransactionRefundSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____SUBMIT',
  CityLedgerAccountTransactionRefundView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____VIEW',
  CityLedgerAccountTransactionView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_______VIEW',
  CityLedgerCreditControlView = 'CITY_LEDGER_CREDIT_CONTROL_______VIEW',
  CityLedgerIgnoreView = 'CITY_LEDGER_IGNORE_______VIEW',
  CityLedgerScheduleBillingPost = 'CITY_LEDGER_SCHEDULE_BILLING_______POST',
  CityLedgerScheduleBillingView = 'CITY_LEDGER_SCHEDULE_BILLING_______VIEW',
  CityLedgerSetupDebtorProfileActive = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____ACTIVE',
  CityLedgerSetupDebtorProfileCreate = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____CREATE',
  CityLedgerSetupDebtorProfileEdit = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____EDIT',
  CityLedgerSetupDebtorProfileView = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____VIEW',
  CityLedgerSetupDebtorTypeCreate = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____CREATE',
  CityLedgerSetupDebtorTypeDelete = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____DELETE',
  CityLedgerSetupDebtorTypeEdit = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____EDIT',
  CityLedgerSetupDebtorTypeView = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____VIEW',
  CityLedgerSetupView = 'CITY_LEDGER_SETUP_______VIEW',
  CityLedgerStatementOfAccShare = 'CITY_LEDGER_STATEMENT_OF_ACC_______SHARE',
  CityLedgerStatementOfAccView = 'CITY_LEDGER_STATEMENT_OF_ACC_______VIEW',
  CommonSettingsCompanyListingActive = 'COMMON_SETTINGS_COMPANY_LISTING_______ACTIVE',
  CommonSettingsCompanyListingCreate = 'COMMON_SETTINGS_COMPANY_LISTING_______CREATE',
  CommonSettingsCompanyListingEdit = 'COMMON_SETTINGS_COMPANY_LISTING_______EDIT',
  CommonSettingsCompanyListingView = 'COMMON_SETTINGS_COMPANY_LISTING_______VIEW',
  CommonSettingsIgnoreView = 'COMMON_SETTINGS_IGNORE_______VIEW',
  CommonSettingsTaxPolicyCreate = 'COMMON_SETTINGS_TAX_POLICY_______CREATE',
  CommonSettingsTaxPolicyDelete = 'COMMON_SETTINGS_TAX_POLICY_______DELETE',
  CommonSettingsTaxPolicyEdit = 'COMMON_SETTINGS_TAX_POLICY_______EDIT',
  CommonSettingsTaxPolicyView = 'COMMON_SETTINGS_TAX_POLICY_______VIEW',
  CommonSettingsTaxSchemePolicyCreate = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______CREATE',
  CommonSettingsTaxSchemePolicyDelete = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______DELETE',
  CommonSettingsTaxSchemePolicyEdit = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______EDIT',
  CommonSettingsTaxSchemePolicyView = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______VIEW',
  ConsolidatedEInvoiceIgnoreCreate = 'CONSOLIDATED_E_INVOICE_IGNORE_______CREATE',
  ConsolidatedEInvoiceIgnoreRead = 'CONSOLIDATED_E_INVOICE_IGNORE_______READ',
  ConsolidatedEInvoiceIgnoreView = 'CONSOLIDATED_E_INVOICE_IGNORE_______VIEW',
  CorpGovtGovtBodiesAnalysisView = 'CORP_GOVT_GOVT_BODIES_ANALYSIS____VIEW',
  CorpGovtGovtBodiesBookingRecordsView = 'CORP_GOVT_GOVT_BODIES_BOOKING_RECORDS____VIEW',
  CorpGovtGovtBodiesCompanyInfoEdit = 'CORP_GOVT_GOVT_BODIES_COMPANY_INFO____EDIT',
  CorpGovtGovtBodiesCompanyInfoView = 'CORP_GOVT_GOVT_BODIES_COMPANY_INFO____VIEW',
  CorpGovtGovtBodiesContractsActive = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____ACTIVE',
  CorpGovtGovtBodiesContractsCreate = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____CREATE',
  CorpGovtGovtBodiesContractsDelete = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____DELETE',
  CorpGovtGovtBodiesContractsEdit = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____EDIT',
  CorpGovtGovtBodiesContractsView = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____VIEW',
  CorpGovtGovtBodiesActive = 'CORP_GOVT_GOVT_BODIES_______ACTIVE',
  CorpGovtGovtBodiesCreate = 'CORP_GOVT_GOVT_BODIES_______CREATE',
  CorpGovtGovtBodiesDelete = 'CORP_GOVT_GOVT_BODIES_______DELETE',
  CorpGovtGovtBodiesView = 'CORP_GOVT_GOVT_BODIES_______VIEW',
  CorpGovtIgnoreView = 'CORP_GOVT_IGNORE_______VIEW',
  CorpGovtPrivateCorporationAnalysisView = 'CORP_GOVT_PRIVATE_CORPORATION_ANALYSIS____VIEW',
  CorpGovtPrivateCorporationBookingRecordsView = 'CORP_GOVT_PRIVATE_CORPORATION_BOOKING_RECORDS____VIEW',
  CorpGovtPrivateCorporationCompanyInfoEdit = 'CORP_GOVT_PRIVATE_CORPORATION_COMPANY_INFO____EDIT',
  CorpGovtPrivateCorporationCompanyInfoView = 'CORP_GOVT_PRIVATE_CORPORATION_COMPANY_INFO____VIEW',
  CorpGovtPrivateCorporationContractsActive = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____ACTIVE',
  CorpGovtPrivateCorporationContractsCreate = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____CREATE',
  CorpGovtPrivateCorporationContractsDelete = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____DELETE',
  CorpGovtPrivateCorporationContractsEdit = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____EDIT',
  CorpGovtPrivateCorporationContractsView = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____VIEW',
  CorpGovtPrivateCorporationActive = 'CORP_GOVT_PRIVATE_CORPORATION_______ACTIVE',
  CorpGovtPrivateCorporationCreate = 'CORP_GOVT_PRIVATE_CORPORATION_______CREATE',
  CorpGovtPrivateCorporationDelete = 'CORP_GOVT_PRIVATE_CORPORATION_______DELETE',
  CorpGovtPrivateCorporationView = 'CORP_GOVT_PRIVATE_CORPORATION_______VIEW',
  CorpGovtRatePolicyView = 'CORP_GOVT_RATE_POLICY_______VIEW',
  DigitalMarketingGuestRatingReviewInfoShare = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____SHARE',
  DigitalMarketingGuestRatingReviewInfoUpdateStatus = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____UPDATE_STATUS',
  DigitalMarketingGuestRatingReviewInfoView = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____VIEW',
  DigitalMarketingGuestRatingReviewView = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_______VIEW',
  DigitalMarketingIgnoreView = 'DIGITAL_MARKETING_IGNORE_______VIEW',
  DigitalMarketingMarketingAdsCreate = 'DIGITAL_MARKETING_MARKETING_ADS_______CREATE',
  DigitalMarketingMarketingAdsEdit = 'DIGITAL_MARKETING_MARKETING_ADS_______EDIT',
  DigitalMarketingMarketingAdsEmailBlasting = 'DIGITAL_MARKETING_MARKETING_ADS_______EMAIL_BLASTING',
  DigitalMarketingMarketingAdsShare = 'DIGITAL_MARKETING_MARKETING_ADS_______SHARE',
  DigitalMarketingMarketingAdsView = 'DIGITAL_MARKETING_MARKETING_ADS_______VIEW',
  DigitalMarketingPromoCodeActive = 'DIGITAL_MARKETING_PROMO_CODE_______ACTIVE',
  DigitalMarketingPromoCodeCreate = 'DIGITAL_MARKETING_PROMO_CODE_______CREATE',
  DigitalMarketingPromoCodeEdit = 'DIGITAL_MARKETING_PROMO_CODE_______EDIT',
  DigitalMarketingPromoCodeView = 'DIGITAL_MARKETING_PROMO_CODE_______VIEW',
  FrontDeskCheckInAdvancePaymentListCreate = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_CREATE',
  FrontDeskCheckInAdvancePaymentListPrint = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_PRINT',
  FrontDeskCheckInAdvancePaymentListRefund = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_REFUND',
  FrontDeskCheckInAdvancePaymentView = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT____VIEW',
  FrontDeskCheckInBillingInstructionCreate = 'FRONT_DESK_CHECK_IN_BILLING_INSTRUCTION____CREATE',
  FrontDeskCheckInBillingInstructionView = 'FRONT_DESK_CHECK_IN_BILLING_INSTRUCTION____VIEW',
  FrontDeskCheckInBillingScheduleEdit = 'FRONT_DESK_CHECK_IN_BILLING_SCHEDULE____EDIT',
  FrontDeskCheckInBillingScheduleView = 'FRONT_DESK_CHECK_IN_BILLING_SCHEDULE____VIEW',
  FrontDeskCheckInBookingInfoEdit = 'FRONT_DESK_CHECK_IN_BOOKING_INFO____EDIT',
  FrontDeskCheckInBookingInfoView = 'FRONT_DESK_CHECK_IN_BOOKING_INFO____VIEW',
  FrontDeskCheckInCheckinCancelCancel = 'FRONT_DESK_CHECK_IN_CHECKIN_CANCEL____CANCEL',
  FrontDeskCheckInDepositListCreate = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_CREATE',
  FrontDeskCheckInDepositListPrint = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_PRINT',
  FrontDeskCheckInDepositListRefund = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_REFUND',
  FrontDeskCheckInDepositView = 'FRONT_DESK_CHECK_IN_DEPOSIT____VIEW',
  FrontDeskCheckInFolioListCreate = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_CREATE',
  FrontDeskCheckInFolioListInhouse = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_INHOUSE',
  FrontDeskCheckInFolioListPrint = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_PRINT',
  FrontDeskCheckInFolioListSplit = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_SPLIT',
  FrontDeskCheckInFolioListSuspend = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_SUSPEND',
  FrontDeskCheckInFolioListTransfer = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_TRANSFER',
  FrontDeskCheckInFolioListView = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_VIEW',
  FrontDeskCheckInFolioListVoid = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_VOID',
  FrontDeskCheckInFolioView = 'FRONT_DESK_CHECK_IN_FOLIO____VIEW',
  FrontDeskCheckInIncidentalChargesCreate = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____CREATE',
  FrontDeskCheckInIncidentalChargesView = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____VIEW',
  FrontDeskCheckInIncidentalChargesVoid = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____VOID',
  FrontDeskCheckInKeyCardCreate = 'FRONT_DESK_CHECK_IN_KEY_CARD____CREATE',
  FrontDeskCheckInKeyCardView = 'FRONT_DESK_CHECK_IN_KEY_CARD____VIEW',
  FrontDeskCheckInRoomingListListCreate = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_CREATE',
  FrontDeskCheckInRoomingListListDelete = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_DELETE',
  FrontDeskCheckInRoomingListListEdit = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_EDIT',
  FrontDeskCheckInRoomingListListView = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_VIEW',
  FrontDeskCheckInRoomingListView = 'FRONT_DESK_CHECK_IN_ROOMING_LIST____VIEW',
  FrontDeskCheckInSpecialRequestCreate = 'FRONT_DESK_CHECK_IN_SPECIAL_REQUEST____CREATE',
  FrontDeskCheckInSpecialRequestView = 'FRONT_DESK_CHECK_IN_SPECIAL_REQUEST____VIEW',
  FrontDeskCheckInWalkInCreate = 'FRONT_DESK_CHECK_IN_WALK_IN____CREATE',
  FrontDeskCheckInAssignment = 'FRONT_DESK_CHECK_IN_______ASSIGNMENT',
  FrontDeskCheckInCheckIn = 'FRONT_DESK_CHECK_IN_______CHECK_IN',
  FrontDeskCheckInView = 'FRONT_DESK_CHECK_IN_______VIEW',
  FrontDeskCheckOutBillingInstructionCreate = 'FRONT_DESK_CHECK_OUT_BILLING_INSTRUCTION____CREATE',
  FrontDeskCheckOutBillingInstructionView = 'FRONT_DESK_CHECK_OUT_BILLING_INSTRUCTION____VIEW',
  FrontDeskCheckOutBillingScheduleEdit = 'FRONT_DESK_CHECK_OUT_BILLING_SCHEDULE____EDIT',
  FrontDeskCheckOutBillingScheduleView = 'FRONT_DESK_CHECK_OUT_BILLING_SCHEDULE____VIEW',
  FrontDeskCheckOutCheckoutReinstatementCreate = 'FRONT_DESK_CHECK_OUT_CHECKOUT_REINSTATEMENT____CREATE',
  FrontDeskCheckOutDepositListCreate = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_CREATE',
  FrontDeskCheckOutDepositListPrint = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_PRINT',
  FrontDeskCheckOutDepositListRefund = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_REFUND',
  FrontDeskCheckOutDepositView = 'FRONT_DESK_CHECK_OUT_DEPOSIT____VIEW',
  FrontDeskCheckOutFolioHistoryPrint = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____PRINT',
  FrontDeskCheckOutFolioHistoryShare = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____SHARE',
  FrontDeskCheckOutFolioHistoryView = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____VIEW',
  FrontDeskCheckOutFolioListCreate = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_CREATE',
  FrontDeskCheckOutFolioListInhouse = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_INHOUSE',
  FrontDeskCheckOutFolioListPrint = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_PRINT',
  FrontDeskCheckOutFolioListSplit = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_SPLIT',
  FrontDeskCheckOutFolioListSuspend = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_SUSPEND',
  FrontDeskCheckOutFolioListTransfer = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_TRANSFER',
  FrontDeskCheckOutFolioListView = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_VIEW',
  FrontDeskCheckOutFolioListVoid = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_VOID',
  FrontDeskCheckOutFolioView = 'FRONT_DESK_CHECK_OUT_FOLIO____VIEW',
  FrontDeskCheckOutIncidentalChargesCreate = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____CREATE',
  FrontDeskCheckOutIncidentalChargesView = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____VIEW',
  FrontDeskCheckOutIncidentalChargesVoid = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____VOID',
  FrontDeskCheckOutView = 'FRONT_DESK_CHECK_OUT_______VIEW',
  FrontDeskContactTracingInfoShare = 'FRONT_DESK_CONTACT_TRACING_INFO____SHARE',
  FrontDeskContactTracingInfoView = 'FRONT_DESK_CONTACT_TRACING_INFO____VIEW',
  FrontDeskContactTracingView = 'FRONT_DESK_CONTACT_TRACING_______VIEW',
  FrontDeskDepositListCreate = 'FRONT_DESK_DEPOSIT_LIST____CREATE',
  FrontDeskDepositListPrint = 'FRONT_DESK_DEPOSIT_LIST____PRINT',
  FrontDeskDepositListRefund = 'FRONT_DESK_DEPOSIT_LIST____REFUND',
  FrontDeskDepositListShare = 'FRONT_DESK_DEPOSIT_LIST____SHARE',
  FrontDeskDepositView = 'FRONT_DESK_DEPOSIT_______VIEW',
  FrontDeskGroupCheckinListAssignment = 'FRONT_DESK_GROUP_CHECKIN_LIST____ASSIGNMENT',
  FrontDeskGroupCheckinListCheckIn = 'FRONT_DESK_GROUP_CHECKIN_LIST____CHECK_IN',
  FrontDeskGroupCheckinListView = 'FRONT_DESK_GROUP_CHECKIN_LIST____VIEW',
  FrontDeskGroupCheckinView = 'FRONT_DESK_GROUP_CHECKIN_______VIEW',
  FrontDeskIgnoreView = 'FRONT_DESK_IGNORE_______VIEW',
  FrontDeskInhouseAdvancePaymentListCreate = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_CREATE',
  FrontDeskInhouseAdvancePaymentListPrint = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_PRINT',
  FrontDeskInhouseAdvancePaymentListRefund = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_REFUND',
  FrontDeskInhouseAdvancePaymentView = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT____VIEW',
  FrontDeskInhouseAttachmentsView = 'FRONT_DESK_INHOUSE_ATTACHMENTS____VIEW',
  FrontDeskInhouseAttachmentsCreate = 'FRONT_DESK_INHOUSE_Attachments____CREATE',
  FrontDeskInhouseAttachmentsDelete = 'FRONT_DESK_INHOUSE_Attachments____DELETE',
  FrontDeskInhouseAttachmentsEdit = 'FRONT_DESK_INHOUSE_Attachments____EDIT',
  FrontDeskInhouseBillingInstructionCreate = 'FRONT_DESK_INHOUSE_BILLING_INSTRUCTION____CREATE',
  FrontDeskInhouseBillingInstructionView = 'FRONT_DESK_INHOUSE_BILLING_INSTRUCTION____VIEW',
  FrontDeskInhouseBillingScheduleEdit = 'FRONT_DESK_INHOUSE_BILLING_SCHEDULE____EDIT',
  FrontDeskInhouseBillingScheduleView = 'FRONT_DESK_INHOUSE_BILLING_SCHEDULE____VIEW',
  FrontDeskInhouseBookingInfoEdit = 'FRONT_DESK_INHOUSE_BOOKING_INFO____EDIT',
  FrontDeskInhouseBookingInfoView = 'FRONT_DESK_INHOUSE_BOOKING_INFO____VIEW',
  FrontDeskInhouseCheckinCancelCancel = 'FRONT_DESK_INHOUSE_CHECKIN_CANCEL____CANCEL',
  FrontDeskInhouseDepositListCreate = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_CREATE',
  FrontDeskInhouseDepositListPrint = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_PRINT',
  FrontDeskInhouseDepositListRefund = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_REFUND',
  FrontDeskInhouseDepositView = 'FRONT_DESK_INHOUSE_DEPOSIT____VIEW',
  FrontDeskInhouseEarlyCheckoutView = 'FRONT_DESK_INHOUSE_EARLY_CHECKOUT____VIEW',
  FrontDeskInhouseExtendStayCreate = 'FRONT_DESK_INHOUSE_EXTEND_STAY____CREATE',
  FrontDeskInhouseExtendStayView = 'FRONT_DESK_INHOUSE_EXTEND_STAY____VIEW',
  FrontDeskInhouseFolioHistoryPrint = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____PRINT',
  FrontDeskInhouseFolioHistoryShare = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____SHARE',
  FrontDeskInhouseFolioHistoryView = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____VIEW',
  FrontDeskInhouseFolioListCreate = 'FRONT_DESK_INHOUSE_FOLIO_LIST_CREATE',
  FrontDeskInhouseFolioListInhouse = 'FRONT_DESK_INHOUSE_FOLIO_LIST_INHOUSE',
  FrontDeskInhouseFolioListPrint = 'FRONT_DESK_INHOUSE_FOLIO_LIST_PRINT',
  FrontDeskInhouseFolioListSplit = 'FRONT_DESK_INHOUSE_FOLIO_LIST_SPLIT',
  FrontDeskInhouseFolioListSuspend = 'FRONT_DESK_INHOUSE_FOLIO_LIST_SUSPEND',
  FrontDeskInhouseFolioListTransfer = 'FRONT_DESK_INHOUSE_FOLIO_LIST_TRANSFER',
  FrontDeskInhouseFolioListView = 'FRONT_DESK_INHOUSE_FOLIO_LIST_VIEW',
  FrontDeskInhouseFolioListVoid = 'FRONT_DESK_INHOUSE_FOLIO_LIST_VOID',
  FrontDeskInhouseFolioView = 'FRONT_DESK_INHOUSE_FOLIO____VIEW',
  FrontDeskInhouseHouseLimitCreate = 'FRONT_DESK_INHOUSE_HOUSE_LIMIT____CREATE',
  FrontDeskInhouseHouseLimitView = 'FRONT_DESK_INHOUSE_HOUSE_LIMIT____VIEW',
  FrontDeskInhouseIncidentalChargesCreate = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____CREATE',
  FrontDeskInhouseIncidentalChargesView = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____VIEW',
  FrontDeskInhouseIncidentalChargesVoid = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____VOID',
  FrontDeskInhouseKeyCardCreate = 'FRONT_DESK_INHOUSE_KEY_CARD____CREATE',
  FrontDeskInhouseKeyCardView = 'FRONT_DESK_INHOUSE_KEY_CARD____VIEW',
  FrontDeskInhouseLateCheckoutCreate = 'FRONT_DESK_INHOUSE_LATE_CHECKOUT____CREATE',
  FrontDeskInhouseLateCheckoutView = 'FRONT_DESK_INHOUSE_LATE_CHECKOUT____VIEW',
  FrontDeskInhouseRateAdjustmentListView = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT__LIST_VIEW',
  FrontDeskInhouseRateAdjustmentCreate = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT____CREATE',
  FrontDeskInhouseRateAdjustmentView = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT____VIEW',
  FrontDeskInhouseRoomingListListCreate = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_CREATE',
  FrontDeskInhouseRoomingListListDelete = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_DELETE',
  FrontDeskInhouseRoomingListListEdit = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_EDIT',
  FrontDeskInhouseRoomingListListView = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_VIEW',
  FrontDeskInhouseRoomingListView = 'FRONT_DESK_INHOUSE_ROOMING_LIST____VIEW',
  FrontDeskInhouseRoomTransferCreate = 'FRONT_DESK_INHOUSE_ROOM_TRANSFER____CREATE',
  FrontDeskInhouseRoomTransferView = 'FRONT_DESK_INHOUSE_ROOM_TRANSFER____VIEW',
  FrontDeskInhouseRoomUpgradeCreate = 'FRONT_DESK_INHOUSE_ROOM_UPGRADE____CREATE',
  FrontDeskInhouseRoomUpgradeView = 'FRONT_DESK_INHOUSE_ROOM_UPGRADE____VIEW',
  FrontDeskInhouseServiceRequestListCreate = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST_LIST_CREATE',
  FrontDeskInhouseServiceRequestEdit = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____EDIT',
  FrontDeskInhouseServiceRequestUpdateStatus = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____UPDATE_STATUS',
  FrontDeskInhouseServiceRequestView = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____VIEW',
  FrontDeskInhouseView = 'FRONT_DESK_INHOUSE_______VIEW',
  FrontDeskNonguestFolioListFolioCharges = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_CHARGES',
  FrontDeskNonguestFolioListFolioClose = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_CLOSE',
  FrontDeskNonguestFolioListFolioPayment = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_PAYMENT',
  FrontDeskNonguestFolioListFolioPrint = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_PRINT',
  FrontDeskNonguestFolioListCreate = 'FRONT_DESK_NONGUEST_FOLIO_LIST____CREATE',
  FrontDeskNonguestFolioListDelete = 'FRONT_DESK_NONGUEST_FOLIO_LIST____DELETE',
  FrontDeskNonguestFolioListEdit = 'FRONT_DESK_NONGUEST_FOLIO_LIST____EDIT',
  FrontDeskNonguestFolioListFolio = 'FRONT_DESK_NONGUEST_FOLIO_LIST____FOLIO',
  FrontDeskNonguestFolioView = 'FRONT_DESK_NONGUEST_FOLIO_______VIEW',
  FrontDeskRoomAssignmentAssignRoomAssignment = 'FRONT_DESK_ROOM_ASSIGNMENT_ASSIGN_ROOM____ASSIGNMENT',
  FrontDeskRoomAssignmentAssignment = 'FRONT_DESK_ROOM_ASSIGNMENT_______ASSIGNMENT',
  FrontDeskRoomAssignmentView = 'FRONT_DESK_ROOM_ASSIGNMENT_______VIEW',
  FrontDeskServiceRequestListAssignment = 'FRONT_DESK_SERVICE_REQUEST_LIST____ASSIGNMENT',
  FrontDeskServiceRequestListCreate = 'FRONT_DESK_SERVICE_REQUEST_LIST____CREATE',
  FrontDeskServiceRequestListEdit = 'FRONT_DESK_SERVICE_REQUEST_LIST____EDIT',
  FrontDeskServiceRequestListUpdateStatus = 'FRONT_DESK_SERVICE_REQUEST_LIST____UPDATE_STATUS',
  FrontDeskServiceRequestView = 'FRONT_DESK_SERVICE_REQUEST_______VIEW',
  FrontDeskStayViewView = 'FRONT_DESK_STAY_VIEW_______VIEW',
  FrontDeskSuspendFolioListFolioCharges = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_CHARGES',
  FrontDeskSuspendFolioListFolioClose = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_CLOSE',
  FrontDeskSuspendFolioListFolioPayment = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_PAYMENT',
  FrontDeskSuspendFolioListFolioPrint = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_PRINT',
  FrontDeskSuspendFolioListCreate = 'FRONT_DESK_SUSPEND_FOLIO_LIST____CREATE',
  FrontDeskSuspendFolioListDelete = 'FRONT_DESK_SUSPEND_FOLIO_LIST____DELETE',
  FrontDeskSuspendFolioListEdit = 'FRONT_DESK_SUSPEND_FOLIO_LIST____EDIT',
  FrontDeskSuspendFolioListFolio = 'FRONT_DESK_SUSPEND_FOLIO_LIST____FOLIO',
  FrontDeskSuspendFolioView = 'FRONT_DESK_SUSPEND_FOLIO_______VIEW',
  GuestProfileIgnoreEdit = 'GUEST_PROFILE_IGNORE_______EDIT',
  GuestProfileIgnoreView = 'GUEST_PROFILE_IGNORE_______VIEW',
  HotelSettingsDepartmentIncidentalChargesQrCodePrint = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES_QR_CODE_PRINT',
  HotelSettingsDepartmentIncidentalChargesQrCodeView = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES_QR_CODE_VIEW',
  HotelSettingsDepartmentIncidentalChargesActive = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____ACTIVE',
  HotelSettingsDepartmentIncidentalChargesCreate = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____CREATE',
  HotelSettingsDepartmentIncidentalChargesDelete = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____DELETE',
  HotelSettingsDepartmentIncidentalChargesView = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____VIEW',
  HotelSettingsDepartmentReasonCreate = 'HOTEL_SETTINGS_DEPARTMENT_REASON____CREATE',
  HotelSettingsDepartmentReasonDelete = 'HOTEL_SETTINGS_DEPARTMENT_REASON____DELETE',
  HotelSettingsDepartmentReasonEdit = 'HOTEL_SETTINGS_DEPARTMENT_REASON____EDIT',
  HotelSettingsDepartmentReasonView = 'HOTEL_SETTINGS_DEPARTMENT_REASON____VIEW',
  HotelSettingsDepartmentSalesChannelCreate = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____CREATE',
  HotelSettingsDepartmentSalesChannelDelete = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____DELETE',
  HotelSettingsDepartmentSalesChannelEdit = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____EDIT',
  HotelSettingsDepartmentSalesChannelView = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____VIEW',
  HotelSettingsDepartmentCreate = 'HOTEL_SETTINGS_DEPARTMENT_______CREATE',
  HotelSettingsDepartmentView = 'HOTEL_SETTINGS_DEPARTMENT_______VIEW',
  HotelSettingsEdoctemplateView = 'HOTEL_SETTINGS_EDOCTEMPLATE_______VIEW',
  HotelSettingsGuestappActive = 'HOTEL_SETTINGS_GUESTAPP_______ACTIVE',
  HotelSettingsGuestappView = 'HOTEL_SETTINGS_GUESTAPP_______VIEW',
  HotelSettingsHotelSetupAboutEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_ABOUT____EDIT',
  HotelSettingsHotelSetupAboutView = 'HOTEL_SETTINGS_HOTEL_SETUP_ABOUT____VIEW',
  HotelSettingsHotelSetupFacilitiesQrCodePrint = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES_QR_CODE_PRINT',
  HotelSettingsHotelSetupFacilitiesEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES____EDIT',
  HotelSettingsHotelSetupFacilitiesView = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES____VIEW',
  HotelSettingsHotelSetupGalleryEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_GALLERY____EDIT',
  HotelSettingsHotelSetupGalleryView = 'HOTEL_SETTINGS_HOTEL_SETUP_GALLERY____VIEW',
  HotelSettingsHotelSetupProfileEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_PROFILE____EDIT',
  HotelSettingsHotelSetupProfileView = 'HOTEL_SETTINGS_HOTEL_SETUP_PROFILE____VIEW',
  HotelSettingsHotelSetupView = 'HOTEL_SETTINGS_HOTEL_SETUP_______VIEW',
  HotelSettingsIgnoreView = 'HOTEL_SETTINGS_IGNORE_______VIEW',
  HotelSettingsLatecheckoutChargeEdit = 'HOTEL_SETTINGS_LATECHECKOUT_CHARGE_______EDIT',
  HotelSettingsLatecheckoutChargeView = 'HOTEL_SETTINGS_LATECHECKOUT_CHARGE_______VIEW',
  HotelSettingsLocationCreate = 'HOTEL_SETTINGS_LOCATION_______CREATE',
  HotelSettingsLocationEdit = 'HOTEL_SETTINGS_LOCATION_______EDIT',
  HotelSettingsLocationView = 'HOTEL_SETTINGS_LOCATION_______VIEW',
  HotelSettingsRatePolicyAddOnActive = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____ACTIVE',
  HotelSettingsRatePolicyAddOnCreate = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____CREATE',
  HotelSettingsRatePolicyAddOnEdit = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____EDIT',
  HotelSettingsRatePolicyAddOnView = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____VIEW',
  HotelSettingsRatePolicyRateElementActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____ACTIVE',
  HotelSettingsRatePolicyRateElementCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____CREATE',
  HotelSettingsRatePolicyRateElementEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____EDIT',
  HotelSettingsRatePolicyRateElementView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____VIEW',
  HotelSettingsRatePolicyRateSetupValidityPeriodActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_ACTIVE',
  HotelSettingsRatePolicyRateSetupValidityPeriodCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_CREATE',
  HotelSettingsRatePolicyRateSetupValidityPeriodEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_EDIT',
  HotelSettingsRatePolicyRateSetupValidityPeriodView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_VIEW',
  HotelSettingsRatePolicyRateSetupActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____ACTIVE',
  HotelSettingsRatePolicyRateSetupCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____CREATE',
  HotelSettingsRatePolicyRateSetupEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____EDIT',
  HotelSettingsRatePolicyRateSetupView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____VIEW',
  HotelSettingsRatePolicyRateTypeActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____ACTIVE',
  HotelSettingsRatePolicyRateTypeCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____CREATE',
  HotelSettingsRatePolicyRateTypeEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____EDIT',
  HotelSettingsRatePolicyRateTypeView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____VIEW',
  HotelSettingsRatePolicySeasonCalendarEdit = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_CALENDAR____EDIT',
  HotelSettingsRatePolicySeasonCalendarView = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_CALENDAR____VIEW',
  HotelSettingsRatePolicySeasonSetupActive = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____ACTIVE',
  HotelSettingsRatePolicySeasonSetupCreate = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____CREATE',
  HotelSettingsRatePolicySeasonSetupEdit = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____EDIT',
  HotelSettingsRatePolicySeasonSetupView = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____VIEW',
  HotelSettingsRatePolicyView = 'HOTEL_SETTINGS_RATE_POLICY_______VIEW',
  HotelSettingsRoomListActive = 'HOTEL_SETTINGS_ROOM_LIST____ACTIVE',
  HotelSettingsRoomListCreate = 'HOTEL_SETTINGS_ROOM_LIST____CREATE',
  HotelSettingsRoomListDelete = 'HOTEL_SETTINGS_ROOM_LIST____DELETE',
  HotelSettingsRoomListEdit = 'HOTEL_SETTINGS_ROOM_LIST____EDIT',
  HotelSettingsRoomListView = 'HOTEL_SETTINGS_ROOM_LIST____VIEW',
  HotelSettingsRoomSaleOptEdit = 'HOTEL_SETTINGS_ROOM_SALE_OPT_______EDIT',
  HotelSettingsRoomSaleOptView = 'HOTEL_SETTINGS_ROOM_SALE_OPT_______VIEW',
  HotelSettingsRoomStatusEdit = 'HOTEL_SETTINGS_ROOM_STATUS_______EDIT',
  HotelSettingsRoomStatusView = 'HOTEL_SETTINGS_ROOM_STATUS_______VIEW',
  HotelSettingsRoomTypeActive = 'HOTEL_SETTINGS_ROOM_TYPE_______ACTIVE',
  HotelSettingsRoomTypeCreate = 'HOTEL_SETTINGS_ROOM_TYPE_______CREATE',
  HotelSettingsRoomTypeEdit = 'HOTEL_SETTINGS_ROOM_TYPE_______EDIT',
  HotelSettingsRoomTypeView = 'HOTEL_SETTINGS_ROOM_TYPE_______VIEW',
  HotelSettingsRoomView = 'HOTEL_SETTINGS_ROOM_______VIEW',
  HotelSettingsSegmentActive = 'HOTEL_SETTINGS_SEGMENT_______ACTIVE',
  HotelSettingsSegmentCreate = 'HOTEL_SETTINGS_SEGMENT_______CREATE',
  HotelSettingsSegmentEdit = 'HOTEL_SETTINGS_SEGMENT_______EDIT',
  HotelSettingsSegmentView = 'HOTEL_SETTINGS_SEGMENT_______VIEW',
  HotelSettingsStandardPolicyCeillingFloorPriceEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_CEILLING_FLOOR_PRICE____EDIT',
  HotelSettingsStandardPolicyCeillingFloorPriceView = 'HOTEL_SETTINGS_STANDARD_POLICY_CEILLING_FLOOR_PRICE____VIEW',
  HotelSettingsStandardPolicyHotelOperationalEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_HOTEL_OPERATIONAL____EDIT',
  HotelSettingsStandardPolicyHotelOperationalView = 'HOTEL_SETTINGS_STANDARD_POLICY_HOTEL_OPERATIONAL____VIEW',
  HotelSettingsStandardPolicyRoomStatusEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_ROOM_STATUS____EDIT',
  HotelSettingsStandardPolicyRoomStatusView = 'HOTEL_SETTINGS_STANDARD_POLICY_ROOM_STATUS____VIEW',
  HotelSettingsStandardPolicySecurityDepositEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_SECURITY_DEPOSIT____EDIT',
  HotelSettingsStandardPolicySecurityDepositView = 'HOTEL_SETTINGS_STANDARD_POLICY_SECURITY_DEPOSIT____VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TAX_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE____VIEW',
  HotelSettingsStandardPolicyView = 'HOTEL_SETTINGS_STANDARD_POLICY_______VIEW',
  HouseKeepingBlockRoomBlockRoomStatusOoiView = 'HOUSE_KEEPING_BLOCK_ROOM_BLOCK_ROOM_STATUS_OOI_______VIEW',
  HouseKeepingBlockRoomBlockRoomStatusOooView = 'HOUSE_KEEPING_BLOCK_ROOM_BLOCK_ROOM_STATUS_OOO_______VIEW',
  HouseKeepingBlockRoomBlockRoomStatusView = 'HOUSE_KEEPING_BLOCK_ROOM_BLOCK_ROOM_STATUS_______VIEW',
  HouseKeepingBlockRoomEdit = 'HOUSE_KEEPING_BLOCK_ROOM_______EDIT',
  HouseKeepingBlockRoomView = 'HOUSE_KEEPING_BLOCK_ROOM_______VIEW',
  HouseKeepingIgnoreView = 'HOUSE_KEEPING_IGNORE_______VIEW',
  HouseKeepingIncidentalChargesCreate = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______CREATE',
  HouseKeepingIncidentalChargesView = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______VIEW',
  HouseKeepingIncidentalChargesVoid = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______VOID',
  HouseKeepingRoomStatusUpdateStatus = 'HOUSE_KEEPING_ROOM_STATUS_______UPDATE_STATUS',
  HouseKeepingRoomStatusView = 'HOUSE_KEEPING_ROOM_STATUS_______VIEW',
  HouseKeepingServiceRequestCreate = 'HOUSE_KEEPING_SERVICE_REQUEST_______CREATE',
  HouseKeepingServiceRequestEdit = 'HOUSE_KEEPING_SERVICE_REQUEST_______EDIT',
  HouseKeepingServiceRequestUpdateStatus = 'HOUSE_KEEPING_SERVICE_REQUEST_______UPDATE_STATUS',
  HouseKeepingServiceRequestView = 'HOUSE_KEEPING_SERVICE_REQUEST_______VIEW',
  PackagesRedemptionIgnoreEdit = 'PACKAGES_REDEMPTION_IGNORE_______EDIT',
  PackagesRedemptionIgnoreView = 'PACKAGES_REDEMPTION_IGNORE_______VIEW',
  RoomServiceIgnoreCancel = 'ROOM_SERVICE_IGNORE_______CANCEL',
  RoomServiceIgnoreCreate = 'ROOM_SERVICE_IGNORE_______CREATE',
  RoomServiceIgnoreUpdateStatus = 'ROOM_SERVICE_IGNORE_______UPDATE_STATUS',
  RoomServiceIgnoreView = 'ROOM_SERVICE_IGNORE_______VIEW',
  ServiceRequestIgnoreAssignment = 'SERVICE_REQUEST_IGNORE_______ASSIGNMENT',
  ServiceRequestIgnoreCreate = 'SERVICE_REQUEST_IGNORE_______CREATE',
  ServiceRequestIgnoreEdit = 'SERVICE_REQUEST_IGNORE_______EDIT',
  ServiceRequestIgnoreUpdateStatus = 'SERVICE_REQUEST_IGNORE_______UPDATE_STATUS',
  ServiceRequestIgnoreView = 'SERVICE_REQUEST_IGNORE_______VIEW',
  SystemAdminHotelView = 'SYSTEM_ADMIN_HOTEL____VIEW',
  SystemAdminIgnoreView = 'SYSTEM_ADMIN_IGNORE_______VIEW',
  SystemAdminRolesView = 'SYSTEM_ADMIN_ROLES____VIEW',
  SystemAdminUserView = 'SYSTEM_ADMIN_USER____VIEW',
  TravelAgentAgentListingAnalysisView = 'TRAVEL_AGENT_AGENT_LISTING_ANALYSIS____VIEW',
  TravelAgentAgentListingBookingRecordsView = 'TRAVEL_AGENT_AGENT_LISTING_BOOKING_RECORDS____VIEW',
  TravelAgentAgentListingCompanyInfoEdit = 'TRAVEL_AGENT_AGENT_LISTING_COMPANY_INFO____EDIT',
  TravelAgentAgentListingCompanyInfoView = 'TRAVEL_AGENT_AGENT_LISTING_COMPANY_INFO____VIEW',
  TravelAgentAgentListingContractsActive = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____ACTIVE',
  TravelAgentAgentListingContractsCreate = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____CREATE',
  TravelAgentAgentListingContractsDelete = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____DELETE',
  TravelAgentAgentListingContractsEdit = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____EDIT',
  TravelAgentAgentListingContractsView = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____VIEW',
  TravelAgentAgentListingActive = 'TRAVEL_AGENT_AGENT_LISTING_______ACTIVE',
  TravelAgentAgentListingCreate = 'TRAVEL_AGENT_AGENT_LISTING_______CREATE',
  TravelAgentAgentListingDelete = 'TRAVEL_AGENT_AGENT_LISTING_______DELETE',
  TravelAgentAgentListingView = 'TRAVEL_AGENT_AGENT_LISTING_______VIEW',
  TravelAgentIgnoreView = 'TRAVEL_AGENT_IGNORE_______VIEW',
  TravelAgentRatePolicyView = 'TRAVEL_AGENT_RATE_POLICY_______VIEW'
}

export type HotelQ3RevenueAcctJson = {
  __typename?: 'HotelQ3RevenueAcctJson';
  GLRevenueAcct?: Maybe<Scalars['JSON']>;
  HTLRevenueAcct?: Maybe<Array<Q3RevenueAcctJson>>;
  RoundingRevenueAcct?: Maybe<Array<Q3RevenueAcctJson>>;
};

export type HotelQ3RevenueAcctJsonInput = {
  GLRevenueAcct?: InputMaybe<Scalars['JSON']>;
  HTLRevenueAcct?: InputMaybe<Array<Q3RevenueAcctJsonInput>>;
  RoundingRevenueAcct?: InputMaybe<Array<Q3RevenueAcctJsonInput>>;
};

export type HotelRatingEntity = InterfaceBase & {
  __typename?: 'HotelRatingEntity';
  Booking?: Maybe<BookingEntity>;
  BookingID: Scalars['String'];
  Cleanliness?: Maybe<Scalars['Float']>;
  ComfortQuality?: Maybe<Scalars['Float']>;
  Comments?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Facilities?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Location?: Maybe<Scalars['Float']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  OverallExperience?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReviewCount?: Maybe<Scalars['Float']>;
  ReviewDate: Scalars['DateTime'];
  Service?: Maybe<Scalars['Float']>;
  TravelAs?: Maybe<Scalars['String']>;
};

export type HotelRatingInput = {
  BookingID?: InputMaybe<Scalars['String']>;
  Cleanliness?: InputMaybe<Scalars['Float']>;
  ComfortQuality?: InputMaybe<Scalars['Float']>;
  Comments?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Facilities?: InputMaybe<Scalars['Float']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Location?: InputMaybe<Scalars['Float']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  OverallExperience?: InputMaybe<Scalars['Float']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  ReviewCount?: InputMaybe<Scalars['Float']>;
  ReviewDate?: InputMaybe<Scalars['DateTime']>;
  Service?: InputMaybe<Scalars['Float']>;
  TravelAs?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type HotelStatisticRespond = {
  __typename?: 'HotelStatisticRespond';
  OOOOOI?: Maybe<Scalars['Float']>;
  TotalArr?: Maybe<Scalars['Float']>;
  TotalComplimentary?: Maybe<Scalars['Float']>;
  TotalDayUse?: Maybe<Scalars['Float']>;
  TotalFnBRevenue?: Maybe<Scalars['Float']>;
  TotalHouseUse?: Maybe<Scalars['Float']>;
  TotalOccupancyRate?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalOtherRevenue?: Maybe<Scalars['Float']>;
  TotalPax?: Maybe<Scalars['Float']>;
  TotalRentableRoom?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalRoomRevenue?: Maybe<Scalars['Float']>;
};

export type HotelTaxOutput = {
  __typename?: 'HotelTaxOutput';
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  SeqNo?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
};

export type HotelTaxPolicyEntity = InterfaceBase & {
  __typename?: 'HotelTaxPolicyEntity';
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  TTxExempted?: Maybe<ReasonEntity>;
  TTxExemptedReason?: Maybe<Scalars['String']>;
  TTxNonExempted?: Maybe<ReasonEntity>;
  TTxNonExemptedReason?: Maybe<Scalars['String']>;
  TaxType?: Maybe<TaxType>;
  Value?: Maybe<Scalars['Float']>;
};

export type HotelTaxPolicyInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  EffectiveDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsFixAmount?: InputMaybe<Scalars['Boolean']>;
  IsTaxable?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  TTxExemptedReason?: InputMaybe<Scalars['String']>;
  TTxNonExemptedReason?: InputMaybe<Scalars['String']>;
  TaxType?: InputMaybe<TaxType>;
  UserID?: InputMaybe<Scalars['String']>;
  Value?: InputMaybe<Scalars['Float']>;
};

export type HotelVendorInfoEntity = InterfaceTrx & {
  __typename?: 'HotelVendorInfoEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  VendorInfo: Scalars['String'];
  VendorName: Scalars['String'];
};

export type HotelVendorInfoInput = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  VendorInfo?: InputMaybe<Scalars['String']>;
  VendorName?: InputMaybe<Scalars['String']>;
};

export type HotelXOtpEntity = InterfaceTrx & {
  __typename?: 'HotelXOtpEntity';
  Code?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Expiry?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IPAddress?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Type?: Maybe<Scalars['String']>;
};

export type HouseKeepingLogEntity = InterfaceTrx & {
  __typename?: 'HouseKeepingLogEntity';
  CleanedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  HotelDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Room?: Maybe<RoomEntity>;
  RoomID: Scalars['String'];
  Status: Scalars['String'];
};

export type HouseKeepingLogInput = {
  CleanedBy?: InputMaybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  RoomID?: InputMaybe<Scalars['String']>;
  Status: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type HouseLimitInput = {
  HouseLimit?: InputMaybe<Scalars['Float']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};

export type HouseUseOutput = {
  __typename?: 'HouseUseOutput';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  MediaType: Scalars['String'];
  PrivateUrl?: Maybe<Scalars['String']>;
  Url: Scalars['String'];
};

export type IncidentalChargeEntity = InterfaceBase & {
  __typename?: 'IncidentalChargeEntity';
  Amount?: Maybe<Scalars['Float']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Department?: Maybe<DepartmentEntity>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  EInvClassification?: Maybe<Scalars['String']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsEShop?: Maybe<Scalars['Boolean']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  IsFnB?: Maybe<Scalars['Boolean']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
  IsNegative?: Maybe<Scalars['Boolean']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsPackage?: Maybe<Scalars['Boolean']>;
  IsRateElement?: Maybe<Scalars['Boolean']>;
  IsRoomRevenue?: Maybe<Scalars['Boolean']>;
  MSICCode?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  OutletCode?: Maybe<Scalars['String']>;
  Package?: Maybe<Array<PackagesEntity>>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Boolean']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  isEInvIntegrated?: Maybe<Scalars['Boolean']>;
};

export type IncidentalChargeInput = {
  Amount?: InputMaybe<Scalars['Float']>;
  Attachment?: InputMaybe<Scalars['Upload']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DisplaySequence?: InputMaybe<Scalars['Float']>;
  EInvClassification?: InputMaybe<Scalars['String']>;
  GalleryID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsEShop?: InputMaybe<Scalars['Boolean']>;
  IsEventInterface?: InputMaybe<Scalars['Boolean']>;
  IsFnB?: InputMaybe<Scalars['Boolean']>;
  IsKiosk?: InputMaybe<Scalars['Boolean']>;
  IsNegative?: InputMaybe<Scalars['Boolean']>;
  IsOutlet?: InputMaybe<Scalars['Boolean']>;
  IsPackage?: InputMaybe<Scalars['Boolean']>;
  IsRateElement?: InputMaybe<Scalars['Boolean']>;
  IsRoomRevenue?: InputMaybe<Scalars['Boolean']>;
  IsRoomService?: InputMaybe<Scalars['Boolean']>;
  MSICCode?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  OutletCode?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Boolean']>;
  Tax?: InputMaybe<Scalars['Boolean']>;
  TaxSchemeID?: InputMaybe<Scalars['String']>;
  isEInvIntegrated?: InputMaybe<Scalars['Boolean']>;
};

export type InputItem = {
  Amount?: InputMaybe<Scalars['Float']>;
  LocationID?: InputMaybe<Scalars['String']>;
  RoomID: Array<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
};

export type InputReg = {
  RegID?: InputMaybe<Scalars['String']>;
};

export type InterestEntity = InterfaceTrx & {
  __typename?: 'InterestEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  IsWaived: Scalars['Boolean'];
  LedgerID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  OverDueAmt: Scalars['Float'];
  OverDueDays: Scalars['Float'];
  TrxStatus: ArTransactionStatus;
};

export type InterestEntityInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  IsWaived: Scalars['Boolean'];
  LedgerID: Scalars['String'];
  OverDueAmt: Scalars['Float'];
  OverDueDays: Scalars['Float'];
  TrxStatus?: InputMaybe<ArTransactionStatus>;
};

export type InterestResponse = {
  __typename?: 'InterestResponse';
  TotalInterestAmt: Scalars['Float'];
  TotalTrxAmt: Scalars['Float'];
};

export type InterfaceBase = {
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type InterfaceTaxTrx = InterfaceTrx & {
  __typename?: 'InterfaceTaxTrx';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  TaxAmount: Scalars['Float'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxSchemeDetailID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
};

export type InterfaceTrx = {
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
};

export type InterfaceTrxPabx = {
  rowid: Scalars['String'];
};

export type InvoiceEntity = InterfaceTrx & {
  __typename?: 'InvoiceEntity';
  AccountID: Scalars['String'];
  Allocation: Array<AllocationEntity>;
  Amount: Scalars['Float'];
  Attachment?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  GroupDocumentNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  LedgerTrx: Array<InvoiceTrxEntity>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  OutstandingAmount: Scalars['Float'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  Status: ArTransactionStatus;
  VoidDate?: Maybe<Scalars['DateTime']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
};

export type InvoiceEntityInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Amount: Scalars['Float'];
  Currency?: InputMaybe<Currency>;
  CurrencyRate?: InputMaybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  PostedDate?: InputMaybe<Scalars['DateTime']>;
  ReferenceNo: Scalars['String'];
  Status?: InputMaybe<ArTransactionStatus>;
  einvoice_doc_ref_invoice_id?: InputMaybe<Scalars['String']>;
  einvoice_status?: InputMaybe<Scalars['String']>;
  is_einvoice?: InputMaybe<Scalars['Boolean']>;
};

export type InvoiceTrxEntity = InterfaceTrx & {
  __typename?: 'InvoiceTrxEntity';
  BaseAmount: Scalars['Float'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DiscountedAmount: Scalars['Float'];
  ID: Scalars['String'];
  InvoiceID: Scalars['String'];
  InvoiceTrxTax?: Maybe<Array<InvoiceTrxTaxEntity>>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Quantity: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  ServiceCharge: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TransactionID: Scalars['String'];
  TrxAmount: Scalars['Float'];
  TrxName?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
};

export type InvoiceTrxInput = {
  AllocationAmount?: InputMaybe<Scalars['Float']>;
  BaseAmount: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  Quantity: Scalars['Float'];
  ReferenceNo?: InputMaybe<Scalars['String']>;
  Remarks?: InputMaybe<Scalars['String']>;
  ServiceCharge: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TransactionID: Scalars['String'];
  TrxAmount: Scalars['Float'];
  TrxName?: InputMaybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
};

export type InvoiceTrxTaxEntity = InterfaceTrx & {
  __typename?: 'InvoiceTrxTaxEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  InvoiceTrx?: Maybe<InvoiceTrxEntity>;
  InvoiceTrxID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  TaxAmount: Scalars['Float'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxSchemeDetailID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
};

export enum InvoiceType {
  Company = 'Company',
  ForeignBuyer = 'ForeignBuyer',
  Government = 'Government',
  Individual = 'Individual'
}

export type KeyCardEntity = {
  __typename?: 'KeyCardEntity';
  audit_date?: Maybe<Scalars['DateTime']>;
  rowid: Scalars['Float'];
  s_data?: Maybe<Scalars['String']>;
  status_flag?: Maybe<Scalars['String']>;
};

export type KeyCardInput = {
  CheckInDate?: InputMaybe<Scalars['String']>;
  CheckOutDate?: InputMaybe<Scalars['String']>;
  GuestName?: InputMaybe<Scalars['String']>;
  Operation?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  RoomNo?: InputMaybe<Scalars['String']>;
  audit_date?: InputMaybe<Scalars['DateTime']>;
  commonDoor?: InputMaybe<Scalars['String']>;
  encoder?: InputMaybe<Scalars['String']>;
  lift?: InputMaybe<Scalars['String']>;
  s_data?: InputMaybe<Scalars['String']>;
  status_flag?: InputMaybe<Scalars['String']>;
};

export type KiwireLogEntity = InterfaceTrx & {
  __typename?: 'KiwireLogEntity';
  ActionType: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  LogData: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  RoomNo: Scalars['String'];
};

export type LateCheckOutChargeEntity = InterfaceBase & {
  __typename?: 'LateCheckOutChargeEntity';
  Amount?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name: Scalars['String'];
};

export type LateCheckOutChargeInput = {
  Amount?: InputMaybe<Scalars['Float']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name: Scalars['String'];
};

export type LedgerIDsInput = {
  AdvancePaymentIDs?: InputMaybe<Array<Scalars['String']>>;
  BillLedgerIDs?: InputMaybe<Array<Scalars['String']>>;
  BillSchedules?: InputMaybe<Array<Scalars['String']>>;
  BillSchedulesDate?: InputMaybe<Array<Scalars['DateTime']>>;
  BillSchedulesDescription?: InputMaybe<Array<Scalars['String']>>;
  BillSchedulesSplit?: InputMaybe<Array<Scalars['Float']>>;
  DepositLedgerIDs?: InputMaybe<Array<Scalars['String']>>;
  PaymentIDs?: InputMaybe<Array<Scalars['String']>>;
  RoomLedgers?: InputMaybe<Array<Scalars['String']>>;
  RoomLedgersDate?: InputMaybe<Array<Scalars['DateTime']>>;
  RoomLedgersDescription?: InputMaybe<Array<Scalars['String']>>;
  RoomLedgersSplit?: InputMaybe<Array<Scalars['Float']>>;
  TaxLedgerIDs?: InputMaybe<Array<Scalars['String']>>;
};

export type LedgerInfoOutput = {
  __typename?: 'LedgerInfoOutput';
  BaseAmount?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
};

export type LedgerOutput = {
  __typename?: 'LedgerOutput';
  TaxAmount?: Maybe<Scalars['Float']>;
  TaxInfo?: Maybe<TaxEntity>;
  TaxRate?: Maybe<Scalars['Float']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
};

export type ListEntRoleUsrAsgInput = {
  ID?: InputMaybe<Scalars['String']>;
  commonStatus?: InputMaybe<CommonStatus>;
  createdBy?: InputMaybe<Scalars['String']>;
  hotelID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LocationEntity = InterfaceBase & {
  __typename?: 'LocationEntity';
  Code: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Room?: Maybe<Array<RoomEntity>>;
};


export type LocationEntityRoomArgs = {
  RoomTypeID?: InputMaybe<Scalars['String']>;
};

export type LocationFloorPlanEntity = InterfaceBase & {
  __typename?: 'LocationFloorPlanEntity';
  AccountID: Scalars['String'];
  Coordinates: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsHotel?: Maybe<Scalars['Boolean']>;
  IsPreCheckin?: Maybe<Scalars['Boolean']>;
  LocationID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Room?: Maybe<RoomEntity>;
  RoomID: Scalars['String'];
  RoomNo: Scalars['String'];
  RoomTypeID: Scalars['String'];
};

export type LocationFloorPlanInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Coordinates?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsHotel?: InputMaybe<Scalars['Boolean']>;
  IsPreCheckin?: InputMaybe<Scalars['Boolean']>;
  LocationID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RoomID?: InputMaybe<Scalars['String']>;
  RoomNo?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type LocationInput = {
  Attachment?: InputMaybe<Scalars['Upload']>;
  Code?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  DisplaySequence?: InputMaybe<Scalars['Float']>;
  GalleryID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type ManagerReportOutput = {
  __typename?: 'ManagerReportOutput';
  BookingCancellation?: Maybe<Array<BookingCancellation>>;
  CompRoom?: Maybe<Array<CompOutput>>;
  CountryReport?: Maybe<Array<CountryReportOutput>>;
  DepartmentReport?: Maybe<Array<DepartmentReportOutput>>;
  ExtendStay?: Maybe<Array<ExtendStayOutPut>>;
  GroupInHouse?: Maybe<Array<GroupInHouseOutput>>;
  HouseUseRoom?: Maybe<Array<HouseUseOutput>>;
  PaymentReport?: Maybe<Array<PaymentOutput>>;
  SegmentReport?: Maybe<Array<SegmentReportOutput>>;
  SourceReport?: Maybe<Array<SourceReportOutput>>;
  TaxReport?: Maybe<Array<TaxReportOutput>>;
  ThreeDayForcast?: Maybe<Array<ForecastOutPut>>;
  TomorrowGroup?: Maybe<Array<TmrGroupOutput>>;
  VIPInHouse?: Maybe<Array<VipInHouseOutput>>;
};

export enum Marital {
  Divorced = 'Divorced',
  Married = 'Married',
  Seperated = 'Seperated',
  Single = 'Single',
  Widowed = 'Widowed'
}

export enum MeasureType {
  FootSquare = 'Foot_Square',
  SquareMetre = 'Square_Metre'
}

export type MeasurementEntity = InterfaceBase & {
  __typename?: 'MeasurementEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Measurement: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Size?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
};

export type MeasurementInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Measurement: Scalars['String'];
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Size?: InputMaybe<Scalars['Float']>;
  Type: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AccountMappingGLInsert: Scalars['Boolean'];
  AccountMappingTaxGLInsert: Scalars['Boolean'];
  ActivityInsert: Scalars['Boolean'];
  ActivityUpdate: Scalars['Boolean'];
  AdvancePaymentInsert: AdvancePaymentEntity;
  AdvancePaymentRefund: Scalars['Boolean'];
  AdvancePaymentUpdate: Scalars['Boolean'];
  AgentDelete: Scalars['Boolean'];
  AllocateCreditNote: Scalars['Boolean'];
  AllocateDebitNote: Scalars['Boolean'];
  AllocateInvoice: Scalars['Boolean'];
  AllocateOfficialReceipt: Scalars['Boolean'];
  AllocateRefund: Scalars['Boolean'];
  AssignRoom: Scalars['Boolean'];
  AtestInsert: Scalars['Boolean'];
  AvailabilityUpdate: Scalars['Boolean'];
  BISharedGAlleryInsert: SharedGalleryEntity;
  BankAccountDelete: Scalars['Boolean'];
  BankAccountInActive: Scalars['Boolean'];
  BankAccountInsert: BankAccountEntity;
  BankAccountUpdate: Scalars['Boolean'];
  BillLedgerCancel: Scalars['Boolean'];
  BillLedgerInsert: Scalars['Boolean'];
  BillLedgerUpdate: Scalars['Boolean'];
  BillScheduleAdjustment: Scalars['Boolean'];
  BlockRoomLogInsert: BlockRoomLogEntity;
  BlockRoomLogInsertV2: Array<BlockRoomLogEntity>;
  BlockRoomLogUpdate: Scalars['Boolean'];
  BookingAttachmentDelete: Scalars['Boolean'];
  BookingAttachmentInsert: Scalars['Boolean'];
  BookingAttachmentUpdate: Scalars['Boolean'];
  BookingIncChargesCancel: Scalars['Boolean'];
  BookingInsert: Scalars['String'];
  BookingRoomAssignment: Scalars['Boolean'];
  BookingRoomTypeUpdate: Scalars['Boolean'];
  BookingUpdate: Scalars['Boolean'];
  BookingUpdateV2: Scalars['Boolean'];
  CBPost: Scalars['JSON'];
  CMSManualAvailUpdate: Scalars['Boolean'];
  CMSRateSync: Scalars['Boolean'];
  CancelBooking: Scalars['Boolean'];
  CancelCheckIn: Scalars['Boolean'];
  CancelRoom: Scalars['Boolean'];
  ChangePayment: Scalars['Boolean'];
  CheckIn: Scalars['Boolean'];
  CheckInInsertV2: Scalars['Boolean'];
  CheckOut: Scalars['Boolean'];
  ChooseRoomAllocationDelete: Scalars['Boolean'];
  ChooseRoomAllocationInsert: Scalars['Boolean'];
  ChooseRoomAllocationUpdate: Scalars['Boolean'];
  CityLedgerGLInsert: Scalars['Boolean'];
  CityLedgerTaxGLInsert: Scalars['Boolean'];
  CloseFolio: Scalars['Boolean'];
  CompanyInsert: CompanyEntity;
  CompanyUpdate: Scalars['Boolean'];
  CompanyUpdateStatus: Scalars['Boolean'];
  CorporateContractInsert: ContractEntity;
  CorporateContractUpdate: Scalars['Boolean'];
  CorporateDelete: Scalars['Boolean'];
  CorporateInsert: DebtorEntity;
  CorporateUpdate: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  CreditNoteCancel: Scalars['Boolean'];
  CreditNoteDelete: Scalars['Boolean'];
  CreditNoteInsert: CreditNoteEntity;
  CreditNoteUpdate: Scalars['Boolean'];
  CutOffBilling: Scalars['String'];
  DebitNoteCancel: Scalars['Boolean'];
  DebitNoteDelete: Scalars['Boolean'];
  DebitNoteInsert: DebitNoteEntity;
  DebitNoteUpdate: Scalars['Boolean'];
  DebtorChangePassword: DebtorProfileEntity;
  DebtorForgotPassword: Scalars['Boolean'];
  DebtorLastAccessedHotelUpdate: Scalars['Boolean'];
  DebtorLogin: DebtorProfileEntity;
  DebtorPaymentInsert: Scalars['Boolean'];
  DebtorResetPassword: Scalars['Boolean'];
  DebtorTypeDelete: Scalars['Boolean'];
  DebtorTypeInsert: DebtorTypeEntity;
  DebtorTypeUpdate: Scalars['Boolean'];
  DeleteImage: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  DepartmentDelete: Scalars['Boolean'];
  DepartmentInsert: Scalars['Boolean'];
  DepartmentUpdate: Scalars['Boolean'];
  DepositInsert: Scalars['Boolean'];
  DisplaySequenceUpdate: Scalars['Boolean'];
  DocumentNumberingSetupInsert: Scalars['Boolean'];
  DocumentNumberingUpdate: Scalars['Boolean'];
  EDocInsert: Scalars['Boolean'];
  EDocTemplateUpdate: Scalars['Boolean'];
  EmailCampaign: Scalars['Boolean'];
  EmailCampignInsert: EmailCampignEntity;
  EmailCampignUpdate: Scalars['Boolean'];
  EmailDelete: Scalars['Boolean'];
  EmailResetPassword: Scalars['Boolean'];
  EmailSend: Scalars['Boolean'];
  EventBillLedgerInsert: Scalars['Boolean'];
  EventScheduleBilling: Scalars['Boolean'];
  EventVoidBillLedger: Scalars['Boolean'];
  ExpressCheckinConfirmation: Scalars['Boolean'];
  ExtendStayInsert: Scalars['JSON'];
  FDReinstateBooking: Scalars['Boolean'];
  FacilitiesInactiveDelete: Scalars['Boolean'];
  FloorPlanReset: Scalars['Boolean'];
  FloorPlanUpdate: Scalars['Boolean'];
  FolioAdjustmentInsert: PaymentEntity;
  FolioBeneficiaryNameUpdate: Scalars['Boolean'];
  FolioDelete: Scalars['Boolean'];
  FolioInsert: Scalars['Boolean'];
  FrontDeskPaymentInsert: PaymentEntity;
  GLPost: GlPostResponse;
  GenerateConsolidatedDocOld: Scalars['Boolean'];
  GovermentTestingUpdate: Scalars['Boolean'];
  GuestAppAuthGenerator: GuestProfileEntity;
  GuestAppRoomTypeDelete: Scalars['Boolean'];
  GuestAppSetupUpdate: Scalars['Boolean'];
  GuestChangePassword: GuestProfileEntity;
  GuestContactUpdate: Scalars['Boolean'];
  GuestForgotPassword: Scalars['Boolean'];
  GuestInsert: Scalars['Boolean'];
  GuestLogin: GuestProfileEntity;
  GuestMovementInsert: Scalars['Boolean'];
  GuestProfileDelete: Scalars['Boolean'];
  GuestProfileInsert: RegistrationEntity;
  GuestRegister: Scalars['Boolean'];
  GuestResetPassword: Scalars['Boolean'];
  GuestReviewActiveUpdate: Scalars['Boolean'];
  GuestRoomServiceInsert: Scalars['JSON'];
  GuestUpdate: Scalars['Boolean'];
  HLSBookingNotification: Scalars['Boolean'];
  HotelContactInsert: ContactEntity;
  HotelContactUpdate: Scalars['Boolean'];
  HotelFacilityInsert: FacilityEntity;
  HotelFacilityUpdate: Scalars['Boolean'];
  HotelInsert: HotelEntity;
  HotelNewsInsert: NewsEntity;
  HotelNewsUpdate: Scalars['Boolean'];
  HotelNotificationUpdate: Scalars['Boolean'];
  HotelRatingInsert: HotelRatingEntity;
  HotelTaxPolicyDelete: Scalars['Boolean'];
  HotelTaxPolicyInsert: Scalars['Boolean'];
  HotelTaxPolicyUpdate: Scalars['Boolean'];
  HotelUpdate: Scalars['Boolean'];
  HouseKeepingLogInsert: Scalars['Boolean'];
  HouseLimitUpdate: Scalars['Boolean'];
  HousekeepingBillLedgerUpdate: Scalars['Boolean'];
  HousekeepingServiceRequestInsert: ServiceRequestEntity;
  HousekeepingServiceRequestUpdate: Scalars['Boolean'];
  HuaweiOCR?: Maybe<Scalars['JSON']>;
  ImageUpload: GalleryEntity;
  IncidentalChargeDelete: Scalars['Boolean'];
  IncidentalChargeInsert: Scalars['Boolean'];
  IncidentalChargeUpdate: Scalars['Boolean'];
  IncidentalChargesUpdate: Scalars['Boolean'];
  Innov8OCR?: Maybe<Scalars['JSON']>;
  InsertInterestAmt: Scalars['Boolean'];
  InterestDelete: Scalars['Boolean'];
  InterestUpdate: Scalars['Boolean'];
  InvoiceCancel: Scalars['Boolean'];
  InvoiceDelete: Scalars['Boolean'];
  InvoiceInsert: InvoiceEntity;
  InvoiceRemark: Scalars['Boolean'];
  InvoiceUpdate: Scalars['Boolean'];
  KeyCardInsert: Scalars['Boolean'];
  LateCheckOutChargeInsert: Scalars['Boolean'];
  LateCheckOutInsert: Scalars['JSON'];
  LocationDelete: Scalars['Boolean'];
  LocationInsert: LocationEntity;
  LocationUpdate: Scalars['Boolean'];
  ManualAvailabilityUpdate: Scalars['String'];
  ManualHotelNA: Scalars['Boolean'];
  ManualNARegistrationInsert: Scalars['String'];
  ManualNightAudit: Scalars['Boolean'];
  ManualNightAuditforAllHotel: Scalars['Boolean'];
  MenuOptionUpdate: Scalars['Boolean'];
  MenuXDailyRevenue: Scalars['Boolean'];
  MigrationAPI: Scalars['Boolean'];
  MultiCheckIn: Scalars['Boolean'];
  NewBookingInfoUpdate: Scalars['Boolean'];
  NewBookingRemark: Scalars['Boolean'];
  NoOfPaxUpdate: Scalars['Boolean'];
  NonGuestCloseFolio: Scalars['Boolean'];
  NonGuestFolioInsert: Scalars['Boolean'];
  NonGuestFolioUpdate: Scalars['Boolean'];
  NonGuestPaymentInsert: PaymentEntity;
  NonGuestTransferFolio: Scalars['Boolean'];
  NonGuestVoidLedger: Scalars['Boolean'];
  NotificationInsert: Scalars['Boolean'];
  OfficialReceiptCancel: Scalars['Boolean'];
  OfficialReceiptDelete: Scalars['Boolean'];
  OfficialReceiptInsert: OfficialReceiptEntity;
  OfficialReceiptUpdate: Scalars['Boolean'];
  OnlineBookingInsert: Scalars['JSON'];
  OnlinePayment?: Maybe<CheckOut>;
  OnlinePaymentRoomServices: OnlineItemEntity;
  POSChargeToCityLedger: Scalars['Boolean'];
  POSV7DailyRevenue: Scalars['Boolean'];
  POSV7ScheduleBilling: Scalars['Boolean'];
  PackageInsert: Scalars['Boolean'];
  PackageRedemptionInsert: Scalars['Boolean'];
  PackageUpdate: Scalars['Boolean'];
  PaymentTypeGLInsert: Scalars['Boolean'];
  PaymentUpdate: Scalars['Boolean'];
  PosV7BillLedgerInsert: Scalars['Boolean'];
  PosV7VoidBillLedger: Scalars['Boolean'];
  PostScheduleBilling: Scalars['Boolean'];
  PreCheckInInsert: Scalars['Boolean'];
  PreRegPaymentInsert: Scalars['JSON'];
  PromotionActiveUpdate: Scalars['Boolean'];
  PromotionDelete: Scalars['Boolean'];
  PromotionDetailInsert: PromotionDetailEntity;
  PromotionDetailUpdate: Scalars['Boolean'];
  PromotionInsert: PromotionEntity;
  PromotionUpdate: Scalars['Boolean'];
  Q3DocumentInsert: Scalars['Boolean'];
  QrScannerInsert: Scalars['String'];
  RateAddOnInsert: RateAddOnEntity;
  RateAddOnUpdate: Scalars['Boolean'];
  RateAdjustmentAuditInsert: Scalars['Boolean'];
  RateEffectiveInsert: Scalars['Boolean'];
  RateEffectiveStatusUpdate: Scalars['Boolean'];
  RateEffectiveUpdate: Scalars['Boolean'];
  RateElementInsert: RateElementEntity;
  RateElementUpdate: Scalars['Boolean'];
  RateInsert: RateEntity;
  RatePolicyDelete: Scalars['Boolean'];
  RatePolicyInsert: RatePolicyEntity;
  RatePolicyUpdate: Scalars['Boolean'];
  RateTypeInsert: RateTypeEntity;
  RateTypeUpdate: Scalars['Boolean'];
  RateUpdate: Scalars['Boolean'];
  ReasonDelete: Scalars['Boolean'];
  ReasonInsert: Scalars['Boolean'];
  ReasonUpdate: Scalars['Boolean'];
  RefundCancel: Scalars['Boolean'];
  RefundDelete: Scalars['Boolean'];
  RefundDeposit: Scalars['Boolean'];
  RefundInsert: RefundEntity;
  RefundPayment?: Maybe<CheckOut>;
  RefundUpdate: Scalars['Boolean'];
  RegenBSQueueConfirm: Scalars['Boolean'];
  RegenBSQueueInsert: Scalars['Boolean'];
  RegenerateBillSchedule: Scalars['Boolean'];
  RegenerateNARegistration: Scalars['JSON'];
  RegistrationSign: Scalars['Boolean'];
  RegistrationSignatureUpdate: RegistrationEntity;
  ReinstateBooking: Scalars['Boolean'];
  ReinstateRoom: Scalars['Boolean'];
  ReleaseRoomLogUpdateV2: Scalars['Boolean'];
  ReminderDelete: Scalars['Boolean'];
  ReminderInsert: ReminderEntity;
  ReminderUpdate: Scalars['Boolean'];
  RevenueMappingInsert: Scalars['Boolean'];
  RoomDelete: Scalars['Boolean'];
  RoomInsert: RoomEntity;
  RoomSaleOptimizationUpdate: Scalars['Boolean'];
  RoomServicePaymentUpdate: Scalars['Boolean'];
  RoomStatusInsert: Scalars['Boolean'];
  RoomTypeDelete: Scalars['Boolean'];
  RoomTypeInsert: RoomTypeEntity;
  RoomTypeUpdate: Scalars['Boolean'];
  RoomUpGradeInsert: Scalars['JSON'];
  RoomUpdate: Scalars['Boolean'];
  SalesChannelDelete: Scalars['Boolean'];
  SalesChannelInsert: Scalars['Boolean'];
  SalesChannelUpdate: Scalars['Boolean'];
  SeasonCalendarUpdate: Scalars['Boolean'];
  SeasonInsert: SeasonEntity;
  SeasonUpdate: Scalars['Boolean'];
  SegmentDelete: Scalars['Boolean'];
  SegmentInsert: SegmentEntity;
  SegmentMappingInsert: Scalars['Boolean'];
  SegmentUpdate: Scalars['Boolean'];
  SetDefaultTaxScheme: Scalars['Boolean'];
  ShortenStayInsert: Scalars['String'];
  SpecialReqUpdate: Scalars['Boolean'];
  SplitPaymentV2: Scalars['Boolean'];
  StatisticMappingInsert: Scalars['Boolean'];
  SuspendFolioInsert: Scalars['Boolean'];
  TaxDelete: Scalars['Boolean'];
  TaxInsert: Scalars['Boolean'];
  TaxLedgerInsert: Array<Scalars['String']>;
  TaxSchemeDelete: Scalars['Boolean'];
  TaxSchemeInsert: Scalars['Boolean'];
  TaxSchemeUpdate: Scalars['Boolean'];
  TaxUpdate: Scalars['Boolean'];
  TransferPayment: Scalars['Boolean'];
  TransferRoom: Scalars['Boolean'];
  TravelAgentContractInsert: ContractEntity;
  TravelAgentContractUpdate: Scalars['Boolean'];
  TravelAgentDelete: Scalars['Boolean'];
  TravelAgentInsert: DebtorEntity;
  TravelAgentMainUpdate: Scalars['Boolean'];
  TravelAgentUpdate: Scalars['Boolean'];
  UpdateAllotment: Scalars['Boolean'];
  UpdateInterestAmt: Scalars['Float'];
  UpdateRolePermission: Scalars['Boolean'];
  UpdateRoomAssignment: Scalars['Boolean'];
  UploadImage: Array<Scalars['String']>;
  VoidBillLedger: Scalars['Boolean'];
  VoidLedger: Scalars['Boolean'];
  WaitinglistNewAPI: Scalars['Boolean'];
  WalkIngBookingInsert: Scalars['JSON'];
  activateUser: Scalars['Boolean'];
  asyncInterestInsert: InterestEntity;
  changePassword: UserEntity;
  createPassword: Scalars['Boolean'];
  createSuperUser: UserEntity;
  createUser: UserEntity;
  forgotPassword: Scalars['Boolean'];
  genHashPassword: Scalars['JSON'];
  login: LoginResponse;
  logout: Scalars['Boolean'];
  reSendActivationEmail: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  sendRegisterOTP: Scalars['Boolean'];
  testapi: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  unLockUser: Scalars['Boolean'];
  updateProfile: Scalars['Boolean'];
  updateUser: UserEntity;
  verifyOTP: Scalars['Boolean'];
  walkInRoomAssigned: Scalars['Boolean'];
};


export type MutationAccountMappingGlInsertArgs = {
  GLInput: AccountMappingGlInput;
  GuestLedgerAcctInput: GuestLedgerAcctInput;
  HotelID: Scalars['String'];
};


export type MutationAccountMappingTaxGlInsertArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  TaxGLInput: AccountMappingTaxGlInput;
};


export type MutationActivityInsertArgs = {
  ActivitiesInput?: InputMaybe<Array<ActivitiesInput>>;
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationActivityUpdateArgs = {
  ActivitiesInput?: InputMaybe<Array<ActivitiesInput>>;
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationAdvancePaymentInsertArgs = {
  AdvancePaymentInput: AdvancePaymentInput;
};


export type MutationAdvancePaymentRefundArgs = {
  AdvancePaymentInput: Array<AdvancePaymentInput>;
};


export type MutationAdvancePaymentUpdateArgs = {
  AppName?: InputMaybe<Scalars['String']>;
  CheckOutID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  IsGuestApp?: InputMaybe<Scalars['Boolean']>;
  OrderID?: InputMaybe<Scalars['String']>;
  ReceiptNo?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<Scalars['String']>;
};


export type MutationAgentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationAllocateCreditNoteArgs = {
  CreditNoteID: Scalars['String'];
  allocInput: Array<CreditAllocationInput>;
};


export type MutationAllocateDebitNoteArgs = {
  DebitNoteID: Scalars['String'];
  allocInput: Array<DebitAllocationInput>;
};


export type MutationAllocateInvoiceArgs = {
  InvoiceID: Scalars['String'];
  allocInput: Array<DebitAllocationInput>;
};


export type MutationAllocateOfficialReceiptArgs = {
  ReceiptID: Scalars['String'];
  allocInput: Array<CreditAllocationInput>;
};


export type MutationAllocateRefundArgs = {
  RefundID: Scalars['String'];
  allocInput: Array<DebitAllocationInput>;
};


export type MutationAssignRoomArgs = {
  BookingID: Scalars['String'];
  Mode?: InputMaybe<Scalars['Boolean']>;
  RegistrationID: Scalars['String'];
  RoomID?: InputMaybe<Scalars['String']>;
};


export type MutationAtestInsertArgs = {
  BookingID: Scalars['String'];
};


export type MutationAvailabilityUpdateArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  RoomTypeID?: InputMaybe<Scalars['String']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
  TravelAgentID?: InputMaybe<Scalars['String']>;
};


export type MutationBiSharedGAlleryInsertArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  HotelID: Scalars['String'];
};


export type MutationBankAccountDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationBankAccountInActiveArgs = {
  ID: Scalars['String'];
  IsActive: Scalars['Boolean'];
};


export type MutationBankAccountInsertArgs = {
  BankAccountInput: BankAccountInput;
};


export type MutationBankAccountUpdateArgs = {
  BankAccountInput: BankAccountInput;
};


export type MutationBillLedgerCancelArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationBillLedgerInsertArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
  IsBookingIncCharges?: InputMaybe<Scalars['Boolean']>;
  IsNonGuest?: InputMaybe<Scalars['Boolean']>;
};


export type MutationBillLedgerUpdateArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationBillScheduleAdjustmentArgs = {
  Discount: Scalars['Float'];
  IsBaypassCMSControl?: InputMaybe<Scalars['Boolean']>;
  RateID: Scalars['String'];
  Reason: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
  TrxDates: Array<Scalars['DateTime']>;
};


export type MutationBlockRoomLogInsertArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationBlockRoomLogInsertV2Args = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: Array<BlockRoomLogInput>;
};


export type MutationBlockRoomLogUpdateArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationBookingAttachmentDeleteArgs = {
  BookingAttachmentID?: InputMaybe<Scalars['String']>;
};


export type MutationBookingAttachmentInsertArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  BookingID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
};


export type MutationBookingAttachmentUpdateArgs = {
  AttachmentDetailsID?: InputMaybe<Array<Scalars['String']>>;
  AttachmentInput: Array<Scalars['Upload']>;
  BookingAttachmentID?: InputMaybe<Scalars['String']>;
  BookingID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
};


export type MutationBookingIncChargesCancelArgs = {
  BillID?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
};


export type MutationBookingInsertArgs = {
  BookingInput: BookingInput;
  ContactInput: ContactInput;
  DebtorType?: InputMaybe<Scalars['String']>;
  IsSharedConfirmationLetter?: InputMaybe<Scalars['Boolean']>;
  RegistrationInput: Array<RegistrationInput>;
};


export type MutationBookingRoomAssignmentArgs = {
  BookingID: Scalars['String'];
  BookingRoomAssignInput?: InputMaybe<Array<BookingRoomAssignInput>>;
  RegistrationIDs?: InputMaybe<Array<Scalars['String']>>;
  RoomIDs?: InputMaybe<Array<Scalars['String']>>;
  RoomTypeID: Scalars['String'];
};


export type MutationBookingRoomTypeUpdateArgs = {
  EditRegInput: EditRegInput;
};


export type MutationBookingUpdateArgs = {
  BookingInput: BookingInput;
  ContactInput?: InputMaybe<ContactInput>;
  SpecialRequestInput?: InputMaybe<Array<SpecialRequestInput>>;
};


export type MutationBookingUpdateV2Args = {
  BookingID: Scalars['String'];
  BookingInput: BookingInput;
  ContactInput?: InputMaybe<ContactInput>;
  EditRegInput?: InputMaybe<Array<EditRegInput>>;
  IsBaypassCMSControl?: InputMaybe<Scalars['Boolean']>;
  PrintRate?: InputMaybe<Scalars['Boolean']>;
  SpecialRequestInput?: InputMaybe<Array<SpecialRequestInput>>;
};


export type MutationCbPostArgs = {
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  GLDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  IsTransfer?: InputMaybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
};


export type MutationCmsManualAvailUpdateArgs = {
  AvailAmt: Scalars['String'];
  HotelID: Scalars['String'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type MutationCmsRateSyncArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RateID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type MutationCancelBookingArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ReasonID?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
};


export type MutationCancelCheckInArgs = {
  BookingID: Scalars['String'];
  ReasonID?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  Remark?: InputMaybe<Scalars['String']>;
  RoomID: Scalars['String'];
};


export type MutationCancelRoomArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ReasonID?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  Remark?: InputMaybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
};


export type MutationChangePaymentArgs = {
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
  PaymentInput: PaymentInput;
  ReversedPaymentID: Scalars['String'];
};


export type MutationCheckInArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  BookingID: Scalars['String'];
  ContactInput?: InputMaybe<ContactInput>;
  GroupCheckIn?: InputMaybe<Scalars['Boolean']>;
  GuestAppCheckIn?: InputMaybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
  IdPicture?: InputMaybe<Array<Scalars['Upload']>>;
  IdPictureBack?: InputMaybe<Array<Scalars['Upload']>>;
  IsPDPA?: InputMaybe<Scalars['Boolean']>;
  IsTTx?: InputMaybe<Scalars['Boolean']>;
  RegistrationIDs: Array<Scalars['String']>;
  TTxReasonID?: InputMaybe<Scalars['String']>;
};


export type MutationCheckInInsertV2Args = {
  AddInformationInput?: InputMaybe<Array<AddInformationInput>>;
  BookingID: Scalars['String'];
  EditInformationInput?: InputMaybe<Array<EditInformationInput>>;
  HotelID: Scalars['String'];
};


export type MutationCheckOutArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
  RoomID: Scalars['String'];
};


export type MutationChooseRoomAllocationDeleteArgs = {
  AccountID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationChooseRoomAllocationInsertArgs = {
  RoomAllocationInput: ChooseRoomAllocationInput;
};


export type MutationChooseRoomAllocationUpdateArgs = {
  OldEffectiveDate: Scalars['DateTime'];
  RoomAllocationInput: ChooseRoomAllocationInput;
};


export type MutationCityLedgerGlInsertArgs = {
  GLInput: AccountMappingGlInput;
  HotelID: Scalars['String'];
};


export type MutationCityLedgerTaxGlInsertArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  TaxGLInput: AccountMappingTaxGlInput;
};


export type MutationCloseFolioArgs = {
  FolioInput: FolioInput;
  PaymentID: Scalars['String'];
  RegistrationID: Array<Scalars['String']>;
};


export type MutationCompanyInsertArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateStatusArgs = {
  CompanyID: Scalars['String'];
  Status: Scalars['String'];
};


export type MutationCorporateContractInsertArgs = {
  ContractInput: ContractInput;
  RatePolicyIDs: Array<Scalars['String']>;
};


export type MutationCorporateContractUpdateArgs = {
  ContractInput: ContractInput;
  RatePolicyIDs?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationCorporateDeleteArgs = {
  DebtorID: Scalars['String'];
};


export type MutationCorporateInsertArgs = {
  ContactInput: ContactInput;
  DebtorContactInput: Array<ContactInput>;
  DebtorInput: DebtorInput;
};


export type MutationCorporateUpdateArgs = {
  ContactInput: ContactInput;
  DebtorContactInput?: InputMaybe<Array<ContactInput>>;
  DebtorInput: DebtorInput;
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntRoleUsrAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  hotelID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};


export type MutationCreateRolePermissionArgs = {
  input: RoleInput;
  permissionArr: Array<HotelPermission>;
};


export type MutationCreditNoteCancelArgs = {
  ID: Scalars['String'];
  Reason: Scalars['String'];
  ReasonCode: Scalars['String'];
};


export type MutationCreditNoteDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationCreditNoteInsertArgs = {
  ChargeItemInput: Array<CreditNoteTrxInput>;
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  input: CreditNoteEntityInput;
};


export type MutationCreditNoteUpdateArgs = {
  ChargeItemInput: Array<CreditNoteTrxInput>;
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  ID: Scalars['String'];
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  input: CreditNoteEntityInput;
};


export type MutationCutOffBillingArgs = {
  FolioItemID?: InputMaybe<Scalars['String']>;
  FolioName?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IsSuspend?: InputMaybe<Scalars['Boolean']>;
  Operation: Scalars['String'];
  RoomNo?: InputMaybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
};


export type MutationDebitNoteCancelArgs = {
  ID: Scalars['String'];
  Reason: Scalars['String'];
  ReasonCode: Scalars['String'];
};


export type MutationDebitNoteDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDebitNoteInsertArgs = {
  ChargeItemInput: Array<DebitNoteTrxInput>;
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  input: DebitNoteEntityInput;
};


export type MutationDebitNoteUpdateArgs = {
  ChargeItemInput: Array<DebitNoteTrxInput>;
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  ID: Scalars['String'];
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  input: DebitNoteEntityInput;
};


export type MutationDebtorChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationDebtorForgotPasswordArgs = {
  Email: Scalars['String'];
};


export type MutationDebtorLastAccessedHotelUpdateArgs = {
  Email: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationDebtorLoginArgs = {
  Email?: InputMaybe<Scalars['String']>;
  Login?: InputMaybe<Scalars['String']>;
  MobileNo?: InputMaybe<Scalars['String']>;
  Password: Scalars['String'];
};


export type MutationDebtorPaymentInsertArgs = {
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  DebtorPaymentInput: Array<DebtorPaymentInput>;
  HotelID: Scalars['String'];
};


export type MutationDebtorResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationDebtorTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDebtorTypeInsertArgs = {
  input: DebtorTypeInput;
};


export type MutationDebtorTypeUpdateArgs = {
  ID: Scalars['String'];
  input: DebtorTypeInput;
};


export type MutationDeleteImageArgs = {
  OldGalleryID: Array<Scalars['String']>;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationDepartmentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDepartmentInsertArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDepartmentUpdateArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDepositInsertArgs = {
  input: DepositInput;
};


export type MutationDisplaySequenceUpdateArgs = {
  Input: Array<DisplaySequenceInput>;
  Type: Scalars['String'];
};


export type MutationDocumentNumberingSetupInsertArgs = {
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderinput: DocumentNumberHeaderInput;
};


export type MutationDocumentNumberingUpdateArgs = {
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderID: Scalars['String'];
  SampleOutput: Scalars['String'];
};


export type MutationEDocInsertArgs = {
  EDocTemplateInput: EDocTemplateInput;
};


export type MutationEDocTemplateUpdateArgs = {
  EDocTemplateInput: EDocTemplateInput;
};


export type MutationEmailCampaignArgs = {
  HotelID: Scalars['String'];
  IsSelectAll: Scalars['Boolean'];
  email: Array<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationEmailCampignInsertArgs = {
  EmailCampignInput: EmailCampignInput;
};


export type MutationEmailCampignUpdateArgs = {
  EmailCampignInput: EmailCampignInput;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationEmailDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationEmailResetPasswordArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  Email?: InputMaybe<Scalars['String']>;
};


export type MutationEmailSendArgs = {
  Attachments?: InputMaybe<EmailAttachmentInput>;
  Body: EmailBodyInput;
  BookingID?: InputMaybe<Scalars['String']>;
  Email?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  isGuestEmail?: InputMaybe<Scalars['Boolean']>;
};


export type MutationEventBillLedgerInsertArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
  IsNonGuest?: InputMaybe<Scalars['Boolean']>;
};


export type MutationEventScheduleBillingArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationEventVoidBillLedgerArgs = {
  IsNonGuest?: InputMaybe<Scalars['Boolean']>;
  input: BillLedgerInput;
};


export type MutationExpressCheckinConfirmationArgs = {
  AddConfirmationInput?: InputMaybe<Array<AddConfirmationInput>>;
  BookingID: Scalars['String'];
  EditConfirmationInput?: InputMaybe<Array<EditConfirmationInput>>;
  HotelID: Scalars['String'];
};


export type MutationExtendStayInsertArgs = {
  AdjustedAmount?: InputMaybe<Scalars['Float']>;
  AppName?: InputMaybe<Scalars['String']>;
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  ReceivedAmount?: InputMaybe<Scalars['Float']>;
  RoomTypeID: Scalars['String'];
  input: FrontDeskPaymentInput;
};


export type MutationFdReinstateBookingArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ReasonID?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  Remark?: InputMaybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
};


export type MutationFacilitiesInactiveDeleteArgs = {
  id: Scalars['String'];
  isInactive: Scalars['Boolean'];
};


export type MutationFloorPlanResetArgs = {
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  Type?: InputMaybe<Scalars['String']>;
};


export type MutationFloorPlanUpdateArgs = {
  LocationFloorPlanInput: Array<LocationFloorPlanInput>;
  Type?: InputMaybe<Scalars['String']>;
};


export type MutationFolioAdjustmentInsertArgs = {
  AdvancePaymentInput: Array<AdvancePaymentInput>;
  BillLedgerInput: Array<BillLedgerInput>;
  DepositLedgerInput: Array<DepositLedgerInput>;
  FolioType: Scalars['String'];
  HotelID: Scalars['String'];
  IsEInvoice?: InputMaybe<Scalars['Boolean']>;
  IsSuspend?: InputMaybe<Scalars['Boolean']>;
  PaymentInput: PaymentInput;
  RefFolioID?: InputMaybe<Scalars['String']>;
  RoomLedgerInput: Array<RoomLedgerInput>;
  TaxLedgerInput: Array<TaxLedgerInput>;
};


export type MutationFolioBeneficiaryNameUpdateArgs = {
  BeneficiaryAddress: Scalars['String'];
  BeneficiaryName: Scalars['String'];
  FolioID: Scalars['String'];
};


export type MutationFolioDeleteArgs = {
  FolioID: Scalars['String'];
};


export type MutationFolioInsertArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
  PaymentInput: PaymentInput;
  RefFolioID?: InputMaybe<Scalars['String']>;
};


export type MutationFrontDeskPaymentInsertArgs = {
  AdjustedAmount?: InputMaybe<Scalars['Float']>;
  AppName?: InputMaybe<Scalars['String']>;
  BeneficiaryAddress?: InputMaybe<Scalars['String']>;
  BeneficiaryName?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  IsGroupFolio?: InputMaybe<Scalars['Boolean']>;
  TotalPaymentAmount: Scalars['Float'];
  input: Array<FrontDeskPaymentInput>;
};


export type MutationGlPostArgs = {
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  GLDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  IsTransfer?: InputMaybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
};


export type MutationGenerateConsolidatedDocOldArgs = {
  DocNo?: InputMaybe<Scalars['String']>;
  DocType?: InputMaybe<Scalars['String']>;
  EInvoiceConsolidationInput: EInvoiceConsolidationInput;
  Month?: InputMaybe<Scalars['Float']>;
  Name?: InputMaybe<Scalars['String']>;
  Submenu?: InputMaybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Year?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Float']>;
};


export type MutationGovermentTestingUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationGuestAppAuthGeneratorArgs = {
  BookingID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type MutationGuestAppRoomTypeDeleteArgs = {
  BookingID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
  SharerNo?: InputMaybe<Scalars['Float']>;
};


export type MutationGuestAppSetupUpdateArgs = {
  GuestAppSettingInput: GuestAppSettingInput;
};


export type MutationGuestChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationGuestContactUpdateArgs = {
  ArrivalDate?: InputMaybe<Scalars['DateTime']>;
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  BookingID?: InputMaybe<Scalars['String']>;
  ContactInput: ContactInput;
  DepartureDate?: InputMaybe<Scalars['DateTime']>;
  HotelID?: InputMaybe<Scalars['String']>;
  IsPrincipal?: InputMaybe<Scalars['Boolean']>;
  IsTTx?: InputMaybe<Scalars['Boolean']>;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  TTxReasonID?: InputMaybe<Scalars['String']>;
  VehicleNo?: InputMaybe<Scalars['String']>;
  guestType?: InputMaybe<GuestType>;
};


export type MutationGuestForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGuestInsertArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  BookingID: Scalars['String'];
  ContactInput: Array<ContactInput>;
  HotelID: Scalars['String'];
  IsTTx?: InputMaybe<Scalars['Boolean']>;
  RegistrationID: Scalars['String'];
  TTxReasonID?: InputMaybe<Scalars['String']>;
  guestType?: InputMaybe<GuestType>;
};


export type MutationGuestLoginArgs = {
  AccountID: Scalars['String'];
  Email?: InputMaybe<Scalars['String']>;
  Login?: InputMaybe<Scalars['String']>;
  MobileNo?: InputMaybe<Scalars['String']>;
  Password: Scalars['String'];
};


export type MutationGuestMovementInsertArgs = {
  GuestMovementInput: GuestMovementInput;
};


export type MutationGuestProfileDeleteArgs = {
  BookingID?: InputMaybe<Scalars['String']>;
  GuestID?: InputMaybe<Scalars['String']>;
  PrincipalRegistrationID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  SharerNo?: InputMaybe<Scalars['Float']>;
};


export type MutationGuestProfileInsertArgs = {
  ContactInput: ContactInput;
  RegistrationID: Scalars['String'];
};


export type MutationGuestRegisterArgs = {
  AccountID: Scalars['String'];
  Email: Scalars['String'];
  FullName: Scalars['String'];
  Login: Scalars['String'];
  MobileNo: Scalars['String'];
  NRIC: Scalars['String'];
  Password: Scalars['String'];
};


export type MutationGuestResetPasswordArgs = {
  Email?: InputMaybe<Scalars['String']>;
  MobileNo?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationGuestReviewActiveUpdateArgs = {
  HotelRatingInput: HotelRatingInput;
};


export type MutationGuestRoomServiceInsertArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  AppName?: InputMaybe<Scalars['String']>;
  BillLedgerInput: Array<BillLedgerInput>;
  BookingID: Scalars['String'];
  PaymentMode: Scalars['String'];
};


export type MutationGuestUpdateArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  ContactInput: ContactInput;
  GuestProfileInput: GuestProfileInput;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  ProfilePictureAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationHlsBookingNotificationArgs = {
  BookingId: Scalars['String'];
  HotelId: Scalars['String'];
};


export type MutationHotelContactInsertArgs = {
  ContactInput: ContactInput;
  HotelID: Scalars['String'];
};


export type MutationHotelContactUpdateArgs = {
  ContactInput: ContactInput;
};


export type MutationHotelFacilityInsertArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
};


export type MutationHotelFacilityUpdateArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationHotelInsertArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
  Type?: InputMaybe<Scalars['String']>;
};


export type MutationHotelNewsInsertArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
};


export type MutationHotelNewsUpdateArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationHotelNotificationUpdateArgs = {
  input: Array<HotelNotificationInput>;
  operationType?: InputMaybe<Scalars['String']>;
};


export type MutationHotelRatingInsertArgs = {
  input: HotelRatingInput;
};


export type MutationHotelTaxPolicyDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationHotelTaxPolicyInsertArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelTaxPolicyUpdateArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelUpdateArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  Type?: InputMaybe<Scalars['String']>;
};


export type MutationHouseKeepingLogInsertArgs = {
  HouseKeepingLogInput: HouseKeepingLogInput;
  RoomIDs?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationHouseLimitUpdateArgs = {
  HouseLimitInput?: InputMaybe<Array<HouseLimitInput>>;
};


export type MutationHousekeepingBillLedgerUpdateArgs = {
  BillLedgerInput: BillLedgerInput;
};


export type MutationHousekeepingServiceRequestInsertArgs = {
  AppName?: InputMaybe<Scalars['String']>;
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHousekeepingServiceRequestUpdateArgs = {
  AppName?: InputMaybe<Scalars['String']>;
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHuaweiOcrArgs = {
  File: Scalars['String'];
};


export type MutationImageUploadArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationIncidentalChargeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationIncidentalChargeInsertArgs = {
  IncidentalChargeInput: IncidentalChargeInput;
};


export type MutationIncidentalChargeUpdateArgs = {
  IncidentalChargeInput: IncidentalChargeInput;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationIncidentalChargesUpdateArgs = {
  ID: Scalars['String'];
  status: Scalars['Boolean'];
};


export type MutationInnov8OcrArgs = {
  File: Scalars['String'];
};


export type MutationInterestDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationInterestUpdateArgs = {
  ID: Scalars['String'];
  input: InterestEntityInput;
};


export type MutationInvoiceCancelArgs = {
  ID: Scalars['String'];
  Reason: Scalars['String'];
  ReasonCode: Scalars['String'];
};


export type MutationInvoiceDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationInvoiceInsertArgs = {
  ChargeItemInput: Array<InvoiceTrxInput>;
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  input: InvoiceEntityInput;
};


export type MutationInvoiceRemarkArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  InvoiceRemark?: InputMaybe<Scalars['String']>;
};


export type MutationInvoiceUpdateArgs = {
  ChargeItemInput: Array<InvoiceTrxInput>;
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  ID: Scalars['String'];
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  input: InvoiceEntityInput;
};


export type MutationKeyCardInsertArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  input: KeyCardInput;
};


export type MutationLateCheckOutChargeInsertArgs = {
  LateCheckOutChargeInput: Array<LateCheckOutChargeInput>;
};


export type MutationLateCheckOutInsertArgs = {
  AdjustedAmount?: InputMaybe<Scalars['Float']>;
  AppName?: InputMaybe<Scalars['String']>;
  ReceivedAmount?: InputMaybe<Scalars['Float']>;
  hour: Scalars['Float'];
  input: FrontDeskPaymentInput;
};


export type MutationLocationDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationLocationInsertArgs = {
  LocationInput: LocationInput;
};


export type MutationLocationUpdateArgs = {
  LocationInput: LocationInput;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationManualAvailabilityUpdateArgs = {
  BlockDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomTypeID?: InputMaybe<Scalars['String']>;
  days: Scalars['Float'];
};


export type MutationManualHotelNaArgs = {
  Action: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationManualNaRegistrationInsertArgs = {
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  UserID: Scalars['String'];
};


export type MutationManualNightAuditArgs = {
  EffectiveDate?: InputMaybe<Scalars['DateTime']>;
  HotelID?: InputMaybe<Scalars['String']>;
};


export type MutationManualNightAuditforAllHotelArgs = {
  EffectiveDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationMenuOptionUpdateArgs = {
  input: RoleInput;
};


export type MutationMenuXDailyRevenueArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Input?: InputMaybe<Array<Posv7DailyRevenueInput>>;
};


export type MutationMultiCheckInArgs = {
  AdditionalMultiRegInput?: InputMaybe<Array<AdditionalMultiRegInput>>;
  BookingID: Scalars['String'];
  ContactInput?: InputMaybe<Array<ContactInput>>;
  GroupCheckIn?: InputMaybe<Scalars['Boolean']>;
  GuestAppCheckIn?: InputMaybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type MutationNewBookingInfoUpdateArgs = {
  BookingID: Scalars['String'];
  BookingInput: BookingInput;
  SpecialRequestInput?: InputMaybe<Array<SpecialRequestInput>>;
};


export type MutationNewBookingRemarkArgs = {
  BookingID?: InputMaybe<Scalars['String']>;
  BookingRemark: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type MutationNoOfPaxUpdateArgs = {
  BookingID: Scalars['String'];
  IsBaypassCMSControl?: InputMaybe<Scalars['Boolean']>;
  NoOfAdult: Scalars['Float'];
  RoomTypeID: Scalars['String'];
  SharerNo: Scalars['Float'];
};


export type MutationNonGuestCloseFolioArgs = {
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
  eInvoiceType?: InputMaybe<Scalars['String']>;
};


export type MutationNonGuestFolioInsertArgs = {
  FolioInput: FolioInput;
};


export type MutationNonGuestFolioUpdateArgs = {
  FolioInput: FolioInput;
};


export type MutationNonGuestPaymentInsertArgs = {
  BillLedgerIDs?: InputMaybe<Array<Scalars['String']>>;
  HotelID?: InputMaybe<Scalars['String']>;
  PaymentInput: PaymentInput;
};


export type MutationNonGuestTransferFolioArgs = {
  FolioID: Scalars['String'];
  HotelID?: InputMaybe<Scalars['String']>;
  IsSuspend?: InputMaybe<Scalars['Boolean']>;
  RecordIDs: Array<Scalars['String']>;
};


export type MutationNonGuestVoidLedgerArgs = {
  BillLedgerID?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  PaymentID?: InputMaybe<Scalars['String']>;
  Reason?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
};


export type MutationOfficialReceiptCancelArgs = {
  ID: Scalars['String'];
  Reason: Scalars['String'];
  ReasonCode: Scalars['String'];
};


export type MutationOfficialReceiptDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationOfficialReceiptInsertArgs = {
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  input: OfficialReceiptEntityInput;
};


export type MutationOfficialReceiptUpdateArgs = {
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  ID: Scalars['String'];
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  input: OfficialReceiptEntityInput;
};


export type MutationOnlineBookingInsertArgs = {
  AppName?: InputMaybe<Scalars['String']>;
  BookingInput: BookingInput;
  ContactInput: ContactInput;
  RegistrationInput: Array<RegistrationInput>;
};


export type MutationOnlinePaymentArgs = {
  AdvancePaymentID?: InputMaybe<Scalars['String']>;
  Detail: Scalars['String'];
  HotelID: Scalars['String'];
  PayAmount: Scalars['Float'];
  PaymentID?: InputMaybe<Scalars['String']>;
  ReceiptNo?: InputMaybe<Scalars['String']>;
  Title: Scalars['String'];
  redirectUrl: Scalars['String'];
};


export type MutationOnlinePaymentRoomServicesArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
  PaymentInput: PaymentInput;
};


export type MutationPosChargeToCityLedgerArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationPosv7DailyRevenueArgs = {
  POSV7DailyRevenueInput: Array<Posv7DailyRevenueInput>;
};


export type MutationPosv7ScheduleBillingArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationPackageInsertArgs = {
  PackagesInput: Array<PackagesInput>;
};


export type MutationPackageRedemptionInsertArgs = {
  PackageRedemptionInput: PackageRedemptionInput;
};


export type MutationPackageUpdateArgs = {
  PackagesInput: PackagesInput;
};


export type MutationPaymentTypeGlInsertArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  PaymentType: Array<Scalars['String']>;
};


export type MutationPaymentUpdateArgs = {
  AppName?: InputMaybe<Scalars['String']>;
  CheckOutID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  IsGuestApp?: InputMaybe<Scalars['Boolean']>;
  Mode?: InputMaybe<Scalars['String']>;
  OrderID?: InputMaybe<Scalars['String']>;
  ReceiptNo?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<Scalars['String']>;
};


export type MutationPosV7BillLedgerInsertArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
  IsNonGuest?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPosV7VoidBillLedgerArgs = {
  IsNonGuest?: InputMaybe<Scalars['Boolean']>;
  input: BillLedgerInput;
};


export type MutationPostScheduleBillingArgs = {
  HotelID: Scalars['String'];
  InvoiceDate?: InputMaybe<Scalars['DateTime']>;
  input: Array<ScheduleBillingInput>;
};


export type MutationPreCheckInInsertArgs = {
  BookingID: Scalars['String'];
  IcPhotoBack?: InputMaybe<Array<Scalars['Upload']>>;
  IcPhotoFront?: InputMaybe<Array<Scalars['Upload']>>;
  PreCheckInInput: PreCheckInInput;
  RegistrationID: Scalars['String'];
  Signature: Array<Scalars['Upload']>;
};


export type MutationPreRegPaymentInsertArgs = {
  Amount: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  RoomID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
};


export type MutationPromotionActiveUpdateArgs = {
  PromotionInput: PromotionInput;
};


export type MutationPromotionDeleteArgs = {
  Date: Scalars['DateTime'];
  ID: Scalars['String'];
  PromoCode: Scalars['String'];
};


export type MutationPromotionDetailInsertArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationPromotionDetailUpdateArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationPromotionInsertArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationPromotionUpdateArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationQ3DocumentInsertArgs = {
  Q3DocumentDetailInput: Array<Q3DocumentDetailInput>;
  Q3DocumentHeaderInput: Q3DocumentHeaderInput;
};


export type MutationQrScannerInsertArgs = {
  DeviceData?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  QrData?: InputMaybe<Scalars['String']>;
};


export type MutationRateAddOnInsertArgs = {
  RateAddOnInput: RateAddOnInput;
};


export type MutationRateAddOnUpdateArgs = {
  RateAddOnInput: RateAddOnInput;
};


export type MutationRateAdjustmentAuditInsertArgs = {
  RateAdjustmentAuditInput: RateAdjustmentAuditInput;
};


export type MutationRateEffectiveInsertArgs = {
  RateEffectiveInput: RateEffectiveInput;
  SeasonalRateInput?: InputMaybe<Array<SeasonalRateInput>>;
};


export type MutationRateEffectiveStatusUpdateArgs = {
  IsActive: Scalars['Boolean'];
  RateEffectiveID: Scalars['String'];
};


export type MutationRateEffectiveUpdateArgs = {
  RateEffectiveInput: RateEffectiveInput;
  SeasonalRateInput?: InputMaybe<Array<SeasonalRateInput>>;
};


export type MutationRateElementInsertArgs = {
  RateElementInput: RateElementInput;
};


export type MutationRateElementUpdateArgs = {
  RateElementInput: RateElementInput;
};


export type MutationRateInsertArgs = {
  RateInput: RateInput;
};


export type MutationRatePolicyDeleteArgs = {
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationRatePolicyInsertArgs = {
  PackagesInput: Array<PackagesInput>;
  PricingInput: Array<PricingInput>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationRatePolicyUpdateArgs = {
  PricingInput?: InputMaybe<Array<PricingInput>>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationRateTypeInsertArgs = {
  RateTypeInput: RateTypeInput;
};


export type MutationRateTypeUpdateArgs = {
  RateTypeInput: RateTypeInput;
};


export type MutationRateUpdateArgs = {
  RateInput: RateInput;
};


export type MutationReasonDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationReasonInsertArgs = {
  ReasonInput: ReasonInput;
};


export type MutationReasonUpdateArgs = {
  ReasonInput: ReasonInput;
};


export type MutationRefundCancelArgs = {
  ID: Scalars['String'];
  Reason: Scalars['String'];
  ReasonCode: Scalars['String'];
};


export type MutationRefundDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationRefundDepositArgs = {
  input: RefundDepositInput;
};


export type MutationRefundInsertArgs = {
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  input: RefundEntityInput;
};


export type MutationRefundPaymentArgs = {
  HotelID: Scalars['String'];
  ReceiptNo: Scalars['String'];
};


export type MutationRefundUpdateArgs = {
  CityLedgerAttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  ID: Scalars['String'];
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  input: RefundEntityInput;
};


export type MutationRegenBsQueueConfirmArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type MutationRegenBsQueueInsertArgs = {
  ArrEndDate?: InputMaybe<Scalars['DateTime']>;
  ArrStartDate?: InputMaybe<Scalars['DateTime']>;
  BookingNo?: InputMaybe<Scalars['String']>;
  FullName?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  IsCms?: InputMaybe<Scalars['Boolean']>;
  RateCodeID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
};


export type MutationRegenerateBillScheduleArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  IsCms: Scalars['Boolean'];
  IsInhouse: Scalars['Boolean'];
  StartDate: Scalars['DateTime'];
};


export type MutationRegenerateNaRegistrationArgs = {
  EndArrDate: Scalars['DateTime'];
  HotelID?: InputMaybe<Scalars['String']>;
  StartArrDate: Scalars['DateTime'];
};


export type MutationRegistrationSignArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  ID: Scalars['String'];
};


export type MutationRegistrationSignatureUpdateArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  RegistrationID: Scalars['String'];
};


export type MutationReinstateBookingArgs = {
  BookingID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ReasonID?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
};


export type MutationReinstateRoomArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ReasonID?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  Remark?: InputMaybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
};


export type MutationReleaseRoomLogUpdateV2Args = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationReminderDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationReminderInsertArgs = {
  input: ReminderEntityInput;
};


export type MutationReminderUpdateArgs = {
  ID: Scalars['String'];
  input: ReminderEntityInput;
};


export type MutationRevenueMappingInsertArgs = {
  AccountID: Scalars['String'];
  GuestLedgerAcctInput: RevenueGuestLedgerAcctInput;
  HotelID: Scalars['String'];
  RevenueInput: RevenueMappingInput;
};


export type MutationRoomDeleteArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};


export type MutationRoomInsertArgs = {
  RoomInput: RoomInput;
};


export type MutationRoomSaleOptimizationUpdateArgs = {
  input: Array<AvailabilityInput>;
};


export type MutationRoomServicePaymentUpdateArgs = {
  CardNo?: InputMaybe<Scalars['String']>;
  CheckOutID?: InputMaybe<Scalars['String']>;
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  OrderID?: InputMaybe<Scalars['String']>;
  PaymentType?: InputMaybe<Scalars['String']>;
  ReceiptNo?: InputMaybe<Scalars['String']>;
  ReferenceNo?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<Scalars['String']>;
};


export type MutationRoomStatusInsertArgs = {
  RoomStatusInput: Array<RoomStatusInput>;
};


export type MutationRoomTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationRoomTypeInsertArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  RoomTypeAmenitiesInput: Array<RoomTypeAmenitiesInput>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomTypeUpdateArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  OldGalleryID?: InputMaybe<Array<Scalars['String']>>;
  RoomTypeAmenitiesInput?: InputMaybe<Array<RoomTypeAmenitiesInput>>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomUpGradeInsertArgs = {
  AdjustedAmount?: InputMaybe<Scalars['Float']>;
  IsBaypassCMSControl?: InputMaybe<Scalars['Boolean']>;
  IsBooking?: InputMaybe<Scalars['Boolean']>;
  PaymentInput?: InputMaybe<FrontDeskPaymentInput>;
  ReceivedAmount?: InputMaybe<Scalars['Float']>;
  RoomUpgradeInput: RoomUpgradeInput;
};


export type MutationRoomUpdateArgs = {
  RoomInput: RoomInput;
};


export type MutationSalesChannelDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSalesChannelInsertArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSalesChannelUpdateArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSeasonCalendarUpdateArgs = {
  CalendarDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  SeasonCalendarInput: Array<SeasonCalendarInput>;
};


export type MutationSeasonInsertArgs = {
  SeasonInput: SeasonInput;
};


export type MutationSeasonUpdateArgs = {
  SeasonInput: SeasonInput;
};


export type MutationSegmentDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationSegmentInsertArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSegmentMappingInsertArgs = {
  HotelID: Scalars['String'];
  SegmentInput: Array<SegmentInput>;
};


export type MutationSegmentUpdateArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSetDefaultTaxSchemeArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
};


export type MutationShortenStayInsertArgs = {
  ArrivalDate: Scalars['DateTime'];
  BookingID: Scalars['String'];
  DepartureDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};


export type MutationSpecialReqUpdateArgs = {
  RegistrationID: Scalars['String'];
  SpecialReq: Scalars['String'];
};


export type MutationSplitPaymentV2Args = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RecordToSplit: Array<PaymentFolioInput>;
  SplitBy: Scalars['Float'];
  SplitType: Scalars['String'];
  SplitValue: Scalars['Float'];
};


export type MutationStatisticMappingInsertArgs = {
  HotelID: Scalars['String'];
  Q3Statistic: Array<Q3StatisticInput>;
};


export type MutationSuspendFolioInsertArgs = {
  FolioInput: FolioInput;
};


export type MutationTaxDeleteArgs = {
  TaxDeleteInput: Array<TaxDetailInput>;
  TaxID: Scalars['String'];
};


export type MutationTaxInsertArgs = {
  AccountID: Scalars['String'];
  TaxInsertInput: TaxPolicyInput;
};


export type MutationTaxLedgerInsertArgs = {
  TaxLedgerInput: Array<TaxLedgerInput>;
};


export type MutationTaxSchemeDeleteArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
};


export type MutationTaxSchemeInsertArgs = {
  AccountID: Scalars['String'];
  TaxSchemeInput: TaxSchemeInput;
};


export type MutationTaxSchemeUpdateArgs = {
  AccountID: Scalars['String'];
  EditTaxSchemeInput: EditTaxSchemeInput;
};


export type MutationTaxUpdateArgs = {
  AccountID: Scalars['String'];
  TaxEditInput: EditTaxPolicyInput;
};


export type MutationTransferPaymentArgs = {
  FolioID?: InputMaybe<Scalars['String']>;
  FromRegistrationID?: InputMaybe<Scalars['String']>;
  IsNonguest?: InputMaybe<Scalars['Boolean']>;
  NewRegistrationID: Scalars['String'];
  RecordIDs: Array<Scalars['String']>;
};


export type MutationTransferRoomArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  NewRoomID: Scalars['String'];
  OldRoomID: Scalars['String'];
  Reason?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  Remark?: InputMaybe<Scalars['String']>;
};


export type MutationTravelAgentContractInsertArgs = {
  ContractInput: ContractInput;
  HotelID: Scalars['String'];
  RatePolicyIDs: Array<Scalars['String']>;
  RoomTypeList: Array<ContractAllotmentInput>;
};


export type MutationTravelAgentContractUpdateArgs = {
  ContractInput: ContractInput;
  HotelID: Scalars['String'];
  RatePolicyIDs?: InputMaybe<Array<Scalars['String']>>;
  RoomTypeList?: InputMaybe<Array<ContractAllotmentInput>>;
};


export type MutationTravelAgentDeleteArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationTravelAgentInsertArgs = {
  ContactInput: ContactInput;
  DebtorContactInput: Array<ContactInput>;
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentMainUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentUpdateArgs = {
  ContactInput: ContactInput;
  DebtorContactInput?: InputMaybe<Array<ContactInput>>;
  DebtorInput: DebtorInput;
};


export type MutationUpdateAllotmentArgs = {
  AllotmentInput: Array<ContractAllotmentInput>;
  DebtorID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type MutationUpdateInterestAmtArgs = {
  InterestRate: Scalars['Float'];
};


export type MutationUpdateRolePermissionArgs = {
  input: RoleInput;
  permissionArr: Array<HotelPermission>;
};


export type MutationUpdateRoomAssignmentArgs = {
  BookingID: Scalars['String'];
  IsCompleted?: InputMaybe<Scalars['Boolean']>;
  RegistrationID: Scalars['String'];
  RoomID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
};


export type MutationUploadImageArgs = {
  FileInput: Array<Scalars['Upload']>;
};


export type MutationVoidBillLedgerArgs = {
  input: BillLedgerInput;
};


export type MutationVoidLedgerArgs = {
  ChargeType?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  FromRegistration?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  LedgerType: Scalars['String'];
  Reason?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  Remark?: InputMaybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
};


export type MutationWaitinglistNewApiArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type MutationWalkIngBookingInsertArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  BookingInput: BookingInput;
  ContactInput: ContactInput;
  IdPicture?: InputMaybe<Array<Scalars['Upload']>>;
  IsTTx?: InputMaybe<Scalars['Boolean']>;
  RegistrationInput: RegistrationInput;
  TTxReasonID?: InputMaybe<Scalars['String']>;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationAsyncInterestInsertArgs = {
  input: InterestEntityInput;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationCreatePasswordArgs = {
  accountID: Scalars['String'];
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
  softwareCode: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationCreateUserArgs = {
  HotelID: Scalars['String'];
  input: UserInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGenHashPasswordArgs = {
  Password?: InputMaybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  loginId: Scalars['String'];
  password: Scalars['String'];
};


export type MutationReSendActivationEmailArgs = {
  userID: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  accountID?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
  userID?: InputMaybe<Scalars['String']>;
};


export type MutationSendRegisterOtpArgs = {
  MobileNo: Scalars['String'];
  OtpType: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUnLockUserArgs = {
  accountID: Scalars['String'];
  password: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationUpdateProfileArgs = {
  AttachmentInput?: InputMaybe<Array<Scalars['Upload']>>;
  input: UserInput;
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationVerifyOtpArgs = {
  MobileNo: Scalars['String'];
  OtpCode: Scalars['String'];
  OtpType: Scalars['String'];
};


export type MutationWalkInRoomAssignedArgs = {
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomTypeID?: InputMaybe<Scalars['String']>;
};

export type NaProcessLogEntity = InterfaceBase & {
  __typename?: 'NAProcessLogEntity';
  AutoExtendStay?: Maybe<Scalars['Boolean']>;
  AutoExtendStayComplete?: Maybe<Scalars['DateTime']>;
  AvailabilityUpdate?: Maybe<Scalars['Boolean']>;
  AvailabilityUpdateComplete?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DailyRegistration?: Maybe<Scalars['Boolean']>;
  DailyRegistrationComplete?: Maybe<Scalars['DateTime']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  HouseKeepingUpdate?: Maybe<Scalars['Boolean']>;
  HouseKeepingUpdateComplete?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  NAStatus?: Maybe<Scalars['String']>;
  NoShowBooking?: Maybe<Scalars['Boolean']>;
  NoShowBookingComplete?: Maybe<Scalars['DateTime']>;
  PendingBooking?: Maybe<Scalars['Boolean']>;
  PendingBookingComplete?: Maybe<Scalars['DateTime']>;
  PostAdvancePayment?: Maybe<Scalars['Boolean']>;
  PostAdvancePaymentComplete?: Maybe<Scalars['DateTime']>;
  PostRoomRevenue?: Maybe<Scalars['Boolean']>;
  PostRoomRevenueComplete?: Maybe<Scalars['DateTime']>;
  ReleaseBlockRoom?: Maybe<Scalars['Boolean']>;
  ReleaseBlockRoomComplete?: Maybe<Scalars['DateTime']>;
};

export type NaRegistration2Entity = InterfaceTrx & {
  __typename?: 'NARegistration2Entity';
  BookingID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  SegmentID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
  Source?: Maybe<Scalars['String']>;
  TTxAmount?: Maybe<Scalars['Float']>;
  TourismTax?: Maybe<Scalars['Boolean']>;
};

export type NaRegistrationEntity = InterfaceTrx & {
  __typename?: 'NARegistrationEntity';
  BookingID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  SegmentID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
  Source?: Maybe<Scalars['String']>;
  TTxAmount?: Maybe<Scalars['Float']>;
  TourismTax?: Maybe<Scalars['Boolean']>;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bahraini = 'Bahraini',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belarusian = 'Belarusian',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bruneian = 'Bruneian',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Deutsch = 'Deutsch',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kazakhstani = 'Kazakhstani',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Maldivian = 'Maldivian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mauritian = 'Mauritian',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Omani = 'Omani',
  Others = 'Others',
  Pakistani = 'Pakistani',
  Palestinians = 'Palestinians',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  RepublicOfTrinidad = 'Republic_Of_Trinidad',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Uzbekistani = 'Uzbekistani',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Yemeni = 'Yemeni',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export type NewsEntity = InterfaceBase & {
  __typename?: 'NewsEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  NewsGallery?: Maybe<Array<NewsGalleryEntity>>;
  Title?: Maybe<Scalars['String']>;
};

export type NewsGalleryEntity = InterfaceBase & {
  __typename?: 'NewsGalleryEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  News?: Maybe<NewsEntity>;
  NewsID: Scalars['String'];
};

export type NewsGalleryInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  NewsID: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type NewsInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Title?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type NonGuestBalance = {
  __typename?: 'NonGuestBalance';
  TotalBill?: Maybe<Scalars['Float']>;
  TotalLeft?: Maybe<Scalars['Float']>;
  TotalPaid?: Maybe<Scalars['Float']>;
};

export type NonGuestFolioResponse = {
  __typename?: 'NonGuestFolioResponse';
  BaseAmount?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ID?: Maybe<Scalars['String']>;
  IncidentalDescription?: Maybe<Scalars['String']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  Qty?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedID?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TaxBillLedger?: Maybe<Array<BillLedgerTaxEntity>>;
  TaxRoomLedger?: Maybe<Array<RoomLedgerTaxEntity>>;
  TransactionType?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
};

export type NonGuestInfoJson = {
  __typename?: 'NonGuestInfoJson';
  DebtorID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  ReferenceID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
};

export type NonGuestInfoJsonInput = {
  DebtorID?: InputMaybe<Scalars['String']>;
  EffectiveDate?: InputMaybe<Scalars['String']>;
  HouseLimit?: InputMaybe<Scalars['Float']>;
  ReferenceID?: InputMaybe<Scalars['String']>;
  Remark?: InputMaybe<Scalars['String']>;
  Title?: InputMaybe<Scalars['String']>;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  Date: Scalars['DateTime'];
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['ID'];
  Message?: Maybe<Scalars['String']>;
};

export type Occupancy = {
  __typename?: 'Occupancy';
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Occupancy?: Maybe<Scalars['Float']>;
  Season: Scalars['String'];
  TotalBlock?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  TotalVacantShow?: Maybe<Scalars['Float']>;
};

export type OfficialReceiptEntity = InterfaceTrx & {
  __typename?: 'OfficialReceiptEntity';
  AccountID: Scalars['String'];
  Allocation: Array<AllocationEntity>;
  Amount: Scalars['Float'];
  Attachment?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  Commission: Scalars['Float'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  OutstandingAmount: Scalars['Float'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Status: ArTransactionStatus;
  VoidDate?: Maybe<Scalars['DateTime']>;
  bankDetails: BankDetails;
};

export type OfficialReceiptEntityInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Amount: Scalars['Float'];
  Commission: Scalars['Float'];
  Currency?: InputMaybe<Currency>;
  CurrencyRate?: InputMaybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  PostedDate?: InputMaybe<Scalars['DateTime']>;
  ReferenceNo: Scalars['String'];
  Status?: InputMaybe<ArTransactionStatus>;
  bankDetails: BankDetailsInput;
  einvoice_doc_ref_invoice_id?: InputMaybe<Scalars['String']>;
  einvoice_status?: InputMaybe<Scalars['String']>;
  is_einvoice?: InputMaybe<Scalars['Boolean']>;
};

export type OnlineItemEntity = InterfaceTrx & {
  __typename?: 'OnlineItemEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type OnlineItemInput = {
  FolioID?: InputMaybe<Scalars['String']>;
  ReceiptNo?: InputMaybe<Scalars['String']>;
};

export type OpenClosedResponse = {
  __typename?: 'OpenClosedResponse';
  ClosedCreditNote?: Maybe<Array<Data>>;
  ClosedInvoice?: Maybe<Array<Data>>;
  ClosedOR?: Maybe<Array<Data>>;
  ClosedRefund?: Maybe<Array<Data>>;
  CloseddebitNote?: Maybe<Array<Data>>;
  OpenCreditNote?: Maybe<Array<Data>>;
  OpenInvoice?: Maybe<Array<Data>>;
  OpenOR?: Maybe<Array<Data>>;
  OpenRefund?: Maybe<Array<Data>>;
  OpendebitNote?: Maybe<Array<Data>>;
};

export type OutgoingDocTemplateEntity = InterfaceBase & {
  __typename?: 'OutgoingDocTemplateEntity';
  APStatement?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreditNote?: Maybe<Scalars['String']>;
  DebitNote?: Maybe<Scalars['String']>;
  DepositInvoice?: Maybe<Scalars['String']>;
  DepositRefund?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Invoice?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  OfficialReceipt?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Refund?: Maybe<Scalars['String']>;
};

export type OutgoingDocTemplateInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Folio?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  Invoice?: InputMaybe<Scalars['String']>;
  Receipt?: InputMaybe<Scalars['String']>;
};

export type Output = {
  __typename?: 'Output';
  isEmailExist?: Maybe<Scalars['String']>;
  isLoginIDExist?: Maybe<Scalars['String']>;
  isNRICExist?: Maybe<Scalars['String']>;
};

export type PabxLogEntity = InterfaceTrx & {
  __typename?: 'PABXLogEntity';
  ActionType: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  LogData: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  RoomNo: Scalars['String'];
};

export type Posv7DailyRevenueEntity = InterfaceBase & {
  __typename?: 'POSV7DailyRevenueEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DiscountedAmount?: Maybe<Scalars['Float']>;
  DocumentDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  MajorDescription?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  OutletCode?: Maybe<Scalars['String']>;
  OutletDescription?: Maybe<Scalars['String']>;
  PaymentDescription?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TrxType?: Maybe<Scalars['String']>;
};

export type Posv7DailyRevenueHistoryEntity = InterfaceBase & {
  __typename?: 'POSV7DailyRevenueHistoryEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DiscountedAmount?: Maybe<Scalars['Float']>;
  DocumentDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  MajorDescription?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  OutletCode?: Maybe<Scalars['String']>;
  OutletDescription?: Maybe<Scalars['String']>;
  PaymentDescription?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TrxType?: Maybe<Scalars['String']>;
};

export type Posv7DailyRevenueInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  CardNo?: InputMaybe<Scalars['String']>;
  DiscountedAmount?: InputMaybe<Scalars['Float']>;
  DocumentDate?: InputMaybe<Scalars['DateTime']>;
  HotelID?: InputMaybe<Scalars['String']>;
  MajorDescription?: InputMaybe<Scalars['String']>;
  OutletCode?: InputMaybe<Scalars['String']>;
  OutletDescription?: InputMaybe<Scalars['String']>;
  PaymentDescription?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  TaxAmount?: InputMaybe<Scalars['Float']>;
  TrxType?: InputMaybe<Scalars['String']>;
};

export type PackagePriceOutput = {
  __typename?: 'PackagePriceOutput';
  Code?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  Pricing?: Maybe<Array<PricingOutput>>;
  RateFrequency?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeCode?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomTypeName?: Maybe<Scalars['String']>;
  TotalVacant?: Maybe<Scalars['Float']>;
};

export type PackageRedemptionEntity = InterfaceBase & {
  __typename?: 'PackageRedemptionEntity';
  BillScheduleID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IncidentalChargeID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsBillLedger?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
};

export type PackageRedemptionInput = {
  BillScheduleID?: InputMaybe<Scalars['String']>;
  BookingID?: InputMaybe<Scalars['String']>;
  DepartmentID?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  GuestName?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IncidentalChargeID?: InputMaybe<Scalars['String']>;
  IsBillLedger?: InputMaybe<Scalars['Boolean']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  RoomNo?: InputMaybe<Scalars['String']>;
  TrxDate?: InputMaybe<Scalars['DateTime']>;
};

export type PackageRedemptionListOutput = {
  __typename?: 'PackageRedemptionListOutput';
  Amount?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  ComputationRule?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GuestNames?: Maybe<Array<Scalars['String']>>;
  GuestPackageRedeem?: Maybe<Array<GuestPackageRedeem>>;
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  MaxPackage?: Maybe<Scalars['Float']>;
  Redeemed?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  Total?: Maybe<Scalars['Float']>;
};

export type PackagesEntity = InterfaceBase & {
  __typename?: 'PackagesEntity';
  Amount?: Maybe<Scalars['Float']>;
  ComputationRules?: Maybe<ComputationRules>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description: Scalars['String'];
  DisplaySeq?: Maybe<Scalars['Float']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  IncludeRate?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  RatePolicyID: Scalars['String'];
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Boolean']>;
};

export type PackagesInput = {
  Amount?: InputMaybe<Scalars['Float']>;
  ComputationRules?: InputMaybe<ComputationRules>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  DisplaySeq?: InputMaybe<Scalars['Float']>;
  GalleryID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IncidentalChargeID?: InputMaybe<Scalars['String']>;
  IncludeRate?: InputMaybe<Scalars['Boolean']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RatePolicyID?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Boolean']>;
  Tax?: InputMaybe<Scalars['Boolean']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type PassCodeInput = {
  keyboardPwd?: InputMaybe<Scalars['String']>;
  keyboardPwdId?: InputMaybe<Scalars['String']>;
};

export enum PaymentClass {
  Advance = 'Advance',
  Bill = 'Bill',
  Folio = 'Folio',
  Forfeit = 'Forfeit',
  Refund = 'Refund',
  Room = 'Room',
  Rounding = 'Rounding',
  Tax = 'Tax'
}

export type PaymentEntity = InterfaceTrx & {
  __typename?: 'PaymentEntity';
  Amount?: Maybe<Scalars['Float']>;
  CardNo?: Maybe<Scalars['String']>;
  ChangeAmount?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
  Folio?: Maybe<FolioEntity>;
  FolioID?: Maybe<Scalars['String']>;
  ForfeitTax?: Maybe<Array<ForfeitTaxEntity>>;
  FromRegistration?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsARPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  PaySessionNo: Scalars['Float'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentDate: Scalars['DateTime'];
  PaymentStatus: PaymentStatus;
  PaymentType?: Maybe<PaymentType>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  ReferenceID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ReversedPaymentID?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
};

export type PaymentFoilioResponse = {
  __typename?: 'PaymentFoilioResponse';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  BillLedgerTax?: Maybe<Array<BillLedgerTaxEntity>>;
  BillScheduleTax?: Maybe<Array<BillScheduleTaxEntity>>;
  ChargeType?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  FolioID?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  GuestAppDepartment?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  LedgerType?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomLedgerTax?: Maybe<Array<RoomLedgerTaxEntity>>;
  RoomNo?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
};

export type PaymentFolioInput = {
  ArrivalDate?: InputMaybe<Scalars['DateTime']>;
  BaseAmount?: InputMaybe<Scalars['Float']>;
  ChargeType?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  CreatedDT?: InputMaybe<Scalars['DateTime']>;
  CreatedName?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  DebtorName?: InputMaybe<Scalars['String']>;
  DepartureDate?: InputMaybe<Scalars['DateTime']>;
  Description?: InputMaybe<Scalars['String']>;
  DiscountAmount?: InputMaybe<Scalars['Float']>;
  FolioID?: InputMaybe<Scalars['String']>;
  FullName?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsPosted?: InputMaybe<Scalars['Boolean']>;
  IsSelected?: InputMaybe<Scalars['Boolean']>;
  LedgerType?: InputMaybe<Scalars['String']>;
  Reason?: InputMaybe<Scalars['String']>;
  ReferenceNo?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  RoomNo?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  SplitGroup?: InputMaybe<Scalars['Float']>;
  TaxAmount?: InputMaybe<Scalars['Float']>;
  TrxAmount?: InputMaybe<Scalars['Float']>;
  TrxDate?: InputMaybe<Scalars['DateTime']>;
  Type?: InputMaybe<Scalars['String']>;
  UnitPrice?: InputMaybe<Scalars['Float']>;
};

export type PaymentGlEntity = InterfaceTrx & {
  __typename?: 'PaymentGLEntity';
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  PaymentType?: Maybe<Scalars['String']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type PaymentGatewayEntity = InterfaceBase & {
  __typename?: 'PaymentGatewayEntity';
  Client?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Environment?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
  PrivateKey?: Maybe<Scalars['String']>;
  PublicKey?: Maybe<Scalars['String']>;
  Secret?: Maybe<Scalars['String']>;
  Store?: Maybe<Scalars['String']>;
};

export type PaymentGatewayInput = {
  Client?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Environment?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  PrivateKey?: InputMaybe<Scalars['String']>;
  PublicKey?: InputMaybe<Scalars['String']>;
  Secret?: InputMaybe<Scalars['String']>;
  Store?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type PaymentInput = {
  Amount?: InputMaybe<Scalars['Float']>;
  CardNo?: InputMaybe<Scalars['String']>;
  CheckOutID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  PaySessionNo?: InputMaybe<Scalars['Float']>;
  PaymentClass: PaymentClass;
  PaymentDate: Scalars['DateTime'];
  PaymentStatus: PaymentStatus;
  PaymentType: PaymentType;
  ReceiptNo?: InputMaybe<Scalars['String']>;
  ReferenceNo?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type PaymentListing123 = {
  __typename?: 'PaymentListing123';
  Amount?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  PaymentClass?: Maybe<Scalars['String']>;
  PaymentDate?: Maybe<Scalars['DateTime']>;
  PaymentStatus?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  Result?: Maybe<Array<RegistrationResult>>;
};

export type PaymentOutput = {
  __typename?: 'PaymentOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export enum PaymentStatus {
  Cancelled = 'Cancelled',
  Paid = 'Paid',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type PaymentTestClass = {
  __typename?: 'PaymentTestClass';
  Amount?: Maybe<Scalars['Float']>;
  Booking?: Maybe<BookingEntity>;
  BookingID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  ChangeAmount?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Folio?: Maybe<Array<FolioEntity>>;
  FolioID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<Array<HotelEntity>>;
  HotelID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  PaySessionNo: Scalars['Float'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentDate: Scalars['DateTime'];
  PaymentStatus: PaymentStatus;
  PaymentType?: Maybe<PaymentType>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Registration?: Maybe<Array<RegistrationEntity>>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedPaymentID?: Maybe<Scalars['String']>;
};

export enum PaymentType {
  AmericanExpressCard = 'AmericanExpressCard',
  BankTt = 'BankTT',
  Cash = 'Cash',
  Cheque = 'Cheque',
  CityLedger = 'CityLedger',
  DebitCard = 'DebitCard',
  DinersCard = 'DinersCard',
  EWallet = 'EWallet',
  JcbCard = 'JCBCard',
  MasterCard = 'MasterCard',
  Online = 'Online',
  Other = 'Other',
  Room = 'Room',
  Unionpay = 'UNIONPAY',
  VisaCard = 'VisaCard',
  Voucher = 'Voucher'
}

export type PermRoleAsgInput = {
  ID?: InputMaybe<Scalars['String']>;
  commonStatus?: InputMaybe<CommonStatus>;
  createdBy?: InputMaybe<Scalars['String']>;
  hotelPerms?: InputMaybe<HotelPermission>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type PlatformAmenitiesEntity = InterfaceBase & {
  __typename?: 'PlatformAmenitiesEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type PlatformDepartmentEntity = InterfaceBase & {
  __typename?: 'PlatformDepartmentEntity';
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  AllowIncidental?: Maybe<Scalars['Boolean']>;
  AllowReason?: Maybe<Scalars['Boolean']>;
  AllowSalesChannel?: Maybe<Scalars['Boolean']>;
  Code?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type PlatformHeritageTaxEntity = InterfaceBase & {
  __typename?: 'PlatformHeritageTaxEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type PlatformHeritageTaxInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  DefaultValue?: InputMaybe<Scalars['Float']>;
  ID: Scalars['String'];
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type PlatformLevyEntity = InterfaceBase & {
  __typename?: 'PlatformLevyEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type PlatformLevyInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  DefaultValue?: InputMaybe<Scalars['Float']>;
  ID: Scalars['String'];
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type PlatformServiceChargeEntity = InterfaceBase & {
  __typename?: 'PlatformServiceChargeEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type PlatformServiceChargeInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  DefaultValue?: InputMaybe<Scalars['Float']>;
  ID: Scalars['String'];
  IsTaxable?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type PlatformStateFundEntity = InterfaceBase & {
  __typename?: 'PlatformStateFundEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type PlatformStateFundInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  DefaultValue?: InputMaybe<Scalars['Float']>;
  ID: Scalars['String'];
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type PlatformTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTaxEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  TaxCode?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
};

export type PlatformTaxInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  EffectiveDate?: InputMaybe<Scalars['DateTime']>;
  IsFixAmount?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  TaxCode?: InputMaybe<Scalars['String']>;
  TaxScheme?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
  Value?: InputMaybe<Scalars['Float']>;
};

export type PlatformTourismTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTourismTaxEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type PlatformTourismTaxInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  DefaultValue?: InputMaybe<Scalars['Float']>;
  ID: Scalars['String'];
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type PreCheckInInput = {
  Address?: InputMaybe<AddressInput>;
  DateofBirth: Scalars['DateTime'];
  IsPreCheckIn?: InputMaybe<Scalars['Boolean']>;
  MobileNo: Scalars['String'];
  NRICNo: Scalars['String'];
  Nationality: Scalars['String'];
  SpecialRequest?: InputMaybe<Scalars['String']>;
};

export type PricingEntity = InterfaceBase & {
  __typename?: 'PricingEntity';
  Ceiling?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Floor?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PackageAmount?: Maybe<Scalars['Float']>;
  PeakCeiling?: Maybe<Scalars['Float']>;
  PeakFloor?: Maybe<Scalars['Float']>;
  PeakStandard?: Maybe<Scalars['Float']>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  RatePolicyID: Scalars['String'];
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Season?: Maybe<Scalars['String']>;
  SellingPrice?: Maybe<Scalars['Float']>;
  Standard?: Maybe<Scalars['Float']>;
  SuperPeakCeiling?: Maybe<Scalars['Float']>;
  SuperPeakFloor?: Maybe<Scalars['Float']>;
  SuperPeakStandard?: Maybe<Scalars['Float']>;
};

export type PricingInput = {
  Ceiling?: InputMaybe<Scalars['Float']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Floor?: InputMaybe<Scalars['Float']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  PeakCeiling?: InputMaybe<Scalars['Float']>;
  PeakFloor?: InputMaybe<Scalars['Float']>;
  PeakStandard?: InputMaybe<Scalars['Float']>;
  RatePolicyID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  Standard?: InputMaybe<Scalars['Float']>;
  SuperPeakCeiling?: InputMaybe<Scalars['Float']>;
  SuperPeakFloor?: InputMaybe<Scalars['Float']>;
  SuperPeakStandard?: InputMaybe<Scalars['Float']>;
};

export type PricingOutput = {
  __typename?: 'PricingOutput';
  BasePrice?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  PackageName?: Maybe<Scalars['Float']>;
  PackagePrice?: Maybe<Scalars['String']>;
  Packages?: Maybe<Array<PackagesEntity>>;
  Price?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
};

export type PromoCodeInput = {
  BaseAmt?: InputMaybe<Scalars['Float']>;
  Pricing?: InputMaybe<Array<PromoPricingInput>>;
  RatePolicyID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
};

export type PromoCodeResponse = {
  __typename?: 'PromoCodeResponse';
  DiscountAmt?: Maybe<Scalars['Float']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
};

export type PromoPricingInput = {
  Date?: InputMaybe<Scalars['DateTime']>;
  Price?: InputMaybe<Scalars['Float']>;
};

export type PromotionDetailEntity = InterfaceBase & {
  __typename?: 'PromotionDetailEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Promotion?: Maybe<PromotionEntity>;
  PromotionID?: Maybe<Scalars['String']>;
  RatePolicy?: Maybe<RateEntity>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
};

export type PromotionDetailInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  PromotionID?: InputMaybe<Scalars['String']>;
  RatePolicyID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
  Value?: InputMaybe<Scalars['Float']>;
};

export type PromotionEntity = InterfaceBase & {
  __typename?: 'PromotionEntity';
  Code?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: Maybe<Scalars['Float']>;
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PromotionDetail?: Maybe<Array<PromotionDetailEntity>>;
  PromotionDetailRatePolicy?: Maybe<Array<PromotionDetailEntity>>;
  StartDate: Scalars['DateTime'];
};


export type PromotionEntityPromotionDetailRatePolicyArgs = {
  RatePolicyID?: InputMaybe<Scalars['String']>;
};

export type PromotionInput = {
  Code?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: InputMaybe<Scalars['Float']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  UserID?: InputMaybe<Scalars['String']>;
};

export enum PropertyType {
  Apartment = 'Apartment',
  Bungalow = 'Bungalow',
  Cabin = 'Cabin',
  Campsite = 'Campsite',
  Cottage = 'Cottage',
  Dorm = 'Dorm',
  Room = 'Room',
  Villa = 'Villa'
}

export type Q3DocumentDetailEntity = InterfaceTrx & {
  __typename?: 'Q3DocumentDetailEntity';
  AccountID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DownloadDate?: Maybe<Scalars['DateTime']>;
  DownloadNo?: Maybe<Scalars['Float']>;
  HeaderID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Q3DocumentHeader?: Maybe<Q3DocumentHeaderEntity>;
};

export type Q3DocumentDetailInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  CreatedDT?: InputMaybe<Scalars['DateTime']>;
  DownloadDate?: InputMaybe<Scalars['DateTime']>;
  HeaderID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ModifiedDT?: InputMaybe<Scalars['DateTime']>;
};

export type Q3DocumentHeaderEntity = InterfaceTrx & {
  __typename?: 'Q3DocumentHeaderEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocumentType: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Q3DocumentDetail?: Maybe<Array<Q3DocumentDetailEntity>>;
};

export type Q3DocumentHeaderInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  CreatedDT?: InputMaybe<Scalars['DateTime']>;
  DocumentType?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ModifiedDT?: InputMaybe<Scalars['DateTime']>;
};

export type Q3FStatisticResponse = {
  __typename?: 'Q3FStatisticResponse';
  CancellationRoom?: Maybe<Scalars['Float']>;
  ComplimentaryRoom?: Maybe<Scalars['Float']>;
  DayUseRoom?: Maybe<Scalars['Float']>;
  HouseUse?: Maybe<Scalars['Float']>;
  NoOfRoomGuests?: Maybe<Scalars['Float']>;
  NoOfShowRoom?: Maybe<Scalars['Float']>;
  OutOfOrderRoom?: Maybe<Scalars['Float']>;
  RoomOccupiedWithComplimentary?: Maybe<Scalars['Float']>;
  RoomsAvailable?: Maybe<Scalars['Float']>;
  TotalRoomOccupied?: Maybe<Scalars['Float']>;
  TotalRooms?: Maybe<Scalars['Float']>;
};

export type Q3MarketSegmentMapping = {
  __typename?: 'Q3MarketSegmentMapping';
  GuestsAnalysis?: Maybe<Scalars['String']>;
  GuestsDebit?: Maybe<Scalars['String']>;
  GuestsDepartment?: Maybe<Scalars['String']>;
  RoomAnalysis?: Maybe<Scalars['String']>;
  RoomDebit?: Maybe<Scalars['String']>;
  RoomDepartment?: Maybe<Scalars['String']>;
};

export type Q3MarketSegmentMappingInput = {
  GuestsAnalysis?: InputMaybe<Scalars['String']>;
  GuestsDebit?: InputMaybe<Scalars['String']>;
  GuestsDepartment?: InputMaybe<Scalars['String']>;
  RoomAnalysis?: InputMaybe<Scalars['String']>;
  RoomDebit?: InputMaybe<Scalars['String']>;
  RoomDepartment?: InputMaybe<Scalars['String']>;
};

export type Q3RevenueAcctJson = {
  __typename?: 'Q3RevenueAcctJson';
  ExpenseCredit?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDiv?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
};

export type Q3RevenueAcctJsonInput = {
  ExpenseCredit?: InputMaybe<Scalars['String']>;
  ExpenseDebit?: InputMaybe<Scalars['String']>;
  ExpenseDept?: InputMaybe<Scalars['String']>;
  ExpenseDiv?: InputMaybe<Scalars['String']>;
  RevenueCredit?: InputMaybe<Scalars['String']>;
  RevenueDebit?: InputMaybe<Scalars['String']>;
  RevenueDept?: InputMaybe<Scalars['String']>;
  RevenueDiv?: InputMaybe<Scalars['String']>;
  ReverseCredit?: InputMaybe<Scalars['String']>;
  ReverseDebit?: InputMaybe<Scalars['String']>;
  ReverseDept?: InputMaybe<Scalars['String']>;
  ReverseDiv?: InputMaybe<Scalars['String']>;
};

export type Q3Statistic = {
  __typename?: 'Q3Statistic';
  Analysis?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type Q3StatisticInput = {
  Analysis?: InputMaybe<Scalars['String']>;
  Debit?: InputMaybe<Scalars['String']>;
  Department?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
};

export type Q3StatisticsMapping = {
  __typename?: 'Q3StatisticsMapping';
  CancellationRoom?: Maybe<Q3Statistic>;
  ComplimentaryRoom?: Maybe<Q3Statistic>;
  DayUseRoom?: Maybe<Q3Statistic>;
  HouseUse?: Maybe<Q3Statistic>;
  NoOfRoomGuests?: Maybe<Q3Statistic>;
  NoOfShowRoom?: Maybe<Q3Statistic>;
  OutOfOrderRoom?: Maybe<Q3Statistic>;
  RoomOccupiedWithComplimentary?: Maybe<Q3Statistic>;
  RoomsAvailable?: Maybe<Q3Statistic>;
  TotalRoomOccupied?: Maybe<Q3Statistic>;
  TotalRooms?: Maybe<Q3Statistic>;
};

export type Q3StatisticsMappingInput = {
  CancellationRoom?: InputMaybe<Q3StatisticInput>;
  ComplimentaryRoom?: InputMaybe<Q3StatisticInput>;
  DayUseRoom?: InputMaybe<Q3StatisticInput>;
  HouseUse?: InputMaybe<Q3StatisticInput>;
  NoOfRoomGuests?: InputMaybe<Q3StatisticInput>;
  NoOfShowRoom?: InputMaybe<Q3StatisticInput>;
  OutOfOrderRoom?: InputMaybe<Q3StatisticInput>;
  RoomOccupiedWithComplimentary?: InputMaybe<Q3StatisticInput>;
  RoomsAvailable?: InputMaybe<Q3StatisticInput>;
  TotalRoomOccupied?: InputMaybe<Q3StatisticInput>;
  TotalRooms?: InputMaybe<Q3StatisticInput>;
};

export type QrScannerDetailEntity = InterfaceBase & {
  __typename?: 'QrScannerDetailEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DeviceData?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  QrData?: Maybe<Scalars['String']>;
};

export type QrScannerDetailInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsScanned?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  QrData?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  ARAccountSummary: ArAccountSummary;
  ARAgingAnalysis: ArAgingAnalysisRespose;
  ARInvoiceListings: Array<InvoiceEntity>;
  ARTotalDue: Scalars['Float'];
  ARTotalOutstanding: ArAccountOsLastPayment;
  AccountMappingAdvPaymentList: Scalars['JSON'];
  AccountMappingChargesList: Scalars['JSON'];
  AccountMappingDepositList: Scalars['JSON'];
  AccountMappingPaymentList: Scalars['JSON'];
  AccountMappingRefundList: Scalars['JSON'];
  AccountMappingTaxGLList: Scalars['JSON'];
  ActiveBookingRoomListing: Array<RoomEntity>;
  ActivitiesCountListing: Scalars['JSON'];
  ActivitiesListing: Array<ActivitiesEntity>;
  AdjustedFolioDetails: Array<FolioEntity>;
  AdvancePaymentDetail: AdvancePaymentEntity;
  AdvancePaymentDetails: AdvancePaymentEntity;
  AdvancePaymentFullListing: Array<AdvancePaymentEntity>;
  AdvancePaymentListing: Array<AdvancePaymentEntity>;
  AdvancePaymentReversedDetails: Array<AdvancePaymentEntity>;
  AgentInUseLisiting: Scalars['Boolean'];
  AgingByDebtor: Scalars['JSON'];
  AllRoomAsignListing: Array<RoomEntity>;
  AllocationDetailListing: Scalars['JSON'];
  AllotmentListing: Array<ContractAllotmentEntity>;
  ArrayChallenge: Scalars['JSON'];
  AssignRoomFrontDeskListing: Array<BookingEntity>;
  AuditLogDetails: Scalars['JSON'];
  AvailabilityCheckingRevamp: Scalars['JSON'];
  AvailabilityListing: HotelAvailabilityOutput;
  BIAdvancePaymentListing: Array<AdvancePaymentEntity>;
  BIAnalysis: Array<BookingEntity>;
  BIBillLedgerListing: Array<BillLedgerEntity>;
  BIBookingStatus: Array<BookingEntity>;
  BICorpGovTAYTDRevenue: RevenueAnalysis;
  BICorpGovTa: Array<BookingEntity>;
  BICorpGovTaV2: Scalars['JSON'];
  BIDepositLedgerListing: Array<BookingEntity>;
  BIFolioListing: BiFolioResponse;
  BIForeignListing: Array<BookingEntity>;
  BIHotelDetails: HotelEntity;
  BIHotelListing: HotelEntity;
  BIInHouse: Array<RegistrationEntity>;
  BIMarketingPersonnal: Array<BookingEntity>;
  BIMarketingPersonnalTopSales: RevenueAnalysis;
  BIMarketingPersonnalV2: Scalars['JSON'];
  BIPackage: Array<BillScheduleEntity>;
  BIPaymentListing: Array<PaymentTestClass>;
  BIPaymentListingTest: Array<PaymentListing123>;
  BIReceiptRefundListing: BiReceiptRefundResponse;
  BIRegistrationListing: Array<BookingEntity>;
  BIRevenueListing: Array<RevenueAnalysis>;
  BIRoomDiscrepancyLog: Array<RoomDiscrepancyLogEntity>;
  BIRoomListing: Array<RoomEntity>;
  BIRoomRateListing: Array<BookingEntity>;
  BIRoomStatus: Array<RoomEntity>;
  BIRoomTypeRevenue: RevenueAnalysis;
  BIRoomTypeRevenueDetail: Array<BookingEntity>;
  BIRoomTypeRevenueDetailV2: Scalars['JSON'];
  BISecurityKeyCardDeposit: Array<DepositLedgerEntity>;
  BISegment: Array<BookingEntity>;
  BISegmentRevenue: RevenueAnalysis;
  BISegmentV2: Scalars['JSON'];
  BIServiceRequest: Array<RevenueAnalysis>;
  BIServiceRequestListing: Array<ServiceRequestEntity>;
  BISource: Array<BookingEntity>;
  BISourceV2: Scalars['JSON'];
  BISourceYTDRevenue: RevenueAnalysis;
  BITestListing: Array<FolioOutput>;
  BITop5OTA: Array<BookingEntity>;
  BITop5OTAV2: Scalars['JSON'];
  BITop10Country: Array<BookingEntity>;
  BITop10CountryV2: Scalars['JSON'];
  BITop10Nationality: Array<BookingEntity>;
  BITop10NationalityV2: Scalars['JSON'];
  BITop10Revenue: Array<BookingEntity>;
  BITop10RevenueV2: Scalars['JSON'];
  BITopCountry: Array<RevenueAnalysis>;
  BITopNationality: Array<RevenueAnalysis>;
  BITopTransaction: RevenueAnalysis;
  BITransactionCancellation: Array<BillLedgerEntity>;
  BITransactionLedger: Array<BookingEntity>;
  BITravelAgent: Array<RevenueAnalysis>;
  BIUsersLisiting: UserEntity;
  BIYTDRevenue: RevenueAnalysis;
  BankAccountListing: Array<BankAccountEntity>;
  BiAdvPaymentListing: Array<AdvancePaymentEntity>;
  BillLedgerFullListing: Array<BillLedgerEntity>;
  BillLedgerListing: Array<BillLedgerEntity>;
  BillScheduleListByBooking: Scalars['JSON'];
  BillScheduleListing: Scalars['JSON'];
  BillSummaryDetails: Scalars['JSON'];
  BillingByRegistration: Array<PaymentFoilioResponse>;
  BlockRoomLogListing: Scalars['Boolean'];
  BlockRoomLogListingV2: Array<BlockRoomLogEntity>;
  BookingAttachmentListing: Array<BookingAttachmentEntity>;
  BookingAttachmentSummary: Array<BookingAttachmentEntity>;
  BookingBillScheduleInfo: BookingBillScheduleResponse;
  BookingDetails: BookingEntity;
  BookingIncChargesRegistrationListing: Array<RegistrationEntity>;
  BookingListing: Array<BookingEntity>;
  BookingPackagePriceListing: Array<PackagePriceOutput>;
  BookingProfile: BookingEntity;
  BookingSubMenuInfo: Scalars['JSON'];
  BusinessAnalyticInfoLine: Scalars['JSON'];
  CBExport: Scalars['JSON'];
  CBExportDetailsByTrxType: Scalars['JSON'];
  CMSCustomControl: Scalars['Boolean'];
  CalculateDebtorListing: Scalars['JSON'];
  CancelReasonListing: Array<ReasonEntity>;
  ChangeOfDateCheckAvailability: Scalars['Boolean'];
  ChangeOfDateCheckAvailabilityBookEdit: Scalars['Boolean'];
  ChannelManagerProfile: ChannelManagerEntity;
  Check: Scalars['Boolean'];
  CheckBanknameInUse: Scalars['JSON'];
  CheckForBlockRoom: Scalars['Boolean'];
  CheckInListing: Array<BookingEntity>;
  CheckInListingCount: Scalars['JSON'];
  CheckIsGroupInvoice: Scalars['JSON'];
  CheckRecord?: Maybe<Output>;
  CheckedOutFolioListing: Scalars['JSON'];
  CheckedOutFolioListingOLD: Scalars['JSON'];
  CheckoutAdjustmentListing: Array<FolioEntity>;
  CheckoutReinstateInfo: CheckoutReinstateResponse;
  ChooseRoomAllocationDetail: Array<ChooseRoomAllocationEntity>;
  ChooseRoomAllocationListing: Array<Scalars['JSON']>;
  CityLedgerChargesList: Scalars['JSON'];
  CityLedgerDebtorList: Scalars['JSON'];
  CityLedgerInvoiceListings: Scalars['JSON'];
  CityLedgerTaxGLList: Scalars['JSON'];
  CollectionByDebtorTypeVSOverdue: Scalars['JSON'];
  CompanyListing: Array<CompanyEntity>;
  ComputeBookingbyHotel: Array<Scalars['JSON']>;
  ComputeTaxArrByHotel: Array<TaxOutput>;
  ComputeTaxBooking: Array<Scalars['JSON']>;
  ComputeTaxHotelX: TaxOutput;
  ComputeTaxbyHotel: TaxOutput;
  ComputeTaxbyTaxType: HotelTaxOutput;
  ComputeUpgradebyHotel: TaxOutput;
  ConsolidatedInvoiceListing: Array<EInvoiceConsolidationEntity>;
  ContactPagination: Array<ContactEntity>;
  ContractDetails: ContractEntity;
  CoporateInUseListing: Scalars['Boolean'];
  CorporateBookingListing: Array<BookingEntity>;
  CorporateContractListing: Array<ContractEntity>;
  CorporateGovernmentDetail: DebtorEntity;
  CorporateGovernmentListing: Array<DebtorEntity>;
  CorporateInUseListing: Scalars['Boolean'];
  CountItem: RegAndBooking;
  CreditNoteAllocationListing: Scalars['JSON'];
  CreditNoteAndInvoiceCount: Scalars['JSON'];
  CreditNoteAndInvoiceListings: Scalars['JSON'];
  CreditNoteInfo: ArSummaryInfo;
  CreditNoteListings: Scalars['JSON'];
  CreditNoteListingsOld: Array<CreditNoteEntity>;
  CurrencyListing: Array<CurrencyEntity>;
  CurrentInhouseList: Array<CurrentInhouseOutput>;
  CurrentInhouseListV7: Scalars['JSON'];
  CurrentTaxDetails: CurrentTaxDetailsOutput;
  CustomizedDocListing: Array<CustomizedDocumentEntity>;
  DashBoardAllListing: DashBoardRespond;
  DashBoardListing: Array<BookingEntity>;
  DebitNoteAllocationListing: Scalars['JSON'];
  DebitNoteDetail: DebitNoteEntity;
  DebitNoteInfo: ArSummaryInfo;
  DebitNoteListings: Array<DebitNoteEntity>;
  DebtorAging: Scalars['JSON'];
  DebtorAgingListing: Scalars['JSON'];
  DebtorAnalysis: Array<ContractEntity>;
  DebtorCountListing: Scalars['JSON'];
  DebtorDetail: DebtorEntity;
  DebtorDetailByAccountCode: DebtorEntity;
  DebtorFolioInfo?: Maybe<DebtorEntity>;
  DebtorListing: Array<DebtorEntity>;
  DebtorListingCreditFacility: Array<DebtorEntity>;
  DebtorListingDropDown: Array<DebtorEntity>;
  DebtorListingNonCreditFacility: Array<DebtorEntity>;
  DebtorTypeCount: Scalars['JSON'];
  DebtorTypeCountListing: Scalars['JSON'];
  DebtorTypeInUse: Scalars['Boolean'];
  DebtorTypeListing: Array<DebtorTypeEntity>;
  DeliveredRoomServiceListing: Array<BillLedgerEntity>;
  DeliveredRoomServiceRegistrationListing: Array<RegistrationEntity>;
  DeparmentChecking: Scalars['Boolean'];
  DepartmentInUseListing: Scalars['Boolean'];
  DepartmentListing: Array<DepartmentEntity>;
  DepositClass: DepositLedgerEntity;
  DepositForfeitlist: Array<DepositLedgerEntity>;
  DepositLedgerDetails: Scalars['JSON'];
  DepositListing: BiFolioResponse;
  DepositListingByBooking: Array<DepositLedgerEntity>;
  DepositListingByBookingV2: Scalars['JSON'];
  DepositRefundDetails: DepositLedgerEntity;
  DepositRefundlist: Array<DepositLedgerEntity>;
  DisCountByPromoCode: Array<PromoCodeResponse>;
  DocToConsolidateListing: Scalars['JSON'];
  DocToConsolidateView: Scalars['JSON'];
  DocumentNumberHeaderListing: Array<DocumentNumberHeaderEntity>;
  DocumentTemplateList: DocumentTemplateEntity;
  EDocParamsListing: Array<EDocParamsEntity>;
  EDocTemplateListing: Array<EDocTemplateEntity>;
  EInvSubscription: Array<EInvoiceSubscriptionEntity>;
  EInvoiceDocListings: Array<InvoiceEntity>;
  EInvoiceInformation: Scalars['JSON'];
  EmailMarketingDetail: Array<EmailCampignEntity>;
  EmailMarketingListing: Array<EmailCampignEntity>;
  EntRoleUsrAsgList: Array<EntRoleUsrAsgEntity>;
  ExportedCBDetails: Array<Scalars['JSON']>;
  ExportedCBListing: Scalars['JSON'];
  ExportedGLDetails: Array<Scalars['JSON']>;
  ExportedGLListing: Scalars['JSON'];
  ExportedGLTransferDetails: Array<ExportedGlTransferResponse>;
  FloorPlanGallery: GalleryEntity;
  FloorPlanListing: Array<LocationFloorPlanEntity>;
  FolioChargeToList: Scalars['JSON'];
  FolioDetails: FolioEntity;
  FolioDetailsWithIDs: RegistrationEntity;
  FolioHistoryListing: Array<FolioHistoryResponse>;
  FolioListing: Array<FolioEntity>;
  FolioListingByRegistration: Array<FolioEntity>;
  FolioUseListing: Scalars['Boolean'];
  FrontDeskBookingInfo: BookingInfoResponse;
  FrontDeskIncidentalChargeListing: Array<BillLedgerEntity>;
  FrontDeskInfoLines: FrontDeskInfoLineResponse;
  FrontDeskListing: Array<BookingEntity>;
  FrontDeskListingV2: Array<RegistrationEntity>;
  FrontDeskPaymentListing: Array<FrontDeskResponse>;
  FrontDeskServiceRequestListing: Array<ServiceRequestEntity>;
  FrontDeskSubMenuInfo: Array<FrontDeskInfoResponse>;
  GAInHouseGuestListing: GaInHouseResponse;
  GAMarketingAdsListing: Array<EmailCampignEntity>;
  GLAccountPeriod: Scalars['JSON'];
  GLExport: Scalars['JSON'];
  GLExportDetailsByTrxType: Scalars['JSON'];
  GetAdjustmentTax: AdjustmentTaxOutput;
  GetCurrentGovTax: CurrentTaxOutput;
  GetCurrentTourismTax: CurrentTaxOutput;
  GetLevyTax: CurrentTaxOutput;
  GetMenuOption: RoleEntity;
  GetPlatformTax: CurrentTaxOutput;
  GetServiceChargeTax: CurrentTaxOutput;
  GetTaxDetails: TaxEntity;
  GetTaxListing: Scalars['JSON'];
  GetTaxListingDropdown: Array<TaxListingOutput>;
  GetTaxSchemeDetail: Array<TaxSchemeDetailOutput>;
  GetTaxSchemeDetailItemRate: Array<TaxSchemeDetailItemRateOutput>;
  GetTaxSchemeListing: Array<TaxSchemeEntity>;
  GetTaxTypeListing: Scalars['JSON'];
  GetUserByUsername: UserEntity;
  GovernmentListing: Array<DebtorEntity>;
  GroupCreditNoteAndInvoiceListings: Scalars['JSON'];
  GroupInvoiceCount: Scalars['JSON'];
  GuestAppFeature: GuestAppFeatureEntity;
  GuestAppFeatureList: GuestAppFeatureEntity;
  GuestBookingListing: Array<BookingEntity>;
  GuestHistoryListing: Array<RegistrationEntity>;
  GuestHistoryListingV2?: Maybe<Scalars['JSON']>;
  GuestListingByLocationTime: Array<GuestMovementEntity>;
  GuestMovementList?: Maybe<Scalars['JSON']>;
  GuestMovementListing: Array<GuestMovementEntity>;
  GuestProfile: GuestProfileEntity;
  GuestProfileHistoryListing: Array<RegistrationEntity>;
  GuestProfileListbyRoom: Array<GuestProfileEntity>;
  GuestProfileListing: GuestProfileResponse;
  GuestProfileListingByID: Array<RegistrationEntity>;
  GuestProfileListingV2: Array<GuestProfileEntity>;
  GuestReviewDetail?: Maybe<Scalars['JSON']>;
  GuestReviewInfoLines: GuestReviewInfoLineResponse;
  GuestReviewListing: Array<HotelRatingEntity>;
  GuestReviewsHotelX: Array<HotelRatingEntity>;
  GuestRoomResult: Scalars['JSON'];
  GuestRoomServiceListing: Array<BillLedgerEntity>;
  GuestappsettingDetail?: Maybe<Scalars['JSON']>;
  HKLocationMasterListing: Scalars['JSON'];
  HKMasterListing: Scalars['JSON'];
  HLSInvetoryUpdate: Scalars['String'];
  HotelAccountXList?: Maybe<HotelVendorInfoEntity>;
  HotelAuthorizationInfoLine: Scalars['JSON'];
  HotelDetails: HotelEntity;
  HotelListing: Array<HotelEntity>;
  HotelNotificationListing: Array<HotelNotificationEntity>;
  HotelPreRegQr: Scalars['JSON'];
  HotelPricesByDateRange: HotelEntity;
  HotelReviewListing: Array<HotelRatingEntity>;
  HotelRoomNumberList: Array<RoomEntity>;
  HotelSalesChannelListing: Array<SalesChannelEntity>;
  HotelStateAnalysis: Scalars['JSON'];
  HotelStatisticListing: HotelStatisticRespond;
  HotelStatisticListingV2: Scalars['JSON'];
  HotelTaxDetails: CurrentTaxDetailsOutput;
  HotelXCredentials: Scalars['JSON'];
  HotelsByCity: Array<HotelEntity>;
  HousekeepingDetail: RoomEntity;
  HousekeepingIncidentalChargeListing: Array<IncidentalChargeEntity>;
  HousekeepingListing: Array<RoomEntity>;
  HousekeepingRoomStatusListing: Array<RoomStatusEntity>;
  HousekeepingServiceRequestListing: Array<ServiceRequestEntity>;
  IncidentalChargeByID: IncidentalChargeEntity;
  IncidentalChargeInRateElement: Scalars['JSON'];
  IncidentalChargeInUseListing: Scalars['Boolean'];
  IncidentalChargeListing: Array<IncidentalChargeEntity>;
  IncidentalChargeListingByHotel: Array<IncidentalChargeEntity>;
  IncidentalDepartmentListing: Array<DepartmentEntity>;
  InhousePaymentListing: Array<PaymentEntity>;
  InhouseQr: Scalars['JSON'];
  InterestListing: InterestEntity;
  InterestListings: Array<ReminderEntity>;
  InterstTotal: InterestResponse;
  InventoryRatePlansHotelX: HotelEntity;
  InvoiceDetail: InvoiceEntity;
  InvoiceInfo: ArSummaryInfo;
  InvoiceListings: Array<InvoiceEntity>;
  IsBlockRoomAvailable: Scalars['JSON'];
  IsBookingExist: Scalars['JSON'];
  IsChargeable: GuestAppFeatureEntity;
  IsExistRegistrationIncCharges: Scalars['Boolean'];
  IsGuestAppUser: GuestProfileEntity;
  IsHotelLockUser?: Maybe<Scalars['Boolean']>;
  IsPaymentGateWayHotel: Scalars['Boolean'];
  IsPresetIncChargesExist: Scalars['Boolean'];
  IsQ3FInterface: Scalars['Boolean'];
  IsRateInUse: Scalars['Boolean'];
  IsRoomAvailable: Scalars['Boolean'];
  IsRoomAvailableGroup: Scalars['JSON'];
  IsRoomExist: Scalars['Boolean'];
  IsRoomNoAvailable: Scalars['JSON'];
  IsTaxInUse: Scalars['Boolean'];
  IsTaxSameCode: Scalars['Boolean'];
  IsTaxSameName: Scalars['Boolean'];
  IsTaxSchemeInUse: Scalars['Boolean'];
  LateCheckOutChargeListing: Array<LateCheckOutChargeEntity>;
  LocationFloorPlanListing: Array<LocationEntity>;
  LocationInUseListing: Scalars['Boolean'];
  LocationListing: Array<LocationEntity>;
  LoyaltyAppHotelGroup: Scalars['JSON'];
  ManagerReport: ManagerReportOutput;
  ManagerReportDepartment: ManagerReportOutput;
  MeasurementListing: Array<MeasurementEntity>;
  MenuXDebtorList: Scalars['JSON'];
  MenuXHotelListing: Array<HotelEntity>;
  MenuXInhouseList: Scalars['JSON'];
  MenuXInhouseStatus: Scalars['Boolean'];
  MonthlyStatementListing: Scalars['JSON'];
  NARegistrationDetails: Array<NaRegistrationEntity>;
  NonGuestBillingListing: Array<NonGuestFolioResponse>;
  NonGuestFolioListing: Array<FolioEntity>;
  NonGuestFolioListingv2: SuspendFolioResponse;
  OAuthHotelX: Scalars['JSON'];
  OfficialReceiptDetail: OfficialReceiptEntity;
  OfficialReceiptInfo: ArSummaryInfo;
  OfficialReceiptListings: Scalars['JSON'];
  OfficialReceiptListingsOld: Array<OfficialReceiptEntity>;
  OnlineGAInHouseGuestListing: Array<RegistrationEntity>;
  OpeningClosingAmount: OpenClosedResponse;
  OpeningClosingAmountV2: Scalars['JSON'];
  OutGoingDocTemplate?: Maybe<OutgoingDocTemplateEntity>;
  PMSTravelAgentDetail: DebtorEntity;
  PackagePriceListing: Array<PackagePriceOutput>;
  PackageRedemptionByItem: Array<PackageRedemptionListOutput>;
  PackageRedemptionListing: Array<PackageRedemptionListOutput>;
  PaymentBillLedgerListing: Array<BillLedgerEntity>;
  PaymentDetail: PaymentEntity;
  PaymentDetails: PaymentEntity;
  PaymentListing: Array<PaymentEntity>;
  PaymentRegistrationListing: RegistrationEntity;
  PaymentRoomServiceDetail: PaymentEntity;
  PendingBillingListing: Array<NonGuestFolioResponse>;
  PlatformAmenitiesListing: Array<PlatformAmenitiesEntity>;
  PlatformDepartmentListing: Array<PlatformDepartmentEntity>;
  PlatformHeritageTaxListing: PlatformHeritageTaxEntity;
  PostedScheduleBilling: Scalars['JSON'];
  PreCheckInQr: Scalars['JSON'];
  PreRegBooking?: Maybe<Scalars['JSON']>;
  PreRegBookingInfo?: Maybe<Scalars['JSON']>;
  PreRegRegistrationDetails: RegistrationEntity;
  PreRegRoomAllocation: Array<Scalars['JSON']>;
  PrincipalGuestListing: Array<RegistrationEntity>;
  PromoDiscHotelX: Array<PromoCodeResponse>;
  PromotionDetailListing: Array<PromotionDetailEntity>;
  PromotionDetailsInUseListing: Scalars['Boolean'];
  PromotionInUseListing: Scalars['Boolean'];
  PromotionListHotelX: Array<PromotionEntity>;
  PromotionListing: Array<PromotionEntity>;
  Q3DocumentDetailByHeader: Array<Q3DocumentDetailEntity>;
  Q3DocumentHeader: Array<Q3DocumentHeaderEntity>;
  Q3FRevenue: Scalars['JSON'];
  Q3FRevenueOld: Scalars['JSON'];
  Q3FSegment: Scalars['JSON'];
  Q3FStatistic: Scalars['JSON'];
  Q3Listings: Array<Scalars['JSON']>;
  Q3MappingAdvPaymentList: Scalars['JSON'];
  Q3MappingChargesList: Scalars['JSON'];
  Q3MappingDebtorList: Scalars['JSON'];
  Q3MappingDepositList: Scalars['JSON'];
  Q3MappingPaymentList: Scalars['JSON'];
  Q3MappingRefundList: Scalars['JSON'];
  Q3MappingTaxList: Scalars['JSON'];
  RateAddOnListing: Array<RateAddOnEntity>;
  RateAdjustmentAuditListing: Array<RateAdjustmentAuditEntity>;
  RateEffectiveListing: Array<RateEffectiveEntity>;
  RateElementInactiveCheck: Scalars['JSON'];
  RateElementListing: Array<RateElementEntity>;
  RateInHouseListing: Scalars['Boolean'];
  RateListing: Array<RateEntity>;
  RatePolicyDetails: RatePolicyEntity;
  RatePolicyInUseListing: Scalars['Boolean'];
  RatePolicyInfo: RatePolicyResponse;
  RatePolicyListing: Array<RateEntity>;
  RatePolicyRoomTypeListing: Array<RoomTypeEntity>;
  RateTypeListing: Array<RateTypeEntity>;
  ReaonInUseListing: Scalars['Boolean'];
  ReasonListing: Array<ReasonEntity>;
  ReceiptListing: Array<PaymentFoilioResponse>;
  RefundDetail: RefundEntity;
  RefundInfo: ArSummaryInfo;
  RefundListings: Array<RefundEntity>;
  RegenBSQueueListing: Array<RegenBsQueueEntity>;
  RegistrationByBookingListing: Array<RegistrationEntity>;
  RegistrationDetails: RegistrationEntity;
  RegistrationDetailsMulti: Array<RegistrationEntity>;
  RegistrationListing: Array<RegistrationEntity>;
  RegistrationRoomTypeListing: Scalars['Boolean'];
  RegistrationTaxLedgerListing: Array<TaxLedgerEntity>;
  ReminderListing: ReminderEntity;
  ReminderListings: Array<ReminderEntity>;
  RoleView: Scalars['JSON'];
  RoomAllocationLocationListing: Array<Scalars['JSON']>;
  RoomAssignListing: Array<RoomEntity>;
  RoomBlockRoomListing: Scalars['Boolean'];
  RoomInHouseListing: Scalars['Boolean'];
  RoomInUseListing: Scalars['Boolean'];
  RoomListing: Array<RoomEntity>;
  RoomNumberForFoodListing: Array<BookingEntity>;
  RoomSaleOptimizationListing: Array<RoomSaleOptimizationOutput>;
  RoomServiceBookingListing: Array<BookingEntity>;
  RoomServiceFolioListing: Array<FolioEntity>;
  RoomServiceListing: Array<BillLedgerEntity>;
  RoomServiceRegistrationListing: Array<RegistrationEntity>;
  RoomServicesCount: Scalars['Float'];
  RoomStatusListing: Array<RoomStatusEntity>;
  RoomTypeAmenitiesListing: Array<RoomTypeAmenitiesEntity>;
  RoomTypeAvailable: Scalars['JSON'];
  RoomTypeListing: Array<RoomTypeEntity>;
  RoomUpgradeCheck: Scalars['Boolean'];
  RoundingAdjustment: Scalars['JSON'];
  RoundingAmount: Scalars['JSON'];
  SalesChannelListing: Array<SalesChannelEntity>;
  SalesChannelinUseListing: Scalars['Boolean'];
  ScheduleBillingCountList: Scalars['JSON'];
  ScheduleBillingList: Array<ScheduleBillingResponse>;
  SearchFolio?: Maybe<FolioEntity>;
  SearchGuest?: Maybe<Array<SearchGuestResponse>>;
  SeasonCalendarListing: Array<SeasonCalendarEntity>;
  SeasonListing: Array<SeasonEntity>;
  SeasonListingCalender: Array<SeasonEntity>;
  SegmentInUseListing: Scalars['Boolean'];
  SegmentListing: Array<SegmentEntity>;
  SelectFolio: FolioEntity;
  SelectedGuestListing: GuestProfileEntity;
  SelectedRegistration: RegistrationEntity;
  ServicePaymentSetting: Scalars['JSON'];
  ServicePendingCount: Scalars['JSON'];
  ServiceRequestDetail: Array<ServiceRequestEntity>;
  ServiceRequestFullListing: Array<ServiceRequestEntity>;
  ServiceRequestListing: Array<ServiceRequestEntity>;
  ServiceRequestv2Listing: Array<ServiceRequestEntity>;
  ShowBookingConfirmationEmail: Scalars['String'];
  SrLocationListing: Array<LocationEntity>;
  StateAnalysisRateAndRoom: Array<BookingEntity>;
  StatementOfAccDocument: DocResponse;
  StayViewListing: Scalars['JSON'];
  SubscribedPackages: Scalars['JSON'];
  SuspendDetail: FolioEntity;
  SuspendFolioListing: Array<FolioEntity>;
  SuspendFolioListingv2: SuspendFolioResponse;
  TTlockInfo?: Maybe<Scalars['JSON']>;
  TaxEffectiveDateListing: Array<TaxEffectiveDateEntity>;
  TaxInUseListing: Scalars['Boolean'];
  TaxPolicyListing: Array<HotelTaxPolicyEntity>;
  TestService: Scalars['String'];
  TodayBookingListing: Array<BookingEntity>;
  Top10DebtorRevenue: Scalars['JSON'];
  TotalAging: Scalars['JSON'];
  TotalAgingBI: Scalars['String'];
  TotalDueAging: Scalars['JSON'];
  TotalOverdueByDebtorType: Scalars['JSON'];
  TravelAgBookingListing: Array<BookingEntity>;
  TravelAgentContractListing: Array<ContractEntity>;
  TravelAgentDetail: DebtorEntity;
  TravelAgentHotelListing: Array<HotelEntity>;
  TravelAgentInUseListing: Scalars['Boolean'];
  TravelAgentListing: Array<DebtorEntity>;
  TravelAgentRoomRevenue: Array<RegistrationEntity>;
  UnutilizedDepositListing?: Maybe<DepositResponse>;
  UnutilizedDepositListingV2: Scalars['JSON'];
  UserEntities: Array<Scalars['String']>;
  UserType: Scalars['JSON'];
  UtilizedDepositListing?: Maybe<DepositResponse>;
  UtilizedDepositListingV2: Scalars['JSON'];
  WHBookingNotification: Scalars['String'];
  activeUser: Scalars['Float'];
  checkoutfoliodeposiit: Array<FolioEntity>;
  getClassificationListing: Array<EInvoiceClassificationEntity>;
  getClassificationListingByCode: EInvoiceClassificationEntity;
  getGeneralTin: Array<GeneralTinEntity>;
  getHotelPermission: Scalars['JSON'];
  getMsicCodeListing: Array<EInvoiceMsicEntity>;
  getRole: Array<RoleEntity>;
  getRolePermission: Scalars['JSON'];
  getSuperUsers: Array<UserEntity>;
  getUser: UserEntity;
  getUsersByAccount: Array<UserEntity>;
  getUsersBySoftware: Array<UserEntity>;
  getUsersRoleContract: Array<UserEntity>;
  getUsersbyIds: Array<UserEntity>;
  isLoginPermitted: UserEntity;
  isUserValid: Scalars['Boolean'];
  loggedInDebtorProfile: DebtorProfileEntity;
  loggedInGuestProfile?: Maybe<GuestProfileEntity>;
  loggedInUserProfile: UserEntity;
  updateLastestAccessHotel: UserEntity;
  userRoleIDs: Array<UserRoleId>;
};


export type QueryArAccountSummaryArgs = {
  DebtorID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryArAgingAnalysisArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryArInvoiceListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryArTotalDueArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryArTotalOutstandingArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAccountMappingAdvPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingDepositListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingRefundListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingTaxGlListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryActiveBookingRoomListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryActivitiesCountListingArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryActivitiesListingArgs = {
  ActivityDate?: InputMaybe<Scalars['DateTime']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  DebtorTypeID?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Status?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryAdjustedFolioDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAdvancePaymentDetailArgs = {
  BookingID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  OrderID?: InputMaybe<Scalars['String']>;
};


export type QueryAdvancePaymentDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAdvancePaymentFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAdvancePaymentListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryAdvancePaymentReversedDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAgentInUseLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryAgingByDebtorArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAllRoomAsignListingArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  IsBooking?: InputMaybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
};


export type QueryAllocationDetailListingArgs = {
  CreditID: Scalars['String'];
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAllotmentListingArgs = {
  ContractID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  RoomTypeID?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
};


export type QueryArrayChallengeArgs = {
  arr: Array<Scalars['Float']>;
};


export type QueryAssignRoomFrontDeskListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAuditLogDetailsArgs = {
  FieldID: Scalars['String'];
  TableName: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryAvailabilityCheckingRevampArgs = {
  ArrivalDate?: InputMaybe<Scalars['DateTime']>;
  DepartureDate?: InputMaybe<Scalars['DateTime']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  NoOfRoom?: InputMaybe<Scalars['Float']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
};


export type QueryAvailabilityListingArgs = {
  Adddate?: InputMaybe<Scalars['Float']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  RoomTypeIDs?: InputMaybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
  TravelAgentID?: InputMaybe<Scalars['String']>;
};


export type QueryBiAdvancePaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiAnalysisArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiBillLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiBookingStatusArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiCorpGovTaytdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiCorpGovTaV2Args = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiDepositLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiFolioListingArgs = {
  AccountID: Scalars['String'];
  DocumentType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  SearchValue?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryBiForeignListingArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiHotelDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiHotelListingArgs = {
  ID: Scalars['String'];
};


export type QueryBiInHouseArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiMarketingPersonnalTopSalesArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalV2Args = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiPackageArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiPaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiPaymentListingTestArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiReceiptRefundListingArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  Limit?: InputMaybe<Scalars['Float']>;
  Offset?: InputMaybe<Scalars['Float']>;
  SearchValue?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
};


export type QueryBiRegistrationListingArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiRevenueListingArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiRoomDiscrepancyLogArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomListingArgs = {
  HotelID: Scalars['String'];
  RoomTypeID?: InputMaybe<Scalars['String']>;
};


export type QueryBiRoomRateListingArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  GuestID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Status?: InputMaybe<Scalars['String']>;
};


export type QueryBiRoomStatusArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  LocationID?: InputMaybe<Scalars['String']>;
};


export type QueryBiRoomTypeRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueDetailArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiRoomTypeRevenueDetailV2Args = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  Mode?: InputMaybe<Scalars['String']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiSecurityKeyCardDepositArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiSegmentArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiSegmentRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiSegmentV2Args = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiServiceRequestArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiServiceRequestListingArgs = {
  Department?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiSourceArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiSourceV2Args = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiSourceYtdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTestListingArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiTop5OtaArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiTop5Otav2Args = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiTop10CountryArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiTop10CountryV2Args = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiTop10NationalityArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiTop10NationalityV2Args = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiTop10RevenueArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiTop10RevenueV2Args = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiTopCountryArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopNationalityArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopTransactionArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTransactionCancellationArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBiTransactionLedgerArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryBiTravelAgentArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiUsersLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryBiytdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBankAccountListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiAdvPaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryBillLedgerFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryBillLedgerListingArgs = {
  HotelID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryBillScheduleListByBookingArgs = {
  BookingID: Scalars['String'];
  IsFrontDesk?: InputMaybe<Scalars['Boolean']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryBillScheduleListingArgs = {
  BookingID: Scalars['String'];
  IsFrontDesk?: InputMaybe<Scalars['Boolean']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryBillSummaryDetailsArgs = {
  BillSummaryInput: BillSummaryInput;
};


export type QueryBillingByRegistrationArgs = {
  BookingID: Scalars['String'];
  IsHMS?: InputMaybe<Scalars['Boolean']>;
  RegistrationID: Array<Scalars['String']>;
};


export type QueryBlockRoomLogListingArgs = {
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryBlockRoomLogListingV2Args = {
  HotelID: Scalars['String'];
};


export type QueryBookingAttachmentListingArgs = {
  BookingID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryBookingAttachmentSummaryArgs = {
  BookingID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryBookingBillScheduleInfoArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  IsCurrentDate?: InputMaybe<Scalars['Boolean']>;
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryBookingDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryBookingIncChargesRegistrationListingArgs = {
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryBookingListingArgs = {
  AccountName?: InputMaybe<Scalars['String']>;
  ArrEndDate?: InputMaybe<Scalars['DateTime']>;
  ArrStartDate?: InputMaybe<Scalars['DateTime']>;
  BookingDate?: InputMaybe<Scalars['DateTime']>;
  BookingNo?: InputMaybe<Scalars['String']>;
  BookingStatus?: InputMaybe<Scalars['String']>;
  DebtorProfileID?: InputMaybe<Scalars['String']>;
  DebtorType: Scalars['String'];
  DepartEndDate?: InputMaybe<Scalars['DateTime']>;
  DepartStartDate?: InputMaybe<Scalars['DateTime']>;
  FullName?: InputMaybe<Scalars['String']>;
  GroupName?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ReferenceNo?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  SearchValue?: InputMaybe<Scalars['String']>;
  SortBy?: InputMaybe<Scalars['String']>;
  SortByOrder?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryBookingPackagePriceListingArgs = {
  DebtorID?: InputMaybe<Scalars['String']>;
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  IsOnline?: InputMaybe<Scalars['Boolean']>;
  NoOfRoom?: InputMaybe<Scalars['Float']>;
  RatePolicyID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
};


export type QueryBookingProfileArgs = {
  ID: Scalars['String'];
};


export type QueryBookingSubMenuInfoArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBusinessAnalyticInfoLineArgs = {
  HotelID: Scalars['String'];
};


export type QueryCbExportArgs = {
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryCbExportDetailsByTrxTypeArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  TrxType: Scalars['String'];
};


export type QueryCmsCustomControlArgs = {
  BookingID: Scalars['String'];
  ControlOP: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCalculateDebtorListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryCancelReasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryChangeOfDateCheckAvailabilityArgs = {
  ArrivalDate: Scalars['DateTime'];
  BookingID: Scalars['String'];
  DepartureDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  Reinstate?: InputMaybe<Scalars['Boolean']>;
};


export type QueryChangeOfDateCheckAvailabilityBookEditArgs = {
  ArrivalDate: Scalars['DateTime'];
  BookingID: Scalars['String'];
  DepartureDate: Scalars['DateTime'];
  EditRegInput: Array<EditRegInput>;
  HotelID: Scalars['String'];
  Reinstate?: InputMaybe<Scalars['Boolean']>;
  roomTypeIDs?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryChannelManagerProfileArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryCheckBanknameInUseArgs = {
  BankName: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCheckForBlockRoomArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomID?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
};


export type QueryCheckInListingArgs = {
  AccountID: Scalars['String'];
  BookingStatus: Scalars['String'];
  HotelID: Scalars['String'];
  SearchValue?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryCheckInListingCountArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCheckIsGroupInvoiceArgs = {
  HotelID: Scalars['String'];
  SourceID: Scalars['String'];
};


export type QueryCheckRecordArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  Email?: InputMaybe<Scalars['String']>;
  Login?: InputMaybe<Scalars['String']>;
  Mode?: InputMaybe<Scalars['String']>;
  NRIC?: InputMaybe<Scalars['String']>;
};


export type QueryCheckedOutFolioListingArgs = {
  AccountName?: InputMaybe<Scalars['String']>;
  Adjusted?: InputMaybe<Scalars['Boolean']>;
  BookingNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  FolioDate?: InputMaybe<Scalars['DateTime']>;
  FolioNo?: InputMaybe<Scalars['String']>;
  FullName?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomNo?: InputMaybe<Scalars['String']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryCheckedOutFolioListingOldArgs = {
  AccountName?: InputMaybe<Scalars['String']>;
  Adjusted?: InputMaybe<Scalars['Boolean']>;
  BookingNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  FolioDate?: InputMaybe<Scalars['DateTime']>;
  FolioNo?: InputMaybe<Scalars['String']>;
  FullName?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomNo?: InputMaybe<Scalars['String']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryCheckoutAdjustmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryCheckoutReinstateInfoArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryChooseRoomAllocationDetailArgs = {
  AccountID: Scalars['String'];
  EffectiveDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryChooseRoomAllocationListingArgs = {
  AccountID: Scalars['String'];
  DateSearch?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryCityLedgerChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCityLedgerDebtorListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCityLedgerInvoiceListingsArgs = {
  Amount?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Status?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryCityLedgerTaxGlListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCollectionByDebtorTypeVsOverdueArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCompanyListingArgs = {
  AccountID: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryComputeBookingbyHotelArgs = {
  HotelID: Scalars['String'];
  input: Array<TaxInput>;
};


export type QueryComputeTaxArrByHotelArgs = {
  TaxInput: Array<TaxInput>;
};


export type QueryComputeTaxBookingArgs = {
  DiscountAmount: Scalars['Float'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RateID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryComputeTaxHotelXArgs = {
  input: TaxInput;
};


export type QueryComputeTaxbyHotelArgs = {
  input: TaxInput;
};


export type QueryComputeTaxbyTaxTypeArgs = {
  HotelID: Scalars['String'];
  input: TaxType;
};


export type QueryComputeUpgradebyHotelArgs = {
  Amount?: InputMaybe<Scalars['Float']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  Mode: Scalars['String'];
  RateID: Scalars['String'];
  RegistrationID: Scalars['String'];
  RoomTypeID?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
};


export type QueryConsolidatedInvoiceListingArgs = {
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  DocNo?: InputMaybe<Scalars['String']>;
  DocType?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Submenu?: InputMaybe<Scalars['String']>;
};


export type QueryContactPaginationArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryContractDetailsArgs = {
  DebtorID: Scalars['String'];
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
};


export type QueryCoporateInUseListingArgs = {
  DebtorID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryCorporateBookingListingArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCorporateContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryCorporateGovernmentDetailArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryCorporateGovernmentListingArgs = {
  Adjusted?: InputMaybe<Scalars['Boolean']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorCategory?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Name?: InputMaybe<Scalars['String']>;
  SalesChannel?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryCorporateInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryCountItemArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteAllocationListingArgs = {
  CreditID: Scalars['String'];
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteAndInvoiceCountArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteAndInvoiceListingsArgs = {
  Amount?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  IsGroupInvoice?: InputMaybe<Scalars['Boolean']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryCreditNoteInfoArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
};


export type QueryCreditNoteListingsArgs = {
  Amount?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Status?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryCreditNoteListingsOldArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCurrentInhouseListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCurrentInhouseListV7Args = {
  HotelID: Scalars['String'];
};


export type QueryCurrentTaxDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryCustomizedDocListingArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryDashBoardAllListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDashBoardListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebitNoteAllocationListingArgs = {
  DebitID: Scalars['String'];
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDebitNoteDetailArgs = {
  ID: Scalars['String'];
};


export type QueryDebitNoteInfoArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
};


export type QueryDebitNoteListingsArgs = {
  Amount?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Status?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryDebtorAgingArgs = {
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryDebtorAgingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorAnalysisArgs = {
  DebtorID: Scalars['String'];
  EndDate?: InputMaybe<Scalars['DateTime']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryDebtorCountListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorDetailArgs = {
  ID: Scalars['String'];
};


export type QueryDebtorDetailByAccountCodeArgs = {
  AccountCode: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDebtorFolioInfoArgs = {
  DebtorAccount?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorListingArgs = {
  CompanyName?: InputMaybe<Scalars['String']>;
  Country?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorType?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryDebtorListingCreditFacilityArgs = {
  CompanyName?: InputMaybe<Scalars['String']>;
  Country?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorType?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryDebtorListingDropDownArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorListingNonCreditFacilityArgs = {
  CompanyName?: InputMaybe<Scalars['String']>;
  Country?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorType?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryDebtorTypeCountArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorTypeCountListingArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
};


export type QueryDebtorTypeInUseArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
};


export type QueryDebtorTypeListingArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryDeliveredRoomServiceListingArgs = {
  HotelID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
};


export type QueryDeliveredRoomServiceRegistrationListingArgs = {
  HotelID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
};


export type QueryDeparmentCheckingArgs = {
  DepartmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepartmentInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDepositClassArgs = {
  ReversedID: Scalars['String'];
};


export type QueryDepositForfeitlistArgs = {
  ReversedID: Scalars['String'];
};


export type QueryDepositLedgerDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryDepositListingArgs = {
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  SearchValue?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryDepositListingByBookingArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryDepositListingByBookingV2Args = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryDepositRefundDetailsArgs = {
  DepositID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositRefundlistArgs = {
  ReversedID: Scalars['String'];
};


export type QueryDisCountByPromoCodeArgs = {
  HotelID: Scalars['String'];
  PromoCode: Scalars['String'];
  PromoCodeInput: Array<PromoCodeInput>;
};


export type QueryDocToConsolidateListingArgs = {
  AccountID: Scalars['String'];
  DocNo?: InputMaybe<Scalars['String']>;
  DocType?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IsSearch?: InputMaybe<Scalars['Boolean']>;
  Month?: InputMaybe<Scalars['Float']>;
  Name?: InputMaybe<Scalars['String']>;
  Submenu?: InputMaybe<Scalars['String']>;
  Year?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryDocToConsolidateViewArgs = {
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  ConsolidationID: Scalars['String'];
  DocType?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Submenu?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryDocumentNumberHeaderListingArgs = {
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
};


export type QueryDocumentTemplateListArgs = {
  ID: Scalars['String'];
};


export type QueryEDocParamsListingArgs = {
  DocType: Scalars['String'];
};


export type QueryEDocTemplateListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryEInvSubscriptionArgs = {
  AccountID: Scalars['String'];
};


export type QueryEInvoiceDocListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryEInvoiceInformationArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  Type: Scalars['String'];
};


export type QueryEmailMarketingDetailArgs = {
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
};


export type QueryEmailMarketingListingArgs = {
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
};


export type QueryEntRoleUsrAsgListArgs = {
  ID?: InputMaybe<Scalars['String']>;
  entityID?: InputMaybe<Scalars['String']>;
  orderByAsc?: InputMaybe<Scalars['String']>;
  orderByDesc?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryExportedCbDetailsArgs = {
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryExportedCbListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryExportedGlDetailsArgs = {
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryExportedGlListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryExportedGlTransferDetailsArgs = {
  AccountID: Scalars['String'];
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryFloorPlanGalleryArgs = {
  ID: Scalars['String'];
};


export type QueryFloorPlanListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  LocationID?: InputMaybe<Scalars['String']>;
};


export type QueryFolioChargeToListArgs = {
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryFolioDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryFolioDetailsWithIDsArgs = {
  HotelID: Scalars['String'];
  LedgerIDs?: InputMaybe<LedgerIDsInput>;
  RegistrationID: Scalars['String'];
};


export type QueryFolioHistoryListingArgs = {
  BookingID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryFolioListingArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryFolioListingByRegistrationArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  BookingID: Scalars['String'];
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryFolioUseListingArgs = {
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryFrontDeskBookingInfoArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryFrontDeskIncidentalChargeListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryFrontDeskInfoLinesArgs = {
  HotelID: Scalars['String'];
};


export type QueryFrontDeskListingArgs = {
  ArrEndDate?: InputMaybe<Scalars['DateTime']>;
  ArrStartDate?: InputMaybe<Scalars['DateTime']>;
  ArrivalDate?: InputMaybe<Scalars['DateTime']>;
  DepartEndDate?: InputMaybe<Scalars['DateTime']>;
  DepartStartDate?: InputMaybe<Scalars['DateTime']>;
  GuestID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReferenceNo?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  SortBy?: InputMaybe<Scalars['String']>;
  SortByOrder?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<Scalars['String']>;
};


export type QueryFrontDeskListingV2Args = {
  ArrEndDate?: InputMaybe<Scalars['DateTime']>;
  ArrStartDate?: InputMaybe<Scalars['DateTime']>;
  DepartEndDate?: InputMaybe<Scalars['DateTime']>;
  DepartStartDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ReferenceNo?: InputMaybe<Scalars['String']>;
  Search?: InputMaybe<Scalars['String']>;
  SearchValue?: InputMaybe<Scalars['String']>;
  SortBy?: InputMaybe<Scalars['String']>;
  SortByOrder?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryFrontDeskPaymentListingArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryFrontDeskServiceRequestListingArgs = {
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryFrontDeskSubMenuInfoArgs = {
  HotelID: Scalars['String'];
  RegistrationIDs?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGaInHouseGuestListingArgs = {
  GuestID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryGaMarketingAdsListingArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  isLoyaltyApp?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGlAccountPeriodArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGlExportArgs = {
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryGlExportDetailsByTrxTypeArgs = {
  AccountCode?: InputMaybe<Scalars['String']>;
  AccountID: Scalars['String'];
  BatchNo: Scalars['Float'];
  Department?: InputMaybe<Scalars['String']>;
  Division?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  TrxDesc: Scalars['String'];
  TrxType: Scalars['String'];
};


export type QueryGetAdjustmentTaxArgs = {
  EffectiveDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGetCurrentGovTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetCurrentTourismTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetLevyTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetPlatformTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetServiceChargeTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetTaxDetailsArgs = {
  AccountID: Scalars['String'];
  TaxID: Scalars['String'];
};


export type QueryGetTaxListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxListingDropdownArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailArgs = {
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailItemRateArgs = {
  AccountID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  TaxSchemeID: Scalars['String'];
};


export type QueryGetTaxSchemeListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxTypeListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetUserByUsernameArgs = {
  UserName: Scalars['String'];
};


export type QueryGovernmentListingArgs = {
  DebtorCategory?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryGroupCreditNoteAndInvoiceListingsArgs = {
  Amount?: InputMaybe<Scalars['String']>;
  BookingNo?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  GroupInvoiceNo?: InputMaybe<Scalars['String']>;
  GroupReferenceNo?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryGroupInvoiceCountArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestAppFeatureArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestAppFeatureListArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestBookingListingArgs = {
  GuestID: Scalars['String'];
  HotelID?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryGuestHistoryListingArgs = {
  GuestID: Scalars['String'];
};


export type QueryGuestHistoryListingV2Args = {
  GuestID: Scalars['String'];
};


export type QueryGuestListingByLocationTimeArgs = {
  CheckInDate: Scalars['DateTime'];
  GuestID: Scalars['String'];
  HotelID: Scalars['String'];
  RefID: Scalars['String'];
  Skip?: InputMaybe<Scalars['Float']>;
  Take?: InputMaybe<Scalars['Float']>;
};


export type QueryGuestMovementListArgs = {
  BookingID: Scalars['String'];
  GuestID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestMovementListingArgs = {
  HotelID: Scalars['String'];
  Skip?: InputMaybe<Scalars['Float']>;
  Take?: InputMaybe<Scalars['Float']>;
};


export type QueryGuestProfileArgs = {
  GuestID: Scalars['String'];
};


export type QueryGuestProfileHistoryListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListbyRoomArgs = {
  BookingID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryGuestProfileListingArgs = {
  Age1?: InputMaybe<Scalars['String']>;
  Age2?: InputMaybe<Scalars['String']>;
  BirthMonth?: InputMaybe<Scalars['String']>;
  Country?: InputMaybe<Scalars['String']>;
  FullName?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Nationality?: InputMaybe<Scalars['String']>;
  RoomNo?: InputMaybe<Scalars['String']>;
  SearchValue?: InputMaybe<Scalars['String']>;
  SpecialRequest?: InputMaybe<Scalars['String']>;
  VisitNo1?: InputMaybe<Scalars['String']>;
  VisitNo2?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryGuestProfileListingByIdArgs = {
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryGuestProfileListingV2Args = {
  AccountID: Scalars['String'];
  Age1?: InputMaybe<Scalars['String']>;
  Age2?: InputMaybe<Scalars['String']>;
  BirthMonth?: InputMaybe<Scalars['String']>;
  Country?: InputMaybe<Scalars['String']>;
  FullName?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Nationality?: InputMaybe<Scalars['String']>;
  RoomNo?: InputMaybe<Scalars['String']>;
  SpecialRequest?: InputMaybe<Scalars['String']>;
  VisitNo1?: InputMaybe<Scalars['String']>;
  VisitNo2?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryGuestReviewDetailArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Rating?: InputMaybe<Scalars['Float']>;
  RegistrationID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGuestReviewInfoLinesArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestReviewListingArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Rating?: InputMaybe<Scalars['Float']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGuestReviewsHotelXArgs = {
  ClientID?: InputMaybe<Scalars['String']>;
};


export type QueryGuestRoomResultArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestRoomServiceListingArgs = {
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ServiceType?: InputMaybe<Scalars['String']>;
};


export type QueryGuestappsettingDetailArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryHkLocationMasterListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHkMasterListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsBlocked?: InputMaybe<Scalars['Boolean']>;
  LocationID?: InputMaybe<Scalars['String']>;
  RoomStatusID?: InputMaybe<Scalars['String']>;
};


export type QueryHlsInvetoryUpdateArgs = {
  DebtorID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RateIDs?: InputMaybe<Array<Scalars['String']>>;
  RoomTypeIDs?: InputMaybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
};


export type QueryHotelAccountXListArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelAuthorizationInfoLineArgs = {
  AccountID: Scalars['String'];
};


export type QueryHotelDetailsArgs = {
  CorporateURL?: InputMaybe<Scalars['String']>;
  GuestAppURL?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  OnlineBookingURL?: InputMaybe<Scalars['String']>;
  TravelAgentURL?: InputMaybe<Scalars['String']>;
};


export type QueryHotelListingArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
};


export type QueryHotelNotificationListingArgs = {
  HotelID: Scalars['String'];
  NotificationType: Scalars['String'];
  UserID: Scalars['String'];
};


export type QueryHotelPreRegQrArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelPricesByDateRangeArgs = {
  CheckIn?: InputMaybe<Scalars['DateTime']>;
  CheckOut?: InputMaybe<Scalars['DateTime']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  DebtorType?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryHotelReviewListingArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryHotelRoomNumberListArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHotelSalesChannelListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelStateAnalysisArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryHotelStatisticListingArgs = {
  BlockRoom?: InputMaybe<Scalars['Boolean']>;
  Complimentary?: InputMaybe<Scalars['Boolean']>;
  DayUse?: InputMaybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  HouseUse?: InputMaybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
};


export type QueryHotelStatisticListingV2Args = {
  BlockRoom?: InputMaybe<Scalars['Boolean']>;
  Complimentary?: InputMaybe<Scalars['Boolean']>;
  DayUse?: InputMaybe<Scalars['Boolean']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  HouseUse?: InputMaybe<Scalars['Boolean']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryHotelTaxDetailsArgs = {
  ClientID: Scalars['String'];
};


export type QueryHotelXCredentialsArgs = {
  Authorization: Scalars['String'];
  isHotel?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHotelsByCityArgs = {
  AccountID: Scalars['String'];
  CheckIn?: InputMaybe<Scalars['DateTime']>;
  CheckOut?: InputMaybe<Scalars['DateTime']>;
  City: Scalars['String'];
  DebtorProfileID?: InputMaybe<Scalars['String']>;
  DebtorType?: InputMaybe<Scalars['String']>;
  GuestID?: InputMaybe<Scalars['String']>;
  NoOfGuests?: InputMaybe<Scalars['Float']>;
  NoOfRooms?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryHousekeepingDetailArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryHousekeepingIncidentalChargeListingArgs = {
  DepartmentID?: InputMaybe<Scalars['String']>;
};


export type QueryHousekeepingListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  LocationID?: InputMaybe<Scalars['String']>;
};


export type QueryHousekeepingRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHousekeepingServiceRequestListingArgs = {
  HotelID: Scalars['String'];
  RoomID?: InputMaybe<Scalars['String']>;
};


export type QueryIncidentalChargeByIdArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalChargeInRateElementArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryIncidentalChargeInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalChargeListingArgs = {
  DepartmentID?: InputMaybe<Scalars['String']>;
};


export type QueryIncidentalChargeListingByHotelArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryIncidentalDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryInhousePaymentListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryInhouseQrArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryInterestListingArgs = {
  ID: Scalars['String'];
};


export type QueryInventoryRatePlansHotelXArgs = {
  ClientID: Scalars['String'];
  EndDate?: InputMaybe<Scalars['DateTime']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryInvoiceDetailArgs = {
  ID: Scalars['String'];
};


export type QueryInvoiceInfoArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
};


export type QueryInvoiceListingsArgs = {
  Amount?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryIsBlockRoomAvailableArgs = {
  BlockRoomID?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomID: Array<Scalars['String']>;
  StartDate: Scalars['DateTime'];
};


export type QueryIsBookingExistArgs = {
  BookingNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsChargeableArgs = {
  HotelID: Scalars['String'];
};


export type QueryIsExistRegistrationIncChargesArgs = {
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryIsGuestAppUserArgs = {
  Login?: InputMaybe<Scalars['String']>;
};


export type QueryIsHotelLockUserArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryIsPaymentGateWayHotelArgs = {
  HotelID: Scalars['String'];
};


export type QueryIsPresetIncChargesExistArgs = {
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  newDepartureDate: Scalars['DateTime'];
};


export type QueryIsQ3FInterfaceArgs = {
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
};


export type QueryIsRateInUseArgs = {
  RateEffectiveID: Scalars['String'];
  RateID: Scalars['String'];
};


export type QueryIsRoomAvailableArgs = {
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryIsRoomAvailableGroupArgs = {
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
  RoomIDs: Array<Scalars['String']>;
};


export type QueryIsRoomExistArgs = {
  ArrivalDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};


export type QueryIsRoomNoAvailableArgs = {
  HotelID: Scalars['String'];
  RegistrationInput?: InputMaybe<Array<RegistrationInput>>;
};


export type QueryIsTaxInUseArgs = {
  AccountID: Scalars['String'];
  TaxID: Scalars['String'];
};


export type QueryIsTaxSameCodeArgs = {
  AccountID: Scalars['String'];
  TaxCode: Scalars['String'];
};


export type QueryIsTaxSameNameArgs = {
  AccountID: Scalars['String'];
  TaxSchemeName: Scalars['String'];
};


export type QueryIsTaxSchemeInUseArgs = {
  HotelID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
};


export type QueryLateCheckOutChargeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryLocationFloorPlanListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLocationInUseListingArgs = {
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
};


export type QueryLocationListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLoyaltyAppHotelGroupArgs = {
  LoyaltyAppURL?: InputMaybe<Scalars['String']>;
};


export type QueryManagerReportArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryManagerReportDepartmentArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryMenuXDebtorListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryMenuXHotelListingArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
};


export type QueryMenuXInhouseListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryMenuXInhouseStatusArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryMonthlyStatementListingArgs = {
  ID: Scalars['String'];
};


export type QueryNaRegistrationDetailsArgs = {
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryNonGuestBillingListingArgs = {
  FolioID: Scalars['String'];
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryNonGuestFolioListingArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Type?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNonGuestFolioListingv2Args = {
  AccountID?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  FolioDate?: InputMaybe<Scalars['DateTime']>;
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReferenceID?: InputMaybe<Scalars['String']>;
  Title?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryOAuthHotelXArgs = {
  ClientID?: InputMaybe<Scalars['String']>;
  Secret: Scalars['String'];
};


export type QueryOfficialReceiptDetailArgs = {
  ID: Scalars['String'];
};


export type QueryOfficialReceiptInfoArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
};


export type QueryOfficialReceiptListingsArgs = {
  Amount?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  DocumentNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Status?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryOfficialReceiptListingsOldArgs = {
  DebtorID?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Status?: InputMaybe<Scalars['String']>;
};


export type QueryOnlineGaInHouseGuestListingArgs = {
  GuestID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryOpeningClosingAmountArgs = {
  DebtorID: Scalars['String'];
  EndDate?: InputMaybe<Scalars['DateTime']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryOpeningClosingAmountV2Args = {
  DebtorID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryOutGoingDocTemplateArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryPmsTravelAgentDetailArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryPackagePriceListingArgs = {
  DebtorID?: InputMaybe<Scalars['String']>;
  DebtorType?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  IsOnline?: InputMaybe<Scalars['Boolean']>;
  NoOfRoom?: InputMaybe<Scalars['Float']>;
  RatePolicyID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
};


export type QueryPackageRedemptionByItemArgs = {
  ComputationRule: Scalars['String'];
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
};


export type QueryPackageRedemptionListingArgs = {
  HotelID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryPaymentBillLedgerListingArgs = {
  FolioID: Scalars['String'];
};


export type QueryPaymentDetailArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  OrderID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryPaymentDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryPaymentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentRegistrationListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryPaymentRoomServiceDetailArgs = {
  OrderID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryPendingBillingListingArgs = {
  FolioID: Scalars['String'];
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryPostedScheduleBillingArgs = {
  DebtorID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryPreCheckInQrArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryPreRegBookingArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryPreRegBookingInfoArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  QR?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryPreRegRegistrationDetailsArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryPreRegRoomAllocationArgs = {
  ArrivalDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};


export type QueryPrincipalGuestListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryPromoDiscHotelXArgs = {
  ClientID: Scalars['String'];
  PromoCode: Scalars['String'];
  PromoCodeInput: Array<PromoCodeInput>;
};


export type QueryPromotionDetailListingArgs = {
  PromotionID: Scalars['String'];
};


export type QueryPromotionDetailsInUseListingArgs = {
  PromoCode: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryPromotionInUseListingArgs = {
  Date: Scalars['DateTime'];
  ID: Scalars['String'];
  PromoCode: Scalars['String'];
};


export type QueryPromotionListHotelXArgs = {
  ClientID: Scalars['String'];
};


export type QueryPromotionListingArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryQ3DocumentDetailByHeaderArgs = {
  HeaderID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3DocumentHeaderArgs = {
  DocumentType: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3FRevenueArgs = {
  AccountID: Scalars['String'];
  BatchNo?: InputMaybe<Scalars['Float']>;
  DownloadDate: Scalars['DateTime'];
  DownloadMode: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3FRevenueOldArgs = {
  AccountID: Scalars['String'];
  DownloadDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryQ3FSegmentArgs = {
  AccountID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryQ3FStatisticArgs = {
  AccountID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryQ3ListingsArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingAdvPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingDebtorListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingDepositListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingRefundListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingTaxListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRateAddOnListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateAdjustmentAuditListingArgs = {
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryRateEffectiveListingArgs = {
  RateID: Scalars['String'];
};


export type QueryRateElementInactiveCheckArgs = {
  Action: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryRateElementListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateInHouseListingArgs = {
  HotelID: Scalars['String'];
  RateID: Scalars['String'];
};


export type QueryRateListingArgs = {
  Category?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryRatePolicyDetailsArgs = {
  HotelID: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryRatePolicyInUseListingArgs = {
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryRatePolicyInfoArgs = {
  HotelID: Scalars['String'];
};


export type QueryRatePolicyListingArgs = {
  DebtorType?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryRatePolicyRoomTypeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateTypeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryReaonInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryReasonListingArgs = {
  DepartmentID?: InputMaybe<Scalars['String']>;
};


export type QueryReceiptListingArgs = {
  PaymentClass: PaymentClass;
  ReceiptNo: Scalars['String'];
};


export type QueryRefundDetailArgs = {
  ID: Scalars['String'];
};


export type QueryRefundInfoArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
};


export type QueryRefundListingsArgs = {
  Amount?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentNo?: InputMaybe<Scalars['String']>;
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
  Status?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryRegenBsQueueListingArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
};


export type QueryRegistrationByBookingListingArgs = {
  BookingID: Scalars['String'];
  RegistrationStatus: Scalars['String'];
};


export type QueryRegistrationDetailsArgs = {
  BookingID: Scalars['String'];
  GuestID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryRegistrationDetailsMultiArgs = {
  InputReg?: InputMaybe<Array<InputReg>>;
};


export type QueryRegistrationListingArgs = {
  RoomID: Scalars['String'];
};


export type QueryRegistrationRoomTypeListingArgs = {
  RoomTypeID: Scalars['String'];
};


export type QueryRegistrationTaxLedgerListingArgs = {
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryReminderListingArgs = {
  ID: Scalars['String'];
};


export type QueryRoleViewArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};


export type QueryRoomAllocationLocationListingArgs = {
  AccountID: Scalars['String'];
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryRoomAssignListingArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  IsBooking?: InputMaybe<Scalars['Boolean']>;
  RegistrationIDs?: InputMaybe<Array<Scalars['String']>>;
  RoomID?: InputMaybe<Array<Scalars['String']>>;
  RoomTypeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryRoomBlockRoomListingArgs = {
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryRoomInHouseListingArgs = {
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryRoomInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryRoomListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Location?: InputMaybe<Array<Scalars['String']>>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
};


export type QueryRoomNumberForFoodListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomSaleOptimizationListingArgs = {
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryRoomServiceBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomServiceFolioListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomServiceListingArgs = {
  HotelID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
};


export type QueryRoomServiceRegistrationListingArgs = {
  HotelID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
};


export type QueryRoomServicesCountArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomTypeAvailableArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomTypeID?: InputMaybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
};


export type QueryRoomTypeListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRoomUpgradeCheckArgs = {
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryRoundingAdjustmentArgs = {
  Amount: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryRoundingAmountArgs = {
  Amount: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QuerySalesChannelListingArgs = {
  DepartmentID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySalesChannelinUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryScheduleBillingCountListArgs = {
  DebtorAcc?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IsPosted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryScheduleBillingListArgs = {
  BookingNo?: InputMaybe<Scalars['String']>;
  DebtorAcc: Array<Scalars['String']>;
  DebtorTypeID: Array<Scalars['String']>;
  DocEndDate?: InputMaybe<Scalars['DateTime']>;
  DocStartDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  IsGroupBooking?: InputMaybe<Scalars['String']>;
  IsGroupInvoice?: InputMaybe<Scalars['String']>;
  IsPosted?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QuerySearchFolioArgs = {
  FolioNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QuerySearchGuestArgs = {
  GuestName: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QuerySeasonCalendarListingArgs = {
  CalendarDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QuerySeasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySeasonListingCalenderArgs = {
  HotelID: Scalars['String'];
};


export type QuerySegmentInUseListingArgs = {
  HotelID: Scalars['String'];
  SegmentID: Scalars['String'];
};


export type QuerySegmentListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySelectFolioArgs = {
  ID: Scalars['String'];
};


export type QuerySelectedGuestListingArgs = {
  GuestID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QuerySelectedRegistrationArgs = {
  ID: Scalars['String'];
};


export type QueryServicePaymentSettingArgs = {
  HotelID: Scalars['String'];
};


export type QueryServicePendingCountArgs = {
  GuestID?: InputMaybe<Scalars['String']>;
};


export type QueryServiceRequestDetailArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestListingArgs = {
  DepartmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryServiceRequestv2ListingArgs = {
  DepartmentID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Status?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryShowBookingConfirmationEmailArgs = {
  BookingID: Scalars['String'];
};


export type QuerySrLocationListingArgs = {
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueryStateAnalysisRateAndRoomArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryStatementOfAccDocumentArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryStayViewListingArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Location?: InputMaybe<Array<Scalars['String']>>;
  RoomNo?: InputMaybe<Array<Scalars['String']>>;
  RoomTypeIDs?: InputMaybe<Array<Scalars['String']>>;
  enddate: Scalars['DateTime'];
  startdate: Scalars['DateTime'];
};


export type QuerySubscribedPackagesArgs = {
  accountID?: InputMaybe<Scalars['String']>;
};


export type QuerySuspendDetailArgs = {
  ID: Scalars['String'];
};


export type QuerySuspendFolioListingArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QuerySuspendFolioListingv2Args = {
  AccountID?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  FolioDate?: InputMaybe<Scalars['DateTime']>;
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReferenceID?: InputMaybe<Scalars['String']>;
  Title?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryTTlockInfoArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
};


export type QueryTaxInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryTaxPolicyListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryTodayBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryTop10DebtorRevenueArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryTotalAgingArgs = {
  DebtorID?: InputMaybe<Array<Scalars['String']>>;
  HotelID: Scalars['String'];
};


export type QueryTotalAgingBiArgs = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryTotalDueAgingArgs = {
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryTotalOverdueByDebtorTypeArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryTravelAgBookingListingArgs = {
  AccountName?: InputMaybe<Scalars['String']>;
  ArrEndDate?: InputMaybe<Scalars['DateTime']>;
  ArrStartDate?: InputMaybe<Scalars['DateTime']>;
  BookingDate?: InputMaybe<Scalars['DateTime']>;
  BookingNo?: InputMaybe<Scalars['String']>;
  BookingStatus?: InputMaybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  DebtorType?: InputMaybe<Scalars['String']>;
  DepartEndDate?: InputMaybe<Scalars['DateTime']>;
  DepartStartDate?: InputMaybe<Scalars['DateTime']>;
  FullName?: InputMaybe<Scalars['String']>;
  GroupName?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReferenceNo?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  SortBy?: InputMaybe<Scalars['String']>;
  SortByOrder?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryTravelAgentContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryTravelAgentDetailArgs = {
  ID: Scalars['String'];
};


export type QueryTravelAgentHotelListingArgs = {
  AccountID?: InputMaybe<Scalars['String']>;
  DebtorCategory: Scalars['String'];
  Login: Scalars['String'];
};


export type QueryTravelAgentInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryTravelAgentListingArgs = {
  Adjusted?: InputMaybe<Scalars['Boolean']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Name?: InputMaybe<Scalars['String']>;
  SalesChannel?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};


export type QueryTravelAgentRoomRevenueArgs = {
  DebtorID?: InputMaybe<Scalars['String']>;
  DebtorType: Scalars['String'];
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  StartDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryUnutilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryUnutilizedDepositListingV2Args = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryUserEntitiesArgs = {
  accountID?: InputMaybe<Scalars['String']>;
  userID: Scalars['String'];
};


export type QueryUtilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryUtilizedDepositListingV2Args = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};


export type QueryWhBookingNotificationArgs = {
  HotelID: Scalars['String'];
};


export type QueryCheckoutfoliodeposiitArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetClassificationListingByCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  LatetestUser?: InputMaybe<Scalars['Boolean']>;
  accountID: Scalars['String'];
  status?: InputMaybe<CommonStatus>;
  superUser?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetUsersRoleContractArgs = {
  hotelID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsLoginPermittedArgs = {
  deviceIP: Scalars['String'];
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryUpdateLastestAccessHotelArgs = {
  hotelID: Scalars['String'];
  userIDs: Scalars['String'];
};


export type QueryUserRoleIDsArgs = {
  hotelID: Scalars['String'];
};

export enum Race {
  Chinese = 'Chinese',
  Indian = 'Indian',
  Malay = 'Malay',
  Others = 'Others'
}

export type RateAddOnEntity = InterfaceBase & {
  __typename?: 'RateAddOnEntity';
  Amount: Scalars['Float'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  IncidentalChargeID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PostingRythm?: Maybe<Scalars['String']>;
};

export type RateAddOnInput = {
  Amount: Scalars['Float'];
  Description: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IncidentalChargeID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  PostingRythm: Scalars['String'];
};

export type RateAdjustmentAuditEntity = InterfaceBase & {
  __typename?: 'RateAdjustmentAuditEntity';
  Booking?: Maybe<BookingEntity>;
  BookingID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EffectiveDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
  NewRate?: Maybe<RateEntity>;
  NewRateID: Scalars['String'];
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldRate?: Maybe<RateEntity>;
  OldRateID: Scalars['String'];
  OldRoomRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID: Scalars['String'];
};

export type RateAdjustmentAuditInput = {
  BookingID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  NewDiscountAmount?: InputMaybe<Scalars['Float']>;
  NewPromoCode?: InputMaybe<Scalars['String']>;
  NewRateID: Scalars['String'];
  NewRoomRate?: InputMaybe<Scalars['Float']>;
  OldDiscountAmount?: InputMaybe<Scalars['Float']>;
  OldPromoCode?: InputMaybe<Scalars['String']>;
  OldRateID: Scalars['String'];
  OldRoomRate?: InputMaybe<Scalars['Float']>;
  Reason?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
};

export type RateAndDisc = {
  Discount?: InputMaybe<Scalars['Float']>;
  RoomRate?: InputMaybe<Scalars['Float']>;
};

export type RateAndDiscs = {
  Discount?: InputMaybe<Scalars['Float']>;
  RoomRate?: InputMaybe<Scalars['Float']>;
};

export enum RateCategory {
  Complimentary = 'Complimentary',
  Corporate = 'Corporate',
  DayUse = 'DayUse',
  Government = 'Government',
  HouseUse = 'HouseUse',
  Public = 'Public',
  TravelAgent = 'TravelAgent'
}

export type RateEffectiveEntity = InterfaceBase & {
  __typename?: 'RateEffectiveEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<RateEntity>;
  RateAddOnIDs?: Maybe<Array<EffectiveRateJson>>;
  RateElementIDs?: Maybe<Array<EffectiveRateJson>>;
  RateID: Scalars['String'];
  SeasonalRate?: Maybe<Array<SeasonalRateEntity>>;
  StartDate: Scalars['DateTime'];
};

export type RateEffectiveInput = {
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  RateAddOnIDs?: InputMaybe<Array<EffectiveRateJsonInput>>;
  RateElementIDs?: InputMaybe<Array<EffectiveRateJsonInput>>;
  RateID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};

export type RateElementEntity = InterfaceBase & {
  __typename?: 'RateElementEntity';
  Amount: Scalars['Float'];
  ComputationRule?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  IncidentalChargeID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Max: Scalars['Float'];
  Min: Scalars['Float'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type RateElementInput = {
  Amount: Scalars['Float'];
  ComputationRule: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IncidentalChargeID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Max?: InputMaybe<Scalars['Float']>;
  Min?: InputMaybe<Scalars['Float']>;
};

export type RateEntity = InterfaceBase & {
  __typename?: 'RateEntity';
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Category?: Maybe<RateCategory>;
  ContractRates?: Maybe<Array<ContractRateEntity>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  OnlineRate: Scalars['Boolean'];
  RateCode?: Maybe<Scalars['String']>;
  RateEffective?: Maybe<Array<RateEffectiveEntity>>;
  RateFrequency?: Maybe<Scalars['String']>;
  RateType?: Maybe<RateTypeEntity>;
  RateTypeID?: Maybe<Scalars['String']>;
};

export type RateGainBookingRecordEntity = InterfaceBase & {
  __typename?: 'RateGainBookingRecordEntity';
  BookingID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  Processed?: Maybe<Scalars['Boolean']>;
  RGStatus?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
};

export type RateGainBookingRecordInput = {
  BookingID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ProcessCount?: InputMaybe<Scalars['Float']>;
  Processed?: InputMaybe<Scalars['Boolean']>;
  RGStatus?: InputMaybe<Scalars['String']>;
  ReservationPayload?: InputMaybe<Scalars['String']>;
};

export type RateInput = {
  Category?: InputMaybe<RateCategory>;
  Description?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  OnlineRate: Scalars['Boolean'];
  RateCode?: InputMaybe<Scalars['String']>;
  RateFrequency?: InputMaybe<Scalars['String']>;
  RateTypeID: Scalars['String'];
};

export type RatePolicyEntity = InterfaceBase & {
  __typename?: 'RatePolicyEntity';
  Category?: Maybe<RateCategory>;
  Code: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Package?: Maybe<Array<PackagesEntity>>;
  Pricing?: Maybe<Array<PricingEntity>>;
  PublishOnline?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
};

export type RatePolicyInput = {
  Category: RateCategory;
  Code: Scalars['String'];
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  PublishOnline?: InputMaybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type RatePolicyResponse = {
  __typename?: 'RatePolicyResponse';
  RateElementDate?: Maybe<Scalars['DateTime']>;
  RateSetupDate?: Maybe<Scalars['DateTime']>;
  RateTypeDate?: Maybe<Scalars['DateTime']>;
  SeasonCalendarDate?: Maybe<Scalars['DateTime']>;
  TotalAddOn?: Maybe<Scalars['Float']>;
  TotalSeason?: Maybe<Scalars['Float']>;
};

export type RateRoomTypeEntity = InterfaceBase & {
  __typename?: 'RateRoomTypeEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  RateEffective?: Maybe<RateEffectiveEntity>;
  RateEffectiveID: Scalars['String'];
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID: Scalars['DateTime'];
};

export type RateRoomTypeInput = {
  RateEffectiveID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};

export type RateTypeEntity = InterfaceBase & {
  __typename?: 'RateTypeEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type RateTypeInput = {
  Description?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
};

export type Rates = {
  Date?: InputMaybe<Scalars['DateTime']>;
  Rate?: InputMaybe<Scalars['Float']>;
  RoomIndex?: InputMaybe<Scalars['Float']>;
  roomID?: InputMaybe<Scalars['String']>;
};

export type Ratess = {
  Date?: InputMaybe<Scalars['DateTime']>;
  Rate?: InputMaybe<Scalars['Float']>;
  RoomIndex?: InputMaybe<Scalars['Float']>;
  roomID?: InputMaybe<Scalars['String']>;
};

export type ReasonEntity = InterfaceBase & {
  __typename?: 'ReasonEntity';
  Code?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Department?: Maybe<DepartmentEntity>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type ReasonInput = {
  Code: Scalars['String'];
  CreatedBy?: InputMaybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
};

export type RefundDepositInput = {
  AccountID: Scalars['String'];
  BookingID: Scalars['String'];
  DepositID: Scalars['String'];
  FolioID: Scalars['String'];
  ForfeitureAmount?: InputMaybe<Scalars['Float']>;
  HotelID: Scalars['String'];
  RefundAmount: Scalars['Float'];
  RegistrationID: Scalars['String'];
  Remarks: Scalars['String'];
};

export type RefundEntity = InterfaceTrx & {
  __typename?: 'RefundEntity';
  AccountID: Scalars['String'];
  Allocation: Array<AllocationEntity>;
  Amount: Scalars['Float'];
  Attachment?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  Commission: Scalars['Float'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  OutstandingAmount: Scalars['Float'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Status: ArTransactionStatus;
  VoidDate?: Maybe<Scalars['DateTime']>;
  bankDetails: BankDetails;
};

export type RefundEntityInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  Amount: Scalars['Float'];
  Commission: Scalars['Float'];
  Currency?: InputMaybe<Currency>;
  CurrencyRate?: InputMaybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  PostedDate?: InputMaybe<Scalars['DateTime']>;
  ReferenceNo: Scalars['String'];
  Status?: InputMaybe<ArTransactionStatus>;
  bankDetails: BankDetailsInput;
  einvoice_doc_ref_invoice_id?: InputMaybe<Scalars['String']>;
  einvoice_status?: InputMaybe<Scalars['String']>;
  is_einvoice?: InputMaybe<Scalars['Boolean']>;
};

export type RefundGlEntity = InterfaceTrx & {
  __typename?: 'RefundGLEntity';
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  RefundType?: Maybe<Scalars['String']>;
};

export type RegAndBooking = {
  __typename?: 'RegAndBooking';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
};

export type RegenBsLogEntity = InterfaceTrx & {
  __typename?: 'RegenBSLogEntity';
  AccountID: Scalars['String'];
  ActionType?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  UserID: Scalars['String'];
};

export type RegenBsLogInput = {
  AccountID: Scalars['String'];
  ActionType?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  UserID: Scalars['String'];
};

export type RegenBsQueueEntity = InterfaceBase & {
  __typename?: 'RegenBSQueueEntity';
  AccountID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID: Scalars['String'];
  Status: RegenBsQueueStatus;
  UserID: Scalars['String'];
};

export type RegenBsQueueInput = {
  AccountID: Scalars['String'];
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  RegistrationID: Scalars['String'];
  Status: RegenBsQueueStatus;
  UserID: Scalars['String'];
};

export enum RegenBsQueueStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Pending = 'Pending',
  Processing = 'Processing'
}

export type RegistrationEntity = InterfaceTrx & {
  __typename?: 'RegistrationEntity';
  AdvPaymentSum: Scalars['Float'];
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  AdvancePaymentSum: Scalars['Float'];
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Booking?: Maybe<BookingEntity>;
  BookingID: Scalars['String'];
  BookingRemark?: Maybe<Scalars['String']>;
  ChargeRoomTypeID: Scalars['String'];
  CheckInBy?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutBy?: Maybe<Scalars['String']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  DepositSum: Scalars['Float'];
  DiscountAmount?: Maybe<Scalars['Float']>;
  FolioCount?: Maybe<Scalars['Float']>;
  Guest?: Maybe<GuestProfileEntity>;
  GuestID?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  ID: Scalars['String'];
  IncidentalChargesCount: Scalars['Float'];
  IncidentalChargesSum: Scalars['Float'];
  IsChooseRoom?: Maybe<Scalars['Boolean']>;
  IsEmailBlasted?: Maybe<Scalars['Boolean']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  IsPreCheckIn?: Maybe<Scalars['Boolean']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  IsRoomUpgrade?: Maybe<Scalars['Boolean']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  PackageRedemption?: Maybe<Array<PackageRedemptionEntity>>;
  PassCode: Scalars['String'];
  Payment?: Maybe<Array<PaymentEntity>>;
  PaymentSum: Scalars['Float'];
  PrintRate?: Maybe<Scalars['Boolean']>;
  PromoCode?: Maybe<Scalars['String']>;
  RatePolicy?: Maybe<RateEntity>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  RegistrationSignature?: Maybe<GalleryEntity>;
  RegistrationStatus?: Maybe<BookingStatus>;
  Remark?: Maybe<Scalars['String']>;
  Room?: Maybe<RoomEntity>;
  RoomCharges?: Maybe<Array<RoomLedgerEntity>>;
  RoomID?: Maybe<Scalars['String']>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  RoomOutStanding: Scalars['Float'];
  RoomRate?: Maybe<Scalars['Float']>;
  RoomRateDisplay: Scalars['Float'];
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID: Scalars['String'];
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
  ServiceRequestCount: Scalars['Float'];
  SharerGuest?: Maybe<Array<Scalars['String']>>;
  SharerNo?: Maybe<Scalars['Float']>;
  SharerRegistration?: Maybe<Array<RegistrationEntity>>;
  Signature?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  TotalOutStandingAmount: Scalars['Float'];
  TotalPackagePrice: Scalars['Float'];
  TotalPrice: Scalars['Float'];
  TotalRoomPrice: Scalars['Float'];
  TotalRoomTax: Scalars['Float'];
};

export type RegistrationInput = {
  ArrivalDate?: InputMaybe<Scalars['DateTime']>;
  AvailabilityID?: InputMaybe<Scalars['String']>;
  BookedRoom?: InputMaybe<Scalars['Float']>;
  BookingID?: InputMaybe<Scalars['String']>;
  CheckInBy?: InputMaybe<Scalars['String']>;
  CheckInDate?: InputMaybe<Scalars['DateTime']>;
  CheckOutBy?: InputMaybe<Scalars['String']>;
  CheckOutDate?: InputMaybe<Scalars['DateTime']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DepartureDate?: InputMaybe<Scalars['DateTime']>;
  DiscountAmount?: InputMaybe<Scalars['Float']>;
  GuestID?: InputMaybe<Scalars['String']>;
  HouseLimit?: InputMaybe<Scalars['Float']>;
  ID?: InputMaybe<Scalars['String']>;
  IsChooseRoom?: InputMaybe<Scalars['Boolean']>;
  IsPDPA?: InputMaybe<Scalars['Boolean']>;
  IsPrincipal?: InputMaybe<Scalars['Boolean']>;
  IsRoomUpgrade?: InputMaybe<Scalars['Boolean']>;
  IsTTx?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  NoOfAdult?: InputMaybe<Scalars['Float']>;
  NoOfChild?: InputMaybe<Scalars['Float']>;
  NoOfInfant?: InputMaybe<Scalars['Float']>;
  NoOfRoom?: InputMaybe<Scalars['Float']>;
  Price?: InputMaybe<Scalars['Float']>;
  PrintRate?: InputMaybe<Scalars['Boolean']>;
  PromoCode?: InputMaybe<Scalars['String']>;
  RatePolicyID?: InputMaybe<Scalars['String']>;
  RegistrationStatus?: InputMaybe<BookingStatus>;
  RoomID?: InputMaybe<Scalars['String']>;
  RoomRate?: InputMaybe<Scalars['Float']>;
  RoomTypeID: Scalars['String'];
  SharerNo?: InputMaybe<Scalars['Float']>;
  Signature?: InputMaybe<Scalars['String']>;
  SpecialRequest?: InputMaybe<Scalars['String']>;
  TTxReasonID?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
  contactInput?: InputMaybe<Array<ContactInput>>;
};

export type RegistrationResult = {
  __typename?: 'RegistrationResult';
  AdvancePayment?: Maybe<AdvancePaymentEntity>;
  BillLedger?: Maybe<BillLedgerEntity>;
  BillSchedule?: Maybe<BillScheduleEntity>;
  Booking?: Maybe<BookingEntity>;
  BookingID?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
  GuestProfile?: Maybe<GuestProfileEntity>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Room?: Maybe<RoomEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export enum Religion {
  Buddhism = 'Buddhism',
  Christianity = 'Christianity',
  Hinduism = 'Hinduism',
  Islam = 'Islam',
  Others = 'Others',
  Taoism = 'Taoism'
}

export type ReminderEntity = InterfaceTrx & {
  __typename?: 'ReminderEntity';
  AccountID: Scalars['String'];
  BalanceAmt: Scalars['Float'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  LedgerID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ReminderDate: Scalars['DateTime'];
  ReminderNo: Scalars['String'];
  ReminderSeq: Scalars['Float'];
};

export type ReminderEntityInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  BalanceAmt: Scalars['Float'];
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  LedgerID: Scalars['String'];
  ReminderDate: Scalars['DateTime'];
  ReminderNo: Scalars['String'];
  ReminderSeq: Scalars['Float'];
};

export type RevenueAnalysis = {
  __typename?: 'RevenueAnalysis';
  Arr?: Maybe<Scalars['Float']>;
  AvailableRoom?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  CompUse?: Maybe<Scalars['Float']>;
  DayUse?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  DueIn?: Maybe<Scalars['Float']>;
  DueOut?: Maybe<Scalars['Float']>;
  ExpOcc?: Maybe<Scalars['Float']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  NoOfPax?: Maybe<Scalars['Float']>;
  OCC?: Maybe<Scalars['Float']>;
  OCCRoom?: Maybe<Scalars['Float']>;
  OOI?: Maybe<Scalars['Float']>;
  OOO?: Maybe<Scalars['Float']>;
  OtherRevenue?: Maybe<Scalars['Float']>;
  RoomRevenue?: Maybe<Scalars['Float']>;
};

export type RevenueGuestLedgerAcctInput = {
  Acct?: InputMaybe<Scalars['String']>;
  Dept?: InputMaybe<Scalars['String']>;
  Div?: InputMaybe<Scalars['String']>;
  Type?: InputMaybe<Scalars['String']>;
};

export type RevenueInput = {
  Description?: InputMaybe<Scalars['String']>;
  DescriptionType?: InputMaybe<Scalars['String']>;
  GuestLedgerAcct?: InputMaybe<Scalars['JSON']>;
  ID?: InputMaybe<Scalars['String']>;
  Q3FAcct?: InputMaybe<Q3RevenueAcctJsonInput>;
};

export type RevenueMappingInput = {
  AdvancePayment?: InputMaybe<Array<RevenueInput>>;
  Charges?: InputMaybe<Array<RevenueInput>>;
  DebtorType?: InputMaybe<Array<RevenueInput>>;
  Deposit?: InputMaybe<Array<RevenueInput>>;
  OfficialReceipt?: InputMaybe<Array<RevenueInput>>;
  Payment?: InputMaybe<Array<RevenueInput>>;
  Refund?: InputMaybe<Array<RevenueInput>>;
  RefundDebtor?: InputMaybe<Array<RevenueInput>>;
  Tax?: InputMaybe<Array<RevenueTaxInput>>;
};

export type RevenueTaxInput = {
  Description?: InputMaybe<Scalars['String']>;
  DescriptionType?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  Q3FAcct?: InputMaybe<Q3RevenueAcctJsonInput>;
  TaxID?: InputMaybe<Scalars['String']>;
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  ID: Scalars['String'];
  MenuOption?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  rolePerm?: Maybe<Array<RolePermAsgEntity>>;
};

export type RoleInput = {
  ID?: InputMaybe<Scalars['String']>;
  commonStatus?: InputMaybe<CommonStatus>;
  createdBy?: InputMaybe<Scalars['String']>;
  menuOption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type RolePermAsgEntity = AuditEntity & {
  __typename?: 'RolePermAsgEntity';
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  permName?: Maybe<Scalars['String']>;
  permissionID: Scalars['Float'];
  roleID: Scalars['String'];
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoomDiscrepancyLogEntity = InterfaceTrx & {
  __typename?: 'RoomDiscrepancyLogEntity';
  AttendedBy: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DiscrepancyReason: DiscrepancyReason;
  Hotel?: Maybe<HotelEntity>;
  HotelDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  HouseKeepingStatus?: Maybe<RoomStatusEntity>;
  HouseKeepingStatusID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsSolved?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Remarks?: Maybe<Scalars['String']>;
  Room?: Maybe<RoomEntity>;
  RoomID: Scalars['String'];
  RoomStatus?: Maybe<RoomStatusEntity>;
  RoomStatusID?: Maybe<Scalars['String']>;
};

export type RoomDiscrepancyLogInput = {
  AttendedBy: Scalars['String'];
  CreatedBy?: InputMaybe<Scalars['String']>;
  DiscrepancyReason: DiscrepancyReason;
  HotelDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsSolved?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Remarks?: InputMaybe<Scalars['String']>;
  RoomID: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type RoomEntity = InterfaceBase & {
  __typename?: 'RoomEntity';
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  FloorPlan?: Maybe<Array<LocationFloorPlanEntity>>;
  FloorPlanID?: Maybe<Scalars['String']>;
  HKRoomStatus: Scalars['JSON'];
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Location?: Maybe<LocationEntity>;
  LocationID: Scalars['String'];
  MaxGuest?: Maybe<Scalars['Float']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PrincipalRegistration?: Maybe<Array<RegistrationEntity>>;
  Registrations?: Maybe<Array<RegistrationEntity>>;
  RoomAssignmentStatus?: Maybe<RoomStatusEntity>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  RoomNo: Scalars['String'];
  RoomSize?: Maybe<Scalars['Float']>;
  RoomStatus?: Maybe<RoomStatusEntity>;
  RoomStatusCode: Scalars['String'];
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID: Scalars['String'];
  ServiceRequestCount: Scalars['Float'];
  TodayBookingNo?: Maybe<Array<RegistrationEntity>>;
};


export type RoomEntityRoomAssignmentStatusArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  StartDate?: InputMaybe<Scalars['DateTime']>;
};

export type RoomInfoResponse = {
  __typename?: 'RoomInfoResponse';
  BaseAmount?: Maybe<Scalars['Float']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  LedgerInfo?: Maybe<Array<LedgerInfoOutput>>;
  LedgerTax?: Maybe<Array<LedgerOutput>>;
  RateDescription?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
};

export type RoomInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  DisplaySequence?: InputMaybe<Scalars['Float']>;
  FloorPlanID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  LocationID: Scalars['String'];
  MaxGuest?: InputMaybe<Scalars['Float']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RoomNo: Scalars['String'];
  RoomSize: Scalars['Float'];
  RoomTypeID: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type RoomLedgerEntity = InterfaceTrx & {
  __typename?: 'RoomLedgerEntity';
  AccountID?: Maybe<Scalars['String']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  ChargeType: ChargeType;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Folio?: Maybe<FolioEntity>;
  FolioID?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  LedgerID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  PaymentID?: Maybe<Scalars['String']>;
  RateID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  RefRoomLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  ReversedRoomLedgerID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TaxRoomLedger?: Maybe<Array<RoomLedgerTaxEntity>>;
  Transaction?: Maybe<IncidentalChargeEntity>;
  TransactionID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionType>;
  TrxAmount?: Maybe<Scalars['Float']>;
  TrxDate: Scalars['DateTime'];
  TrxDescription?: Maybe<Scalars['String']>;
};

export type RoomLedgerInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  BaseAmount?: InputMaybe<Scalars['Float']>;
  ChargeType: ChargeType;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  FolioID: Scalars['String'];
  HotelID?: InputMaybe<Scalars['String']>;
  ID: Scalars['String'];
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RateID?: InputMaybe<Scalars['String']>;
  Reason: Scalars['String'];
  RefRoomLedgerID?: InputMaybe<Scalars['String']>;
  ReferenceNo?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReversedRoomLedgerID?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Float']>;
  TaxAmount?: InputMaybe<Scalars['Float']>;
  TransactionID?: InputMaybe<Scalars['String']>;
  TransactionType: TransactionType;
  TrxAmount?: InputMaybe<Scalars['Float']>;
  TrxDate: Scalars['DateTime'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type RoomLedgerTaxEntity = InterfaceTrx & {
  __typename?: 'RoomLedgerTaxEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RoomLedger?: Maybe<RoomLedgerEntity>;
  RoomLedgerID: Scalars['String'];
  TaxAmount: Scalars['Float'];
  TaxID: Scalars['String'];
  TaxInfo?: Maybe<TaxEntity>;
  TaxRate: Scalars['Float'];
  TaxSchemeDetailID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
};

export type RoomSaleOptimizationOutput = {
  __typename?: 'RoomSaleOptimizationOutput';
  Availability?: Maybe<Array<AvailabilityEntity>>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export enum RoomStatus {
  OccupiedClean = 'Occupied_Clean',
  OccupiedDirty = 'Occupied_Dirty',
  OutOfInventory = 'Out_of_Inventory',
  OutOfOrder = 'Out_of_Order',
  VacantClean = 'Vacant_Clean',
  VacantDirty = 'Vacant_Dirty',
  VacantInspection = 'Vacant_Inspection',
  VacantReady = 'Vacant_Ready'
}

export type RoomStatusEntity = InterfaceBase & {
  __typename?: 'RoomStatusEntity';
  CleanOrDirty?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description: RoomStatus;
  Hotel?: Maybe<HotelEntity>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  RGBColor?: Maybe<Scalars['String']>;
  VacantOrOccupied?: Maybe<Scalars['String']>;
};

export type RoomStatusInput = {
  CleanOrDirty: Scalars['String'];
  Code: Scalars['String'];
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description: RoomStatus;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RGBColor?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
  VacantOrOccupied: Scalars['String'];
};

export type RoomStatusMovementEntity = InterfaceTrx & {
  __typename?: 'RoomStatusMovementEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  Location: Scalars['String'];
  LocationID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Name: Scalars['String'];
  NewStatus: Scalars['String'];
  NewStatusID: Scalars['String'];
  OldStatus: Scalars['String'];
  OldStatusID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomNo: Scalars['String'];
  RoomType: Scalars['String'];
  RoomTypeID: Scalars['String'];
};

export type RoomStatusMovementInput = {
  HotelDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  Location?: InputMaybe<Scalars['String']>;
  LocationID: Scalars['String'];
  Name?: InputMaybe<Scalars['String']>;
  NewStatus?: InputMaybe<Scalars['String']>;
  NewStatusID?: InputMaybe<Scalars['String']>;
  OldStatus?: InputMaybe<Scalars['String']>;
  OldStatusID?: InputMaybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RoomNo?: InputMaybe<Scalars['String']>;
  RoomType?: InputMaybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
};

export type RoomTransferLogEntity = InterfaceTrx & {
  __typename?: 'RoomTransferLogEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  FromRoomID: Scalars['String'];
  FromRoomType?: Maybe<Scalars['String']>;
  FromRoomTypeID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  Room?: Maybe<RoomEntity>;
  RoomID: Scalars['String'];
  RoomType?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Upgrade?: Maybe<Scalars['String']>;
};

export type RoomTransferLogInput = {
  FromRoomID: Scalars['String'];
  FromRoomType?: InputMaybe<Scalars['String']>;
  FromRoomTypeID?: InputMaybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomType?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
  Upgrade?: InputMaybe<Scalars['String']>;
};

export type RoomTypeAmenitiesEntity = InterfaceBase & {
  __typename?: 'RoomTypeAmenitiesEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsSelected: Scalars['Boolean'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name: Scalars['String'];
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID: Scalars['String'];
};

export type RoomTypeAmenitiesInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  GalleryID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  IsSelected?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Name: Scalars['String'];
  RoomTypeID?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type RoomTypeEntity = InterfaceBase & {
  __typename?: 'RoomTypeEntity';
  Availability?: Maybe<Array<AvailabilityEntity>>;
  Code: Scalars['String'];
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  HouseLimit?: Maybe<Scalars['Float']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  MeasureType?: Maybe<MeasureType>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Overbooking: Scalars['Float'];
  Price?: Maybe<Array<PricingEntity>>;
  PropertyType?: Maybe<PropertyType>;
  Room?: Maybe<Array<RoomEntity>>;
  RoomSize?: Maybe<Scalars['Float']>;
  RoomTypeAmenities?: Maybe<Array<RoomTypeAmenitiesEntity>>;
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
  RoomTypeGallery?: Maybe<Array<RoomTypeGalleryEntity>>;
  TotalPackages?: Maybe<Array<TotalPackagesResponse>>;
  TotalRoom?: Maybe<Scalars['Float']>;
};

export type RoomTypeGalleryEntity = InterfaceBase & {
  __typename?: 'RoomTypeGalleryEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID: Scalars['String'];
};

export type RoomTypeGalleryInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type RoomTypeInput = {
  Code: Scalars['String'];
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  DisplaySequence?: InputMaybe<Scalars['Float']>;
  HotelID: Scalars['String'];
  HouseLimit?: InputMaybe<Scalars['Float']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  MaxGuest?: InputMaybe<Scalars['Float']>;
  MeasureType?: InputMaybe<MeasureType>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Overbooking: Scalars['Float'];
  PropertyType?: InputMaybe<PropertyType>;
  RoomSize?: InputMaybe<Scalars['Float']>;
  TotalRoom?: InputMaybe<Scalars['Float']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type RoomTypesListOutput = {
  __typename?: 'RoomTypesListOutput';
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Overbooking: Scalars['Float'];
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
  TotalRoom?: Maybe<Scalars['Float']>;
};

export type RoomUpgradeInput = {
  BookingID?: InputMaybe<Scalars['String']>;
  FreeUpgrade?: InputMaybe<Scalars['Boolean']>;
  HotelID?: InputMaybe<Scalars['String']>;
  OldRoomID?: InputMaybe<Scalars['String']>;
  OldRoomTypeID?: InputMaybe<Scalars['String']>;
  PromoCode?: InputMaybe<Scalars['String']>;
  RatePolicyID?: InputMaybe<Scalars['String']>;
  Reason?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  RegistrationIDs?: InputMaybe<Array<Scalars['String']>>;
  Remark?: InputMaybe<Scalars['String']>;
  RoomID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
};

export type SalesChannelEntity = InterfaceBase & {
  __typename?: 'SalesChannelEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Department?: Maybe<DepartmentEntity>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type SalesChannelInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type SchBillingInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  BillAmount?: InputMaybe<Scalars['Float']>;
  BillDate?: InputMaybe<Scalars['DateTime']>;
  BillNo?: InputMaybe<Scalars['String']>;
  CardNo?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  IsGLPosted?: InputMaybe<Scalars['Boolean']>;
  IsPosted?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
};

export type ScheduleBillingEntity = {
  __typename?: 'ScheduleBillingEntity';
  AccountID: Scalars['String'];
  BillAmount: Scalars['Float'];
  BillDate: Scalars['DateTime'];
  BillNo: Scalars['String'];
  CardNo: Scalars['String'];
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  IsGLPosted: Scalars['Boolean'];
  IsPosted: Scalars['Boolean'];
};

export type ScheduleBillingInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  BookingID?: InputMaybe<Scalars['String']>;
  BookingNo?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  FolioAmount: Scalars['Float'];
  FolioID?: InputMaybe<Scalars['String']>;
  GroupReferenceNo?: InputMaybe<Scalars['String']>;
  InvoiceDate?: InputMaybe<Scalars['DateTime']>;
  PaymentID?: InputMaybe<Scalars['String']>;
  SourceTable?: InputMaybe<Scalars['String']>;
};

export type ScheduleBillingResponse = {
  __typename?: 'ScheduleBillingResponse';
  BookingID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  GuestEmail?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  PaymentID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
};

export type ScheduleBillingResponse2 = {
  __typename?: 'ScheduleBillingResponse2';
  BookingID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  GuestEmail?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  PaymentID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
};

export type SearchGuestResponse = {
  __typename?: 'SearchGuestResponse';
  Address?: Maybe<Address>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  BookingID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Email?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
};

export type SeasonCalendarEntity = InterfaceBase & {
  __typename?: 'SeasonCalendarEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EffectiveDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Season?: Maybe<SeasonEntity>;
  SeasonID?: Maybe<Scalars['String']>;
  SeasonType: SeasonType;
};

export type SeasonCalendarInput = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  SeasonID?: InputMaybe<Scalars['String']>;
  SeasonType?: InputMaybe<SeasonType>;
};

export type SeasonEntity = InterfaceBase & {
  __typename?: 'SeasonEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  SeasonalRate?: Maybe<Array<SeasonalRateEntity>>;
};


export type SeasonEntitySeasonalRateArgs = {
  RateEffectiveID?: InputMaybe<Scalars['String']>;
};

export type SeasonInput = {
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  Name?: InputMaybe<Scalars['String']>;
  RGBColor?: InputMaybe<Scalars['String']>;
};

export type SeasonRateInput = {
  DynamicRate: Array<DynamicRateJsonInput>;
  StandardRate: Scalars['Float'];
};

export enum SeasonType {
  NonPeak = 'NonPeak',
  Peak = 'Peak',
  SuperPeak = 'SuperPeak'
}

export type SeasonalRateEntity = InterfaceBase & {
  __typename?: 'SeasonalRateEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DynamicRate: Array<DynamicRateJson>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  RateEffective?: Maybe<RateEffectiveEntity>;
  RateEffectiveID: Scalars['String'];
  RoomType?: Maybe<RoomTypeEntity>;
  RoomTypeID: Scalars['String'];
  Season?: Maybe<SeasonEntity>;
  SeasonID: Scalars['String'];
  StandardRate: Scalars['Float'];
};

export type SeasonalRateInput = {
  RoomTypeID: Scalars['String'];
  SeasonID: Scalars['String'];
  SeasonRate: SeasonRateInput;
};

export type SegmentEntity = InterfaceBase & {
  __typename?: 'SegmentEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Q3MarketSegmentMapping?: Maybe<Q3MarketSegmentMapping>;
  StayViewIcon?: Maybe<Scalars['String']>;
};

export type SegmentInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  DisplaySequence?: InputMaybe<Scalars['Float']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Q3MarketSegmentMapping?: InputMaybe<Q3MarketSegmentMappingInput>;
  StayViewIcon?: InputMaybe<Scalars['String']>;
};

export type SegmentReportOutput = {
  __typename?: 'SegmentReportOutput';
  SegmentDARR?: Maybe<Scalars['Float']>;
  SegmentDGuestCount?: Maybe<Scalars['Float']>;
  SegmentDOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentDRoomCount?: Maybe<Scalars['Float']>;
  SegmentDRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentID?: Maybe<Scalars['String']>;
  SegmentMARR?: Maybe<Scalars['Float']>;
  SegmentMGuestCount?: Maybe<Scalars['Float']>;
  SegmentMOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentMRoomCount?: Maybe<Scalars['Float']>;
  SegmentMRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentName?: Maybe<Scalars['String']>;
  SegmentYARR?: Maybe<Scalars['Float']>;
  SegmentYGuestCount?: Maybe<Scalars['Float']>;
  SegmentYOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentYRoomCount?: Maybe<Scalars['Float']>;
  SegmentYRoomRevenue?: Maybe<Scalars['Float']>;
};

export type ServiceRequestEntity = InterfaceTrx & {
  __typename?: 'ServiceRequestEntity';
  AccountID: Scalars['String'];
  ClosedDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Department?: Maybe<DepartmentEntity>;
  DepartmentID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  EstimatedCompletion: Scalars['DateTime'];
  Gallery?: Maybe<GalleryEntity>;
  GalleryID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  RequestBy: Scalars['String'];
  Room?: Maybe<RoomEntity>;
  RoomID: Scalars['String'];
  ServiceRequestGallery?: Maybe<Array<ServiceRequestGalleryEntity>>;
  Status: ServiceRequestStatus;
  Title?: Maybe<Scalars['String']>;
};

export type ServiceRequestGalleryEntity = InterfaceBase & {
  __typename?: 'ServiceRequestGalleryEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ServiceRequest?: Maybe<ServiceRequestEntity>;
  ServiceRequestID: Scalars['String'];
};

export type ServiceRequestInput = {
  AccountID: Scalars['String'];
  CreatedBy?: InputMaybe<Scalars['String']>;
  DepartmentID?: InputMaybe<Scalars['String']>;
  Description: Scalars['String'];
  EstimatedCompletion?: InputMaybe<Scalars['DateTime']>;
  GalleryID?: InputMaybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  Remarks?: InputMaybe<Scalars['String']>;
  RequestBy: Scalars['String'];
  RoomID: Scalars['String'];
  Status: ServiceRequestStatus;
  Title?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export enum ServiceRequestStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  InProgress = 'InProgress',
  Open = 'Open'
}

export type SharedGalleryEntity = InterfaceBase & {
  __typename?: 'SharedGalleryEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  GalleryID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type SharedGalleryInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  HotelID: Scalars['String'];
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
};

export enum Source {
  Alliance = 'Alliance',
  Booking = 'Booking',
  ChannelManager = 'Channel_Manager',
  Corporate = 'Corporate',
  Email = 'Email',
  Government = 'Government',
  Kiosk = 'Kiosk',
  Membership = 'Membership',
  Ota = 'OTA',
  Online = 'Online',
  Telephone = 'Telephone',
  TravelAgent = 'Travel_Agent',
  WalkIn = 'Walk_In',
  WhatsApp = 'WhatsApp'
}

export type SourceReportOutput = {
  __typename?: 'SourceReportOutput';
  SourceDARR?: Maybe<Scalars['Float']>;
  SourceDGuestCount?: Maybe<Scalars['Float']>;
  SourceDOtherRevenue?: Maybe<Scalars['Float']>;
  SourceDRoomCount?: Maybe<Scalars['Float']>;
  SourceDRoomRevenue?: Maybe<Scalars['Float']>;
  SourceMARR?: Maybe<Scalars['Float']>;
  SourceMGuestCount?: Maybe<Scalars['Float']>;
  SourceMOtherRevenue?: Maybe<Scalars['Float']>;
  SourceMRoomCount?: Maybe<Scalars['Float']>;
  SourceMRoomRevenue?: Maybe<Scalars['Float']>;
  SourceName?: Maybe<Scalars['String']>;
  SourceYARR?: Maybe<Scalars['Float']>;
  SourceYGuestCount?: Maybe<Scalars['Float']>;
  SourceYOtherRevenue?: Maybe<Scalars['Float']>;
  SourceYRoomCount?: Maybe<Scalars['Float']>;
  SourceYRoomRevenue?: Maybe<Scalars['Float']>;
};

export type SpecialRequestInput = {
  ID?: InputMaybe<Scalars['String']>;
  SpecialRequest?: InputMaybe<Scalars['String']>;
};

export type StaahBookingRecordEntity = InterfaceBase & {
  __typename?: 'StaahBookingRecordEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ReservationPayload?: Maybe<Scalars['String']>;
};

export type StaahBookingRecordInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ProcessCount?: InputMaybe<Scalars['Float']>;
  Processed?: InputMaybe<Scalars['Boolean']>;
  ReservationPayload?: InputMaybe<Scalars['String']>;
};

export type StandardAuditEntity = {
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export enum State {
  Johor = 'Johor',
  Kedah = 'Kedah',
  Kelantan = 'Kelantan',
  KualaLumpur = 'Kuala_Lumpur',
  Labuan = 'Labuan',
  Malacca = 'Malacca',
  NegeriSembilan = 'Negeri_Sembilan',
  Pahang = 'Pahang',
  Penang = 'Penang',
  Perak = 'Perak',
  Perlis = 'Perlis',
  Putrajaya = 'Putrajaya',
  Sabah = 'Sabah',
  Sarawak = 'Sarawak',
  Selangor = 'Selangor',
  Terengganu = 'Terengganu'
}

export type Subscription = {
  __typename?: 'Subscription';
  HotelNotify: Array<HotelNotificationEntity>;
  NewNotification: NotificationEntity;
  RoomAssignmentNotify: Array<RoomEntity>;
};


export type SubscriptionHotelNotifyArgs = {
  HotelID?: InputMaybe<Scalars['String']>;
  NotificationType?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};


export type SubscriptionNewNotificationArgs = {
  HotelID: Scalars['String'];
  registrationToken: Scalars['String'];
};


export type SubscriptionRoomAssignmentNotifyArgs = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  IsBooking?: InputMaybe<Scalars['Boolean']>;
  RoomID?: InputMaybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
};

export type SubscriptionEntity = InterfaceTrx & {
  __typename?: 'SubscriptionEntity';
  AccountID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Gallery?: Maybe<GalleryEntity>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  LoyaltyAppGroupName: Scalars['String'];
  LoyaltyAppLogo: Scalars['String'];
  LoyaltyAppURL: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
};

export type SuspendFolioResponse = {
  __typename?: 'SuspendFolioResponse';
  Listing?: Maybe<Array<FolioEntity>>;
  TotalCount?: Maybe<Scalars['Float']>;
};

export type TtLockLogEntity = InterfaceTrx & {
  __typename?: 'TTLockLogEntity';
  AccountID: Scalars['String'];
  ActionType: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  LogData: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  RoomNo: Scalars['String'];
};

export type TaxDetailInput = {
  effectiveDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  rate: Scalars['Float'];
  taxId?: InputMaybe<Scalars['String']>;
};

export type TaxEffectiveDateEntity = InterfaceBase & {
  __typename?: 'TaxEffectiveDateEntity';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EffectiveDate: Scalars['DateTime'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
  PlatformID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
};

export type TaxEffectiveDateInput = {
  CreatedBy?: InputMaybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  ID: Scalars['String'];
  IsFixAmount?: InputMaybe<Scalars['Boolean']>;
  IsTaxable?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  PlatformID: Scalars['String'];
  UserID?: InputMaybe<Scalars['String']>;
  Value?: InputMaybe<Scalars['Float']>;
};

export type TaxGlEntity = InterfaceTrx & {
  __typename?: 'TaxGLEntity';
  AccountID: Scalars['String'];
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  Hotel?: Maybe<HotelEntity>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  SchemeTypeID: Scalars['String'];
  TaxID: Scalars['String'];
  tax?: Maybe<TaxEntity>;
  taxSchemeType?: Maybe<TaxSchemeTypeEntity>;
};

export type TaxGlInput = {
  Description?: InputMaybe<Scalars['String']>;
  DescriptionType?: InputMaybe<Scalars['String']>;
  GL?: InputMaybe<GuestLedgerAcctJsonInput>;
  ID?: InputMaybe<Scalars['String']>;
  TaxID?: InputMaybe<Scalars['String']>;
};

export enum TaxIdentifier {
  Incidental = 'Incidental',
  Room = 'Room'
}

export type TaxInput = {
  DiscountAmount?: InputMaybe<Scalars['Float']>;
  EffectiveDate?: InputMaybe<Scalars['DateTime']>;
  HotelID?: InputMaybe<Scalars['String']>;
  IncidentalChargeID?: InputMaybe<Scalars['String']>;
  ServiceCharge?: InputMaybe<Scalars['Boolean']>;
  Tax?: InputMaybe<Scalars['Boolean']>;
  TaxIdentifier: TaxIdentifier;
  TrxAmount?: InputMaybe<Scalars['Float']>;
};

export type TaxLedgerEntity = InterfaceTrx & {
  __typename?: 'TaxLedgerEntity';
  AccountID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Folio?: Maybe<FolioEntity>;
  FolioID?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsAdjustment?: Maybe<Scalars['Boolean']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  PaymentID?: Maybe<Scalars['String']>;
  Qty?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  RefTaxLedgerID?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ReversedTaxLedgerID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
};

export type TaxLedgerInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  Description?: InputMaybe<Scalars['String']>;
  FolioID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsAdjustment?: InputMaybe<Scalars['Boolean']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  PaymentID?: InputMaybe<Scalars['String']>;
  Qty?: InputMaybe<Scalars['Float']>;
  Reason: Scalars['String'];
  RefTaxLedgerID?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  ReversedTaxLedgerID?: InputMaybe<Scalars['String']>;
  TaxAmount?: InputMaybe<Scalars['Float']>;
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
  UserID?: InputMaybe<Scalars['String']>;
};

export type TaxListingOutput = {
  __typename?: 'TaxListingOutput';
  class_type: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  effective_date: Scalars['DateTime'];
  rate: Scalars['String'];
  rn?: Maybe<Scalars['String']>;
  scheme_type_id?: Maybe<Scalars['String']>;
  tax_id: Scalars['String'];
  tax_status: Scalars['String'];
  tax_type?: Maybe<Scalars['String']>;
};

export type TaxOnSeq = {
  __typename?: 'TaxOnSeq';
  seqNo: Scalars['Float'];
  taxSchemeDetailItemId: Scalars['String'];
};

export type TaxOutput = {
  __typename?: 'TaxOutput';
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  HotelTaxOutput?: Maybe<Array<HotelTaxOutput>>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type TaxPolicyInput = {
  classType: Scalars['String'];
  code: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  modBy?: InputMaybe<Scalars['String']>;
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetailInput>;
};

export type TaxReportOutput = {
  __typename?: 'TaxReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export type TaxSchemeDetailInput = {
  detailId?: InputMaybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  taxSchemeDetailItem: Array<TaxSchemeDetailItemInput>;
};

export type TaxSchemeDetailItemInput = {
  classType: Scalars['String'];
  computeMethod: ComputeMethod;
  detailId?: InputMaybe<Scalars['String']>;
  detailItemId?: InputMaybe<Scalars['String']>;
  seqNo?: InputMaybe<Scalars['Float']>;
  taxId?: InputMaybe<Scalars['String']>;
  taxOnSeq?: InputMaybe<Array<TaxOnSeqInput>>;
};

export type TaxSchemeDetailItemRateOutput = {
  __typename?: 'TaxSchemeDetailItemRateOutput';
  computeMethod: Scalars['String'];
  isExclusive: Scalars['Boolean'];
  seqNo: Scalars['String'];
  taxId: Scalars['String'];
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  taxRate: Scalars['Float'];
  taxSchemeDetailId: Scalars['String'];
};

export type TaxSchemeDetailOutput = {
  __typename?: 'TaxSchemeDetailOutput';
  taxCode: Scalars['String'];
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  taxSeqNo: Scalars['String'];
  taxType: Scalars['String'];
};

export type TaxSchemeInput = {
  createdBy: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  isExclusive?: InputMaybe<Scalars['Boolean']>;
  modBy: Scalars['String'];
  name: Scalars['String'];
  taxSchemeDetail?: InputMaybe<Array<TaxSchemeDetailInput>>;
};

export enum TaxType {
  GovTax = 'GovTax',
  HeritageTax = 'HeritageTax',
  Levy = 'Levy',
  ServiceCharge = 'ServiceCharge',
  StateFund = 'StateFund',
  TourismTax = 'TourismTax'
}

export type TestCountry = {
  __typename?: 'TestCountry';
  con: Country;
};

export type TestState = {
  __typename?: 'TestState';
  con: State;
};

export type TmrGroupOutput = {
  __typename?: 'TmrGroupOutput';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  GroupName?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type TotalPackagesResponse = {
  __typename?: 'TotalPackagesResponse';
  PackageName?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  PriceID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  dataURL?: Maybe<Scalars['String']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  otpURL?: Maybe<Scalars['String']>;
  totpSecret?: Maybe<Scalars['String']>;
};

export type TransactionTransferLogEntity = InterfaceTrx & {
  __typename?: 'TransactionTransferLogEntity';
  AccountID: Scalars['String'];
  BaseAmount?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  FolioType?: Maybe<Scalars['String']>;
  FromFolioID?: Maybe<Scalars['String']>;
  FromFolioType?: Maybe<Scalars['String']>;
  FromGuestID?: Maybe<Scalars['String']>;
  FromRegistrationID?: Maybe<Scalars['String']>;
  FromRoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RegistrationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  TrxDate: Scalars['DateTime'];
  TrxType?: Maybe<Scalars['String']>;
};

export type TransactionTransferLogInput = {
  AccountID: Scalars['String'];
  BaseAmount: Scalars['Float'];
  CreatedBy?: InputMaybe<Scalars['String']>;
  CreatedDT: Scalars['DateTime'];
  Description: Scalars['String'];
  FolioID: Scalars['String'];
  FolioType: Scalars['String'];
  FromFolioID: Scalars['String'];
  FromFolioType: Scalars['String'];
  FromGuestID: Scalars['String'];
  FromRegistrationID: Scalars['String'];
  FromRoomID: Scalars['String'];
  GuestID: Scalars['String'];
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IsActive: Scalars['Boolean'];
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RegistrationID?: InputMaybe<Scalars['String']>;
  RoomID: Scalars['String'];
  ServiceCharge: Scalars['Float'];
  SourceID: Scalars['String'];
  SourceTable: Scalars['String'];
  TaxAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  TrxType: Scalars['String'];
};

export enum TransactionType {
  Package = 'Package',
  Rate = 'Rate',
  TourismTax = 'TourismTax'
}

export type TransferAxLogEntity = InterfaceTrx & {
  __typename?: 'TransferAXLogEntity';
  AccountID: Scalars['String'];
  ActionType: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  LogData: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
};

export type UpdateAuditInput = {
  modBy?: InputMaybe<Scalars['String']>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  ID: Scalars['String'];
  accountID: Scalars['String'];
  address?: Maybe<Address>;
  avatar?: Maybe<Scalars['String']>;
  blockCount: Scalars['Float'];
  blockDuration: Scalars['Float'];
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  contactNo: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  department?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  loginAttempt: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  superUser: Scalars['Boolean'];
  userName?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<Scalars['String']>;
  commonStatus?: InputMaybe<CommonStatus>;
  contactNo: Scalars['String'];
  department?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  hashed?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  superUser?: InputMaybe<Scalars['Boolean']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type UserOnlineEntity = InterfaceBase & {
  __typename?: 'UserOnlineEntity';
  AccountID: Scalars['String'];
  CommonStatus: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IPAddress?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  LastLogin: Scalars['DateTime'];
  LastLogout?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
  SoftwareCode?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
};

export type UserOnlineInput = {
  AccountID?: InputMaybe<Scalars['String']>;
  CommonStatus?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  IPAddress?: InputMaybe<Scalars['String']>;
  LastLogin?: InputMaybe<Scalars['DateTime']>;
  LastLogout?: InputMaybe<Scalars['DateTime']>;
  SocketID?: InputMaybe<Scalars['String']>;
  SoftwareCode?: InputMaybe<Scalars['String']>;
  UserID?: InputMaybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  ID: Scalars['String'];
  accountID: Scalars['String'];
  address?: Maybe<Address>;
  avatar?: Maybe<Scalars['String']>;
  blockCount?: Maybe<Scalars['Float']>;
  blockDuration?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  contactNo: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  department?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  errors?: Maybe<Array<FieldError>>;
  is2FA?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  userName: Scalars['String'];
};

export type VipInHouseOutput = {
  __typename?: 'VIPInHouseOutput';
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type VendFunBookingRecordEntity = InterfaceBase & {
  __typename?: 'VendFunBookingRecordEntity';
  BookingID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ReservationPayload?: Maybe<Scalars['String']>;
};

export type VendFunBookingRecordInput = {
  BookingID?: InputMaybe<Scalars['String']>;
  CreatedBy?: InputMaybe<Scalars['String']>;
  DebtorAccount?: InputMaybe<Scalars['String']>;
  DebtorID?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ProcessCount?: InputMaybe<Scalars['Float']>;
  Processed?: InputMaybe<Scalars['Boolean']>;
  ReservationPayload?: InputMaybe<Scalars['String']>;
};

export type WhRates = {
  Date?: InputMaybe<Scalars['DateTime']>;
  Rate?: InputMaybe<Scalars['Float']>;
  roomID?: InputMaybe<Scalars['String']>;
};

export type XPossibleLogEntity = InterfaceTrx & {
  __typename?: 'XPossibleLogEntity';
  ActionType: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  LogData: Scalars['String'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  RoomNo: Scalars['String'];
};

export type CompanyEntity = {
  __typename?: 'companyEntity';
  Hotel?: Maybe<Array<HotelEntity>>;
  accountId: Scalars['String'];
  address?: Maybe<Address>;
  baseCurrencyId: Scalars['String'];
  code: Scalars['String'];
  companyRegNo?: Maybe<Scalars['String']>;
  companyTax: Scalars['String'];
  contactNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  groupGstRegNo?: Maybe<Scalars['String']>;
  gstExpiryDate?: Maybe<Scalars['DateTime']>;
  gstNo?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  imageLogoPosition: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isEInvIntegrated: Scalars['Boolean'];
  isoImplementation: Scalars['Boolean'];
  isoLogoPosition: Scalars['String'];
  name: Scalars['String'];
  officeTel?: Maybe<Scalars['String']>;
  parentCompanyId?: Maybe<Scalars['String']>;
  recordStatus: Scalars['String'];
  regionId?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  ttxNo?: Maybe<Scalars['String']>;
  watermarkImplementation: Scalars['Boolean'];
  watermarkWording: Scalars['String'];
  websiteUrl?: Maybe<Scalars['String']>;
};

export type CurrencyEntity = {
  __typename?: 'currencyEntity';
  code: Scalars['String'];
  common_status: Scalars['String'];
  format: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
};

export type CurrentTaxOutput = {
  __typename?: 'currentTaxOutput';
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  TaxCode?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
};

export type Data = {
  __typename?: 'data';
  DocumentNo?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  osAmt?: Maybe<Scalars['String']>;
};

export type DocResponse = {
  __typename?: 'docResponse';
  CnDoc?: Maybe<Array<CreditNoteEntity>>;
  DnDoc?: Maybe<Array<DebitNoteEntity>>;
  Invoice?: Maybe<Array<Test>>;
  OrDoc?: Maybe<Array<OfficialReceiptEntity>>;
  RefundDoc?: Maybe<Array<RefundEntity>>;
};

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  categoryReference?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'item';
  checkoutId?: Maybe<Scalars['String']>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ReservationInput = {
  bookingId?: InputMaybe<Scalars['String']>;
  checkIn?: InputMaybe<Scalars['DateTime']>;
  checkOut?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  deposit?: InputMaybe<Scalars['Float']>;
  guestAddress?: InputMaybe<Scalars['String']>;
  guestCity?: InputMaybe<Scalars['String']>;
  guestCountry?: InputMaybe<Scalars['String']>;
  guestEmail?: InputMaybe<Scalars['String']>;
  guestID?: InputMaybe<Scalars['String']>;
  guestName?: InputMaybe<Scalars['String']>;
  guestNationality?: InputMaybe<Scalars['String']>;
  guestState?: InputMaybe<Scalars['String']>;
  guestTelNo?: InputMaybe<Scalars['String']>;
  nights?: InputMaybe<Scalars['Float']>;
  noOfAdult?: InputMaybe<Scalars['Float']>;
  noOfChildren?: InputMaybe<Scalars['Float']>;
  roomNo?: InputMaybe<Scalars['String']>;
  roomTypeCode?: InputMaybe<Scalars['String']>;
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type TaxDetailEntity = {
  __typename?: 'taxDetailEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  id: Scalars['String'];
  rate: Scalars['Float'];
  tax: TaxEntity;
  taxId: Scalars['String'];
};

export type TaxEntity = {
  __typename?: 'taxEntity';
  accountId: Scalars['String'];
  classType: Scalars['String'];
  code: Scalars['String'];
  commonStatus: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeType: TaxSchemeTypeEntity;
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetailEntity>;
  taxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
};

export type TaxOnSeqInput = {
  seqNo: Scalars['Float'];
  taxSchemeDetailItemId: Scalars['String'];
};

/** JsonType */
export type TaxOnSeqObjects = {
  __typename?: 'taxOnSeqObjects';
  seqNo: Scalars['Float'];
  taxSchemeDetailItemId: Scalars['String'];
};

export type TaxSchemeDetailEntity = {
  __typename?: 'taxSchemeDetailEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  id: Scalars['String'];
  taxScheme: TaxSchemeEntity;
  taxSchemeDetailItems?: Maybe<Array<TaxSchemeDetailItemEntity>>;
  taxSchemeId: Scalars['String'];
};

export type TaxSchemeDetailItemEntity = {
  __typename?: 'taxSchemeDetailItemEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  computeMethod: Scalars['String'];
  id: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  tax: TaxEntity;
  taxId: Scalars['String'];
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  taxSchemeDetail: TaxSchemeDetailEntity;
  taxSchemeDetailId: Scalars['String'];
};

export type TaxSchemeEntity = {
  __typename?: 'taxSchemeEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isExclusive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  taxSchemeDetails?: Maybe<Array<TaxSchemeDetailEntity>>;
};

export type TaxSchemeTypeEntity = {
  __typename?: 'taxSchemeTypeEntity';
  code: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  softwareCode: Scalars['String'];
  tax?: Maybe<Array<TaxEntity>>;
};

export type Test = InterfaceTrx & {
  __typename?: 'test';
  AccountID: Scalars['String'];
  Allocation: Array<AllocationEntity>;
  Amount: Scalars['Float'];
  Attachment?: Maybe<Scalars['String']>;
  Booking?: Maybe<Scalars['JSON']>;
  BookingNo?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  DebtorID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  DocumentNo: Scalars['String'];
  GroupDocumentNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  LedgerTrx: Array<InvoiceTrxEntity>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  OutstandingAmount: Scalars['Float'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  Status: ArTransactionStatus;
  VoidDate?: Maybe<Scalars['DateTime']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type PreRegBookingInfoQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID?: InputMaybe<Scalars['String']>;
  QR?: InputMaybe<Scalars['String']>;
}>;


export type PreRegBookingInfoQuery = { __typename?: 'Query', PreRegBookingInfo?: any | null };

export type PreRegRegistrationDetailsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type PreRegRegistrationDetailsQuery = { __typename?: 'Query', PreRegRegistrationDetails: { __typename?: 'RegistrationEntity', ID: string, ArrivalDate?: any | null, DepartureDate?: any | null, RoomTypeID: string, Booking?: { __typename?: 'BookingEntity', ID: string, HotelID: string } | null, Guest?: { __typename?: 'GuestProfileEntity', ID: string, Contact?: { __typename?: 'ContactEntity', FullName?: string | null, NRIC?: string | null, PassportNo?: string | null } | null } | null } };

export type BillSummaryDetailsQueryVariables = Exact<{
  BillSummaryInput: BillSummaryInput;
}>;


export type BillSummaryDetailsQuery = { __typename?: 'Query', BillSummaryDetails: any };

export type PreRegRoomAllocationQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  RoomTypeID: Scalars['String'];
}>;


export type PreRegRoomAllocationQuery = { __typename?: 'Query', PreRegRoomAllocation: Array<any> };

export type IsRoomExistQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  RoomTypeID: Scalars['String'];
}>;


export type IsRoomExistQuery = { __typename?: 'Query', IsRoomExist: boolean };

export type RoomAssignListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate?: InputMaybe<Scalars['DateTime']>;
  IsBooking?: InputMaybe<Scalars['Boolean']>;
  RoomID?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  RegistrationIDs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type RoomAssignListingQuery = { __typename?: 'Query', RoomAssignListing: Array<{ __typename?: 'RoomEntity', ID: string, RoomNo: string, RoomTypeID: string, FloorPlanID?: string | null, IsActive?: boolean | null, MaxGuest?: number | null, Description?: string | null, RoomSize?: number | null, FloorPlan?: Array<{ __typename?: 'LocationFloorPlanEntity', ID: string, Coordinates: string, IsHotel?: boolean | null, IsPreCheckin?: boolean | null }> | null, Registrations?: Array<{ __typename?: 'RegistrationEntity', ID: string }> | null, Location?: { __typename?: 'LocationEntity', ID: string, Code: string, Description?: string | null, GalleryID?: string | null, Gallery?: { __typename?: 'GalleryEntity', ID: string, ImageURL?: string | null } | null } | null, RoomAssignmentStatus?: { __typename?: 'RoomStatusEntity', CleanOrDirty?: string | null, Code?: string | null, Description: RoomStatus, ID: string, RGBColor?: string | null, VacantOrOccupied?: string | null } | null }> };

export type PreRegPaymentInsertMutationVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  Amount: Scalars['String'];
  RoomID?: InputMaybe<Scalars['String']>;
  RoomTypeID?: InputMaybe<Scalars['String']>;
}>;


export type PreRegPaymentInsertMutation = { __typename?: 'Mutation', PreRegPaymentInsert: any };

export type PreRegBookingQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type PreRegBookingQuery = { __typename?: 'Query', PreRegBooking?: any | null };

export type ExpressCheckinConfirmationMutationVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  EditConfirmationInput?: InputMaybe<Array<EditConfirmationInput> | EditConfirmationInput>;
  AddConfirmationInput?: InputMaybe<Array<AddConfirmationInput> | AddConfirmationInput>;
}>;


export type ExpressCheckinConfirmationMutation = { __typename?: 'Mutation', ExpressCheckinConfirmation: boolean };

export type CheckInInsertV2MutationVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  EditInformationInput?: InputMaybe<Array<EditInformationInput> | EditInformationInput>;
  AddInformationInput?: InputMaybe<Array<AddInformationInput> | AddInformationInput>;
}>;


export type CheckInInsertV2Mutation = { __typename?: 'Mutation', CheckInInsertV2: boolean };

export type PreCheckInInsertMutationVariables = Exact<{
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  IcPhotoFront?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  IcPhotoBack?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  Signature: Array<Scalars['Upload']> | Scalars['Upload'];
  PreCheckInInput: PreCheckInInput;
}>;


export type PreCheckInInsertMutation = { __typename?: 'Mutation', PreCheckInInsert: boolean };

export type HotelDetailsQueryVariables = Exact<{
  HotelID?: InputMaybe<Scalars['String']>;
  OnlineBookingURL?: InputMaybe<Scalars['String']>;
}>;


export type HotelDetailsQuery = { __typename?: 'Query', HotelDetails: { __typename?: 'HotelEntity', ID: string, OnlineTnC?: string | null, RegCardTnC?: string | null, OnlineBookingURL?: string | null, AboutHotelGalleryID?: string | null, AboutHotel?: string | null, Encoders?: any | null, RegulationClause?: string | null, PDPA?: string | null, GuestNotice?: boolean | null, Logo?: string | null, HotelName?: string | null, NAProcessTime?: any | null, SSTRegNo?: string | null, TTxRegNo?: string | null, LogoImage?: { __typename?: 'GalleryEntity', ID: string, ImageURL?: string | null, BucketFileName?: string | null } | null, HotelAddress?: { __typename?: 'Address', address?: string | null, postCode?: string | null, state?: string | null, city?: string | null, country?: string | null } | null, Contact?: { __typename?: 'ContactEntity', FullName?: string | null, PhoneNo?: string | null, MobileNo?: string | null, GeneralEmail?: string | null, ReservationEmail?: string | null, BusinessEmail?: string | null, WebUrl?: string | null, NotificationMobileNo?: string | null, FaxNo?: string | null } | null, HotelGallery?: Array<{ __typename?: 'HotelGalleryEntity', ID: string, HotelID: string, IsDefault?: boolean | null, GalleryID: string, Gallery?: { __typename?: 'GalleryEntity', ID: string, ImageURL?: string | null, BucketFileName?: string | null } | null }> | null } };

export type AdvancePaymentDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type AdvancePaymentDetailsQuery = { __typename?: 'Query', AdvancePaymentDetails: { __typename?: 'AdvancePaymentEntity', ID: string, ReceiptNo?: string | null, ReferenceNo?: string | null, PaymentDate: any, PaymentType?: PaymentType | null, Amount?: number | null, CreatedBy?: string | null, PaymentStatus?: PaymentStatus | null, PaymentClass?: PaymentClass | null, DebtorAccount?: string | null, Folio?: { __typename?: 'FolioEntity', ID: string, FolioDate?: any | null, FolioNo?: string | null, BeneficiaryName?: string | null, BeneficiaryAddress?: string | null } | null, Booking?: { __typename?: 'BookingEntity', BookingNo: string, IsGroupBooking?: boolean | null, GroupName?: string | null, Registration?: Array<{ __typename?: 'RegistrationEntity', ID: string, ArrivalDate?: any | null, DepartureDate?: any | null, IsPrincipal?: boolean | null, Room?: { __typename?: 'RoomEntity', RoomNo: string } | null }> | null } | null } };

export type OnlinePaymentMutationVariables = Exact<{
  redirectUrl: Scalars['String'];
  PayAmount: Scalars['Float'];
  Detail: Scalars['String'];
  Title: Scalars['String'];
  HotelID: Scalars['String'];
  ReceiptNo: Scalars['String'];
  AdvancePaymentID?: InputMaybe<Scalars['String']>;
}>;


export type OnlinePaymentMutation = { __typename?: 'Mutation', OnlinePayment?: { __typename?: 'CheckOut', code?: string | null, PaySessionNo?: number | null, item?: { __typename?: 'item', checkoutId?: string | null, url?: string | null, qrCodeUrl?: string | null, status?: string | null } | null, error?: { __typename?: 'Errors', code?: string | null, message?: string | null, debug?: string | null } | null } | null };

export type UpdateRoomAssignmentMutationVariables = Exact<{
  RoomTypeID?: InputMaybe<Scalars['String']>;
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  RoomID?: InputMaybe<Scalars['String']>;
  IsCompleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateRoomAssignmentMutation = { __typename?: 'Mutation', UpdateRoomAssignment: boolean };

export type GuestAppFeatureQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type GuestAppFeatureQuery = { __typename?: 'Query', GuestAppFeature: { __typename?: 'GuestAppFeatureEntity', HotelID: string, SelfCheckin?: boolean | null, PreCheckinAdvTime?: number | null, Chargeable?: boolean | null, OnlinePayment?: boolean | null } };

export type ShowBookingConfirmationEmailQueryVariables = Exact<{
  BookingID: Scalars['String'];
}>;


export type ShowBookingConfirmationEmailQuery = { __typename?: 'Query', ShowBookingConfirmationEmail: string };

export type ImageUploadMutationVariables = Exact<{
  AttachmentInput: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type ImageUploadMutation = { __typename?: 'Mutation', ImageUpload: { __typename?: 'GalleryEntity', ImageURL?: string | null } };

export type AdvancePaymentUpdateMutationVariables = Exact<{
  CheckOutID?: InputMaybe<Scalars['String']>;
  OrderID?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<Scalars['String']>;
  HotelID?: InputMaybe<Scalars['String']>;
  ReceiptNo?: InputMaybe<Scalars['String']>;
  RegistrationID?: InputMaybe<Scalars['String']>;
  AppName?: InputMaybe<Scalars['String']>;
}>;


export type AdvancePaymentUpdateMutation = { __typename?: 'Mutation', AdvancePaymentUpdate: boolean };

export type PreCheckInQrQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type PreCheckInQrQuery = { __typename?: 'Query', PreCheckInQr: any };

export type EmailSendMutationVariables = Exact<{
  Body: EmailBodyInput;
  HotelID: Scalars['String'];
  Email?: InputMaybe<Scalars['String']>;
  BookingID?: InputMaybe<Scalars['String']>;
  isGuestEmail?: InputMaybe<Scalars['Boolean']>;
  Attachments?: InputMaybe<EmailAttachmentInput>;
}>;


export type EmailSendMutation = { __typename?: 'Mutation', EmailSend: boolean };

export type IsBookingExistQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingNo: Scalars['String'];
}>;


export type IsBookingExistQuery = { __typename?: 'Query', IsBookingExist: any };


export const PreRegBookingInfoDocument = gql`
    query PreRegBookingInfo($BookingID: String!, $HotelID: String!, $RegistrationID: String, $QR: String) {
  PreRegBookingInfo(
    BookingID: $BookingID
    HotelID: $HotelID
    RegistrationID: $RegistrationID
    QR: $QR
  )
}
    `;

/**
 * __usePreRegBookingInfoQuery__
 *
 * To run a query within a React component, call `usePreRegBookingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreRegBookingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreRegBookingInfoQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *      QR: // value for 'QR'
 *   },
 * });
 */
export function usePreRegBookingInfoQuery(baseOptions: Apollo.QueryHookOptions<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>(PreRegBookingInfoDocument, options);
      }
export function usePreRegBookingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>(PreRegBookingInfoDocument, options);
        }
export type PreRegBookingInfoQueryHookResult = ReturnType<typeof usePreRegBookingInfoQuery>;
export type PreRegBookingInfoLazyQueryHookResult = ReturnType<typeof usePreRegBookingInfoLazyQuery>;
export type PreRegBookingInfoQueryResult = Apollo.QueryResult<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>;
export const PreRegRegistrationDetailsDocument = gql`
    query PreRegRegistrationDetails($HotelID: String!, $BookingID: String!, $RegistrationID: String!) {
  PreRegRegistrationDetails(
    HotelID: $HotelID
    BookingID: $BookingID
    RegistrationID: $RegistrationID
  ) {
    ID
    ArrivalDate
    DepartureDate
    RoomTypeID
    Booking {
      ID
      HotelID
    }
    Guest {
      ID
      Contact {
        FullName
        NRIC
        PassportNo
      }
    }
  }
}
    `;

/**
 * __usePreRegRegistrationDetailsQuery__
 *
 * To run a query within a React component, call `usePreRegRegistrationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreRegRegistrationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreRegRegistrationDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePreRegRegistrationDetailsQuery(baseOptions: Apollo.QueryHookOptions<PreRegRegistrationDetailsQuery, PreRegRegistrationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreRegRegistrationDetailsQuery, PreRegRegistrationDetailsQueryVariables>(PreRegRegistrationDetailsDocument, options);
      }
export function usePreRegRegistrationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreRegRegistrationDetailsQuery, PreRegRegistrationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreRegRegistrationDetailsQuery, PreRegRegistrationDetailsQueryVariables>(PreRegRegistrationDetailsDocument, options);
        }
export type PreRegRegistrationDetailsQueryHookResult = ReturnType<typeof usePreRegRegistrationDetailsQuery>;
export type PreRegRegistrationDetailsLazyQueryHookResult = ReturnType<typeof usePreRegRegistrationDetailsLazyQuery>;
export type PreRegRegistrationDetailsQueryResult = Apollo.QueryResult<PreRegRegistrationDetailsQuery, PreRegRegistrationDetailsQueryVariables>;
export const BillSummaryDetailsDocument = gql`
    query BillSummaryDetails($BillSummaryInput: BillSummaryInput!) {
  BillSummaryDetails(BillSummaryInput: $BillSummaryInput)
}
    `;

/**
 * __useBillSummaryDetailsQuery__
 *
 * To run a query within a React component, call `useBillSummaryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillSummaryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillSummaryDetailsQuery({
 *   variables: {
 *      BillSummaryInput: // value for 'BillSummaryInput'
 *   },
 * });
 */
export function useBillSummaryDetailsQuery(baseOptions: Apollo.QueryHookOptions<BillSummaryDetailsQuery, BillSummaryDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillSummaryDetailsQuery, BillSummaryDetailsQueryVariables>(BillSummaryDetailsDocument, options);
      }
export function useBillSummaryDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillSummaryDetailsQuery, BillSummaryDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillSummaryDetailsQuery, BillSummaryDetailsQueryVariables>(BillSummaryDetailsDocument, options);
        }
export type BillSummaryDetailsQueryHookResult = ReturnType<typeof useBillSummaryDetailsQuery>;
export type BillSummaryDetailsLazyQueryHookResult = ReturnType<typeof useBillSummaryDetailsLazyQuery>;
export type BillSummaryDetailsQueryResult = Apollo.QueryResult<BillSummaryDetailsQuery, BillSummaryDetailsQueryVariables>;
export const PreRegRoomAllocationDocument = gql`
    query PreRegRoomAllocation($HotelID: String!, $ArrivalDate: DateTime!, $RoomTypeID: String!) {
  PreRegRoomAllocation(
    HotelID: $HotelID
    ArrivalDate: $ArrivalDate
    RoomTypeID: $RoomTypeID
  )
}
    `;

/**
 * __usePreRegRoomAllocationQuery__
 *
 * To run a query within a React component, call `usePreRegRoomAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreRegRoomAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreRegRoomAllocationQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      RoomTypeID: // value for 'RoomTypeID'
 *   },
 * });
 */
export function usePreRegRoomAllocationQuery(baseOptions: Apollo.QueryHookOptions<PreRegRoomAllocationQuery, PreRegRoomAllocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreRegRoomAllocationQuery, PreRegRoomAllocationQueryVariables>(PreRegRoomAllocationDocument, options);
      }
export function usePreRegRoomAllocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreRegRoomAllocationQuery, PreRegRoomAllocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreRegRoomAllocationQuery, PreRegRoomAllocationQueryVariables>(PreRegRoomAllocationDocument, options);
        }
export type PreRegRoomAllocationQueryHookResult = ReturnType<typeof usePreRegRoomAllocationQuery>;
export type PreRegRoomAllocationLazyQueryHookResult = ReturnType<typeof usePreRegRoomAllocationLazyQuery>;
export type PreRegRoomAllocationQueryResult = Apollo.QueryResult<PreRegRoomAllocationQuery, PreRegRoomAllocationQueryVariables>;
export const IsRoomExistDocument = gql`
    query IsRoomExist($HotelID: String!, $ArrivalDate: DateTime!, $RoomTypeID: String!) {
  IsRoomExist(
    HotelID: $HotelID
    ArrivalDate: $ArrivalDate
    RoomTypeID: $RoomTypeID
  )
}
    `;

/**
 * __useIsRoomExistQuery__
 *
 * To run a query within a React component, call `useIsRoomExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRoomExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsRoomExistQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      RoomTypeID: // value for 'RoomTypeID'
 *   },
 * });
 */
export function useIsRoomExistQuery(baseOptions: Apollo.QueryHookOptions<IsRoomExistQuery, IsRoomExistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsRoomExistQuery, IsRoomExistQueryVariables>(IsRoomExistDocument, options);
      }
export function useIsRoomExistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsRoomExistQuery, IsRoomExistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsRoomExistQuery, IsRoomExistQueryVariables>(IsRoomExistDocument, options);
        }
export type IsRoomExistQueryHookResult = ReturnType<typeof useIsRoomExistQuery>;
export type IsRoomExistLazyQueryHookResult = ReturnType<typeof useIsRoomExistLazyQuery>;
export type IsRoomExistQueryResult = Apollo.QueryResult<IsRoomExistQuery, IsRoomExistQueryVariables>;
export const RoomAssignListingDocument = gql`
    query RoomAssignListing($HotelID: String!, $RoomTypeID: String!, $StartDate: DateTime!, $EndDate: DateTime, $IsBooking: Boolean, $RoomID: [String!], $RegistrationIDs: [String!]) {
  RoomAssignListing(
    HotelID: $HotelID
    RoomTypeID: $RoomTypeID
    StartDate: $StartDate
    EndDate: $EndDate
    IsBooking: $IsBooking
    RoomID: $RoomID
    RegistrationIDs: $RegistrationIDs
  ) {
    ID
    RoomNo
    RoomTypeID
    FloorPlanID
    FloorPlan {
      ID
      Coordinates
      IsHotel
      IsPreCheckin
    }
    IsActive
    MaxGuest
    Description
    RoomSize
    Registrations {
      ID
    }
    Location {
      ID
      Code
      Description
      GalleryID
      Gallery {
        ID
        ImageURL
      }
    }
    RoomAssignmentStatus(StartDate: $StartDate, EndDate: $EndDate) {
      CleanOrDirty
      Code
      Description
      ID
      RGBColor
      VacantOrOccupied
    }
  }
}
    `;

/**
 * __useRoomAssignListingQuery__
 *
 * To run a query within a React component, call `useRoomAssignListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomAssignListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomAssignListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      IsBooking: // value for 'IsBooking'
 *      RoomID: // value for 'RoomID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *   },
 * });
 */
export function useRoomAssignListingQuery(baseOptions: Apollo.QueryHookOptions<RoomAssignListingQuery, RoomAssignListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoomAssignListingQuery, RoomAssignListingQueryVariables>(RoomAssignListingDocument, options);
      }
export function useRoomAssignListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomAssignListingQuery, RoomAssignListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoomAssignListingQuery, RoomAssignListingQueryVariables>(RoomAssignListingDocument, options);
        }
export type RoomAssignListingQueryHookResult = ReturnType<typeof useRoomAssignListingQuery>;
export type RoomAssignListingLazyQueryHookResult = ReturnType<typeof useRoomAssignListingLazyQuery>;
export type RoomAssignListingQueryResult = Apollo.QueryResult<RoomAssignListingQuery, RoomAssignListingQueryVariables>;
export const PreRegPaymentInsertDocument = gql`
    mutation PreRegPaymentInsert($BookingID: String!, $HotelID: String!, $RegistrationID: String!, $Amount: String!, $RoomID: String, $RoomTypeID: String) {
  PreRegPaymentInsert(
    BookingID: $BookingID
    HotelID: $HotelID
    RegistrationID: $RegistrationID
    Amount: $Amount
    RoomID: $RoomID
    RoomTypeID: $RoomTypeID
  )
}
    `;
export type PreRegPaymentInsertMutationFn = Apollo.MutationFunction<PreRegPaymentInsertMutation, PreRegPaymentInsertMutationVariables>;

/**
 * __usePreRegPaymentInsertMutation__
 *
 * To run a mutation, you first call `usePreRegPaymentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreRegPaymentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preRegPaymentInsertMutation, { data, loading, error }] = usePreRegPaymentInsertMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *      Amount: // value for 'Amount'
 *      RoomID: // value for 'RoomID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *   },
 * });
 */
export function usePreRegPaymentInsertMutation(baseOptions?: Apollo.MutationHookOptions<PreRegPaymentInsertMutation, PreRegPaymentInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreRegPaymentInsertMutation, PreRegPaymentInsertMutationVariables>(PreRegPaymentInsertDocument, options);
      }
export type PreRegPaymentInsertMutationHookResult = ReturnType<typeof usePreRegPaymentInsertMutation>;
export type PreRegPaymentInsertMutationResult = Apollo.MutationResult<PreRegPaymentInsertMutation>;
export type PreRegPaymentInsertMutationOptions = Apollo.BaseMutationOptions<PreRegPaymentInsertMutation, PreRegPaymentInsertMutationVariables>;
export const PreRegBookingDocument = gql`
    query PreRegBooking($BookingID: String!, $HotelID: String!) {
  PreRegBooking(BookingID: $BookingID, HotelID: $HotelID)
}
    `;

/**
 * __usePreRegBookingQuery__
 *
 * To run a query within a React component, call `usePreRegBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreRegBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreRegBookingQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function usePreRegBookingQuery(baseOptions: Apollo.QueryHookOptions<PreRegBookingQuery, PreRegBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreRegBookingQuery, PreRegBookingQueryVariables>(PreRegBookingDocument, options);
      }
export function usePreRegBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreRegBookingQuery, PreRegBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreRegBookingQuery, PreRegBookingQueryVariables>(PreRegBookingDocument, options);
        }
export type PreRegBookingQueryHookResult = ReturnType<typeof usePreRegBookingQuery>;
export type PreRegBookingLazyQueryHookResult = ReturnType<typeof usePreRegBookingLazyQuery>;
export type PreRegBookingQueryResult = Apollo.QueryResult<PreRegBookingQuery, PreRegBookingQueryVariables>;
export const ExpressCheckinConfirmationDocument = gql`
    mutation ExpressCheckinConfirmation($BookingID: String!, $HotelID: String!, $EditConfirmationInput: [EditConfirmationInput!], $AddConfirmationInput: [AddConfirmationInput!]) {
  ExpressCheckinConfirmation(
    BookingID: $BookingID
    HotelID: $HotelID
    EditConfirmationInput: $EditConfirmationInput
    AddConfirmationInput: $AddConfirmationInput
  )
}
    `;
export type ExpressCheckinConfirmationMutationFn = Apollo.MutationFunction<ExpressCheckinConfirmationMutation, ExpressCheckinConfirmationMutationVariables>;

/**
 * __useExpressCheckinConfirmationMutation__
 *
 * To run a mutation, you first call `useExpressCheckinConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpressCheckinConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expressCheckinConfirmationMutation, { data, loading, error }] = useExpressCheckinConfirmationMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      EditConfirmationInput: // value for 'EditConfirmationInput'
 *      AddConfirmationInput: // value for 'AddConfirmationInput'
 *   },
 * });
 */
export function useExpressCheckinConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<ExpressCheckinConfirmationMutation, ExpressCheckinConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExpressCheckinConfirmationMutation, ExpressCheckinConfirmationMutationVariables>(ExpressCheckinConfirmationDocument, options);
      }
export type ExpressCheckinConfirmationMutationHookResult = ReturnType<typeof useExpressCheckinConfirmationMutation>;
export type ExpressCheckinConfirmationMutationResult = Apollo.MutationResult<ExpressCheckinConfirmationMutation>;
export type ExpressCheckinConfirmationMutationOptions = Apollo.BaseMutationOptions<ExpressCheckinConfirmationMutation, ExpressCheckinConfirmationMutationVariables>;
export const CheckInInsertV2Document = gql`
    mutation CheckInInsertV2($BookingID: String!, $HotelID: String!, $EditInformationInput: [EditInformationInput!], $AddInformationInput: [AddInformationInput!]) {
  CheckInInsertV2(
    BookingID: $BookingID
    HotelID: $HotelID
    EditInformationInput: $EditInformationInput
    AddInformationInput: $AddInformationInput
  )
}
    `;
export type CheckInInsertV2MutationFn = Apollo.MutationFunction<CheckInInsertV2Mutation, CheckInInsertV2MutationVariables>;

/**
 * __useCheckInInsertV2Mutation__
 *
 * To run a mutation, you first call `useCheckInInsertV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInInsertV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInInsertV2Mutation, { data, loading, error }] = useCheckInInsertV2Mutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      EditInformationInput: // value for 'EditInformationInput'
 *      AddInformationInput: // value for 'AddInformationInput'
 *   },
 * });
 */
export function useCheckInInsertV2Mutation(baseOptions?: Apollo.MutationHookOptions<CheckInInsertV2Mutation, CheckInInsertV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInInsertV2Mutation, CheckInInsertV2MutationVariables>(CheckInInsertV2Document, options);
      }
export type CheckInInsertV2MutationHookResult = ReturnType<typeof useCheckInInsertV2Mutation>;
export type CheckInInsertV2MutationResult = Apollo.MutationResult<CheckInInsertV2Mutation>;
export type CheckInInsertV2MutationOptions = Apollo.BaseMutationOptions<CheckInInsertV2Mutation, CheckInInsertV2MutationVariables>;
export const PreCheckInInsertDocument = gql`
    mutation PreCheckInInsert($BookingID: String!, $RegistrationID: String!, $IcPhotoFront: [Upload!], $IcPhotoBack: [Upload!], $Signature: [Upload!]!, $PreCheckInInput: PreCheckInInput!) {
  PreCheckInInsert(
    BookingID: $BookingID
    RegistrationID: $RegistrationID
    IcPhotoFront: $IcPhotoFront
    IcPhotoBack: $IcPhotoBack
    Signature: $Signature
    PreCheckInInput: $PreCheckInInput
  )
}
    `;
export type PreCheckInInsertMutationFn = Apollo.MutationFunction<PreCheckInInsertMutation, PreCheckInInsertMutationVariables>;

/**
 * __usePreCheckInInsertMutation__
 *
 * To run a mutation, you first call `usePreCheckInInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreCheckInInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preCheckInInsertMutation, { data, loading, error }] = usePreCheckInInsertMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      IcPhotoFront: // value for 'IcPhotoFront'
 *      IcPhotoBack: // value for 'IcPhotoBack'
 *      Signature: // value for 'Signature'
 *      PreCheckInInput: // value for 'PreCheckInInput'
 *   },
 * });
 */
export function usePreCheckInInsertMutation(baseOptions?: Apollo.MutationHookOptions<PreCheckInInsertMutation, PreCheckInInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreCheckInInsertMutation, PreCheckInInsertMutationVariables>(PreCheckInInsertDocument, options);
      }
export type PreCheckInInsertMutationHookResult = ReturnType<typeof usePreCheckInInsertMutation>;
export type PreCheckInInsertMutationResult = Apollo.MutationResult<PreCheckInInsertMutation>;
export type PreCheckInInsertMutationOptions = Apollo.BaseMutationOptions<PreCheckInInsertMutation, PreCheckInInsertMutationVariables>;
export const HotelDetailsDocument = gql`
    query HotelDetails($HotelID: String, $OnlineBookingURL: String) {
  HotelDetails(HotelID: $HotelID, OnlineBookingURL: $OnlineBookingURL) {
    ID
    OnlineTnC
    RegCardTnC
    OnlineBookingURL
    AboutHotelGalleryID
    AboutHotel
    Encoders
    RegulationClause
    PDPA
    GuestNotice
    Logo
    HotelName
    NAProcessTime
    LogoImage {
      ID
      ImageURL
      BucketFileName
    }
    SSTRegNo
    TTxRegNo
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    Contact {
      FullName
      PhoneNo
      MobileNo
      GeneralEmail
      ReservationEmail
      BusinessEmail
      WebUrl
      NotificationMobileNo
      FaxNo
    }
    HotelGallery {
      ID
      HotelID
      IsDefault
      GalleryID
      Gallery {
        ID
        ImageURL
        BucketFileName
      }
    }
  }
}
    `;

/**
 * __useHotelDetailsQuery__
 *
 * To run a query within a React component, call `useHotelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      OnlineBookingURL: // value for 'OnlineBookingURL'
 *   },
 * });
 */
export function useHotelDetailsQuery(baseOptions?: Apollo.QueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, options);
      }
export function useHotelDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, options);
        }
export type HotelDetailsQueryHookResult = ReturnType<typeof useHotelDetailsQuery>;
export type HotelDetailsLazyQueryHookResult = ReturnType<typeof useHotelDetailsLazyQuery>;
export type HotelDetailsQueryResult = Apollo.QueryResult<HotelDetailsQuery, HotelDetailsQueryVariables>;
export const AdvancePaymentDetailsDocument = gql`
    query AdvancePaymentDetails($ID: String!, $HotelID: String!) {
  AdvancePaymentDetails(ID: $ID, HotelID: $HotelID) {
    ID
    ReceiptNo
    ReferenceNo
    PaymentDate
    PaymentType
    Amount
    CreatedBy
    PaymentStatus
    PaymentClass
    DebtorAccount
    Folio {
      ID
      FolioDate
      FolioNo
      BeneficiaryName
      BeneficiaryAddress
    }
    Booking {
      BookingNo
      IsGroupBooking
      GroupName
      Registration {
        ID
        ArrivalDate
        DepartureDate
        IsPrincipal
        Room {
          RoomNo
        }
      }
    }
  }
}
    `;

/**
 * __useAdvancePaymentDetailsQuery__
 *
 * To run a query within a React component, call `useAdvancePaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancePaymentDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAdvancePaymentDetailsQuery(baseOptions: Apollo.QueryHookOptions<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>(AdvancePaymentDetailsDocument, options);
      }
export function useAdvancePaymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>(AdvancePaymentDetailsDocument, options);
        }
export type AdvancePaymentDetailsQueryHookResult = ReturnType<typeof useAdvancePaymentDetailsQuery>;
export type AdvancePaymentDetailsLazyQueryHookResult = ReturnType<typeof useAdvancePaymentDetailsLazyQuery>;
export type AdvancePaymentDetailsQueryResult = Apollo.QueryResult<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>;
export const OnlinePaymentDocument = gql`
    mutation OnlinePayment($redirectUrl: String!, $PayAmount: Float!, $Detail: String!, $Title: String!, $HotelID: String!, $ReceiptNo: String!, $AdvancePaymentID: String) {
  OnlinePayment(
    redirectUrl: $redirectUrl
    PayAmount: $PayAmount
    Detail: $Detail
    Title: $Title
    HotelID: $HotelID
    ReceiptNo: $ReceiptNo
    AdvancePaymentID: $AdvancePaymentID
  ) {
    item {
      checkoutId
      url
      qrCodeUrl
      status
    }
    code
    error {
      code
      message
      debug
    }
    PaySessionNo
  }
}
    `;
export type OnlinePaymentMutationFn = Apollo.MutationFunction<OnlinePaymentMutation, OnlinePaymentMutationVariables>;

/**
 * __useOnlinePaymentMutation__
 *
 * To run a mutation, you first call `useOnlinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnlinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onlinePaymentMutation, { data, loading, error }] = useOnlinePaymentMutation({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *      PayAmount: // value for 'PayAmount'
 *      Detail: // value for 'Detail'
 *      Title: // value for 'Title'
 *      HotelID: // value for 'HotelID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      AdvancePaymentID: // value for 'AdvancePaymentID'
 *   },
 * });
 */
export function useOnlinePaymentMutation(baseOptions?: Apollo.MutationHookOptions<OnlinePaymentMutation, OnlinePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnlinePaymentMutation, OnlinePaymentMutationVariables>(OnlinePaymentDocument, options);
      }
export type OnlinePaymentMutationHookResult = ReturnType<typeof useOnlinePaymentMutation>;
export type OnlinePaymentMutationResult = Apollo.MutationResult<OnlinePaymentMutation>;
export type OnlinePaymentMutationOptions = Apollo.BaseMutationOptions<OnlinePaymentMutation, OnlinePaymentMutationVariables>;
export const UpdateRoomAssignmentDocument = gql`
    mutation UpdateRoomAssignment($RoomTypeID: String, $BookingID: String!, $RegistrationID: String!, $RoomID: String, $IsCompleted: Boolean) {
  UpdateRoomAssignment(
    BookingID: $BookingID
    RoomTypeID: $RoomTypeID
    RegistrationID: $RegistrationID
    RoomID: $RoomID
    IsCompleted: $IsCompleted
  )
}
    `;
export type UpdateRoomAssignmentMutationFn = Apollo.MutationFunction<UpdateRoomAssignmentMutation, UpdateRoomAssignmentMutationVariables>;

/**
 * __useUpdateRoomAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateRoomAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomAssignmentMutation, { data, loading, error }] = useUpdateRoomAssignmentMutation({
 *   variables: {
 *      RoomTypeID: // value for 'RoomTypeID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      RoomID: // value for 'RoomID'
 *      IsCompleted: // value for 'IsCompleted'
 *   },
 * });
 */
export function useUpdateRoomAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomAssignmentMutation, UpdateRoomAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomAssignmentMutation, UpdateRoomAssignmentMutationVariables>(UpdateRoomAssignmentDocument, options);
      }
export type UpdateRoomAssignmentMutationHookResult = ReturnType<typeof useUpdateRoomAssignmentMutation>;
export type UpdateRoomAssignmentMutationResult = Apollo.MutationResult<UpdateRoomAssignmentMutation>;
export type UpdateRoomAssignmentMutationOptions = Apollo.BaseMutationOptions<UpdateRoomAssignmentMutation, UpdateRoomAssignmentMutationVariables>;
export const GuestAppFeatureDocument = gql`
    query GuestAppFeature($HotelID: String!) {
  GuestAppFeature(HotelID: $HotelID) {
    HotelID
    SelfCheckin
    PreCheckinAdvTime
    Chargeable
    OnlinePayment
  }
}
    `;

/**
 * __useGuestAppFeatureQuery__
 *
 * To run a query within a React component, call `useGuestAppFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestAppFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestAppFeatureQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGuestAppFeatureQuery(baseOptions: Apollo.QueryHookOptions<GuestAppFeatureQuery, GuestAppFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuestAppFeatureQuery, GuestAppFeatureQueryVariables>(GuestAppFeatureDocument, options);
      }
export function useGuestAppFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuestAppFeatureQuery, GuestAppFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuestAppFeatureQuery, GuestAppFeatureQueryVariables>(GuestAppFeatureDocument, options);
        }
export type GuestAppFeatureQueryHookResult = ReturnType<typeof useGuestAppFeatureQuery>;
export type GuestAppFeatureLazyQueryHookResult = ReturnType<typeof useGuestAppFeatureLazyQuery>;
export type GuestAppFeatureQueryResult = Apollo.QueryResult<GuestAppFeatureQuery, GuestAppFeatureQueryVariables>;
export const ShowBookingConfirmationEmailDocument = gql`
    query ShowBookingConfirmationEmail($BookingID: String!) {
  ShowBookingConfirmationEmail(BookingID: $BookingID)
}
    `;

/**
 * __useShowBookingConfirmationEmailQuery__
 *
 * To run a query within a React component, call `useShowBookingConfirmationEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowBookingConfirmationEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowBookingConfirmationEmailQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useShowBookingConfirmationEmailQuery(baseOptions: Apollo.QueryHookOptions<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>(ShowBookingConfirmationEmailDocument, options);
      }
export function useShowBookingConfirmationEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>(ShowBookingConfirmationEmailDocument, options);
        }
export type ShowBookingConfirmationEmailQueryHookResult = ReturnType<typeof useShowBookingConfirmationEmailQuery>;
export type ShowBookingConfirmationEmailLazyQueryHookResult = ReturnType<typeof useShowBookingConfirmationEmailLazyQuery>;
export type ShowBookingConfirmationEmailQueryResult = Apollo.QueryResult<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>;
export const ImageUploadDocument = gql`
    mutation ImageUpload($AttachmentInput: [Upload!]!) {
  ImageUpload(AttachmentInput: $AttachmentInput) {
    ImageURL
  }
}
    `;
export type ImageUploadMutationFn = Apollo.MutationFunction<ImageUploadMutation, ImageUploadMutationVariables>;

/**
 * __useImageUploadMutation__
 *
 * To run a mutation, you first call `useImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageUploadMutation, { data, loading, error }] = useImageUploadMutation({
 *   variables: {
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useImageUploadMutation(baseOptions?: Apollo.MutationHookOptions<ImageUploadMutation, ImageUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImageUploadMutation, ImageUploadMutationVariables>(ImageUploadDocument, options);
      }
export type ImageUploadMutationHookResult = ReturnType<typeof useImageUploadMutation>;
export type ImageUploadMutationResult = Apollo.MutationResult<ImageUploadMutation>;
export type ImageUploadMutationOptions = Apollo.BaseMutationOptions<ImageUploadMutation, ImageUploadMutationVariables>;
export const AdvancePaymentUpdateDocument = gql`
    mutation AdvancePaymentUpdate($CheckOutID: String, $OrderID: String, $Status: String, $HotelID: String, $ReceiptNo: String, $RegistrationID: String, $AppName: String) {
  AdvancePaymentUpdate(
    CheckOutID: $CheckOutID
    OrderID: $OrderID
    Status: $Status
    HotelID: $HotelID
    ReceiptNo: $ReceiptNo
    RegistrationID: $RegistrationID
    AppName: $AppName
  )
}
    `;
export type AdvancePaymentUpdateMutationFn = Apollo.MutationFunction<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>;

/**
 * __useAdvancePaymentUpdateMutation__
 *
 * To run a mutation, you first call `useAdvancePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advancePaymentUpdateMutation, { data, loading, error }] = useAdvancePaymentUpdateMutation({
 *   variables: {
 *      CheckOutID: // value for 'CheckOutID'
 *      OrderID: // value for 'OrderID'
 *      Status: // value for 'Status'
 *      HotelID: // value for 'HotelID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      RegistrationID: // value for 'RegistrationID'
 *      AppName: // value for 'AppName'
 *   },
 * });
 */
export function useAdvancePaymentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>(AdvancePaymentUpdateDocument, options);
      }
export type AdvancePaymentUpdateMutationHookResult = ReturnType<typeof useAdvancePaymentUpdateMutation>;
export type AdvancePaymentUpdateMutationResult = Apollo.MutationResult<AdvancePaymentUpdateMutation>;
export type AdvancePaymentUpdateMutationOptions = Apollo.BaseMutationOptions<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>;
export const PreCheckInQrDocument = gql`
    query PreCheckInQr($BookingID: String!, $HotelID: String!, $RegistrationID: String!) {
  PreCheckInQr(
    BookingID: $BookingID
    HotelID: $HotelID
    RegistrationID: $RegistrationID
  )
}
    `;

/**
 * __usePreCheckInQrQuery__
 *
 * To run a query within a React component, call `usePreCheckInQrQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreCheckInQrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreCheckInQrQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePreCheckInQrQuery(baseOptions: Apollo.QueryHookOptions<PreCheckInQrQuery, PreCheckInQrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreCheckInQrQuery, PreCheckInQrQueryVariables>(PreCheckInQrDocument, options);
      }
export function usePreCheckInQrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreCheckInQrQuery, PreCheckInQrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreCheckInQrQuery, PreCheckInQrQueryVariables>(PreCheckInQrDocument, options);
        }
export type PreCheckInQrQueryHookResult = ReturnType<typeof usePreCheckInQrQuery>;
export type PreCheckInQrLazyQueryHookResult = ReturnType<typeof usePreCheckInQrLazyQuery>;
export type PreCheckInQrQueryResult = Apollo.QueryResult<PreCheckInQrQuery, PreCheckInQrQueryVariables>;
export const EmailSendDocument = gql`
    mutation EmailSend($Body: EmailBodyInput!, $HotelID: String!, $Email: String, $BookingID: String, $isGuestEmail: Boolean, $Attachments: EmailAttachmentInput) {
  EmailSend(
    Body: $Body
    HotelID: $HotelID
    Email: $Email
    BookingID: $BookingID
    isGuestEmail: $isGuestEmail
    Attachments: $Attachments
  )
}
    `;
export type EmailSendMutationFn = Apollo.MutationFunction<EmailSendMutation, EmailSendMutationVariables>;

/**
 * __useEmailSendMutation__
 *
 * To run a mutation, you first call `useEmailSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailSendMutation, { data, loading, error }] = useEmailSendMutation({
 *   variables: {
 *      Body: // value for 'Body'
 *      HotelID: // value for 'HotelID'
 *      Email: // value for 'Email'
 *      BookingID: // value for 'BookingID'
 *      isGuestEmail: // value for 'isGuestEmail'
 *      Attachments: // value for 'Attachments'
 *   },
 * });
 */
export function useEmailSendMutation(baseOptions?: Apollo.MutationHookOptions<EmailSendMutation, EmailSendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailSendMutation, EmailSendMutationVariables>(EmailSendDocument, options);
      }
export type EmailSendMutationHookResult = ReturnType<typeof useEmailSendMutation>;
export type EmailSendMutationResult = Apollo.MutationResult<EmailSendMutation>;
export type EmailSendMutationOptions = Apollo.BaseMutationOptions<EmailSendMutation, EmailSendMutationVariables>;
export const IsBookingExistDocument = gql`
    query IsBookingExist($HotelID: String!, $BookingNo: String!) {
  IsBookingExist(HotelID: $HotelID, BookingNo: $BookingNo)
}
    `;

/**
 * __useIsBookingExistQuery__
 *
 * To run a query within a React component, call `useIsBookingExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsBookingExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsBookingExistQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingNo: // value for 'BookingNo'
 *   },
 * });
 */
export function useIsBookingExistQuery(baseOptions: Apollo.QueryHookOptions<IsBookingExistQuery, IsBookingExistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsBookingExistQuery, IsBookingExistQueryVariables>(IsBookingExistDocument, options);
      }
export function useIsBookingExistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsBookingExistQuery, IsBookingExistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsBookingExistQuery, IsBookingExistQueryVariables>(IsBookingExistDocument, options);
        }
export type IsBookingExistQueryHookResult = ReturnType<typeof useIsBookingExistQuery>;
export type IsBookingExistLazyQueryHookResult = ReturnType<typeof useIsBookingExistLazyQuery>;
export type IsBookingExistQueryResult = Apollo.QueryResult<IsBookingExistQuery, IsBookingExistQueryVariables>;