import React, { useRef, useState } from "react"
import { Nationality, Country, State, useCheckInInsertV2Mutation } from "../../generated/graphql";
import Card from "@mui/material/Card";
import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import Collapse from '@mui/material/Collapse';
import { AddCircle, PersonAdd, RemoveCircle } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from '@mui/material/Grid';
import { MuiTelInput } from "mui-tel-input";
import AddBoxIcon from '@mui/icons-material/AddBox';
import SignaturePad from 'react-signature-canvas';
import { TermsDialog } from "../DialogComponent/TermsDialog";
import { OCRhelper, b64toBlob } from '../../Components/Hooks/OCRhelper';
import { useSnackBar } from "../../Components/Hooks/useSnackBar";
import SnackBarMsg from "../../Components/SnackBar/SnackBarMsg";
import { useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import { ValidateDob } from "../../ValidateDob";
import Loading from "../../Components/Loading/Loading";

interface Props {
  state: any;
  ExpandMore: any;
  expanded: any;
  handleExpandClick: any;
  dispatch: any;
  HotelDetails: any;
  handlePlus: any;
  handleMinus: any;
  localState: any;
  URLHotelID: any;
  URLBookingID: any;
  SharerNo: any
  index: any
  IsOnlinePayment: any
  BookingStatus: boolean
  Status: any
}

export const CheckInForm = ({
  state,
  ExpandMore,
  expanded,
  handleExpandClick,
  dispatch,
  HotelDetails,
  handlePlus,
  handleMinus,
  localState,
  URLHotelID,
  URLBookingID,
  SharerNo,
  index,
  IsOnlinePayment,
  BookingStatus,
  Status,
}: Props) => {

  const dateFormat = require("dateformat");
  const [check, setCheck] = useState(false)
  const [checkPDPA, setCheckPDPA] = useState(false)
  const [openTerm, setOpenTerm] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()
  const navigate = useNavigate();


  const [
    CheckInInsertV2,
    { loading: loadingInsert }
  ] = useCheckInInsertV2Mutation({})

  let NationalityEnum: string[] = Object.values(Nationality).map((i) => {
    return i.replace(/_/g, " ");
  });
  let CountryEnum: any = Object.values(Country).map((i) => {
    return i.replace(/_/g, " ");
  });

  let StateEnum: any = Object.values(State).map(i => {
    return i.replace(/_/g, ' ')
  })

  const handleOpenTerm = (type: any) => {
    setDialogType(type)
    setOpenTerm(true);
  };

  const handleCloseTerm = () => {
    setOpenTerm(false);
  };


  let signCanvas: any = useRef(null)
  let inputFile: any = useRef(null)
  let attachmentFiles: any = [];
  const [uploadFile, setUploadFile] = useState({
    uploadFileFront: "",
    uploadFileBack: "",
  })

  console.log(state,"meoww");
  


  const [prevFile, setPrevFile] = useState({
    IcFront: "",
    IcBack: "",
  })
  const handleUploadFile = async (mode, e) => {

    const file = e.target.files[0];
    // Check if the file is not an image
    if (!file.type.startsWith('image/')) {
      snackFunc('Invalid File Format', false);
      return;
    }
    let OCRhelper2 = await OCRhelper(file);
    let ImageFile = URL.createObjectURL(file);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as any;
    const watermarkText = 'Hotelx';
    const image = new Image();
    image.src = ImageFile;
    image.onload = async () => {
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image on the canvas
      ctx.drawImage(image, 0, 0);

      // Add the watermark
      const fontSize = Math.max(canvas.width / 5, 84); // Adjust the font size based on the canvas width
      ctx.font = `bold ${fontSize}px Arial`;
      ctx.fillStyle = 'black'; // Set the watermark color to black
      ctx.textAlign = 'center'; // Center align the text
      ctx.textBaseline = 'middle'; // Center align vertically
      ctx.fillText(watermarkText, canvas.width / 2, canvas.height / 2); // Center the watermark text

      // Convert the canvas content to a data URL
      // const watermarkedImage = canvas.toDataURL();

      if (mode === "Front") {
        setUploadFile({
          ...uploadFile,
          uploadFileFront: OCRhelper2.picture,
        });
        await dispatch({
          type: 'IcPhotoFront',
          payload: OCRhelper2.Blob,
        });
        attachmentFiles.push({
          Type: "IcPhotoFront",
          ImgUrl: localState?.Guest?.ContactInfo[0]?.IcPhotoFront
        });
        setPrevFile({
          ...prevFile,
          IcFront: '',
        });
      } else if (mode === "Back") {
        setUploadFile({
          ...uploadFile,
          uploadFileBack: OCRhelper2.picture,
        });
        await dispatch({
          type: 'IcPhotoBack',
          payload: OCRhelper2.Blob,
        });
        attachmentFiles.push({
          Type: "IcPhotoBack",
          ImgUrl: localState?.Guest?.ContactInfo[0]?.IcPhotoBack
        });
        setPrevFile({
          ...prevFile,
          IcBack: '',
        });
      }
    };
  };

  const CheckInList = localState?.Guest?.filter(x => x?.SharerNo === state?.SharerNo)

  const Registration = state?.Registrations?.filter(x => x?.SharerNo === SharerNo)[0]

  const onSubmit = (SharerNo) => {
    let inputEdit: any = []
    let inputAdd: any = []

    localState?.Guest?.filter(x => x?.SharerNo === SharerNo)?.map(g => {
      g?.ContactInfo?.forEach(c => {
        const dateBirth = dateFormat(c.BirthDate, "yyyy-mm-dd")
        if (c.Mode === 'add') {
          inputAdd.push({
            RegistrationID: Registration?.ID,
            FullName: c.FullName,
            Email: c.Email,
            OldFullName: c.OldFullName,
            NRIC: c.NRIC,
            OldNRIC: c.OldNRIC,
            BirthDate: `${dateBirth}T00:00:00.000Z`,
            MobileNo: c.MobileNo,
            Address: c.Address,
            IsTerm: true,
            IsPreCheckIn: false,
            Nationality: c.Nationality,
            IcPhotoFront: c?.IcPhotoFront,
            IcPhotoBack: c?.IcPhotoBack,
            Signature: c?.blob,
            SpecialRequest: c?.SpecialRequest
          })
        } else {
          inputEdit.push({
            RegistrationID: c.Registration,
            ContactID: c.ContactID,
            FullName: c.FullName,
            Email: c.Email,
            OldFullName: c.OldFullName,
            NRIC: c.NRIC,
            OldNRIC: c.OldNRIC,
            BirthDate: `${dateBirth}T00:00:00.000Z`,
            MobileNo: c.MobileNo,
            Address: c.Address,
            Nationality: c.Nationality,
            IcPhotoFront:  c?.IcPhotoFront?.ID ? undefined : c?.IcPhotoFront,
            IcPhotoBack: c?.IcPhotoBack?.ID ? undefined : c?.IcPhotoBack,
            IsTerm: true,
            Signature: c?.blob,
            IsPreCheckIn: false,
            SpecialRequest: c?.SpecialRequest
          })
        }
      })
    })

    CheckInInsertV2({
      variables: {
        BookingID: URLBookingID,
        HotelID: URLHotelID,
        EditInformationInput: inputEdit,
        AddInformationInput: inputAdd,
      }
    }).then((r: any) => {
      console.log('sini 2', r)
      if (r.data.CheckInInsertV2 === true) {
        navigate(`/pre-checkin/form/room`, { state: { 
          BookingID: URLBookingID, 
          HotelID: URLHotelID, 
          FullName: Registration?.Guest?.Contact?.FullName,
          RegistrationID: Registration?.ID,
          IsOnlinePayment: IsOnlinePayment
        }})
      }
    })
    .catch(error => {
      snackFunc('Pre CheckIn Fail!', false);
    })
  }


  const checkNationality = (index) => {
    const newArr = localState?.Guest
    let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index]

    return select?.Nationality
  }

  const [change, setChange]= useState(false)

  console.log(CheckInList,"ada check");

 

  return loadingInsert ? <Loading /> : (
    <>
      {CheckInList?.map((x: any, i: any) => {
        return (
          <div key={x?.RoomType}>
            <Card
              sx={{
                mb: "14px",
                borderRadius: "4px",
                boxShadow:
                  "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
              }}
            >
              <CardActions key={x.SharerNo} disableSpacing style={{ backgroundColor: "#fff4dc" }}>
                <Box width="100%">
                  <Box width="100%" display="flex">
                    <Typography fontSize="14px" fontWeight={600} color='#ff9800' align="left" >
                      {"Room " + Number(index + 1) + " - " + x.RoomType}
                    </Typography>
                  </Box>
                  <Box width="100%" display="flex">
                    <Box width="100%" display="flex">
                      <Typography fontSize="14px" fontWeight={500} color="black" align="left" style={{ paddingTop: '10px' }}>
                        {dateFormat(x.ContactInfo[0]?.ArrivalDate, "dd mmm yyyy")}  -  {dateFormat(x.ContactInfo[0]?.DepartureDate, "dd mmm yyyy")}
                      </Typography>
                    </Box>

                    <Box key={x.SharerNo} display="flex" sx={{ justifyContent: 'flex-end' }}>
                      <IconButton
                        style={{ pointerEvents: 'none' }}>
                        <PersonAdd sx={{ fontSize: 20, color: "black" }} />
                      </IconButton>

                      <div style={{ paddingLeft: '' }}>

                      </div>
                      <IconButton
                        aria-label="delete"
                        // size="small"
                        onClick={() => handleMinus(x?.SharerNo, i)}
                        sx={{ margin: 0 }}
                      >
                        <RemoveCircle sx={{
                          fontSize: 20,
                          color: x?.ContactInfo?.length === 1
                            ||
                            expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded === false
                            ? "#D3D3D3"
                            : "#f5ab35"
                        }} />
                      </IconButton>

                      <Typography fontSize="14px" fontWeight={600} color="black" marginTop={1} >
                        {x?.ContactInfo?.length}
                      </Typography>


                      <IconButton
                        aria-label="delete"
                        onClick={() => handlePlus(x.SharerNo, i)}
                        disabled={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded === false || expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.MaxGuest === x?.ContactInfo?.length}
                        sx={{ margin: 0 }}
                      >
                        <AddCircle
                          sx={{
                            fontSize: 20, color:
                              expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded === false || expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.MaxGuest === x?.ContactInfo?.length ? "#D3D3D3" : "#f5ab35"
                          }}

                        />
                      </IconButton>
                      <ExpandMore
                        expand={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded}
                        onClick={(SharerNo) => handleExpandClick(x.SharerNo)}
                        aria-expanded={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded}
                        aria-label="show more"
                        sx={{ fontSize: 20, color: "black", margin: "0" }}
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>

                    </Box>
                  </Box>
                </Box>

              </CardActions>
              <Collapse key={i} in={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded} timeout="auto" unmountOnExit>
                <CardContent>
                  {x?.ContactInfo?.map((k: any, index2: number) => {
                    return (
                      <Box key={index2}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',

                        }}
                      >
                        <Grid key={index2} container spacing={0}>
                          <Grid item xs={0.5} sx={{ pt: "12px", fontSize: "14px" }}>
                            {index2 + 1 + ". "}
                          </Grid>
                          <Grid item xs={11}>
                            <Box key={index2} sx={{ display: 'flex', mb: "10px", pt: "10px" }}>
                              <TextField
                                required
                                key={index2}
                                fullWidth
                                label={'FullName'}
                                type="text"
                                value={k?.FullName as string || ''}
                                name="FullName"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  style: {
                                    padding: "6px 0px 6px 6px",
                                    // fontSize: "12px",
                                  },
                                }
                                }
                                onChange={e => {
                                  const newArr = localState?.Guest
                                  let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                  select.FullName = e.target.value
                                  dispatch({ type: "Guest", payload: newArr })
                                }}
                              />
                            </Box>

                            <Box sx={{ display: 'flex', pb: "10px" }}>
                              <TextField
                                required
                                key={index2}
                                fullWidth
                                label={'Email'}
                                type="text"
                                value={k?.Email as string || ''}
                                name="Email"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  style: {
                                    padding: "6px 0px 6px 6px",
                                    // fontSize: "12px",
                                  },
                                }
                                }
                                onChange={e => {
                                  const newArr = localState?.Guest
                                  let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                  select.Email = e.target.value
                                  dispatch({ type: "Guest", payload: newArr })
                                }}
                              />
                            </Box>
                            <Box width="100%" sx={{ display: 'flex', pb: "10px" }} >
                              <Box width="50%" pr="8px">
                                {checkNationality(index2) === 'Malaysian' || checkNationality(index2) === null ? (
                                  <>
                                    <PatternFormat
                                      required
                                      fullWidth
                                      customInput={TextField}
                                      key={index2}
                                      label={'ID No.(NRIC)'}
                                      type="text"
                                      value={k?.NRIC}
                                      name="NRIC"
                                      format={"######-##-####"}
                                      mask="_"
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{
                                        style: {
                                          padding: "10px 0px 6px 6px",

                                        },
                                      }}
                                      onChange={e => {
                                        const newArr = localState?.Guest
                                        let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                        select.NRIC = e.target.value
                                        setChange(true)
                                        dispatch({ type: "Guest", payload: newArr })
                                      }}
                                      onBlur={() => {
                                        const newArr = localState?.Guest
                                        let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                        if (k?.NRIC !== null) {
                                          const validatedDate = ValidateDob(k?.NRIC)

                                          if (validatedDate?.getDate()) {
                                            select.BirthDate = validatedDate || null
                                            dispatch({ type: "Guest", payload: newArr })
                                          }
                                        }
                                      }}
                                    />
                                    {k?.NRIC !== null && !ValidateDob(k?.NRIC)?.getDate() && 
                                      <span 
                                        style={{ color: 'red', fontSize: '12px' }}
                                      >
                                        Invalid NRIC
                                      </span>
                                    }
                                  </>
                                ) : (
                                  <TextField
                                    required
                                    fullWidth
                                    key={index2}
                                    label={'ID No.(Passport)'}
                                    type="text"
                                    value={k?.NRIC}
                                    name="NRIC"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                      style: {
                                        padding: "10px 0px 6px 6px",

                                      },
                                    }}
                                    onChange={e => {
                                      const newArr = localState?.Guest
                                      let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                      select.NRIC = e.target.value
                                      dispatch({ type: "Guest", payload: newArr })
                                    }}
                                  />
                                )}
                              </Box>
                              <Box width="50%">
                                <TextField
                                  required
                                  fullWidth
                                  key={index2}
                                  label={'Date of Birth'}
                                  type="date"
                                  value={dateFormat(k?.BirthDate, "yyyy-mm-dd")}
                                  name="BirthDate"
                                  InputLabelProps={{ shrink: true }}
                                  size="small"
                                  style={{ fontSize: '12px' }}
                                  onChange={e => {
                                    const newArr = localState?.Guest
                                    let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                    select.BirthDate = e.target.value
                                    dispatch({ type: "Guest", payload: newArr })
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box sx={{ display: 'flex', pb: "10px" }}>
                              <MuiTelInput
                                label="Mobile No."
                                fullWidth
                                defaultCountry="MY"
                                value={k.MobileNo}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  style: {
                                    padding: "6px 0px 6px 6px",
                                    // fontSize: "12px",
                                  },
                                }}
                                onChange={e => {
                                  const newArr = localState?.Guest
                                  let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                  select.MobileNo = e
                                  dispatch({ type: "Guest", payload: newArr })
                                }}
                              />
                            </Box>
                            <Box sx={{ display: 'flex', pb: "10px" }}>
                              <TextField
                                required
                                key={index2}
                                fullWidth
                                label={'Address'}
                                type="text"
                                value={k?.Address?.address || ''}
                                name="Address"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  style: {
                                    padding: "6px 0px 6px 6px",
                                  },
                                }}
                                onChange={e => {
                                  const newArr = localState?.Guest
                                  let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                  select.Address.address = e.target.value
                                  dispatch({ type: "Guest", payload: newArr })
                                }}
                              />
                            </Box>
                            <Box width="100%" sx={{ display: 'flex', pb: "10px" }} >
                              <Box width="50%" pr="8px">
                                <TextField
                                  required
                                  key={index2}
                                  fullWidth
                                  label={'City'}
                                  type="text"
                                  value={k?.Address?.city || ''}
                                  name="City"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    style: {
                                      padding: "6px 0px 6px 6px",
                                    },
                                  }}
                                  onChange={e => {
                                    const newArr = localState?.Guest
                                    let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                    select.Address.city = e.target.value
                                    dispatch({ type: "Guest", payload: newArr })
                                  }}
                                />
                              </Box>
                              <Box width="50%">
                                <TextField
                                  required
                                  key={index2}
                                  fullWidth
                                  label={'Postcode'}
                                  type="text"
                                  value={k?.Address?.postCode || ''}
                                  name="Postcode"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    style: {
                                      padding: "6px 0px 6px 6px",
                                    },
                                  }}
                                  onChange={e => {
                                    const newArr = localState?.Guest
                                    let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                    select.Address.postCode = e.target.value
                                    dispatch({ type: "Guest", payload: newArr })
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box width="100%" sx={{ display: 'flex', pb: "10px" }}>
                              {k?.Address?.country === 'Malaysia' ? (
                                <Autocomplete
                                  fullWidth
                                  id="State"
                                  options={StateEnum}
                                  value={k?.Address?.state}
                                  getOptionLabel={(option) => option || ""}
                                  onChange={(_, data) => {
                                    const newArr = localState?.Guest
                                    let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                    select.Address.state = data
                                    dispatch({ type: "Guest", payload: newArr })
                                  }}
                                  size={"small"}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label="State"
                                      InputLabelProps={{ shrink: true }}
                                      style={{ padding: "0px 0px 0px 0px" }}
                                    />
                                  )}
                                />
                              ) : (
                                <TextField
                                  required
                                  key={index2}
                                  fullWidth
                                  label={'State'}
                                  type="text"
                                  value={k?.Address?.state || ''}
                                  name="State"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    style: {
                                      padding: "6px 0px 6px 6px",
                                    },
                                  }}
                                  onChange={e => {
                                    const newArr = localState?.Guest
                                    let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                    select.Address.state = e.target.value
                                    dispatch({ type: "Guest", payload: newArr })
                                  }}
                                />
                              )}
                              
                            </Box>
                            <Box width="100%" sx={{ display: 'flex', pb: "10px" }} >
                              <Box width="50%" pr="8px">
                                <Autocomplete
                                  fullWidth
                                  id="Nationality"
                                  options={NationalityEnum}
                                  value={k?.Nationality}
                                  getOptionLabel={(option) => option}
                                  onChange={(_, data) => {
                                    const newArr = localState?.Guest
                                    let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                    select.Nationality = data
                                    dispatch({ type: "Guest", payload: newArr })
                                  }}
                                  size={"small"}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      InputLabelProps={{ shrink: true }}
                                      style={{
                                        // fontSize: "12px", 
                                        padding: "0px 0px 0px 0px"
                                      }}
                                      label="Nationality"
                                    />
                                  )}
                                />

                              </Box>
                              <Box width="50%">
                                <Autocomplete
                                  fullWidth
                                  id="Address"
                                  options={CountryEnum}
                                  value={k?.Address?.country}
                                  getOptionLabel={(option) => option || ""}
                                  onChange={(_, data) => {
                                    const newArr = localState?.Guest
                                    let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                    select.Address.country = data
                                    dispatch({ type: "Guest", payload: newArr })
                                  }}
                                  size={"small"}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label="Country"
                                      InputLabelProps={{ shrink: true }}
                                      style={{ padding: "0px 0px 0px 0px" }}
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                            <Box sx={{ display: 'flex', pb: "10px" }}>
                              <TextField
                                key={index2}
                                fullWidth
                                label={'Special Request'}
                                type="text"
                                value={k?.SpecialRequest || ''}
                                name="SpecialRequest"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  style: {
                                    padding: "6px 0px 6px 6px",
                                  },
                                }}
                                onChange={e => {
                                  const newArr = localState?.Guest
                                  let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                  select.SpecialRequest = e.target.value
                                  dispatch({ type: "Guest", payload: newArr })
                                }}
                              />
                            </Box>
                            <Box width="100%" sx={{ display: 'flex', pb: "10px" }}>
                              <Typography fontSize="12px" fontWeight={100} color="grey">
                                Attach IC/Passport*
                              </Typography>
                            </Box>
                            <Box width="100%" sx={{ display: 'flex' }} >
                              <Box width="50%" display="flex">
                                <Typography fontSize="12px" fontWeight={100} color="grey" align="left">
                                  Front
                                </Typography>
                              </Box>
                              <Box width="50%" display="flex" >
                                <Typography fontSize="12px" fontWeight={100} color="grey">
                                  Back
                                </Typography>
                              </Box>
                            </Box>

                            <Box width="100%" sx={{ display: 'flex', pb: "10px" }}>
                              <Box width="50%" >
                                <Box
                                  display="flex"
                                  width="130px"
                                  height="100px"
                                  style={{ borderRadius: '5px' }}
                                  component="label"
                                  onClick={(e: any) => inputFile.current.Click}
                                >
                                  {(k?.IcPhotoFront  && change === false) || uploadFile?.uploadFileFront !== ""
                                    ? (
                                      <>
                                      <Box
                                        boxShadow="0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)"
                                        style={{ borderRadius: '5px' }}
                                        className='margin-auto'
                                      >
                                        <img
                                          src={uploadFile?.uploadFileFront === ""  ? k?.IcPhotoFront?.ImageURL : uploadFile?.uploadFileFront}
                                          alt="empty"
                                          loading="lazy"
                                          height="100px"
                                          width="130px"
                                          style={{ borderRadius: "5px" }}
                                        />
                                      </Box>
                                   {/* {  handleUploadFile("Front", k?.IcPhotoFront)} */}
                                     </>

                                    ) : (
                                      <Box
                                        display="flex"
                                        component="span"
                                        width="130px"
                                        height="100px"
                                        style={{ borderRadius: '5px' }}
                                        sx={{ border: '1px dashed grey' }}
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <AddBoxIcon style={{ color: '#ff9800' }} />
                                      </Box>
                                    )
                                  }
                                  <input
                                    hidden
                                    ref={inputFile}
                                    type="file"
                                    accept="image/jpeg, image/png"
                                    onChange={async (e: any) => {  
                                      console.log(e,"apa");
                                                                          
                                      const newArr = localState?.Guest
                                      let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                      const file = e.target.files[0];
                                      if (!file.type.startsWith('image/')) {
                                        snackFunc('Invalid File Format', false);
                                        return;
                                      }
                                      let OCRhelper2 = await OCRhelper(file);
                                      select.IcPhotoFront = OCRhelper2.Blob
                                      select.icphotoFront = OCRhelper2.Blob
                                      handleUploadFile("Front", e);
                                      setUploadFile({
                                        ...uploadFile,
                                        uploadFileFront: OCRhelper2.picture,
                                      });
                                      attachmentFiles.push({
                                        Type: "IcPhotoFront",
                                        ImgUrl: k?.IcPhotoFront
                                      });

                                      dispatch({ type: "Guest", payload: newArr })

                                    }
                                  }
                                  />
                                </Box>
                              </Box>
                              <Box width="50%" mb="16px" >
                                <Box
                                  display="flex"
                                  width="130px"
                                  height="100px"
                                  component="label"
                                  onClick={(e: any) => inputFile.current.Click}
                                >
                                  {(k?.IcPhotoBack && change === false) || uploadFile?.uploadFileBack !== "" 
                                    ? (
                                      <Box
                                        boxShadow="0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)"
                                        style={{ borderRadius: '5px' }}
                                        className='margin-auto'
                                      >
                                        <img
                                          src={uploadFile?.uploadFileBack === "" ? k?.IcPhotoBack?.ImageURL : uploadFile?.uploadFileBack }
                                          alt="empty"
                                          loading="lazy"
                                          height="100px"
                                          width="130px"
                                          style={{ borderRadius: "5px" }}
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        display="flex"
                                        width="130px"
                                        height="100px"
                                        style={{ borderRadius: '5px' }}
                                        sx={{ border: '1px dashed grey' }}
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <AddBoxIcon style={{ color: '#ff9800' }} />
                                      </Box>
                                    )
                                  }
                                  <input
                                    hidden
                                    ref={inputFile}
                                    type="file"
                                    accept="image/jpeg, image/png"
                                    onChange={async (e: any) => {
                                      const newArr = localState?.Guest
                                      let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                      const file = e.target.files[0];
                                      if (!file.type.startsWith('image/')) {
                                        snackFunc('Invalid File Format', false);
                                        return;
                                      }
                                      let OCRhelper2 = await OCRhelper(file);
                                      select.IcPhotoBack = OCRhelper2.Blob
                                      select.icphotoBack = OCRhelper2.Blob
                                      setUploadFile({
                                        ...uploadFile,
                                        uploadFileBack: OCRhelper2.picture,
                                      });
                                      attachmentFiles.push({
                                        Type: "IcPhotoBack",
                                        ImgUrl: state?.IcPhotoBack
                                      });
                                      handleUploadFile("Back", e);
                                      dispatch({ type: "Guest", payload: newArr })

                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    defaultChecked
                                    checked={check}
                                    onChange={() => {
                                      check === true
                                        ? setCheck(false)
                                        : setCheck(true)
                                      const newArr = localState?.Guest
                                      let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                      select.IsTerm = !check
                                      if (select.blob === null) {
  
                                        select.SignCanvas = signCanvas.current?.clear();
                                        select.SignatureUrl = signCanvas.current?.clear()
                                        select.blob = signCanvas.current?.clear()
                                      }
                                      dispatch({ type: "Guest", payload: newArr })
                                    }}
                                  />
                                }
                                label={
                                  <Typography fontSize="13px" fontWeight={500}>
                                    I acknowledge and accept the hotel{" "}
                                    <Typography
                                      color='#ff9800'
                                      fontSize="13px"
                                      fontWeight={500}
                                      component="span"
                                      style={{ textDecoration: "underline", cursor: "pointer" }}
                                      onClick={() => handleOpenTerm("T&C")}
                                    >
                                      Terms and Condition
                                    </Typography>
                                  </Typography>
                                }
                              />
                              <TermsDialog 
                                HotelDetails={HotelDetails} 
                                openTerm={openTerm} 
                                handleCloseTerm={handleCloseTerm}
                                type={dialogType}
                              />
                            </FormGroup>
                            <FormGroup sx={{ mb: "10px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    defaultChecked
                                    checked={checkPDPA}
                                    onChange={() => {
                                      checkPDPA === true
                                        ? setCheckPDPA(false)
                                        : setCheckPDPA(true)

                                      const newArr = localState?.Guest
                                      let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                      select.IsPDPA = !checkPDPA
                                      console.log(checkPDPA, 'checkPDPA')
                                      dispatch({ type: "Guest", payload: newArr })
                                    }}
                                  />
                                }
                                label={
                                  <Typography fontSize="13px" fontWeight={500}>
                                    I acknowledge and accept the hotel{" "}
                                    <Typography
                                      color='#ff9800'
                                      fontSize="13px"
                                      fontWeight={500}
                                      component="span"
                                      style={{ textDecoration: "underline", cursor: "pointer" }}
                                      onClick={() => handleOpenTerm("PDPA")}
                                    >
                                      PDPA
                                    </Typography>
                                  </Typography>
                                }
                              />
                              <TermsDialog 
                                HotelDetails={HotelDetails} 
                                openTerm={openTerm} 
                                handleCloseTerm={handleCloseTerm}
                                type={dialogType}
                              />
                            </FormGroup>
                            <Box width="100%" display="flex" >
                              <Typography fontSize="12px" fontWeight={100} color="grey">
                                E-Signature
                              </Typography>
                            </Box>
                            <FormGroup sx={{ flexDirection: 'row-reverse' }}>
                              <div onClick={() => {
                                const newArr = localState?.Guest
                                let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]

                                select.SignCanvas = signCanvas.current?.clear()
                                select.SignatureUrl = signCanvas.current?.clear()
                                select.blob = signCanvas.current?.clear()
                                dispatch({ type: "Guest", payload: newArr })
                              }}>
                                <FormControlLabel
                                  control={
                                    <RefreshIcon
                                      style={{ fontSize: "12px", color: "grey" }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      fontSize="12px"
                                      color="grey"
                                    >
                                      Clear
                                    </Typography>
                                  }
                                />
                              </div>
                            </FormGroup>
                            <Box
                              height="100px"
                              display="flex"
                              mb="50px"
                              style={{ borderRadius: '5px', borderColor: 'grey' }}
                              sx={{ border: 1 }}
                              justifyContent="center"
                              alignItems="center"
                            >

                              {k?.Gallery?.find(y => y?.ID === k?.SignatureUrl)?.ImageURL || k?.SignatureUrl ?
                                (
                                  <img
                                    src={k?.Gallery?.find(y => y?.ID === k?.SignatureUrl)?.ImageURL || k?.SignatureUrl}
                                    alt={`IMG`}
                                    loading="lazy"
                                    style={{ objectFit: 'cover' }}                                  />
                                )
                                : <SignaturePad
                                  ref={ref => {
                                    signCanvas.current = ref
                                  }}
                                  onBegin={() => {
                                    signCanvas.current = signCanvas.current
                                    // if (signCanvas.current) {
                                    //   signCanvas.current = null
                                    // } else {
                                    //   signCanvas.current = signCanvas.current
                                    // }
                                  }}
                                  onEnd={() => {
                                    const newArr = localState?.Guest
                                    let select = newArr?.filter(x => x?.SharerNo === state?.SharerNo)[0]?.ContactInfo[index2]
                                    var contentType = "png"
                                  let signUrl = signCanvas.current?.getTrimmedCanvas().toDataURL('image/png')
                                  var img = signUrl.slice(22)
                                  const blob = b64toBlob(img, contentType, 512)
                                  blob['name'] = 'Signature' + '.png'
                                  blob['lastModified'] = Date.UTC(
                                    dateFormat(new Date(), 'yyyy'),
                                    dateFormat(new Date(), 'mm'),
                                    dateFormat(new Date(), 'dd')
                                  )
                                  select.IsSignature = true
                                  select.SignCanvas = signCanvas.current
                                  select.SignatureUrl = signCanvas.current?.getTrimmedCanvas().toDataURL('image/png')
                                  select.blob = blob
                                  dispatch({ type: "Guest", payload: newArr })
                                }}
                                canvasProps={{
                                  className: 'm-b-8',
                                  style: { width: "100%", height: "100%" }
                                }}
                              />
                              }
                            
                            </Box>
                            {index2 === (x?.ContactInfo?.length - 1) ? (
                              <Box style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                                <Button
                                  onClick={() => {
                                    if (BookingStatus === true) {
                                    snackFunc(`Cannot save information. Booking already ${Status}`, false);
                                    } else {
                                       onSubmit(x.SharerNo)
                                    }
                                  }}
                                  disabled={
                                    !k.FullName || !k.Email || !k.NRIC || !k.BirthDate || !k.MobileNo || !k.Nationality || 
                                    !k.Address?.address || !k.Address?.city || !k.Address?.postCode || !k.Address?.state ||
                                    !k.Address?.country || !k.IcPhotoFront || !k.IcPhotoFront || !k.blob || k.IsPDPA === false || k.IsTerm === false ||
                                    (checkNationality(index2) === 'Malaysian' && k?.NRIC !== null && !ValidateDob(k?.NRIC)?.getDate())
                                  }
                                  sx={{
                                    backgroundColor: !k.FullName || !k.Email || !k.NRIC || !k.BirthDate || !k.MobileNo || !k.Nationality ||
                                      !k.Address?.address || !k.Address?.city || !k.Address?.postCode || !k.Address?.state || !k.Address?.country ||
                                      !k.IcPhotoFront || !k.IcPhotoFront || !k.blob || k.IsPDPA === false || k.IsTerm === false ||
                                      (checkNationality(index2) === 'Malaysian' && k?.NRIC !== null && !ValidateDob(k?.NRIC)?.getDate())  
                                        ? "#D3D3D3" : '#ff9800',
                                    "&:hover": {
                                      backgroundColor: '#ff9800',
                                    },
                                    "&:active": {
                                      backgroundColor: '#ff9800',
                                    },

                                    paddingLeft: '40px',
                                    paddingRight: '40px',
                                  }}
                                >
                                  <Typography style={{ color: "#ffffff" }}>
                                    Next
                                  </Typography>
                                </Button>
                              </Box>
                            ) : null}


                          </Grid>
                        </Grid>
                      </Box>
                    )
                  })
                  }
                </CardContent>
              </Collapse>
            </Card>
            <SnackBarMsg open={openSnackBar} message={snackBarMessage} />

          </div>
        )
      })}
    </>
  );

}
