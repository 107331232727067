import {
  Box,
  List,
  styled,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from '@mui/material/Grid';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import React, { Reducer, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Loading from "../Components/Loading/Loading";
import { useGuestAppFeatureQuery, useHotelDetailsQuery, usePreRegBookingLazyQuery } from '../generated/graphql';
import { MainWrapper } from '../Pages/MainWrapper';
import { CheckInForm } from './FormComponent/CheckInForm';
import { CheckInView } from './FormComponent/CheckInView';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
interface Props {
  Guest: any
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface IAction<TType = string, TPayload = any> {
  type: TType;
  payload: TPayload;
}

export default function PreCheckIn() {
  const dateFormat = require("dateformat");
  const location = useLocation();
  const localstate: any = location.state;
  const history = useNavigate();
  // const URLHotelID = "4cbd20d7-d2d0-47ea-a006-3703f65664cc"
  // const URLBookingID = "81345268-a509-4627-a892-09670ce0901b"

  const queryParameters = new URLSearchParams(window.location.search);
  const URLBookingID = queryParameters.get("Booking") as string;
  const URLHotelID = queryParameters.get("Hotel") as string;


  const initialState: Props = {
    Guest: []
  }


  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case "CloseDialog":
        return { ...state, CancelDialog: false };
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const {
    loading: HotelLoadings,
    data: { HotelDetails } = { HotelDetails: null },
  } = useHotelDetailsQuery({
    variables: {
      HotelID: URLHotelID,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: GuestAppFeatureLoadings,
    data: { GuestAppFeature } = { GuestAppFeature: null },
  } = useGuestAppFeatureQuery({
    variables: {
      HotelID: URLHotelID,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      localStorage.setItem('IsOnlinePayment', data.GuestAppFeature.OnlinePayment as any)
    }
  })

  const [
    PreRegInfo,
    {
      loading: Loadings,
      data: { PreRegBooking } = {
        PreRegBooking: []
      },
    },
  ] = usePreRegBookingLazyQuery({

    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (URLHotelID !== null && URLBookingID !== null) {
      PreRegInfo(
        {
          variables: {
            HotelID: URLHotelID,
            BookingID: URLBookingID,
          },
        }
      )

      localStorage.removeItem('SelectedRoom')
      localStorage.removeItem('ReceiptNo')
      localStorage.removeItem('CheckOutID')
    }
  }, [URLHotelID, URLBookingID])

  const [state, dispatch] = useReducer(reducer, initialState)
  const [guest, setGuest] = useState([] as any)

  const currentDate = new Date();
  // Assuming GuestAppFeature is an object containing PreCheckinAdvTime as a number representing hours
  const preCheckinAdvTimeHours = GuestAppFeature?.PreCheckinAdvTime || 0; // Default to 0 if GuestAppFeature or PreCheckinAdvTime is not available
  const futureDate = new Date(currentDate.getTime() + (preCheckinAdvTimeHours * 60 * 60 * 1000)); // Add PreCheckinAdvTime hours in milliseconds
  const formattedDateTime = futureDate.toISOString();
  
console.log(formattedDateTime, "123")
  

  let arr: any = []
  useEffect(() => {
    PreRegBooking.result?.map((t: any) => {
      arr.push({
        ID: t.RoomType,
        SharerNo: t.SharerNo,
        IsExpanded: false,
        MaxGuest: t.Registrations[0]?.RoomType?.MaxGuest

      })
    })
    setExpanded(arr)
  }, [PreRegBooking])

  const [expanded, setExpanded] = useState(arr)
  const handleExpandClick = (id: any) => {
    let NewArray = expanded.map((f: any) => {
      if (f.SharerNo === id) {
        return {
          ID: f.ID,
          SharerNo: f.SharerNo,
          IsExpanded: f.IsExpanded === true ? false : true,
          MaxGuest: f.MaxGuest
        }
      }
      else {
        return {
          ...f
        }
      }
    })
    setExpanded(NewArray);
  };
  const SortRegBySharerNo = PreRegBooking?.result?.sort((a, b) =>
    a.Registrations[0]?.SharerNo - b.Registrations[0]?.SharerNo
  );
  useEffect(() => {
    let GuestArr: any = []

    if (PreRegBooking && guest.length === 0) {
      SortRegBySharerNo?.map((t: any, i: number) => {
        GuestArr.push({
          ContactInfo: t.Registrations.map((y: any, i2: number) => {
            console.log(y,"REGISTRATION");
            return {
              // ...y,
              Registration: y?.ID,
              ContactID: y?.Guest?.ContactID,
              ArrivalDate: y?.ArrivalDate,
              DepartureDate: y?.DepartureDate,
              Order: i2 + 1,
              RoomType: t.RoomType,
              RoomDescription: y.RoomType?.Description,
              OldFullName: y.Guest?.Contact?.FullName,
              OldNRIC: y.Guest?.Contact?.NRIC,
              FullName: y.Guest?.Contact?.FullName,
              Email: y.Guest?.Contact?.Email,
              NRIC: y.Guest?.Contact?.NRIC,
              BirthDate: y.Guest?.Contact?.BirthDate,
              MobileNo: y.Guest?.Contact?.MobileNo,
              Nationality: y.Guest?.Contact?.Nationality,
              Address: y.Guest?.Contact?.Address,
              IcPhotoFront: y.Guest?.ICGallery,
              icphotoFront: '',
              IcPhotoBack:  y.Guest?.ICBackGallery,
              icphotoBack: '',
              IsTerm: false,
              IsPDPA: false,
              IsSignature: false,
              SignatureUrl: y?.Signature,
              blob:  null,
              SpecialRequest: y?.SpecialRequest,
              Gallery: y?.Gallery
            }
          }),
          RoomType: t.RoomType,
          SharerNo: t.SharerNo,
          IsPreCheckIn: t.IsPreCheckIn,
        })
      })
      setGuest(GuestArr)
      dispatch({ type: "Guest", payload: GuestArr })

    }
  }, [PreRegBooking])

  const [maxguest, setMaxGuest] = useState()

  const handlePlus = (i: any, index: any) => {
    let noguest = state?.Guest.find((gg: any) => gg.SharerNo === i)?.ContactInfo?.length
    const maxGuest = expanded.find((ex: any) => ex.SharerNo === i)?.MaxGuest;
    setMaxGuest(maxGuest)
    if (noguest >= maxGuest) {
      return false;
    }

    const newArr = state?.Guest

    let select = newArr?.filter(x => x.SharerNo === i)[0]
    const obj = {

      RoomType: select.RoomType,
      Order: noguest + 1,
      Mode: 'add',
      SharerNo: select.SharerNo,
      FullName: '',
      Email: '',
      NRIC: '',
      BirthDate: new Date(),
      MobileNo: '',
      Nationality: '',
      Address: {},
      SpecialRequest: '',
      IcPhotoFront: '',
      icphotoFront: '',
      IcPhotoBack: '',
      icphotoBack: '',
      IsTerm: false,
      IsPDPA: false,
      IsSignature: false,
      SignatureUrl: null,
      blob: null,
    }
    select.ContactInfo.push(obj)
    dispatch({ type: "Guest", payload: newArr })
    const result = state?.Guest?.map(((g: any) => {
      if (g.SharerNo === i) {
        return {
          ContactInfo: [{
            RoomType: g.RoomType,
            Order: noguest + 1,
            Mode: 'add',
            SharerNo: g.SharerNo,
            FullName: '',
            Email: '',
            NRIC: '',
            BirthDate: new Date(),
            MobileNo: '',
            Nationality: '',
            Address: {},
            SpecialRequest: '',
            IcPhotoFront: '',
            icphotoFront: '',
            IcPhotoBack: '',
            icphotoBack: '',
            IsTerm: false,
            IsPDPA: false,
            IsSignature: false,
            SignatureUrl: null,
            blob: null,
          }, ...g.ContactInfo]
          ,
          RoomType: g.RoomType,
          SharerNo: g.SharerNo,
          IsPreCheckIn: g.IsPreCheckIn,
        }
      }
      else {
        return {
          ...g
        }
      }
    }))
    setGuest(result.map((sort: any) => {
      return {
        ...sort,
        ContactInfo: sort?.ContactInfo?.sort((a: any, b: any) => a.Order - b.Order)
      }
    }))
  }

  const handleMinus = (SharerNo: any, index: any) => {
    const ContactInfoLength: number = state?.Guest?.find((y: any) => y?.SharerNo === SharerNo)?.ContactInfo?.length
    const UpdatedList = state?.Guest?.find((z: any) => z.SharerNo === SharerNo)?.ContactInfo.filter((item: any) => item.Order !== ContactInfoLength)
    const AllGuest = state?.Guest?.map((g: any) => {
      if (g.SharerNo === SharerNo) {
        return {
          ...g,
          ContactInfo: UpdatedList
        }
      }
      else {
        return {
          ...g
        }
      }

    })
    dispatch({ type: "Guest", payload: AllGuest })
    setGuest(AllGuest)
  }
  
console.log(PreRegBooking,"meow");

  const dateControl =  !GuestAppFeatureLoadings && (formattedDateTime < PreRegBooking?.ArrivalDate )

  const BookingStatus =  PreRegBooking?.BookingStatus=== "Cancelled" || PreRegBooking?.BookingStatus==="NoShow" || PreRegBooking?.BookingStatus=== "CheckOut" || PreRegBooking?.BookingStatus === "Inhouse"

  const BookingNow = PreRegBooking?.BookingStatus

  if (!URLHotelID || !URLBookingID || dateControl || BookingStatus === true ) {
    history("/missing-booking-info");
  }
  return HotelLoadings || Loadings || GuestAppFeatureLoadings ? <Loading /> : (
    <>

      <MainWrapper>

        <Box
          sx={{ p: 2 }}
          justifyContent="center"
          alignItems="center"
          width={"800px"}
          margin="auto"
          bgcolor={"#F7FAF7"}
        >
          {" "}

          {/* HEADER */}

          <Grid container spacing={2}>
            <Grid item xs={16}>
              <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
                <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
                  <img
                    src={HotelDetails?.LogoImage?.ImageURL as any}
                    alt={`IMG`}
                    loading="lazy"
                    width="180px"
                  />
                </Box>
              </Box>
              <Typography fontSize="17px" fontWeight={600} mb="8px" align="center">
                Pre Check In
              </Typography>
              <Typography fontSize="13px" fontWeight={500} mb="12px" color="black" align="center">
                Kindly complete the form below
              </Typography>
            </Grid>
          </Grid>

          {/* BOOKING INFO */}

          <Box display="flex" height={20} mb="12px">
            <Typography fontSize="14px" fontWeight={600} >
              Booking Info
            </Typography>
          </Box>

          <Card
            sx={{
              mb: "14px",
              borderRadius: "4px",
              boxShadow:
                "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
            }}
          >
            <CardContent
              sx={{
                ":last-child": { p: "10px" },
              }}
            >
              <Box width="100%" display="flex" mb="10px">
                <Box width="50%" display="flex">
                  <Typography fontSize="14px" fontWeight={500} color="black" align="left">
                    Booking No.
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="14px" fontWeight={500} color="secondary">
                    {PreRegBooking?.BookingNo}
                  </Typography>
                </Box>
              </Box>
              <Box width="100%" display="flex">
                <Box width="50%" display="flex" alignItems={"center"}>
                  <Typography fontSize="14px" fontWeight={500} color="black">
                    No of Room(s)
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="14px" fontWeight={500} color="secondary">
                    {PreRegBooking?.result?.length}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>

          {/* REGISTRATION INFO */}
          <Typography fontSize="14px" fontWeight={600} mb="10px">
            Registration Info
          </Typography>

          <List className="core-list reset-listitem ">
            <div className="rm-padding p-10 ">
              {PreRegBooking?.result?.map((x: any, i: any) => {
                const checkPayment = x?.Registrations?.filter(
                  r => r.IsPrincipal === true)[0]?.AdvancePayment?.filter(
                    adv => adv.PaymentType === 'Online' && adv.PaymentStatus === "Paid")
                    
                return (
                  <>
                    {x?.IsPreCheckIn === true && (checkPayment?.length > 0 || GuestAppFeature?.OnlinePayment === false) ?
                      <CheckInView state={x} index={i} ExpandMore={ExpandMore} expanded={expanded} handleExpandClick={handleExpandClick} HotelDetails={HotelDetails} localState={state} URLHotelID={URLHotelID} URLBookingID={URLBookingID} SharerNo={x?.SharerNo} BookingNo={PreRegBooking?.BookingNo} IsOnlinePayment={GuestAppFeature?.OnlinePayment}/> :
                      <CheckInForm state={x} index={i} ExpandMore={ExpandMore} expanded={expanded} handleExpandClick={handleExpandClick} dispatch={dispatch} HotelDetails={HotelDetails} handlePlus={handlePlus} handleMinus={handleMinus} localState={state} URLHotelID={URLHotelID} URLBookingID={URLBookingID} SharerNo={x?.SharerNo} IsOnlinePayment={GuestAppFeature?.OnlinePayment} BookingStatus={BookingStatus} Status={BookingNow}/>

                    }

                  </>
                )
              })}
            </div>

          </List>
        </Box>
      </MainWrapper>

    </>
  );
}