import React from 'react'
//import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  EmailIcon,
  EmailShareButton,
} from 'react-share'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { 
  DialogActions, 
  DialogContent, 
  Divider, 
  Typography, 
  Button, 
  Dialog, 
  DialogTitle,
  Box
} from '@mui/material';

interface ShareType {
  simple: boolean
  close: any
  Title: string
  ShareTitle: string
  URL: string
  EmailShare?: boolean
  setSimpleEmail?: any
}
const dateFormat = require('dateformat')

export const ShareDialog = (props: ShareType) => {
  const {
    simple,
    close,
    Title,
    URL,
    ShareTitle,
    EmailShare,
    setSimpleEmail,
  } = props

  return (
    <>
      <Dialog open={simple} onClose={close} fullWidth>
        <DialogTitle
          className="dialog-title"
          style={{ fontWeight: "bold", color: "#ff9800", backgroundColor: "#fff5e7", padding: '10px'}}
        >
          <Typography fontSize="15px" fontWeight={600}>
            Share
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '10px' }}>
          <Box display="flex" alignItems="center" justifyContent="center" paddingTop={"10px"}>
            <WhatsappShareButton title={ShareTitle} url={URL}>
              <WhatsappIcon
                style={{ marginRight: '5px' }}
                size={40}
                round={true}
              />
            </WhatsappShareButton>
            <FacebookShareButton title={ShareTitle} url={URL}>
              <FacebookIcon
                style={{ marginRight: '5px' }}
                size={40}
                round={true}
              />
            </FacebookShareButton>
            <TelegramShareButton title={ShareTitle} url={URL}>
              <TelegramIcon
                style={{ marginRight: '5px' }}
                size={40}
                round={true}
              />
            </TelegramShareButton>
            <TwitterShareButton title={ShareTitle} url={URL}>
              <TwitterIcon
                style={{ marginRight: '5px' }}
                size={40}
                round={true}
              />
            </TwitterShareButton>

            {EmailShare && (
              <Button 
                onClick={() => setSimpleEmail(true)}
                style={{ marginBottom: '5px' }}
              >
                <MailOutlineIcon
                  style={{ marginRight: '5px', fontSize: 43, color: 'black' }}
                />
              </Button>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            sx={{
              backgroundColor: "#ff9800",
              "&:hover": {
                backgroundColor: "#ff9800",
              },
              "&:active": {
                backgroundColor: "#ff9800",
              },
            }}
          >
            <Typography style={{ color: "#ffffff", fontWeight: 'bold', fontSize: '13px' }}>
              Close
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
