import React, { useEffect, useState, Reducer, useReducer } from 'react'
import { Box } from '@mui/system'
import { Footer } from '../Components/Footer'
import { Receipt } from './DocumentTemplate/Receipt'
import { IconButton, Typography } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useLocation } from 'react-router-dom'
import AddressConverter from '../Components/helper/addressConverter'
import { useAdvancePaymentDetailsQuery, useEmailSendMutation, useImageUploadMutation } from '../generated/graphql'
import { amtStr } from '../Components/helper/numFormatter'
import Loading from '../Components/Loading/Loading'
import { b64toBlob } from '../Components/Hooks/OCRhelper'
import { ShareDialog } from '../Components/helper/Share'
import { useSnackBar } from '../Components/Hooks/useSnackBar'
import SnackBarMsg from '../Components/SnackBar/SnackBarMsg'

export const PreCheckInDocument = ({type}) => {
  let urlOrigin = new URL(window.location.href)?.origin
  const location = useLocation()
  const localstate: any = location.state
  const dateFormat = require("dateformat")
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()

  const [simple, setSimple] = useState(false)
  const [imgUrl, setimgUrl] = useState(null)
  const [emailShare, setEmailShare] = useState(false)
  const [EmailAddress, setEmailAddress] = useState('')
  const [sendEmail, setSendEmail] = useState(false)

  const {
    loading: AdvancePaymentDetailsLoading,
    data: { AdvancePaymentDetails } = { AdvancePaymentDetails: null },
  } = useAdvancePaymentDetailsQuery({
    variables: {
      HotelID: localstate?.HotelDetails?.ID,
      ID: localstate?.AdvancePaymentID 
    },
    fetchPolicy: 'no-cache',
  })

  const [ImageUpload] = useImageUploadMutation()
  const [
    EmailSend, { loading: loadingInsert }
  ] = useEmailSendMutation()

  const onSubmit = blob => {
    ImageUpload({
      variables: {
        AttachmentInput: blob,
      },
    }).then(i => {
      setimgUrl(i.data!.ImageUpload.ImageURL as any)
    })
  }

  var ReceiptItem = `
    <tr  
      class="room-detail" 
      style="
        color: #212121;
        font-size: 10px;
        line-height: 28px;
        text-align: center;"
    >
      <td style="text-align: center; width: 20%;">
        ${dateFormat(AdvancePaymentDetails?.PaymentDate,'dd/mm/yyyy')}
      </td>
      <td style="text-align: left; line-height: 1.2; ; width: 40%;">
        ${`${AdvancePaymentDetails?.PaymentType} Payment`}
      </td>
      <td style={"width: 20%"}>
        ${!AdvancePaymentDetails?.ReferenceNo 
          ? '' 
          : AdvancePaymentDetails?.ReferenceNo
        }
      </td>
      <td style="text-align: center; width: 20%;">
        ${!AdvancePaymentDetails?.Amount
          ? amtStr(0)
          : amtStr(AdvancePaymentDetails?.Amount)}
      </td>
    </tr>
  `

  var mapObj: any = {
    '!!HotelName!!': localstate?.HotelDetails?.HotelName || '',
    '!!HotelImage!!': localstate?.HotelDetails?.LogoImage
      ? localstate?.HotelDetails?.LogoImage?.ImageURL 
      : '',
    '!!SSTRegNo!!': localstate?.HotelDetails?.SSTRegNo || '',
    '!!HotelMobileNo!!': localstate?.HotelDetails?.Contact?.MobileNo || '',
    '!!HotelEmail!!': localstate?.HotelDetails?.Contact?.BusinessEmail || '',
    '!!HotelWebsite!!': localstate?.HotelDetails?.Contact?.WebUrl || '',
    '!!HotelAddress!!': localstate?.HotelDetails?.HotelAddress
      ? AddressConverter({
          address: localstate?.HotelDetails?.HotelAddress?.address,
          state: localstate?.HotelDetails?.HotelAddress?.state,
          postCode: localstate?.HotelDetails?.HotelAddress?.postCode,
          city: localstate?.HotelDetails?.HotelAddress?.city,
          country: localstate?.HotelDetails?.HotelAddress?.country,
        })
      : '',
    '!!Document!!': 'Receipt',
    '!!ReceiptNo!!': AdvancePaymentDetails?.ReceiptNo || '',
    '!!Date!!': dateFormat(AdvancePaymentDetails?.PaymentDate, 'dd/mm/yyyy'),
    '!!GuestName!!': localstate?.GuestInfo?.FullName || '',
    '!!AgentName!!': '',
    '!!BeneficiaryName!!': '',
    '!!GuestAddress!!': localstate?.GuestInfo?.Address
    ? AddressConverter({
        address: localstate?.GuestInfo?.Address?.address,
        state: localstate?.GuestInfo?.Address?.state,
        postCode: localstate?.GuestInfo?.Address?.postCode,
        city: localstate?.GuestInfo?.Address?.city,
        country: localstate?.GuestInfo?.Address?.country,
      })
    : '',
    '!!IdentityCard!!': localstate?.GuestInfo?.NRIC || '',
    '!!GuestMobileNo!!': localstate?.GuestInfo?.MobileNo || '',
    '!!GuestEmail!!': localstate?.GuestInfo?.Email || '',
    '!!ArrivalDate!!': localstate?.GuestInfo?.ArrivalDate
      ? dateFormat(localstate?.GuestInfo?.ArrivalDate, 'dd/mm/yyyy')
      : '',
    '!!ArrivalTime!!': localstate?.GuestInfo?.ArrivalDate
      ? dateFormat(localstate?.GuestInfo?.ArrivalDate, 'hh:MM:ss TT')
      : '',
    '!!DepartureDate!!': localstate?.GuestInfo?.DepartureDate
      ? dateFormat(localstate?.GuestInfo?.DepartureDate, 'dd/mm/yyyy')
      : '',
    '!!DepartureTime!!': localstate?.GuestInfo?.DepartureDate
      ? dateFormat(localstate?.GuestInfo?.DepartureDate, 'hh:MM:ss TT')
      : '',
    '!!RoomNo!!': localstate?.RoomNo || '',
    '!!BookingNo!!': localstate?.BookingNo,
    '!!CashierName!!': 'Online-Interface',
    '!!ListItem!!': ReceiptItem,
    '!!TotalAmount!!': amtStr(Number(AdvancePaymentDetails?.Amount)),
  }

  var FormContent = type === 'Receipt' 
    ? Receipt.replace(
        /!!HotelName!!|!!HotelImage!!|!!SSTRegNo!!|!!HotelMobileNo!!|!!HotelEmail!!|!!HotelWebsite!!|!!HotelAddress!!|!!Document!!|!!ReceiptNo!!|!!Date!!|!!GuestName!!|!!AgentName!!|!!BeneficiaryName!!|!!GuestAddress!!|!!IdentityCard!!|!!GuestMobileNo!!|!!GuestEmail!!|!!ArrivalDate!!|!!ArrivalTime!!|!!DepartureDate!!|!!DepartureTime!!|!!RoomNo!!|!!BookingNo!!|!!CashierName!!|!!ListItem!!|!!TotalAmount!!/gi,
        function (matched) {
          return mapObj[matched]
        }
      )
    : ''
  
  const printForm = () => {
    var win = window?.open()
    win?.document?.write(FormContent)
    win?.document?.close()
    setTimeout(() => {
      win?.print()
    }, 100)
  }

  const ShareButton = {
    title: `${
      localstate?.HotelDetails?.HotelName
    } has shared you a ${
      type === 'Invoice' ? 'Invoice' :  'Receipt'
    }-${
      type ===  'Invoice' ? AdvancePaymentDetails?.ReceiptNo :  AdvancePaymentDetails?.ReceiptNo
    } please click`,
    url: imgUrl,
    img: `Click Here`,
  }

  useEffect(() => {
    var Email = ''
    Email = !localstate?.GuestInfo?.Email
      ? ''
      : localstate?.GuestInfo?.Email
    if (Email !== '') {
      setEmailShare(true)
      setEmailAddress(Email)
    }
  }, [localstate?.GuestInfo])

  useEffect(() => {
    sendEmail &&
      EmailSend({
        variables: {
          Body: {
            Header: `${ShareButton.title}`,
            Body: FormContent,
          },
          HotelID: localstate?.HotelDetails?.ID,
          Email: EmailAddress,
        },
      }).then(w => {
        if (w.data!.EmailSend === true) {
          snackFunc('Sent Email Successfully!', false)
          setSimple(false)
        } else {
          snackFunc('Sent Email Fail!', false)
        }
      })
  }, [sendEmail])

  const handleClose = () => {
    setSimple(false)
  }

  return AdvancePaymentDetailsLoading ? <Loading /> : (
    <>
      <Box
        sx={{ p: 2 }}
        justifyContent="center"
        alignItems="center"
        width={"770px"}
        margin="auto"
        bgcolor={"#F7FAF7"}
      >
        <Box display="flex" marginBottom={'15px'} alignItems={'center'} paddingTop={'10px'}>
          <IconButton 
            style={{ padding: '0px', color: 'black'}}
            onClick={() => {
              window.location.href = `
                ${urlOrigin}/pre-checkin/form?Hotel=${localstate?.HotelDetails?.ID}&Booking=${localstate?.BookingID}`
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography fontSize="15px" fontWeight={600} paddingLeft={'5px'}>
            {localstate?.GuestInfo?.FullName}
          </Typography>
        </Box>
        <div
          style={{ fontSize: 12 }}
          dangerouslySetInnerHTML={{
            __html: FormContent,
          }}
        />
        <ShareDialog
          ShareTitle={ShareButton.title}
          Title={`${
            localstate?.HotelDetails?.HotelName
          } has shared you a ${
            type === 'Invoice' ? 'Invoice' :  'Receipt'
          }-${
            type ===  'Invoice' ? AdvancePaymentDetails?.ReceiptNo :  AdvancePaymentDetails?.ReceiptNo
          }`}
          URL={ShareButton.url as any}
          close={handleClose}
          simple={simple}
          EmailShare={emailShare}
          setSimpleEmail={setSendEmail}
        />
      </Box>
      <Footer
        primary={true}
        options={[
          {
            name: 'Share',
            onClick: () => {
              var contentType = 'text/html'
              var encodedStringBtoA = btoa(FormContent)
              const blob = b64toBlob(encodedStringBtoA, contentType, 512)
              blob['name'] = type  + '.html'
              blob['lastModified'] = Date.UTC(
                dateFormat(new Date(), 'yyyy'),
                dateFormat(new Date(), 'mm'),
                dateFormat(new Date(), 'dd')
              )
              blob['lastModifiedDate'] = new Date()
              setSimple(true)
              onSubmit(blob)
            },
          },
          {
            name: 'Print',
            onClick: () => {printForm()},
          },
        ]}
      />
      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
    </>
  )
}