import { Accordion, AccordionDetails, AccordionSummary, List, makeStyles } from '@material-ui/core'
import ExpansionPanel, {
  ExpansionPanelProps,
} from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, IconButton } from '@mui/material';
import React from 'react';

interface IExpansion extends ExpansionPanelProps {
  children: any
  summary?: any
  expansion?: boolean
  expanded?: any
  setExpanded?: any
  iconDisabled?: any
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&::before': {
      background: 'none',
    },
    '&.Mui-expanded': {
      margin: '0px',
      minHeight: 'unset',
    },
    borderRadius: '5px',
    boxShadow:
      '0 3px 1px -2px transparent, 2px 4px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)',
  
    },
  summary: {
    height: '35px',
    borderRadius: '5px',
    minHeight: 'unset',
    '&.Mui-expanded': {
      margin: '0px',
      minHeight: 'unset',
    },
    '& > .MuiIconButton-root': {
      padding: '0px 2px 0px 5px',
    },
    color: 'black',
    '& > .MuiAccordionSummary-content': {
      display: 'block',
    },
    
  },
  details: {
    borderTop: '1px solid #ccc',
    padding: '8px 16px',
    display: 'block'
  },
}));

export default function CardExpansion({
  summary,
  children,
  expansion,
  expanded,
  setExpanded,
  iconDisabled,
  ...props
}: IExpansion) {

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setChange(isExpanded ? panel : false)
  }

  const classes = useStyles();

  const setChange = (data) => {
    if (data === 'panel1') {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }

  return (
    <div>
      <Accordion
        {...props}
        elevation={0}
        onClick={(e) => e.stopPropagation()}
        expanded={expanded}
        onChange={handleChange('panel1')}
        className={classes.root}
      >
        <AccordionSummary 
          disabled={iconDisabled}
          expandIcon={
            <ExpandMoreIcon style={{ color: 'black' }} /> 
          }
          className={classes.summary}
        >
          <List onClick={() => handleChange('panel1')}>{summary}</List>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}