import {
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { Reducer, useContext, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router";
import { Footer } from '../../Components/Footer';
import CardExpansion from './CardExpansion';
import { 
  usePreRegRoomAllocationQuery, 
  useRoomAssignListingLazyQuery
} from "../../generated/graphql";
import Loading from "../../Components/Loading/Loading";
import { FabricContextProvider } from "../FloorPlan/FabricContext";
import { PreRegFloorPlan } from "../FloorPlan/PreRegFloorPlan";
import { SocketContext } from "../../Pages/Socket/SocketContext";

interface Props {
  selectedFloor: any,
  selectedRoom: any,
  choosenRoom: any,
}

export const AssignRoom = ({
  activeStep,
  setActiveStep,
  roomSelect,
  setRoomSelect,
  RegistrationDetails,
  UpdateRoomAssign,
}) => {

  interface IAction<TType = string, TPayload = any> {
    type: TType;
    payload: TPayload;
  }

  const initialState: Props = {
    selectedFloor: "",
    selectedRoom: [],
    choosenRoom: ""
  }

  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case "reset":
        return initialState;
      default:
        return { ...state, [action.type]: action.payload };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [FloorListing, setFloorListing] = useState([])
  const [expanded, setExpanded] = useState(false)
  const socket: any = useContext(SocketContext)

  const setChange = (data) => {
    if (data === 'panel1') {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }

  const {
    refetch: roomRefetch,
    loading: PreRegRoomAllocationLoadings,
    data: { PreRegRoomAllocation } = { PreRegRoomAllocation: [] },
  } = usePreRegRoomAllocationQuery({
    variables: {
      HotelID: RegistrationDetails?.Booking?.HotelID,
      ArrivalDate: RegistrationDetails?.ArrivalDate,
      RoomTypeID: RegistrationDetails?.RoomTypeID
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      RoomAssignListingQuery({
        variables: {
          HotelID: RegistrationDetails?.Booking?.HotelID,
          StartDate: RegistrationDetails?.ArrivalDate,
          EndDate: RegistrationDetails?.DepartureDate,
          RoomTypeID: RegistrationDetails?.RoomTypeID
        },
      })
    },
  })

  const [
    RoomAssignListingQuery,
    {
    refetch: roomAssignRefetch,
    loading: RoomAssignListingLoadings,
    data: { RoomAssignListing } = { RoomAssignListing: [] },
  }] = useRoomAssignListingLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      let filteredLocation: any = []
      let RoomAssignListings: any = data?.RoomAssignListing
      if (PreRegRoomAllocation?.length > 0 && RoomAssignListings?.length > 0) {
        const existRoom = JSON.parse(localStorage.getItem('SelectedRoom') as any)?.ID
        const existRoomLoc = JSON.parse(localStorage.getItem('SelectedRoom') as any)?.Location?.ID

        PreRegRoomAllocation?.map(preRoom => {
          let filteredRoom: any = [] 
          preRoom?.Room.map(room => {
            
            const checkRoom = RoomAssignListings?.filter(r => r?.ID === room?.Room?.ID)

              filteredRoom.push({
                ...checkRoom[0],
                IsSelected: false
              })
          })          

          if (existRoom && existRoomLoc === preRoom?.LocationID) {
            filteredRoom.push(JSON.parse(localStorage.getItem('SelectedRoom') as any))
            dispatch({ 
              type: 'choosenRoom', 
              payload: true
            })
          }
  
          if (filteredRoom?.filter(s => s.ID !==undefined ) && filteredRoom?.length > 0) {
            const obj = {
              Location: preRoom?.Location,
              ID: preRoom?.LocationID,
              ImageURL: preRoom?.ImageURL,
              Room: filteredRoom?.filter(s => s.ID !==undefined ),
              TotalRoom: filteredRoom?.filter(s => s.ID !==undefined )?.length
            }
    
            filteredLocation.push(obj)
          }
        })
        setFloorListing(filteredLocation)
        if (existRoom) {
          dispatch({ 
            type: 'selectedFloor', 
            payload: filteredLocation?.filter(x => x?.ID === existRoomLoc)[0]
          })
          dispatch({ 
            type: 'selectedRoom', 
            payload: filteredLocation?.filter(x => x?.ID === existRoomLoc)[0]?.Room})
        } else {
          dispatch({ type: 'selectedFloor', payload: filteredLocation[0] })
          dispatch({ type: 'selectedRoom', payload: filteredLocation[0]?.Room })
        }        
        
      }
    }
  })

  const handleRoomAssign = () => {
    UpdateRoomAssign({
      variables: {
        BookingID: RegistrationDetails?.Booking?.ID,
        RegistrationID: RegistrationDetails?.ID,
        RoomID: state?.selectedRoom?.filter(x => x.IsSelected === true)[0]?.ID,
        RoomTypeID: RegistrationDetails?.RoomTypeID
      }
    })

    setRoomSelect(state?.selectedRoom?.filter(x => x.IsSelected === true)[0])
    localStorage.setItem('SelectedRoom', JSON.stringify(state?.selectedRoom?.filter(x => x.IsSelected === true)[0]))
    setActiveStep(activeStep + 1)
  }

  useEffect(() => {
    if (socket) {
      socket.on('notification', (notification) => {
        const IsRoomType = notification?.roomType?.filter(x => x === RegistrationDetails?.RoomTypeID)

        if (IsRoomType?.length > 0) {
          roomRefetch({
            HotelID: RegistrationDetails?.Booking?.HotelID,
            ArrivalDate: RegistrationDetails?.ArrivalDate,
            RoomTypeID: RegistrationDetails?.RoomTypeID
          })
          roomAssignRefetch({
            HotelID: RegistrationDetails?.Booking?.HotelID,
            StartDate: RegistrationDetails?.ArrivalDate,
            EndDate: RegistrationDetails?.DepartureDate,
            RoomTypeID: RegistrationDetails?.RoomTypeID
          })
        }
       
      });
    }
  }, [socket])

  return (
    PreRegRoomAllocationLoadings ||
    RoomAssignListingLoadings
  ) ? <Loading /> : (
    <>
      <Box>
        <Box width="100%" display="flex">
          <Typography fontSize="14px" fontWeight={600} mb={'5px'}>
            Choose Floor
          </Typography>
        </Box>
        <CardExpansion
          style={{ margin: "0 0 10px 0" }}
          expanded={expanded}
          setExpanded={setExpanded}
          iconDisabled={state?.choosenRoom !== ""}
          summary={
            <ListItemText sx={{ display: 'block' }}
              primary={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography fontSize="12px" style={{ fontWeight: 'bold' }}>
                    {state?.selectedFloor?.Location}
                  </Typography>
                  <Typography fontSize="12px" style={{ fontWeight: 'bold', color: '#00bf0f'}}>
                    {state?.selectedFloor?.TotalRoom} Rooms
                  </Typography>
                </div>
              }
            />
          }
        >
          <Box style={{ display: 'block' }}>
            {FloorListing?.map((el: any, index) => {
              return (
                <List disablePadding key={index}>
                  <ListItem disablePadding
                    onClick={() => {
                      dispatch({
                        type: 'selectedFloor', 
                        payload: el
                      })
                      dispatch({
                        type: 'selectedRoom', 
                        payload: el?.Room
                      })
                      setChange(false)
                    }}
                  >
                    <ListItemText
                      primary={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography fontSize="12px" style={{ fontWeight: 'bold' }}>
                            {el?.Location}
                          </Typography>
                          <Typography fontSize="12px" style={{ fontWeight: 'bold', color: '#00bf0f', paddingRight: '18px'}}>
                            {el?.TotalRoom} Rooms
                          </Typography>
                        </div>
                      }
                    />
                  </ListItem>
                </List>
              )
            })}
          </Box>
        </CardExpansion>
        <Box 
          style={{
            position: 'fixed', 
            top: '225px', 
            zIndex: -1,
          }}
        >
          <Typography fontSize="14px" fontWeight={600} mb={'5px'}>
            Choose Room
          </Typography>
        </Box>
        <Box 
          style={{ 
            position: 'fixed', 
            top: '250px',
            width: '85%',
            maxWidth: '770px',
            paddingTop: '5px',
            zIndex: expanded === true ? -1 : 0,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              width: '100%',
              height: '100%',
              maxWidth: '750px'
            }}
          >
            <FabricContextProvider>
              <PreRegFloorPlan
                state={state}
                dispatch={dispatch}
              />
            </FabricContextProvider>
          </Box>
        </Box>
      </Box>
      <Footer
        primary={true}
        input={state?.selectedRoom?.filter(x => x.IsSelected === true)?.length > 0 ? true : false}
        inputData={state?.selectedRoom?.filter(x => x.IsSelected === true)[0]?.RoomNo}
        options={[
          {
            name: 'Next',
            onClick: () => { handleRoomAssign() },
            disabled: state?.selectedRoom?.filter(x => x.IsSelected === true)?.length === 0
          },
        ]}
      />
    </>
  )
}