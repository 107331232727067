import React, { createContext, useState, useEffect } from 'react';
import io from 'socket.io-client';

export const SocketContext = createContext(null); // Use null as the initial value
export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  let hotelxSocketUrls;
  switch (process.env.REACT_APP_API_URL) {
    case 'dev-build':
      hotelxSocketUrls = ['https://hotelx-dev-api.ifca.io'];
      break;
    case 'prod-build':
      hotelxSocketUrls = ['https://hotelxapi.hotelx.asia'];
      break;
    // case 'hms1-prod-build':
    //   hotelxSocketUrls = ['https://hotelxapi.hotelx.asia','https://hotelx1api.hotelx.asia'];
    //   break;
    // case 'hms2-prod-build':
    //   hotelxSocketUrls = ['https://hotelxapi.hotelx.asia','https://hotelx2api.hotelx.asia'];
    //   break;
    // case 'hms3-prod-build':
    //   hotelxSocketUrls = ['https://hotelxapi.hotelx.asia','https://hotelx3api.hotelx.asia'];
    //   break;
    // case 'promenade-build':
    //   hotelxSocketUrls = ['https://hotelxapi-promenade.hotelx.asia'];
    //   break;
    // case 'promenade-uat-build':
    //   hotelxSocketUrls = ['https://hotelxapi-promenade-uat.hotelx.asia'];
    //   break;
    case 'uat-build':
      hotelxSocketUrls = ['https://hotelx-uat-api.ifca.io'];
      break;
    default:
      hotelxSocketUrls = ['http://localhost:8000'];
      break;
  }
  let hostUrl
  switch (process.env.REACT_APP_API_URL) {
    case 'dev-build':
      hostUrl = 'https://hotelx-dev-api.ifca.io'
      break
    case 'prod-build':
      hostUrl = 'https://hotelxapi.hotelx.asia'
      break
    case 'hms1-prod-build':
      hostUrl = 'https://hotelx1api.hotelx.asia'
      break
    case 'hms2-prod-build':
      hostUrl = 'https://hotelx2api.hotelx.asia'
      break
    case 'hms3-prod-build':
      hostUrl = 'https://hotelx3api.hotelx.asia'
      break
    case 'promenade-build':
      hostUrl = 'https://hotelxapi-promenade.hotelx.asia'
      break
    case 'promenade-uat-build':
      hostUrl = 'https://hotelxapi-promenade-uat.hotelx.asia'
      break
    case 'uat-build':
      hostUrl = 'https://hotelx-uat-api.ifca.io'
      break
    default:
      hostUrl = 'localhost'
      break
  }


  useEffect(() => {
    const newSocket = hotelxSocketUrls.map(url =>
      
      io(url, { transports: ["polling"], upgrade: false }),     
    );
    console.log(newSocket,"test3");
    
    const socketPort = newSocket?.filter(x => x?._opts?.hostname == hostUrl)[0]


    if (socketPort !== null || socketPort !== undefined) {

      setSocket(socketPort);

    }
    // Cleanup function
    return () => {
      socketPort.disconnect();
    };
  }, []);

  console.log(hotelxSocketUrls,socket, "value");

  return socket !== null || socket !== undefined ? (
    <SocketContext.Provider
      value={
        socket
      }
    >
      {children}
    </SocketContext.Provider>

  ) : (<></>)

};
